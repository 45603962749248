import React from 'react'
import { Box, Image, Pressable, Text, View, useTheme } from 'native-base'
import { Platform, useWindowDimensions } from 'react-native'
import { Link } from '@react-navigation/native'
import _ from 'lodash'
import map from 'lodash/map'

export function PageHeading({ title }) {
  return (
    <Text variant="text-pageHeading" mb={5} style={{ letterSpacing: 0.25 }}>
      {title}
    </Text>
  )
}

export function ResultsHeading({ title, cycle }) {
  return (
    <Text mb={5} style={{ letterSpacing: 0.25 }}>
      <Text fontSize="2xl" variant="text-pageHeading">
        {title}
      </Text>
      {'\n'}
      {'\n'}
      {cycle ? (
        <Text fontSize="2xl" variant="text-pageHeading">
          Cycle {cycle}
        </Text>
      ) : null}
    </Text>
  )
}

export function SectionHeading({ title, ...props }) {
  return (
    <Text
      variant="text-sectionHeading"
      mb={4}
      style={props.style}
      {..._.omit(props, ['style'])}
    >
      {title}
    </Text>
  )
}

export function AuthHeading({ style, heading, subHeading }) {
  return (
    <Box>
      <Text
        fontSize="4xl"
        pb={heading && 3}
        style={style && { color: style.fontColor, letterSpacing: 0.5 }}
        bold
      >
        {heading}
      </Text>
      <Text
        fontSize="3xl"
        style={style && { color: style.fontColor, letterSpacing: 0.5 }}
        pb={subHeading && 4}
        lineHeight={36}
      >
        {subHeading}
      </Text>
    </Box>
  )
}

export function AuthFooter({ data, style, version }) {
  return (
    <Box mb={6} width="100%">
      {map(data, ({ variant, link, text, component, prefix }, i) => (
        <Text
          variant={variant}
          textAlign={style[version]?.textAlign || 'left'}
          style={style && { color: style.fontColor, letterSpacing: 0.5 }}
          underline={link ? true : false}
          key={i}
        >
          {prefix}
          {component ? component : <Link to={{ screen: link }}>{text}</Link>}
        </Text>
      ))}
    </Box>
  )
}

export function CustomAppHeader({ image, text1, text2 }) {
  const { ui } = useTheme()
  const { width: deviceWidth } = useWindowDimensions()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: deviceWidth - 90,
        height: '100%',
      }}
    >
      <Box pr={2}>
        <Image
          source={image}
          height={20}
          width={20}
          borderRadius={ui.borderRadius}
          alt={`${text1} ${text2}`}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Text fontSize="3xl">{text1}</Text>
        <Text fontSize="3xl">{text2}</Text>
      </Box>
    </View>
  )
}

export function buildCustomAppHeader(image, text1, text2) {
  return {
    headerBackTitleVisible: false,
    headerTitle: () => {
      return <CustomAppHeader image={image} text1={text1} text2={text2} />
    },
    headerStyle: {
      height: 200,
    },
  }
}

export function CustomNotificationsHeading({ action }) {
  return (
    <Box mr={4} mt={1} p={2}>
      <Pressable onPress={() => action(true)}>
        <Text>Clear</Text>
      </Pressable>
    </Box>
  )
}

export function buildCustomNotificationHeader(action) {
  return {
    headerRight: () => <CustomNotificationsHeading action={action} />,
  }
}
