import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G } from 'react-native-svg'

export function Trash({ width, height, innerFill }) {
  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G fill={innerFill}>
        <Path d="M444.852 66.908h-99.339V47.04c0-21.943-17.792-39.736-39.736-39.736h-99.339c-21.944 0-39.736 17.793-39.736 39.736v19.868H67.363v19.868h20.47l19.887 377.489c0 21.944 17.792 39.736 39.736 39.736h218.546c21.944 0 39.736-17.792 39.736-39.736l19.538-377.489h19.577V66.908zM186.57 47.04c0-10.962 8.926-19.868 19.868-19.868h99.339c10.962 0 19.868 8.906 19.868 19.868v19.868H186.57V47.04zm199.338 416.196l-.039.505v.524c0 10.943-8.906 19.868-19.868 19.868H147.455c-10.942 0-19.868-8.925-19.868-19.868v-.524l-.019-.523L107.72 86.776h297.669l-19.481 376.46z" />
        <Path d="M246.173 126.511H266.041V444.39599999999996H246.173z" />
        <Path d="M206.884 443.757L186.551 126.493 166.722 127.753 187.056 445.017z" />
        <Path d="M345.649 127.132L325.82 125.891 305.777 443.776 325.606 445.017z" />
      </G>
    </Icon>
  )
}

Trash.defaulProps = {
  width: 20,
  height: 20,
  innerFill: '#FFFFFF',
}

Trash.propTypes = {
  width: number,
  height: number,
}
