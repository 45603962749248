import { useContext } from 'react'
import { CyclesContext } from 'providers/CyclesProvider'
import { IUseCycles } from 'state/interfaces'

export function useCycles(): IUseCycles {
  const context = useContext<IUseCycles>(CyclesContext as any)
  if (context === undefined) {
    throw new Error('useCycles must be used within a CyclesProvider')
  }

  return context
}
