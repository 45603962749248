import React from 'react'
import { ActiveReach } from 'screens/Home/ActiveReach'
import { ICycleStepProps } from 'state/interfaces'

export interface Props extends ICycleStepProps {}

const AudioStep: React.FC<Props> = (props) => {
  return <>{props.open && <ActiveReach {...props} />}</>
}

export default AudioStep
