import React from 'react'
import { string } from 'prop-types'
import { Box, Circle, HStack, Text, useTheme, Skeleton } from 'native-base'
import { Link } from '@react-navigation/native'
import getHours from 'date-fns/getHours'
import { Bell } from 'components/Icons'
import { View } from 'react-native'

import { routeNames } from '../../state/routeNames'

interface GreetingCardProps {
  hasNotification: boolean
  name: string
  loading: boolean
}

export function GreetingsCard({
  hasNotification,
  name,
  loading,
}: GreetingCardProps) {
  let message
  const { colors } = useTheme()
  const hour = getHours(new Date())

  switch (true) {
    case hour < 12:
      message = 'Good Morning'
      break

    case hour >= 12 && hour < 18:
      message = 'Good Afternoon'
      break

    case hour >= 18:
      message = 'Good Evening'
      break

    default:
      message = 'Hello'
      break
  }

  return (
    <Box width="100%" pb={5}>
      <HStack justifyContent="space-between">
        <Box>
          <Text variant="text-Greeting">
            {message}
            {name && ','}
          </Text>
          {!loading ? (
            <Text variant="text-GreetingName">{name && name.trim()}</Text>
          ) : (
            <Skeleton variant="circle" size="8" w={150} />
          )}
        </Box>
        <Link to={{ screen: routeNames.NOTIFICATIONS_NAVIGATOR }}>
          <Box pt={3} pr={1}>
            {hasNotification && (
              <View
                style={{
                  position: 'absolute',
                  left: 100,
                  top: 100,
                  elevation: 2,
                  zIndex: 2,
                }}
                position="absolute"
                left={2}
                top={12}
              >
                <Circle width={2} height={2} bg={colors.salmon[500]} />
              </View>
            )}
            <Bell width={25} height={25} />
          </Box>
        </Link>
      </HStack>
    </Box>
  )
}

GreetingsCard.propTypes = {
  name: string,
}
