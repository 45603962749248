import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import format from 'date-fns/format'

import { routeNames } from '../../../state/routeNames'
import { BackPressable } from '../../../components/BackPressable/BackPressable'
import { dateFromTimestampInLocalTimezone } from '../../../utils/dateHelpers'

import CalendarScreen from './CalendarScreen'
import { CalendarDayScreen } from './CalendarDayScreen'

const { Screen, Navigator } = createStackNavigator()

export function HistoryNavigator(mainprops) {
  return (
    <Navigator
      screenOptions={{
        headerLeft: (props) => (
          <BackPressable mainprops={mainprops} {...props} />
        ),
      }}
    >
      <Screen
        name={routeNames.CALENDAR}
        component={CalendarScreen}
        options={{ headerTitle: 'History' }}
      />
      <Screen
        component={CalendarDayScreen}
        name={routeNames.CALENDAR_DAY}
        options={({ route, navigation }) => {
          return {
            title: format(
              dateFromTimestampInLocalTimezone(route.params.timestamp),
              'MMMM d, yyyy'
            ),
          }
        }}
      />
    </Navigator>
  )
}
