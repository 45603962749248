import React, { useState } from 'react'
import { Box, Text, useTheme, ScrollView, Button, useToast } from 'native-base'
import { AppForm } from 'components/Form'
import { AppToast } from 'components/Toast'
import { AUTH_ERRORS } from 'state/consts'
import { changePasswordConfig } from 'screens/Account/Profile/config/changePasswordConfig'
import { useAuth } from 'state/hooks/useAuth'
import { useNavigation } from '@react-navigation/native'
import { auth, crashlytics } from 'config/firebase'
import set from 'lodash/set'

const ChangePassword = () => {
  const { colors } = useTheme()
  const initialValues = {
    password: '',
    confirmPassword: '',
  }
  const [error, setError] = useState(null)
  const config = set(changePasswordConfig, 'initialValues', initialValues)
  const {
    state: { user },
  } = useAuth()

  const navigation = useNavigation()
  const toast = useToast()

  const style = {
    justifyContent: 'flex-start',
    fontColor: colors.tangaroa[500],
    borderColor: colors.tangaroa[500],
  }

  const reauthenticate = (currentPassword) => {
    var cred = auth.EmailAuthProvider.credential(user.email, currentPassword)
    return user.reauthenticateWithCredential(cred)
  }

  const changePassword = (newPassword, currentPassword) => {
    reauthenticate(currentPassword)
      .then(() => {
        return user.updatePassword(newPassword, currentPassword).then((val) => {
          navigation.navigate('AccountProfile')
        })
      })
      .catch((err) => {
        toast.show({
          placement: 'top',
          status: 'error',
          duration: 4900,
          render: () => (
            <AppToast status="error" message={AUTH_ERRORS[err?.code]} />
          ),
        })
        crashlytics().recordError(err)
        setError(err.message)
      })
  }

  set(config, 'actions.submit.handle', (values) => {
    changePassword(values.confirmPassword, values.currentPassword)
  })

  return (
    <ScrollView>
      <Box px={5}>
        <AppForm config={config} haveError={error} style={style} />
      </Box>
    </ScrollView>
  )
}
export default ChangePassword
