import React, { Suspense, lazy } from 'react'
import { Loading } from 'components/Loading'

export function GlossaryView() {
  const Glossary = lazy(() => import('components/Glossary'))
  return (
    <Suspense fallback={<Loading />}>
      <Glossary />
    </Suspense>
  )
}
