import React from 'react'
import { PageContainer } from 'components/Containers/Page'
import { AppTabs } from 'components/Tabs'
import InfoScreen from 'screens/Account/General/Info/InfoScreen'
import EducationResearchScreen from 'screens/Account/General/Info/EducationResearch'

function AppInfoScreen() {
  const tabs = [
    { id: 0, name: 'Clinical Trials', component: InfoScreen },
    { id: 1, name: 'Education Research', component: EducationResearchScreen },
  ]

  return (
    <PageContainer
      scheme="willowbrook"
      pt={4}
      standAlone={true}
      padTabBar={false}
    >
      <AppTabs data={tabs} />
    </PageContainer>
  )
}

export default AppInfoScreen
