import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Polygon, Polyline, Line } from 'react-native-svg'

export function GatherIcon(props) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
      <G
        id="Phase-2"
        stroke="none"
        strokeWidth="1"
        fill="green"
        fillRule="evenodd"
      >
        <G
          id="Home--Notification-Scroll-"
          transform="translate(-43.000000, -545.000000)"
          stroke="#021D3D"
          strokeWidth="1.5"
        >
          <G id="Gather-Copy-2" transform="translate(26.000000, 530.000000)">
            <G id="gather" transform="translate(18.000000, 16.000000)">
              <G
                id="Group-2"
                transform="translate(15.000000, 24.000000) scale(1, -1) translate(-15.000000, -24.000000) translate(0.000000, 18.000000)"
              >
                <Polyline
                  id="Path-Copy-2"
                  points="12 6.2565918 12 12 6.10205078 12"
                />
                <Polyline
                  id="Path-Copy-3"
                  transform="translate(21.051025, 9.128296) scale(-1, 1) translate(-21.051025, -9.128296) "
                  points="24 6.2565918 24 12 18.1020508 12"
                />
                <Line
                  x1="11.5"
                  y1="11.5"
                  x2="0.5"
                  y2="0.5"
                  id="Line-Copy-2"
                  strokeLinecap="square"
                />
                <Line
                  x1="29.5"
                  y1="11.5"
                  x2="18.5"
                  y2="0.5"
                  id="Line-Copy-3"
                  strokeLinecap="square"
                  transform="translate(24.000000, 6.000000) scale(-1, 1) translate(-24.000000, -6.000000) "
                />
              </G>
              <G id="Group-3">
                <Polyline id="Path" points="12 6.2565918 12 12 6.10205078 12" />
                <Polyline
                  id="Path-Copy"
                  transform="translate(21.051025, 9.128296) scale(-1, 1) translate(-21.051025, -9.128296) "
                  points="24 6.2565918 24 12 18.1020508 12"
                />
                <Line
                  x1="11.5"
                  y1="11.5"
                  x2="0.5"
                  y2="0.5"
                  id="Line"
                  strokeLinecap="square"
                />
                <Line
                  x1="29.5"
                  y1="11.5"
                  x2="18.5"
                  y2="0.5"
                  id="Line-Copy"
                  strokeLinecap="square"
                  transform="translate(24.000000, 6.000000) scale(-1, 1) translate(-24.000000, -6.000000) "
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}
