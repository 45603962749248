import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Polygon } from 'react-native-svg'

export function Calendar(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G
          id="calendar_today_black_24dp-(1)"
          transform="translate(-40.000000, -199.000000)"
          filter="url(#filter-1)"
        >
          <G transform="translate(40.000000, 199.000000)">
            <Polygon id="Path" points="0 0 24 0 24 24 0 24" />
            <Path
              d="M20,3 L19,3 L19,1 L17,1 L17,3 L7,3 L7,1 L5,1 L5,3 L4,3 C2.9,3 2,3.9 2,5 L2,21 C2,22.1 2.9,23 4,23 L20,23 C21.1,23 22,22.1 22,21 L22,5 C22,3.9 21.1,3 20,3 Z M20,21 L4,21 L4,8 L20,8 L20,21 Z"
              id="Shape"
              fill={props.color || '#000000'}
              fillRule="nonzero"
            />
          </G>
        </G>
      </G>
    </Icon>
  )
}
