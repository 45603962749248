import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import { routeNames } from '../../state/routeNames'
import { BackPressable } from '../../components/BackPressable/BackPressable'

import { NotificationScreen } from './NotificationScreen'
import NotificationsScreen from './NotificationsScreen'

const { Screen, Navigator } = createStackNavigator()

export function NotificationsNavigator() {
  return (
    <Navigator
      screenOptions={{
        headerLeft: (props) => <BackPressable {...props} />,
      }}
    >
      <Screen
        name={routeNames.NOTIFICATIONS}
        component={NotificationsScreen}
        // options={{ headerShown: false, headerTitle: ' ' }}
      />
      <Screen
        component={NotificationScreen}
        name={routeNames.NOTIFICATION}
        options={{ headerTitle: 'Daily Reminder' }}
      />
    </Navigator>
  )
}
