import React from 'react'
import { string } from 'prop-types'
import { Box } from 'native-base'

export function PlaceHolder({ scheme }) {
  return (
    <Box
      width="100%"
      height={100}
      borderRadius={15}
      backgroundColor={scheme}
      mb={8}
    />
  )
}

PlaceHolder.propTypes = {
  scheme: string.isRequired,
}
