import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'

export function History({ width = 35, height = 35, fill = '#fff' }) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 60 1000 1000"
    >
      <Path
        d="M5162 4375.3c-884.6-103.1-1721.1-474.4-2383.5-1058.8C2026.8 2654.1 1508.9 1691.6 1376 713l-27.5-210.8-460.7 458.3c-435.4 433.1-465.2 458.4-547.7 458.4-110 0-165-29.8-210.8-119.2-73.3-144.4-59.6-165 662.3-884.6l671.5-669.2h201.6l660 657.8c430.9 430.8 669.2 682.9 683 731.1 45.8 137.5-68.8 284.2-224.6 284.2-75.6 0-116.9-34.4-527.1-440l-444.6-442.3 16 103.1c183.3 1205.5 875.5 2218.5 1913.7 2805.1 1194 671.5 2653.9 653.2 3838.8-50.4 579.8-343.8 1150.5-958 1453-1560.7 474.4-941.9 536.3-2067.2 174.2-3038.9-206.3-547.8-465.2-948.8-891.5-1377.4-451.5-449.2-909.8-737.9-1478.2-925.9-582.1-194.8-1123-247.5-1705.1-171.9-783.8 103.1-1432.4 396.5-2062.6 937.3-169.6 144.4-215.4 174.2-281.9 174.2-105.4 0-160.4-32.1-206.3-119.2-59.6-114.6-27.5-201.7 130.6-348.4 969.4-898.4 2333.1-1299.4 3641.7-1070.3C7882.4-3838.6 9152-2756.8 9663.1-1290.1c538.6 1549.3 142.1 3265.8-1019.8 4427.8-630.3 630.3-1416.4 1040.5-2289.6 1194-297.9 52.7-916.7 75.6-1191.7 43.6z"
        transform="matrix(.1 0 0 -.1 0 511)"
        fill={fill}
      />
      <Path
        d="M5457.7 2842.1l-66.5-66.5V1402.8c0-1262.8 4.6-1375.1 41.2-1418.6 77.9-98.5 100.9-100.8 1093.2-100.8 1033.6 0 1049.6 2.3 1106.9 126 36.7 77.9 34.4 137.5-4.6 215.4-59.6 112.3-91.7 116.9-964.8 116.9h-813.6v1191.7c0 1276.5-2.3 1292.6-112.3 1352.2-89.3 45.8-208.5 27.5-279.5-43.5z"
        transform="matrix(.1 0 0 -.1 0 511)"
        fill={fill}
      />
    </Icon>
  )
}

History.defaulProps = {
  width: 40,
  height: 40,
}

History.propTypes = {
  width: number,
  height: number,
}
