import { firestore } from 'config/firebase'

export async function fetchCollectionByKey(key, action) {
  try {
    await firestore()
      .collection(key)
      .orderBy('index')
      .get()
      .then((snapShot) =>
        action(snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() })))
      )
  } catch (error) {
    throw new Error(error)
  }
}
