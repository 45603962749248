import React, { useEffect, useState } from 'react'
import { Animated, useWindowDimensions } from 'react-native'
import { Box, ScrollView, Text, useTheme } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { AppForm } from 'components/Form'
import { FIREBASE_COLLECTIONS } from 'state/consts'
import { NOTIFICATION_STATUS } from '../../state/consts'
import RNLocalize from 'react-native-localize'
import { activeReachConfig } from 'screens/Home/ActiveReach/formNotificationConfig'
import { firestore } from 'config/firebase'
import { formatDateByTimezone } from 'utils/dateHelpers'
import notifee from '@notifee/react-native'
import { routeNames } from 'state/routeNames'
import { saveReminder } from '../../state/firebase/saveReminder'
import set from 'lodash/set'
import { useAuth } from 'state/hooks/useAuth'

export function NotificationScreen(props) {
  const navigation = useNavigation()
  const { colors } = useTheme()
  const { height: deviceHeight } = useWindowDimensions()
  const {
    state: { user },
  } = useAuth()
  const [reminder, setReminder] = useState({ enabled: false })
  const [config, setConfig] = useState(
    set(activeReachConfig, 'initialValues', { reach: '' })
  )
  const lastTimezone = RNLocalize.getTimeZone()

  useEffect(() => {
    if (!user?.uid) {
      return
    }
    async function fetchNotification() {
      const oneDayAgo = new Date(new Date().getTime() - 60 * 60 * 24 * 1000)
      try {
        const snapshotReminder = await firestore()
          .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
          .where('uid', '==', user?.uid)
          .where(
            firestore.FieldPath.documentId(),
            '==',
            props.route.params.reminderId
          )
          .limit(1)
          .get()
        if (!snapshotReminder.empty) {
          console.log('empty')
          const reminderDoc = snapshotReminder.docs.pop()
          const reminderData = reminderDoc.data()
          setReminder({
            ...reminderData,
            enabled:
              reminderData.enabled &&
              reminderData.createdAt.toDate() > oneDayAgo,
          })
          setConfig((c) => ({
            ...c,
            initialValues: {
              interval: reminderData.interval,
              reach: reminderData.messages,
            },
          }))
          if (reminderData.status === NOTIFICATION_STATUS.NEW) {
            console.log('new')
            notifee.setBadgeCount(0)
            saveReminder(
              {
                status: NOTIFICATION_STATUS.SEEN,
                uid: user.uid,
                timezone: lastTimezone,
              },
              reminderDoc.id
            )
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchNotification()
  }, [user, props.route, lastTimezone])

  const styleForm = {
    justifyContent: 'flex-start',
    fontColor: colors.tangaroa[500],
    borderColor: colors.tangaroa[500],
  }

  const styles = {
    position: 'absolute',
    height: deviceHeight,
    backgroundColor: colors.white[500],
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 12,
    elevation: 12,
    opacity: 1,
  }

  set(config, 'actions.submit.handle', (values) => {
    saveReminder(
      {
        interval: values.interval,
        messages: values.reach,
        uid: user.uid,
        timezone: lastTimezone,
      },
      props.route.params.reminderId
    ).then((res) => {
      navigation.navigate(`${routeNames.NOTIFICATIONS}`)
    })
  })

  return (
    <Animated.View style={styles}>
      <Box bgColor={colors.puertorico[500]} flex={1} width="100%">
        <Box
          pb={4}
          px={6}
          pt={12}
          justifyContent="space-between"
          _android={{ py: 8 }}
        >
          <Text fontSize="3xl" variant="player">
            {reminder.enabled
              ? 'Update Active Reach Reminder'
              : 'Past Active Reach Reminder'}
          </Text>
        </Box>
        <ScrollView flex={1}>
          <Box
            bgColor={colors.white[500]}
            flex={1}
            px={6}
            justifyContent="flex-start"
            _android={{ b: 20 }}
          >
            {reminder?.enabled && <AppForm config={config} style={styleForm} />}
            {!reminder.enabled && reminder.messages && (
              <>
                {reminder.notifiedAt && (
                  <Text bold mt={8} fontSize="2xl">
                    {formatDateByTimezone(
                      new Date(reminder.notifiedAt?.seconds * 1000),
                      'MMMM d, yyyy, hh:mm a'
                    )}
                  </Text>
                )}
                <Text mt={3} fontSize="2xl">
                  {reminder.messages}
                </Text>
              </>
            )}
          </Box>
        </ScrollView>
      </Box>
    </Animated.View>
  )
}