import React from 'react'
import { WebViewLoader } from 'components/WebViewLoader'
import { WEBVIEWS } from 'state/consts'
import { Button } from 'native-base'
import { Linking } from 'react-native'

export function YouTubeView() {
  const open = async () => {
    await Linking.openURL(WEBVIEWS.YOUTUBE)
  }
  return (
    <Button onPress={open} variant="primary">
      Watch on YouTube
    </Button>
  )
}
