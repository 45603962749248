import React, { useMemo } from 'react'
import DayModal from 'components/Modal/DayModal'
import { ICycleStepProps } from 'state/interfaces'
import { RESOURCE_VIDEOS } from 'consts'

export interface Props extends ICycleStepProps {}

const VideoStep: React.FC<Props> = (props: Props) => {
  const {
    state: { user },
  } = props.useAuth
  const {
    state: { day, track, steps },
  } = props.useCycles
  const video = useMemo(() => {
    const step = steps[day][track]
    return step && step.resourceId && RESOURCE_VIDEOS[step.resourceId]
  }, [day, track])
  return <>{props.open && video && <DayModal video={video} {...props} />}</>
}

export default VideoStep
