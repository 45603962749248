import React, { useState } from 'react'
import {
  useTheme,
  VStack,
  Box,
  Button,
  Text,
  Spinner,
  useToast,
} from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { PageHeading } from 'components/Headings'
import { PageContainer } from 'components/Containers/Page'
import { useAuth } from 'state/hooks/useAuth'
import { usePlayer } from 'state/hooks/usePlayer'
import { useCycles } from 'state/hooks/useCycles'
import { AppToast } from 'components/Toast'
import { newCycleText } from '../../../../assets/text/faq'
import { routeNames } from '../../../../state/routeNames'

const ResetCycle = () => {
  const toast = useToast()
  const {
    state: { user },
  } = useAuth()
  const {
    dispatch: { dismissPlayer },
  } = usePlayer()
  const {
    dispatch: { cycleNew, dispatch },
    state,
  } = useCycles()
  const { colors } = useTheme()
  const navigation = useNavigation()
  const [loading, setLoading] = useState(false)

  async function resetCycle() {
    if (loading) return
    setLoading(true)
    await cycleNew({
      dispatch,
      state,
      user,
      reset: true,
    })
    await dismissPlayer()
    setLoading(false)
    navigation.navigate(routeNames.ACCOUNT_LANDING)
    toast.show({
      duration: 4900,
      render: () => (
        <AppToast
          status="success"
          message="Restarted your current cycle successfully."
        />
      ),
    })
  }

  return (
    <PageContainer scheme="willowbrook" pt={5} standAlone={false}>
      <PageHeading title="Restart current cycle" />
      <VStack>
        {newCycleText.slice(0, 1).map((t) => (
          <Text mt={2} variant="paragraph">
            {t.text}
          </Text>
        ))}
        <Box>
          {loading && <Spinner color={colors.puertorico[700]} size="sm" />}
          {!loading && (
            <>
              <Button variant="primary" onPress={resetCycle}>
                RESTART CURRENT CYCLE
              </Button>
              <Button onPress={() => navigation.goBack()} variant="ghost">
                CANCEL
              </Button>
            </>
          )}
        </Box>
      </VStack>
    </PageContainer>
  )
}

export default ResetCycle
