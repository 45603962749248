import React from 'react'
import { node } from 'prop-types'
import { Box, Center, Container } from 'native-base'

export function AuthContainer({ children, style }) {
  return (
    <Box
      safeArea
      px={6}
      pt={8}
      flex={1}
      justifyContent="center"
      backgroundColor={style.bg}
      _android={{ pb: 10 }}
    >
      <Center>
        <Container width={'100%'} maxWidth={768}>
          {children}
        </Container>
      </Center>
    </Box>
  )
}

AuthContainer.propTypes = { children: node.isRequired }
