import React from 'react'
import { Box, Link, Text, VStack, HStack } from 'native-base'

const index = () => {
  return (
    <VStack space={8} mt={100} mb={100} alignItems="center">
      <Box>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/neurocycle.appspot.com/o/app_images%2F2023%20Neurocycle-Logo.png?alt=media&token=8cc34621-02bc-417e-8457-dd87732e9f84"
          alt="Neurocycle-Logo.png"
          style={{ maxWidth: 150 }}
        />
      </Box>
      <HStack space={4} justifyContent="space-evenly" flexDirection={'row'}>
        <Link
          data-testid="linkElement"
          href="https://apps.apple.com/us/app/neurocycle/id1460119837"
          rel="noreferrer noopener"
        >
          <Box>
            <img
              src="https://static.wixstatic.com/media/0b1878_846c09d6651c4f669c52667387becd22~mv2.png/v1/fill/w_189,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/appstore.png"
              alt="appstore.png"
              style={{ maxWidth: 150 }}
            />
          </Box>
        </Link>

        <Link
          data-testid="linkElement"
          href="https://play.google.com/store/apps/details?id=com.switch_android&amp;hl=en_US&amp;gl=US"
          className="xQ_iF"
        >
          <Box>
            <img
              src="https://static.wixstatic.com/media/0b1878_4771aa4f32c345cca6ea9c15ca9fe845~mv2.png/v1/fill/w_189,h_56,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/playstore.png"
              alt="playstore.png"
              style={{ maxWidth: 150 }}
            />
          </Box>
        </Link>
      </HStack>
      <VStack space={3}>
        <Box flexDirection="row" justifyContent="space-evenly">
          <Box mx={2}>
            <Link
              isUnderlined
              isExternal
              href="https://www.neurocycle.app/terms-conditions"
            >
              Terms &amp; Conditions
            </Link>
          </Box>
          <Box mx={2}>
            <Link href="/" isUnderlined>
              Home
            </Link>
          </Box>
          <Box mx={2}>
            <Link
              isUnderlined
              isExternal
              href="https://www.neurocycle.app/privacy-policy"
            >
              Privacy Policy
            </Link>
          </Box>
        </Box>
        <Box>
          <Text textAlign="center">
            &#169;
            {` ${new Date().getFullYear()} Switch On Your Brain, LLC.  All Rights Reserved.`}
          </Text>
        </Box>
      </VStack>
    </VStack>
  )
}

export default index
