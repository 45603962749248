import React from 'react'
import { Box } from 'native-base'

export function PlayerHeaderAction({ icon: ActionIcon }) {
  return (
    <Box
      width={30}
      height={30}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ActionIcon color="white.500" size="5" />
    </Box>
  )
}
