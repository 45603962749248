import React from 'react'
import { oneOf } from 'prop-types'
import { Input, useTheme } from 'native-base'
import { INPUT_TYPES } from 'state/consts'
import { AppFormControl as FormControl } from 'components/Form/Inputs/FormControl'

export function TextInput({
  type,
  id,
  formik,
  label,
  required,
  style,
  inputProps,
  dark = false,
}: any) {
  const { ui, colors } = useTheme()
  const { handleBlur, handleChange, values } = formik

  return (
    <FormControl
      id={id}
      formik={formik}
      required={required}
      label={label}
      style={style}
    >
      <Input
        id={id}
        borderRadius={ui.borderRadius}
        fontSize="xl"
        my={2}
        onBlur={handleBlur(id)}
        onChangeText={handleChange(id)}
        px={6}
        py={4}
        type={type}
        value={values[id]}
        variant={dark ? 'outlineDark' : 'outline'}
        placeholderTextColor={dark ? colors.white[500] : undefined}
        dark={dark}
        {...inputProps}
      />
    </FormControl>
  )
}

TextInput.defaultProps = { type: 'text' }
TextInput.propTypes = {
  type: oneOf([INPUT_TYPES.TEXT, INPUT_TYPES.PASSWORD, INPUT_TYPES.NUMBER]),
}
