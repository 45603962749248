import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Button,
  CloseIcon,
  Image,
  Pressable,
  Text,
  Modal,
  useTheme,
  useToast,
  ZStack,
  View,
} from 'native-base'
import { firestore } from 'config/firebase'
// import crashlytics from '@react-native-firebase/crashlytics'
import { AppToast } from 'components/Toast'
import { PlayTrack } from 'components/Icons'
import { Audio, Video, VideoFullscreenUpdate, ResizeMode } from 'expo-av'
import { RESOURCE_VIDEOS } from 'state/consts'
import { useAuth } from 'state/hooks/useAuth'
import { Loading } from 'components/Loading'

const Tutorial = ({
  setProfile,
  userProfile,
  navToProfileInfo,
  user,
  showTutorial,
}) => {
  const { colors } = useTheme()
  const [activeVideo, setActiveVideo] = useState(null)
  const [currentStage, setCurrentStage] = useState(0)
  const [currentVideo, setCurrentVideo] = useState(0)
  const [uniqueKey, setUniqueKey] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const player = useRef(null)
  const toast = useToast()
  const {
    dispatch: { setShowSubscriptionModal },
  } = useAuth()

  const updateStage = () => {
    if (currentStage === 2) {
      saveToFirebase()
      setShowSubscriptionModal(true, true)
      return
    }
    setCurrentStage(currentStage + 1)
    setCurrentVideo(currentStage + 1)
    setUniqueKey(currentStage + 1)
  }

  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
    })
  }, [])

  async function saveToFirebase() {
    setProfile({
      ...userProfile,
      seenTutorial: true,
    })
    await firestore()
      .collection('users')
      .doc(user.uid)
      .set(
        {
          seenTutorial: true,
          uid: user.uid,
        },
        { merge: true }
      )
      .then((res) => {
        showTutorial(false)
        if (!userProfile || !userProfile.firstName || !userProfile.lastName)
          navToProfileInfo()
      })
      .catch((err) => {
        toast.show({
          placement: 'top',
          status: 'error',
          duration: 4900,
          render: () => <AppToast status="error" message={err.message} />,
        })
        // crashlytics().recordError(err)
      })
  }

  useEffect(() => {
    if (player.current) {
      player.current.loadAsync({ uri: activeVideo })
    }
  }, [activeVideo])

  const currentTitle = () => {
    if (currentStage === 0) return 'Welcome'
    if (currentStage === 1) return 'The Neurocycle'
    if (currentStage === 2) return 'Why 63 Days'
  }

  return (
    <Modal isOpen={true} backgroundColor={colors.puertorico[500]} safeArea>
      <Box
        flex={1}
        width="100%"
        justifyContent="space-between"
        px={6}
        pb={8}
        style={{ position: 'relative', zIndex: 9 }}
      >
        <Pressable
          onPress={() => {
            showTutorial(false)
            saveToFirebase()
            if (!userProfile || !userProfile.firstName || !userProfile.lastName)
              navToProfileInfo()
          }}
          style={{
            height: '10%',
            width: 25,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CloseIcon backgroundColor={colors.puertorico[500]} size="4" />
        </Pressable>
        <Box y="70%" justifyContent="flex-start">
          <Text
            fontSize="5xl"
            fontWeight="800"
            style={{ color: 'white' }}
            mb={4}
          >
            {currentTitle()}
          </Text>
          {console.log(currentStage)}
          <Pressable
            style={{ maxHeight: '20rem' }}
            onPress={() => {
              setActiveVideo(RESOURCE_VIDEOS[currentStage])
            }}
          >
            <View
              style={{
                height: '100%',
              }}
            >
              {activeVideo && activeVideo.url ? (
                <Video
                  key={uniqueKey}
                  shouldPlay
                  style={{
                    flex: 1,
                    width: '100%',
                    height: '100%',
                    alignSelf: 'center',
                  }}
                  resizeMode="contain"
                  useNativeControls
                  posterSource={{ uri: RESOURCE_VIDEOS[currentStage].img }}
                  source={{
                    uri: RESOURCE_VIDEOS[currentStage].url,
                  }} // Can be a URL or a local file.
                  ref={player}
                  onLoadStart={() => {
                    setIsLoading(true)
                  }}
                  onLoad={() => setIsLoading(false)}
                  onError={() => setIsLoading(false)}
                />
              ) : (
                <ZStack
                  style={{
                    minHeight: '15rem',
                  }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <PlayTrack
                      innerFill={colors.white[500]}
                      outerfill={colors.puertorico[200]}
                      height={80}
                      width={80}
                    />
                  )}
                  <Image
                    height={'100%'}
                    width={'100%'}
                    resizeMode="contain"
                    source={{
                      uri:
                        RESOURCE_VIDEOS[currentVideo] &&
                        RESOURCE_VIDEOS[currentVideo].img,
                    }}
                  />
                </ZStack>
              )}
            </View>
          </Pressable>
        </Box>
        <Box
          style={{ height: '10%' }}
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Box style={currentStage == 0 ? styles.onStage : styles.offStage} />
          <Box style={currentStage == 1 ? styles.onStage : styles.offStage} />
          <Box style={currentStage == 2 ? styles.onStage : styles.offStage} />
        </Box>
        <Button
          style={{ heigth: '10%' }}
          variant="primary"
          onPress={() => updateStage()}
        >
          {currentStage !== 2 ? 'NEXT' : 'DONE'}
        </Button>
      </Box>
    </Modal>
  )
}

const styles = {
  onStage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#021D3D',
  },
  offStage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: 'white',
  },
}

export default Tutorial
