import React, { useMemo, useState } from 'react'
import { KeyboardAvoidingView, Text, useTheme, useToast } from 'native-base'
import { Platform, ScrollView } from 'react-native'
import { AUTH_ERRORS } from 'state/consts'
import { AppForm } from 'components/Form'
import { AppToast } from 'components/Toast'
import { AuthContainer } from 'components/Containers/Auth'
import { Link } from '@react-navigation/native'
import { auth } from 'config/firebase'
import { resetConfig } from 'screens/Auth/config'
import set from 'lodash/set'

export function ResetScreen() {
  const { colors } = useTheme()
  const initialValues = { email: '' }
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState(false)
  const toast = useToast()

  const config = set({ ...resetConfig }, 'initialValues', initialValues)
  set(config, 'actions.submit.handle', (values: { email: string }) => {
    auth()
      .sendPasswordResetEmail(values.email.trim().toLowerCase())
      .then(() => {
        setEmailSent(true)
      })
      .catch((err) => {
        setError(true)
        toast.show({
          placement: 'top',
          status: 'error',
          duration: 4900,
          render: () => (
            <AppToast status="error" message={AUTH_ERRORS[err?.code]} />
          ),
        })
      })
  })

  const formConfig = useMemo(() => {
    set(config, 'rows', emailSent ? [] : resetConfig.rows)
    set(config, 'actions', emailSent ? [] : resetConfig.actions)
    set(config, 'actionFooter', emailSent ? [] : resetConfig.actionFooter)
    return config
  }, [emailSent, config])

  const style = {
    bg: colors.salmon[500],
    fontColor: colors.white[500],
    borderColor: colors.white[500],
    actionFooter: { textAlign: 'center' },
    placeholderFontColor: colors.puertorico[200],
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'center',
  }

  return (
    <AuthContainer style={style}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={{ flex: 1, width: '100%' }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
        >
          <AppForm
            config={formConfig}
            haveError={error}
            style={style}
            noKeyboardAvoidingView={true}
            noBottomSpacer={false}
          >
            {emailSent && (
              <>
                <Text
                  bold
                  style={{
                    color: colors.white[500],
                    lineHeight: 30,
                  }}
                  fontSize="xl"
                  mt={5}
                  textAlign="center"
                >
                  Check your email for a reset link!
                </Text>
                <Text
                  fontSize="xl"
                  mt={3}
                  style={style && { color: style.fontColor }}
                  textAlign="center"
                >
                  <Link to={{ screen: 'AuthLogin' }}>Back to Log In</Link>
                </Text>
              </>
            )}
          </AppForm>
        </ScrollView>
      </KeyboardAvoidingView>
    </AuthContainer>
  )
}
