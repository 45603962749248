import { Linking } from 'react-native'
import { object, string } from 'yup'
import { INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES } from 'state/consts'
import { Button, Text, Icon } from 'native-base'
import { TextInput } from 'components/Form/Inputs/TextInput'
import { AntDesign } from '@native-base/icons'

const tosUrl = 'https://www.neurocycle.app/terms-conditions'
const privacyUrl = 'https://www.neurocycle.app/privacy-policy'

export const registerConfigStep2 = {
  dark: true,
  validationSchema: object().shape({
    email: string().email('Invalid email').required('Required').trim(),
    password: string()
      .required('Required')
      .min(8, 'Should be at least 8characters'),
  }),
  heading: {
    title: 'Almost there!',
    subTitle: 'Add your email to finish',
  },
  authFooter: [
    {
      link: 'AuthLogin',
      text: (
        <Text style={{ color: 'white' }}>
          <Icon size={4} as={AntDesign} name="arrowleft" color="white" /> Back
          to Login with your credentials
        </Text>
      ),
    },
  ],
  actionFooter: [
    {
      component: (
        <Text variant="paragraph" style={{ color: 'rgba(255,255,255,0.5)' }}>
          {`By tapping "Register", you agree to our\n`}
          <Text
            onPress={() => Linking.openURL(tosUrl)}
            style={{
              color: 'rgba(255,255,255,0.6)',
              textDecorationLine: 'underline',
            }}
          >
            Terms of Service
          </Text>
          &nbsp;&&nbsp;
          <Text
            onPress={() => Linking.openURL(privacyUrl)}
            style={{
              color: 'rgba(255,255,255,0.6)',
              textDecorationLine: 'underline',
            }}
          >
            Privacy Policy.
          </Text>
        </Text>
      ),
    },
  ],
  rows: [
    {
      id: 'register_row0',
      fields: [
        {
          id: 'email',
          label: 'Email',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          inputProps: {
            autoCapitalize: 'none',
            autoComplete: 'email',
            autoCorrect: false,
            autoFocus: true,
          },
          keyboardType: KEYBOARD_TYPES.EMAIL,
          textContentType: TEXT_CONTENT_TYPES.EMAIL,
        },
      ],
    },
    {
      id: 'register_row1',
      fields: [
        {
          id: 'password',
          label: 'Password',
          type: INPUT_TYPES.PASSWORD,
          component: TextInput,
          inputProps: {
            autoCapitalize: 'none',
            autoComplete: 'password-new',
            autoCorrect: false,
          },
          textContentType: TEXT_CONTENT_TYPES.NEW_PASSWORD,
        },
      ],
    },
  ],
  actions: {
    submit: {
      id: 'register_submit_step2',
      component: Button,
      variant: 'primary',
      label: 'REGISTER',
    },
  },
}
