import React, { useMemo } from 'react'
import { Box, Text, useTheme, Pressable } from 'native-base'
import * as Haptics from 'expo-haptics'
import { number, shape, string } from 'prop-types'
import { convertMillisToTimeStamp } from 'utils/dateHelpers'
import { PlayTrack, TrackVisualiser } from 'components/Icons'
import { useAuth } from 'state/hooks/useAuth'
import { usePlayer } from 'state/hooks/usePlayer'
import { useCycles } from 'hooks/useCycles'

export function PlaylistCard(props: any) {
  const { colors, ui } = useTheme()
  const {
    dispatch: { loadSound, toggleSize, toggleSound, playSound },
  } = usePlayer()
  const {
    state: { user, setOpen },
  } = useAuth()

  const {
    dispatch: { cycleNew, dispatch },
    state,
  } = useCycles()
  const {
    data: { id, title, duration, publicUrlMin: publicUrl, type },
    selected: { playing, file },
  } = props

  const selected = useMemo(() => file && file.id === id, [file, id])

  return (
    <Pressable
      onPress={async () => {
        if (selected) {
          await toggleSound()
        } else {
          // Haptics.selectionAsync()
          toggleSize('small')
          const { signal } = new AbortController()
          const sound = await loadSound({
            file: {
              title,
              subTitle: type,
              file: {
                uri: publicUrl,
                locale: 'en',
              },
              duration,
              id,
            },
            signal,
          })
          if (sound) await playSound(sound, signal)
        }
      }}
      width="100%"
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: selected
            ? colors.puertorico[500]
            : colors.white[500],
          width: '100%',
        }}
        mb={4}
        borderRadius={ui.borderRadius}
      >
        <Box p={4}>
          {playing && selected ? (
            <TrackVisualiser
              width={45}
              height={45}
              outerfill={colors.tangaroa[500]}
              innerFill={colors.white[500]}
            />
          ) : (
            <PlayTrack
              width={45}
              height={45}
              outerfill={
                selected ? colors.tangaroa[500] : colors.puertorico[500]
              }
              innerFill={selected ? colors.white[500] : colors.tangaroa[500]}
            />
          )}
        </Box>
        <Box flexDirection="column">
          <Text
            fontSize="2xl"
            mb={1}
            bold={playing && selected}
            maxWidth={1024}
            isTruncated
          >
            {title}
          </Text>
          <Text>{convertMillisToTimeStamp(parseInt(duration, 10))}</Text>
        </Box>
      </Box>
    </Pressable>
  )
}

PlaylistCard.propTypes = {
  data: shape({
    title: string.isRequired,
    duration: number.isRequired,
  }),
}
