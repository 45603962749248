import React from 'react'
import { oneOfType, string, number } from 'prop-types'
import { Spinner, HStack, Center } from 'native-base'

export function Loading({ label, height, color }) {
  return (
    <Center flex={1} style={{ height }}>
      <HStack space={2}>
        <Spinner accessibilityLabel={label} color={color} />
      </HStack>
    </Center>
  )
}

Loading.defaultProps = {
  label: 'Loading',
  height: 100,
  color: 'gray.900',
}

Loading.propTypes = {
  label: string,
  height: oneOfType([number, string]),
  color: string,
}
