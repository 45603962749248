import React, { Suspense, lazy } from 'react'
import { Loading } from 'components/Loading'

export function FAQView() {
  const FAQ = lazy(() => import('components/FAQ'))
  return (
    <Suspense fallback={<Loading />}>
      <FAQ />
    </Suspense>
  )
}
