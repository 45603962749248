import React from 'react'
import { View, Text } from 'native-base'
import { string } from 'prop-types'

import Style from './Style'
export function TextCard({ title, description }) {
  return (
    <View style={Style.cardContainer}>
      <Text style={Style.titleStyle}>{title}</Text>
      <Text style={Style.descriptionStyle}>{description}</Text>
    </View>
  )
}

TextCard.propTypes = {
  title: string.isRequired,
  highlightedText: string.isRequired,
}
