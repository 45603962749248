import ProcessEnv from 'react-native-config'

export const Config = {
  KLAVIYO_KEY: process.env.REACT_NATIVE_KLAVIYO_KEY,
  REVENUE_CAT_ANDROID_KEY: process.env.REACT_NATIVE_REVENUE_CAT_ANDROID_KEY,
  REVENUE_CAT_IOS_KEY: process.env.REACT_NATIVE_REVENUE_CAT_IOS_KEY,
  REVENUE_CAT_WEB_KEY: process.env.REACT_NATIVE_REVENUE_CAT_WEB_KEY,
  MONTHLY: process.env.REACT_NATIVE_STRIPE_MONTHLY_PRICEID,
  QUARTERLY: process.env.REACT_NATIVE_STRIPE_QUARTERLY_PRICEID,
  ANNUAL: process.env.REACT_NATIVE_STRIPE_ANNUAL_PRICEID,
  TRIAL_DAYS: 3,
  LIMIT_DATE: '2022-06-06', // ISO format: YYYY-MM-DD
  WEB_PRICES: {
    'default.monthly': '14.99',
    'default.threeMonth': '29.99',
    'default.annual': '99.99',
    'braindetox2023.threeMonth': '19.99',
  },
}

export function sanityCheck() {
  if (
    !Config.KLAVIYO_KEY ||
    !Config.REVENUE_CAT_ANDROID_KEY ||
    !Config.REVENUE_CAT_IOS_KEY
  ) {
    // throw new Error()
  }
}
