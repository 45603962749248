import { RFValue } from 'react-native-responsive-fontsize'
import { extendTheme } from 'native-base'

const colors = {
  black: { 500: '#000000' },
  cadet: { 400: '#BFBFBF', 500: '#B2BAC4', 800: '#8C8F93' },
  christine: { 500: '#EB5B0E' },
  cinnabar: { 500: '#E94246' },
  endeavour: { 500: '#0152AD' },
  lightBlue: { 500: '#3DBFD3' },
  hotpink: { 500: '#FF6C9F', 600: '#FF6C9F' },
  kournikova: { 500: '#FEE174', 800: '#fecf25' },
  picton: { 500: '#ff6c9f' },
  puertorico: {
    200: '#A1E5DD',
    500: '#43CCBB',
    600: '#02C2AD',
    700: '#24747C',
    blur: 'rgba(47,122,130,0.8)',
  },
  salmon: { 500: '#FF7B6C' },
  shuttle: { 500: '#596a7d' },
  tangaroa: { 500: '#021D3D' },
  white: { 500: '#ffffff' },
  willowbrook: { 500: '#F4F7F0', 800: '#7a7b78' },
}

const gilroyFonts = {
  900: { normal: 'Gilroy-Black', italic: 'Gilroy-BlackItalic' },
  800: { normal: 'Gilroy-Bold', italic: 'Gilroy-BoldItalic' },
  700: { normal: 'Gilroy-Extrabold', italic: 'Gilroy-ExtraboldItalic' },
  600: { normal: 'Gilroy-Heavy', italic: 'Gilroy-HeavyItalic' },
  500: { normal: 'Gilroy-Light', italic: 'Gilroy-LightItalic' },
  400: { normal: 'Gilroy-Medium', italic: 'Gilroy-MediumItalic' },
  300: { normal: 'Gilroy-Regular', italic: 'Gilroy-RegularItalic' },
  200: { normal: 'Gilroy-Semibold', italic: 'Gilroy-SemiboldItalic' },
  100: { normal: 'Gilroy-Thin', italic: 'Gilroy-ThinItalic' },
  50: { normal: 'Gilroy-UltraLight', italic: 'Gilroy-UltraLightItalic' },
}

const borderRadius = 15

export const theme = extendTheme({
  colors,
  fontConfig: {
    Gilroy: gilroyFonts,
    center: {
      textAlign: 'center',
    },
    left: {
      textAlign: 'left',
    },
  },
  fonts: {
    body: 'Gilroy',
    light: 'Gilroy-Light',
    lightItalic: 'Gilroy-LightItalic',
    regular: 'Gilroy-Regular',
    regularItalic: 'Gilroy-RegularItalic',
    semibold: 'Gilroy-Semibold',
    semiboldItalic: 'Gilroy-SemiboldItalic',
    bold: 'Gilroy-Bold',
    boldItalic: 'Gilroy-BoldItalic',
    medium: 'Gilroy-Medium',
    mediumItalic: 'Gilroy-MediumItalic',
  },
  ui: {
    mainCard: {
      height: '100%',
    },
    borderRadius,
    backgroundFill: {
      borderRadius,
      height: '100%',
      overflow: 'hidden',
    },
    modalBg: 'rgba(0, 0, 0, 0.6)',
    'text-appBarText': {
      fontSize: 10,
      fontFamily: 'Gilroy-Semibold',
    },
  },
  components: {
    Button: {
      baseStyle: {
        height: 70,
        borderRadius,
        _text: {
          letterSpacing: 0.5,
          textTransform: 'uppercase',
        },
      },
      variants: {
        input: () => ({
          border: '1px solid',
          borderColor: colors.tangaroa[500],
          _text: {
            fontWeight: 400,
          },
        }),
        primary: () => ({
          bg: colors.tangaroa[500],
        }),
        primaryDisabled: () => ({
          bg: colors.shuttle[500],
        }),
        secondary: () => ({
          bg: colors.white[500],
          border: `2px solid ${colors.tangaroa[500]}`,
          _text: { color: colors.tangaroa[500] },
        }),
        secondaryDisabled: () => ({
          bg: colors.white[500],
          color: colors.shuttle[500],
          border: `2px solid ${colors.shuttle[500]}`,
        }),
        cancel: () => ({
          bg: colors.puertorico[500],
          border: `2px solid ${colors.tangaroa[500]}`,
          _text: { color: colors.tangaroa[500] },
        }),
        confirm: () => ({
          bg: colors.tangaroa[500],
        }),
        questionDefault: () => ({
          bg: colors.white[500],
          border: `1px solid ${colors.cadet[500]}`,
          justifyContent: 'flex-start',
        }),
        questionSelected: () => ({
          bg: colors.kournikova[500],
          border: `2px solid ${colors.kournikova[800]}`,
          justifyContent: 'flex-start',
        }),
        activeReach: () => ({
          bg: colors.white[500],
          border: `2px solid ${colors.tangaroa[500]}`,
          _text: { color: colors.tangaroa[500] },
        }),
        activeReachSelected: () => ({
          bg: colors.puertorico[500],
          _text: { color: colors.white[500] },
          border: 'none',
        }),
        activeNavigation: () => ({
          height: '2rem',
          bg: '#D34434',
          _text: { color: colors.white[500] },
          border: 'none',
        }),
        navigation: () => ({
          height: '2rem',
          _hover: {
            bg: '#D34434',
          },
          bg: 'transparent',
          _text: { color: colors.white[500] },
          border: 'none',
        }),
        outlineDark: () => ({
          border: `1px solid ${colors.white[500]}`,
          color: colors.white[500],
          _text: { color: colors.white[500] },
          _focus: {
            borderColor: colors.white[500],
            color: colors.white[500],
          },
        }),
      },
    },
    Input: {
      baseStyle: {
        border: '1px solid',
      },
      variants: {
        outlineDark: () => ({
          border: `1px solid ${colors.white[500]}`,
          color: colors.white[500],
          _text: {},
          _focus: {
            borderColor: colors.white[500],
            color: colors.white[500],
          },
        }),
      },
    },
    DateInput: {
      zIndex: '2000',
      Button: {
        baseStyle: {
          border: '1px solid',
          fontColor: 'white',
          color: 'white',
          style: {
            fontColor: 'white',
            color: 'white',
          },
        },
      },
    },
    SliderThumb: {
      variants: {
        congrats: () => ({
          bg: 'transparent',
        }),
      },
    },
    SliderTrack: {
      variants: {
        progress: () => ({
          bg: colors.puertorico[600],
          borderRadius: 15,
        }),
        congrats: () => ({
          bg: colors.cinnabar[500],
          borderRadius: 15,
        }),
      },
    },
    SliderFilledTrack: {
      variants: {
        progress: () => ({
          bg: colors.kournikova[800],
          borderRadius: 15,
        }),
        congrats: () => ({
          bg: colors.kournikova[500],
          borderRadius: 15,
        }),
      },
    },
    Text: {
      baseStyle: {
        maxFontSizeMultiplier: 1.25,
      },
      variants: {
        primary: () => ({
          color: colors.white[500],
          fontWeight: 'bold',
          fontSize: 36,
        }),
        primaryDisabled: () => ({
          color: colors.white[500],
          fontSize: 36,
        }),
        secondary: () => ({
          fontWeight: 'bold',
          fontSize: 36,
          maxFontSizeMultiplier: 1,
        }),
        logo: () => ({
          color: colors.puertorico[500],
          fontWeight: 'bold',
          fontSize: 42,
          maxFontSizeMultiplier: 1,
        }),
        authHelper: () => ({
          color: colors.puertorico[500],
          fontWeight: 'bold',
          fontSize: 16,
        }),
        error: () => ({
          color: colors.white[500],
          fontSize: 16,
        }),
        success: () => ({
          color: colors.white[500],
          fontSize: 16,
        }),
        login: () => ({
          color: colors.white[500],
        }),
        register: () => ({
          color: colors.white[500],
        }),
        reset: () => ({
          color: colors.white[500],
        }),
        base: () => ({
          color: colors.tangaroa[500],
        }),
        authLogo: () => ({
          color: colors.white[500],
          fontWeight: 'bold',
          fontSize: 42,
          maxFontSizeMultiplier: 1.25,
        }),
        player: () => ({
          color: colors.white[500],
          maxFontSizeMultiplier: 1.25,
        }),
        notificationNew: () => ({
          color: colors.white[500],
        }),
        notificationSeen: () => ({
          color: colors.tangaroa[500],
        }),
        tangaroa: () => ({
          color: colors.tangaroa[500],
        }),
        paragraph: () => ({
          fontSize: 18,
          lineHeight: 22,
          marginBottom: 10,
        }),
        'stats-title': () => ({
          fontSize: 18,
          fontWeight: 'bold',
          color: colors.puertorico[500],
        }),
        'stats-stat': () => ({
          fontSize: 35,
          color: colors.tangaroa[500],
        }),
        'text-pageTitle': () => ({
          fontSize: 20,
          fontFamily: 'bold',
          color: colors.tangaroa[500],
        }),
        'text-pageHeading': () => ({
          fontSize: 32,
          fontFamily: 'semibold',
        }),
        'text-sectionHeading': () => ({
          fontSize: 20,
          fontFamily: 'bold',
          maxFontSizeMultiplier: 1.5,
        }),
        'text-tabHeading': () => ({
          fontSize: 16,
          fontFamily: 'regular',
          color: colors.tangaroa[500],
          maxFontSizeMultiplier: 1,
        }),
        'text-tabHeadingActive': () => ({
          fontSize: 16,
          fontFamily: 'semibold',
          color: colors.puertorico[500],
          maxFontSizeMultiplier: 1,
        }),
        'text-graphHeading': () => ({
          fontSize: 20,
          fontFamily: 'bold',
        }),
        'text-graphSectionTitles': () => ({
          fontSize: 14,
          fontFamily: 'regular',
        }),
        'text-menuHeading': () => ({
          fontSize: 18,
          fontFamily: 'bold',
        }),
        'text-menuItem': () => ({
          fontSize: 18,
          fontFamily: 'medium',
        }),
        'text-miniCalendarHeading': () => ({
          fontSize: 20,
          fontFamily: 'bold',
        }),
        'text-miniCalendarDays': () => ({
          fontSize: 16,
          fontFamily: 'regular',
        }),
        'text-miniCalendarEntry': () => ({
          fontSize: 16,
          fontFamily: 'bold',
        }),
        'text-inspiration': () => ({
          fontSize: { base: RFValue(22), md: RFValue(22), lg: RFValue(16) },
          fontFamily: 'mediumItalic',
          color: colors.tangaroa[500],
          maxFontSizeMultiplier: 1.25,
        }),
        'text-Greeting': () => ({
          fontSize: { base: RFValue(26), md: RFValue(26), lg: RFValue(16) },
          fontFamily: 'regular',
          maxFontSizeMultiplier: 1.5,
        }),
        'text-SuccessSub': () => ({
          fontSize: { base: RFValue(28), md: RFValue(28), lg: RFValue(20) },
          maxFontSizeMultiplier: 1.5,
        }),

        'text-GreetingName': () => ({
          fontSize: { base: RFValue(34), md: RFValue(34), lg: RFValue(26) },
          fontFamily: 'semibold',
          maxFontSizeMultiplier: 1.5,
        }),
        'text-MainCardTitle': () => ({
          fontSize: { base: RFValue(24), md: RFValue(16), lg: RFValue(14) },
        }),
        'text-MainCardSubTitle': () => ({
          fontSize: { base: RFValue(24), md: RFValue(14), lg: RFValue(14) },
        }),
        'text-MainCardCycleDays': () => ({
          fontSize: { base: RFValue(22), md: RFValue(14), lg: RFValue(14) },
        }),
        'text-MainCardCycleDaysLeft': () => ({
          fontSize: { base: RFValue(22), md: RFValue(14), lg: RFValue(14) },
        }),
        'text-SubCards': () => ({
          fontSize: {
            base: RFValue(24),
            md: RFValue(16),
            lg: RFValue(14),
            xl: RFValue(14),
          },
        }),
        'text-miniPlayerTitle': () => ({
          fontSize: 20,
          fontFamily: 'semibold',
          color: colors.white[500],
          maxFontSizeMultiplier: 1.25,
        }),
        'text-miniPlayerSubTitle': () => ({
          fontSize: 14,
          fontFamily: 'regular',
          color: colors.white[500],
          maxFontSizeMultiplier: 1.25,
        }),
        'text-playerCycle': () => ({
          fontSize: 18,
          fontFamily: 'bold',
          color: colors.white[500],
          maxFontSizeMultiplier: 1.25,
        }),
        'text-playerTitle': () => ({
          fontSize: 50,
          fontFamily: 'semibold',
          color: colors.white[500],
          maxFontSizeMultiplier: 1,
        }),
        'text-playerSubTitle': () => ({
          fontSize: 28,
          fontFamily: 'regular',
          color: colors.white[500],
          maxFontSizeMultiplier: 1.25,
        }),
        'text-playerTimeStamp': () => ({
          fontSize: 14,
          fontFamily: 'light',
          color: colors.white[500],
        }),
        'text-placeHolder': () => ({
          fontFamily: 'regular',
          color: colors.cadet[800],
        }),
        'text-h2': () => ({
          fontSize: 18,
          fontFamily: 'semibold',
          color: colors.tangaroa[500],
        }),
        'text-h3': () => ({
          fontSize: 16,
          fontFamily: 'regular',
          color: colors.tangaroa[500],
        }),
        'text-p': () => ({
          fontSize: 14,
          fontFamily: 'regular',
          color: colors.tangaroa[500],
        }),
      },
    },
    Box: {
      variants: {
        error: () => ({
          backgroundColor: colors.salmon[500],
          margin: 8,
          marginTop: 2,
          padding: 8,
          borderRadius,
        }),
        success: () => ({
          backgroundColor: colors.puertorico[600],
          margin: 8,
          marginTop: 2,
          padding: 8,
          borderRadius,
        }),
        login: () => ({
          backgroundColor: colors.puertorico[500],
        }),
        register: () => ({
          backgroundColor: colors.endeavour[500],
        }),
        reset: () => ({
          backgroundColor: colors.salmon[500],
        }),
        base: () => ({
          backgroundColor: colors.white[500],
        }),
        spaceBetween: () => ({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }),
      },
    },
  },
})

type CustomThemeType = typeof theme
declare module 'native-base' {
  type ICustomTheme = CustomThemeType
}
