import React from 'react'
import { Box, Text, useTheme, HStack } from 'native-base'
import { MiniCard } from 'components/Cards/MiniCard'
import { TextCard } from 'components/Cards/TextCard'
import { ResultsCard } from 'components/Cards/ResultsCard'
import { Calendar, Graph } from 'components/Icons'

export function PromptCard() {
  const { colors, ui } = useTheme()
  const data = [
    { id: 0, count: 25 },
    { id: 1, count: 50 },
    { id: 2, count: 75 },
    { id: 3, count: 100 },
  ]

  return (
    <Box mb={10}>
      <Box
        backgroundColor={colors.white[500]}
        p={6}
        mb={4}
        borderRadius={ui.borderRadius}
        minHeight={100}
      >
        <Text variant="tangaroa" fontSize="lg" flexWrap="wrap">
          Once you have completed your first cycle and questionnaire, then you
          will be able to start seeing your results here.
        </Text>
      </Box>
      <Box px={1} py={2}>
        <Text bold>Stats:</Text>
      </Box>
      <HStack space={6}>
        <MiniCard
          icon={<Calendar width={20} height={22} />}
          title={'LMM\n4 of 4'}
          highlightedText={'Cycle 4\nDay 12'}
        />
        <MiniCard
          icon={<Graph width={20} height={22} />}
          title={'Mind-Management Level'}
          highlightedText="Excellent"
        />
      </HStack>
      <Box px={1} py={2}>
        <Text bold>Graphs:</Text>
      </Box>
      <ResultsCard title="Autonomy" data={data} />
      <Box px={1} py={2}>
        <Text bold>Motivation:</Text>
      </Box>
      <TextCard
        title="Autonomy"
        description="Excellent autonomy means you are self-regulating well enough to see your personal growth; you are learning how  to drive your life in the direction you want it to go."
      />
    </Box>
  )
}
