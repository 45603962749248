import React from 'react'
import { PodcastTeaser } from 'components/Cards'
import { REMOTE_BGS } from 'state/consts'

export function PodcastView() {
  return (
    <PodcastTeaser
      title="Cleaning Up The Mental Mess"
      subTitle="With Dr. Caroline Leaf"
      source={REMOTE_BGS.PODCASTS_TEASER}
    />
  )
}
