import React from 'react'
import { WEBVIEWS } from 'state/consts'
import { Button } from 'native-base'
import { Linking } from 'react-native'
export function StoreView() {
  const open = async () => {
    await Linking.openURL(WEBVIEWS.STORE)
  }
  return (
    <Button onPress={open} variant="primary">
      Visit Our Store
    </Button>
  )
}
