import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Polygon, Ellipse, Line } from 'react-native-svg'

export function Confetti({ width, height, fill, style }) {
  return (
    <Icon
      width={width}
      height={height}
      style={style}
      viewBox="320 50 550 1000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        transform="translate(246 216) scale(-1 1) rotate(-30 -139.939 1687.316)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Ellipse
          stroke={fill}
          strokeWidth={7.19999914}
          cx={627.179633}
          cy={243.02}
          rx={11.5430807}
          ry={11.52}
        />
        <Ellipse
          stroke={fill}
          strokeWidth={7.19999914}
          cx={216.543081}
          cy={215.936698}
          rx={11.5430807}
          ry={11.52}
        />
        <Ellipse
          stroke={fill}
          strokeWidth={7.19999914}
          cx={11.5430807}
          cy={634.52}
          rx={11.5430807}
          ry={11.52}
        />
        <Ellipse
          stroke={fill}
          strokeWidth={7.19999914}
          cx={327.543081}
          cy={529.52}
          rx={11.5430807}
          ry={11.52}
        />
        <Ellipse
          stroke={fill}
          strokeWidth={7.19999914}
          cx={294.767399}
          cy={926.289145}
          rx={11.5430807}
          ry={11.52}
        />
        <Path
          d="M303.013 141.097c-5.766 2.689-8.256 9.552-5.562 15.33 2.694 5.778 9.553 8.282 15.319 5.593M436.762 406.643c6.338.555 11.926-4.144 12.482-10.495.556-6.35-4.132-11.948-10.47-12.503M500.355 751.39c-.555 6.337 4.144 11.926 10.495 12.481 6.35.556 11.949-4.132 12.503-10.47"
          stroke={fill}
          strokeWidth={7.19999914}
        />
        <Path
          stroke={fill}
          strokeWidth={7.19999914}
          strokeLinecap="square"
          d="M552.947889 132.358984L539 146.278984"
        />
        <Path
          stroke={fill}
          strokeWidth={7.19999914}
          strokeLinecap="square"
          d="M327.947889 0L334.635954 24.1324331"
        />
        <Path
          stroke={fill}
          strokeWidth={7.19999914}
          strokeLinecap="square"
          d="M141 413.253774L141 434.92"
        />
        <Path
          stroke={fill}
          strokeWidth={7.19999914}
          strokeLinecap="square"
          d="M419.780306 696.157584L443.932667 690.137968"
        />
        <Path
          stroke={fill}
          strokeWidth={7.19999914}
          strokeLinecap="square"
          d="M555.364484 582.436533L563 593.92"
        />
        <Path
          fill={fill}
          transform="rotate(-15 729.919 328.376)"
          d="M725.421982 323.875657L734.415373 323.875657 734.415373 332.875657 725.421982 332.875657z"
        />
        <Path
          fill={fill}
          transform="rotate(-15 399.185 591.224)"
          d="M394.688045 586.723641L403.681435 586.723641 403.681435 595.723641 394.688045 595.723641z"
        />
        <Path
          stroke={fill}
          strokeWidth={7.19999914}
          strokeLinecap="square"
          d="M126.883722 204.880707L142 199.92"
        />
        <Ellipse
          fill={fill}
          cx={706.188828}
          cy={227.656952}
          rx={4.0881744}
          ry={4.08}
        />
        <Ellipse
          fill={fill}
          cx={370.088174}
          cy={68.08}
          rx={4.0881744}
          ry={4.08}
        />
        <Ellipse
          fill={fill}
          cx={452.088174}
          cy={287.08}
          rx={4.0881744}
          ry={4.08}
        />
        <Ellipse
          fill={fill}
          cx={309.088174}
          cy={82.08}
          rx={4.0881744}
          ry={4.08}
        />
        <Ellipse
          fill={fill}
          cx={235.088174}
          cy={395.08}
          rx={4.0881744}
          ry={4.08}
        />
        <Ellipse
          fill={fill}
          cx={204.011223}
          cy={688.906859}
          rx={4.0881744}
          ry={4.08}
        />
      </G>
    </Icon>
  )
}
