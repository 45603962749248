import { firestore } from 'config/firebase'
import * as RNLocalize from 'react-native-localize'
import { FIREBASE_COLLECTIONS } from '../consts'

async function deactivateOtherReminders(uid) {
  return await firestore()
    .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
    .where('uid', '==', uid)
    .where('enabled', '==', true)
    .get()
    .then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        doc.ref.update({
          enabled: false,
        })
      })
    })
}

export async function saveReminder(data, id) {
  if (!data.uid) throw Error('missing UID')

  let query = firestore().collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)

  if (id) {
    query = query.doc(id).set({ ...data }, { merge: true })
  } else {
    await deactivateOtherReminders(data.uid)
    query = query.add({
      ...data,
      createdAt: firestore.FieldValue.serverTimestamp(),
      generatedAt: firestore.FieldValue.serverTimestamp(),
      timezone: RNLocalize.getTimeZone(),
      enabled: data.hasOwnProperty('enabled') ? data.enabled : true,
    })
  }
  return await query
}
