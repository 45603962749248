import React, { useEffect, useState, useRef } from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { ControlledMenu, MenuItem, SubMenu } from '@szhsin/react-menu'
import { useNavigation } from '@react-navigation/native'
import { useWindowDimensions } from 'react-native'
import { DISPATCH } from 'state/consts'
import '@szhsin/react-menu/dist/index.css'
import './webmenu.css'
import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Image,
  StatusBar,
  useTheme,
  Divider,
  View,
} from 'native-base'
import { usePlayer } from 'state/hooks/usePlayer'
import { useCycles } from 'state/hooks/useCycles'
import { useAuth } from 'state/hooks/useAuth'
import logo from 'assets/images/icons/NLogo.png'
import { TouchableOpacity } from 'react-native'
import { usePurchases } from 'hooks/usePurchases'
import {Subscription} from 'components/Modal/Subscription';
import { Text } from 'react-native-svg'

const NavigationWeb = ({ history }: any) => {
  const {
    dispatch: { dismissPlayer },
  } = usePlayer()
  const {
    dispatch: { dispatch: authDispatch },
    state: { user },
  } = useAuth()

  const {
    dispatch: { cycleNew, dispatch },
    state,
  } = useCycles()
  async function resetCycle() {
    await dismissPlayer()

    await cycleNew({
      dispatch,
      state,
      user,
      reset: true,
    })
  }
  const [hovered, setHover] = useState()
  const [activeRoute, setActiveroute] = useState('Home')
  const purchases = usePurchases()

  const { colors } = useTheme()

  const navigation = useNavigation()
  const currentRoute = navigation.getCurrentRoute()
  const ref = useRef(null)
  const { width: deviceWidth } = useWindowDimensions()

  useEffect(() => {
    if (typeof currentRoute !== 'undefined') {
      setActiveroute(currentRoute.name)
    }
  }, [navigation])

  return (
    <>
      <StatusBar bg="#FF7B6C" barStyle="light-content" />
      <Box safeAreaTop />
      <HStack
        bg='#FF7B6C'
        px="5"
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        style={{
          zIndex: 1000,
        }}
      >
        <HStack alignItems="center">
          <TouchableOpacity
            onPress={() => (
              navigation.navigate('Home'), setActiveroute('Home')
            )}
          >
            <Image
              height={70}
              width={70}
              marginX={5}
              resizeMode="contain"
              source={ logo}
              alt="Neurocycle Logo"
            />
          </TouchableOpacity>
          {deviceWidth >= 525 && (
            <>
              <Button
                variant={
                  activeRoute === 'Home' ? 'activeNavigation' : 'navigation'
                }
                onPress={() => (
                  navigation.navigate('Home'), setActiveroute('Home')
                )}
              >
                HOME
              </Button>
              <Button
                variant={
                  activeRoute === 'Results' ? 'activeNavigation' : 'navigation'
                }
                onPress={() => (
                  navigation.navigate('Results'), setActiveroute('Results')
                )}
              >
                RESULTS
              </Button>
              <Button
                variant={
                  activeRoute === 'Resources'
                    ? 'activeNavigation'
                    : 'navigation'
                }
                onPress={() => (
                  navigation.navigate('Resources'), setActiveroute('Resources')
                )}
              >
                RESOURCES
              </Button>
              {purchases?.state.premium && (
             <Button
             variant={
               activeRoute === 'Neurolive'
                 ? 'activeNavigation'
                 : 'navigation'
             }
             onPress={() => {
               console.log("PRESSING !!!!!!!!");
               navigation.navigate(
                 'Neurolive' 
               );
               setActiveroute('Neurolive');
             }}
             disabled={!purchases?.state.premium}
           >
             NEUROLIVE
           </Button>
)}
{!purchases?.state.premium && (
  <Button
    variant={
      activeRoute === 'AccountSubscription'
        ? 'activeNavigation'
        : 'navigation'
    }
    onPress={() => {
      console.log("PRESSING !!!!!!!!");
      navigation.navigate('AccountSubscription');
      setActiveroute('AccountSubscription');
    }}
    disabled={purchases?.state.premium}
  >
    NEUROLIVE (Premium)
  </Button>
)}

            </>
          )}
        </HStack>

        <HStack>
          <IconButton
            onPressIn={() => setHover(true)}
            onHoverIn={() => setHover(true)}
            ref={ref}
            icon={
              <Icon
                onPressIn={() => setHover(true)}
                onHoverIn={() => setHover(true)}
                as={MaterialIcons}
                name="person"
                size="md"
                color="white"
              />
            }
          />
          <ControlledMenu
            captureFocus
            align={'start'}
            viewScroll={'initial'}
            offsetX={-200}
            offsetY={5}
            state={hovered ? 'open' : 'closed'}
            anchorRef={ref}
            onClose={() => setHover(false)}
          >
            <MenuItem onClick={() => navigation.navigate('Results')}>
              Results
            </MenuItem>
            <MenuItem onClick={() => navigation.navigate('Resources')}>
              Resources
            </MenuItem>
            <MenuItem onClick={() => navigation.navigate('HistoryNavigator')}>
              History
            </MenuItem>
            <Divider my={2} />
            <MenuItem onClick={() => navigation.navigate('ProfileInfo')}>
              Edit Profile
            </MenuItem>
            <MenuItem
              onClick={() => navigation.navigate('AccountSubscription')}
            >
              Manage Subscription
            </MenuItem>
            <Divider my={2} />
            <MenuItem onClick={() => navigation.navigate('GeneralAbout')}>
              About
            </MenuItem>
            <MenuItem onClick={() => navigation.navigate('GeneralInfo')}>
              Info
            </MenuItem>
            <MenuItem onClick={() => navigation.navigate('GeneralHelp')}>
              Help
            </MenuItem>
            <MenuItem onClick={() => navigation.navigate('GeneralContactUs')}>
              Contact Us
            </MenuItem>
            <Divider my={2} />
            <MenuItem onClick={() => navigation.navigate('ResetCycle')}>
              Restart Current Cycle
            </MenuItem>
            <Divider my={2} />
            <MenuItem
              onClick={() => authDispatch({ type: DISPATCH.AUTH.LOGOUT_USER })}
            >
              Logout
            </MenuItem>
          </ControlledMenu>
        </HStack>
      </HStack>
    </>
  )
}

export default NavigationWeb
