import React, { useState, useEffect } from 'react'
import { Center, Container, Text, useTheme } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { CalendarList, LocaleConfig, DateData } from 'react-native-calendars'
import { firestore } from 'config/firebase'
import { useAuth } from 'state/hooks/useAuth'
import {
  addMonths,
  differenceInCalendarDays,
  format,
  isLastDayOfMonth,
  lastDayOfMonth,
  subMonths,
} from 'date-fns'

import { FIREBASE_COLLECTIONS } from '../../state/consts'

LocaleConfig.locales[''] = {
  ...LocaleConfig.locales[''],
  dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
}

function getTimeWindow(date: Date) {
  return {
    end: addMonths(
      new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
      1
    ),
    start: subMonths(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        lastDayOfMonth(date).getDate(),
        23,
        59,
        59
      ),
      1
    ),
  }
}

function toMarkedDates(dates: any[]) {
  return dates.reduce((dateMap: any, date: Date) => {
    const dateKey = format(date, 'yyyy-MM-dd')
    const day = date.getDate()
    if (!dateMap[dateKey]) {
      dateMap[dateKey] = {
        color: '#50cebb',
        startingDay:
          day === 1 ||
          !dates.some((d) => {
            // check if has immediate previous day
            return differenceInCalendarDays(date, d) === 1
          }),
        endingDay:
          isLastDayOfMonth(date) ||
          !dates.some((d) => {
            // check if has immediate next day
            return differenceInCalendarDays(d, date) === 1
          }),
      }
      if (day === new Date().getDate()) {
        dateMap[dateKey].textColor = '#ffffff'
      }
    }
    return dateMap
  }, {})
}

export function AppCalendar(props: { onDayPress: (day: DateData) => void }) {
  const [data, setData] = useState()
  const today = new Date()
  const { colors, fonts } = useTheme()
  const { width: deviceWidth } = useWindowDimensions()
  const [timeWindow, setTimeWindow] = useState(getTimeWindow(today))
  const {
    state: { user },
  } = useAuth()

  useEffect(() => {
    async function getActiveReaches() {
      return firestore()
        .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
        .where('uid', '==', user?.uid)
        .where('createdAt', '>=', timeWindow.start)
        .where('createdAt', '<=', timeWindow.end)
        .orderBy('createdAt')
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            return snapShot.docs.map((d) => d.data().createdAt.toDate())
          }
          return []
        })
    }
    async function getAudioHistory() {
      return firestore()
        .collection(FIREBASE_COLLECTIONS.HISTORY)
        .where('uid', '==', user?.uid)
        .where('createdAt', '>=', timeWindow.start)
        .where('createdAt', '<=', timeWindow.end)
        .orderBy('createdAt')
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            return snapShot.docs.map((d) => d.data().createdAt.toDate())
          }
          return []
        })
    }

    async function getCycleHistory() {
      return firestore()
        .collection(FIREBASE_COLLECTIONS.HISTORY_CYCLE)
        .where('uid', '==', user?.uid)
        .where('createdAt', '>=', timeWindow.start)
        .where('createdAt', '<=', timeWindow.end)
        .orderBy('createdAt')
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            return snapShot.docs.map((d) => d.data().createdAt.toDate())
          }
          return []
        })
    }

    async function getDates() {
      const [reaches, audioHistory, cycleHistory] = await Promise.all([
        getActiveReaches(),
        getAudioHistory(),
        getCycleHistory(),
      ])
      setData((dates) => {
        return {
          ...(dates || {}),
          ...toMarkedDates([
            ...new Set(reaches.concat(audioHistory, cycleHistory)),
          ]),
        }
      })
    }
    if (user?.uid) {
      getDates()
    }
  }, [user, timeWindow])

  function getMonthDifference(startDate: Date, endDate: Date) {
    endDate = new Date(endDate)
    startDate = new Date(startDate)

    console.log(endDate, startDate)
    const final =
      endDate && startDate
        ? endDate.getMonth() -
          startDate.getMonth() +
          12 * (endDate.getFullYear() - startDate.getFullYear())
        : 1

    console.log(final)
    return final + 1
  }

  return (
    <Center width="100%">
      <Container>
        <CalendarList
          calendarStyle={{
            width: Math.min(deviceWidth, 1024),
          }}
          futureScrollRange={0}
          pastScrollRange={10}
          onDayPress={(day) => {
            if (data && day && data[day.dateString]) {
              props.onDayPress?.(day)
            }
          }}
          showScrollIndicator
          animateScroll
          scrollEnabled
          hideExtraDays
          markingType={'period'}
          markedDates={data}
          onVisibleMonthsChange={(months) => {
            data && console.log(Object.keys(data)[0])
            if (months[0]) {
              setTimeWindow(getTimeWindow(new Date(months[0].timestamp)))
            }
          }}
          theme={{
            todayTextColor: colors.tangaroa[500],
            dayTextColor: colors.tangaroa[500],
            'stylesheet.day.period': {
              text: {
                fontSize: 16,
                fontFamily: fonts.semibold,
                marginTop: 8,
                // width: '100%',
              },
            },
            'stylesheet.calendar.header': {
              dayHeader: {
                marginTop: 2,
                marginBottom: 7,
                width: 32,
                textAlign: 'center',
                fontSize: 17,
                fontFamily: fonts.light,
                color: colors.tangaroa[500],
              },
              header: {
                flexDirection: 'row',
                fontFamily: fonts.bold,
                marginTop: 6,
              },
              monthText: {
                fontSize: 20,
                fontFamily: fonts.bold,
                marginBottom: 24,
                marginTop: 24,
              },
            },
          }}
        />
      </Container>
    </Center>
  )
}
