import React, { useMemo } from 'react'
import { string, object } from 'prop-types'
import {
  AspectRatio,
  Box,
  Pressable,
  Skeleton,
  Text,
  VStack,
  useTheme,
} from 'native-base'
import { ImageBackground, ImageSourcePropType } from 'react-native'
import { usePlayer, useCycles, usePurchases, useAuth } from 'state/hooks/index'

interface MainCardProps extends React.ComponentProps<'div'> {
  title: string
  subTitle?: string
  source: ImageSourcePropType
}

export function MainCard({ title, subTitle, source }: MainCardProps) {
  const { ui, fontConfig } = useTheme()
  const {
    dispatch: { toggleSize },
  } = usePlayer()
  const {
    state: cycleState,
    dispatch: { setShowDayInfoCard, dispatch, setOpen },
  } = useCycles()
  const { day } = cycleState
  const purchases = usePurchases()
  const auth = useAuth()
  const loading = useMemo(() => {
    return !cycleState.loaded || !purchases.state.initialized
  }, [cycleState.loaded, purchases.state.initialized])

  const handlePress = async () => {
    if (loading) return
    purchases.dispatch.checkIfShouldShowSubscriptionModal(
      cycleState,
      auth.state.user
    )
    if (day >= 21) {
      setShowDayInfoCard(true, dispatch)
    }
    setOpen(true, dispatch)
  }

  const textShadow = {
    textShadowColor: 'rgba(0, 0, 0, 0.05)',
    textShadowOffset: { width: 1, height: 2 },
    textShadowRadius: 5,
  }

  return (
    <Pressable
      borderRadius={15}
      backgroundColor={'#5ed8c9'}
      style={ui.mainCard}
      onPress={() => handlePress()}
      mb={6}
    >
      <ImageBackground
        source={source}
        style={ui.backgroundFill}
        resizeMode="contain"
      >
        <>
          <Box
            borderRadius={ui.borderRadius}
            width="100%"
            alignItems="flex-start"
            style={ui.mainCard}
            p={2}
          >
            {!loading ? (
              <Text
                variant="text-MainCardTitle"
                bold
                style={{ ...fontConfig.center, ...textShadow, color: '#000812' }}
                p={2}
                fontSize={30}
              >
                {title}
              </Text>
            ) : (
              <VStack mt={2} justifyContent="center" alignItems="center">
                <Skeleton
                  variant="circle"
                  size="8"
                  w={250}
                  mb={2}
                  startColor="puertorico.200"
                />
                <Skeleton
                  variant="circle"
                  size="8"
                  w={225}
                  mb={2}
                  startColor="puertorico.200"
                />
                <Skeleton
                  variant="circle"
                  size="8"
                  w={175}
                  mb={2}
                  startColor="puertorico.200"
                />
              </VStack>
            )}
            <Text
              variant="text-MainCardSubTitle"
              italic
              mt={2}
              style={{ ...fontConfig.center, ...textShadow, color: 'white' }}
            >
              {subTitle}
            </Text>
            <VStack
              width="100%"
              alignItems="flex-start"
              position="absolute"
              bottom={0}
              pb={2}
              px={4}
            >
              {!loading ? (
                <Text
                  variant="text-MainCardCycleDays"
                  bold
                  style={{
                    ...fontConfig.center,
                    ...textShadow,
                    color: '#000812',
                  }}
                >
                  Day {day + 1}
                </Text>
              ) : (
                <Skeleton
                  variant="circle"
                  size="6"
                  w={125}
                  mb={2}
                  startColor="puertorico.200"
                />
              )}
              {!loading ? (
                <Text
                  variant="text-MainCardCycleDaysLeft"
                  italic
                  style={{
                    ...fontConfig.center,
                    ...textShadow,
                    color: '#000812',
                  }}
                >
                  {63 - day - 1} {63 - day - 1 === 1 ? 'day' : 'days'} left
                </Text>
              ) : (
                <Skeleton
                  variant="circle"
                  size="6"
                  w={100}
                  startColor="puertorico.200"
                />
              )}
            </VStack>
          </Box>
        </>
      </ImageBackground>
    </Pressable>
  )
}

MainCard.propTypes = {
  title: string.isRequired,
  subTitle: string.isRequired,
  link: string,
  source: object.isRequired,
}
