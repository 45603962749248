import { AuthContainer } from 'components/Containers/Auth'
import { PageContainer } from 'components/Containers/Page'
import React from 'react'
import { Box, Button, Image, Text, VStack, useTheme } from 'native-base'
import { Platform } from 'react-native'
import { RFValue } from 'react-native-responsive-fontsize'
import logo from 'assets/images/icons/NLogo.png'
import { useNavigation } from '@react-navigation/native'

export function SplashScreen() {
  const navigation = useNavigation()
  const { colors } = useTheme()
  const style = {
    bg: '#FF7B6C',
    fontColor: colors.white[500],
    borderColor: colors.white[500],
    textAlign: 'center',
    authFooter: { textAlign: 'center' },
    actionFooter: { textAlign: 'center' },
    width: '100%',
  }

  return (
    <Box style={{ flex: 1 }}>
      <AuthContainer style={style}>
        <Box flex={1} justifyContent="center">
          <Box mb={Platform.OS === 'android' ? -6 : undefined}>
            <Box display="flex" alignItems="center">
              <Image
                height={260}
                width={'100%'}
                maxWidth={360}
                marginY={-10}
                resizeMode="contain"
                source={logo}
                alt="Neurocycle Logo"
              />
            </Box>
            <Box paddingTop={2} flexDirection="row" justifyContent="center">
              <Text
                fontSize={RFValue(26)}
                pb={1}
                textAlign="center"
                style={{ color: style.fontColor, letterSpacing: 0.5 }}
              >
                neurocycle
              </Text>
              <Text
                color={style.fontColor}
                fontSize={RFValue(10)}
                fontFamily="arial"
                marginLeft={-1}
              >
                &reg;
              </Text>
            </Box>

            <Box
              style={{
                display: 'flex',
              }}
              flexDirection={'column'}
              mb={10}
              mt={7}
              p={2}
            >
              <Text
                textAlign="center"
                fontSize={RFValue(14)}
                style={{
                  color: style.fontColor,
                  letterSpacing: 0.5,
                  lineHeight: 35,
                }}
              >
                The first scientifically researched and tested 5 step program to
                help you manage your mind and mental health.
              </Text>
            </Box>
            <VStack space={2}>
              <Button
                variant="primary"
                onPress={() => navigation.navigate('AuthRegister')}
              >
                REGISTER
              </Button>
              <Button
                variant="secondary"
                border={0}
                onPress={() => navigation.navigate('AuthLogin')}
              >
                LOGIN
              </Button>
            </VStack>
          </Box>
        </Box>
      </AuthContainer>
    </Box>
  )
}
