import React from 'react'
import { Icon } from 'native-base'
import { G, Polyline, Line, Circle } from 'react-native-svg'

export function RecheckIcon(props) {
  return (
    <Icon width="39px" height="38px" viewBox="0 0 39 38" version="1.1">
      <G
        id="Phase-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Home--Notification-Scroll-"
          transform="translate(-39.000000, -1158.000000)"
          stroke="#021D3D"
          strokeWidth="1.5"
        >
          <G id="Recheck-Copy" transform="translate(27.000000, 1148.000000)">
            <G id="Group-5" transform="translate(13.933243, 11.433243)">
              <Circle
                id="Oval"
                transform="translate(17.677670, 17.677670) rotate(-45.000000) translate(-17.677670, -17.677670) "
                cx="17.6776695"
                cy="17.6776695"
                r="12.5"
              />
              <Line
                x1="26.8700577"
                y1="26.8700577"
                x2="33.9411255"
                y2="33.9411255"
                id="Line-4"
                strokeLinecap="square"
              />
              <Polyline
                id="Path-2"
                points="12.2193447 19.3212734 15.1177822 21.9700771 22.521835 12.2503506"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}
