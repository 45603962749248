import React, { useState } from 'react'
import { PageContainer } from 'components/Containers/Page'
import { Box, Text, useTheme, Pressable } from 'native-base'
import { Linking, Platform } from 'react-native'
import { Subscription } from 'components/Modal/Subscription'
import { useAuth } from 'state/hooks/useAuth'

function AccountSubscriptionScreen() {
  const { colors } = useTheme()
  const [, setShowSubscription] = useState(false)

  const {
    dispatch: { setShowSubscriptionModal },
    state: {
      profile,
      user,
      subscriberBool,
      showSubscriptionModal,
      showRemindMeLater,
    },
  } = useAuth()

  const handleCancel = () => {
    if (Platform.OS === 'ios') {
      Linking.openURL('https://apps.apple.com/account/subscriptions')
      return
    }
    if (Platform.OS === 'android') {
      Linking.openURL(
        'https://play.google.com/store/account/subscriptions?package=com.switch_android&sku=com.switch_android'
      )
      return
    }
  }

  if (!subscriberBool) {
    return (
      <Box
        style={{
          minHeight: '100vh',
          borderBottomWidth: 1,
          borderColor: 'rgba(2,29,61,.1)',
        }}
      >
        <Subscription
          showScreen={true}
          showRemindMeLater={showRemindMeLater}
          user={user}
          userProfile={profile}
          setShowSubscription={setShowSubscription}
          showSubscription={showSubscriptionModal}
          setShowSubscriptionModal={setShowSubscriptionModal}
        />
      </Box>
    )
  }

  return (
    <PageContainer scheme="willowbrook" standAlone={false}>
      <Box
        py={5}
        px={5}
        style={{ height: 250, borderRadius: 15, backgroundColor: 'white' }}
      >
        <Box
          style={{
            height: '20%',
            borderBottomWidth: 1,
            borderColor: 'rgba(2,29,61,.1)',
          }}
        >
          <Text
            style={{
              paddingTop: 5,
              fontSize: 20,
              fontWeight: 'bold',
              color: colors.tangaroa[500],
            }}
          >
            Your Plan
          </Text>
        </Box>
        <Box pt={5} style={{ height: '80%', justifyContent: 'space-between' }}>
          <Text
            style={{
              paddingTop: 5,
              fontSize: 20,
              fontWeight: 'bold',
              color: colors.tangaroa[500],
            }}
          >
            {subscriberBool.title || ' '}
          </Text>
          <Text
            style={{
              paddingTop: 18,
              fontSize: 16,
              color: colors.tangaroa[500],
            }}
          >
            Access to the full program - Active reaches, mini guides
          </Text>
          <Pressable
            onPress={() => handleCancel()}
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Text
              style={{
                paddingTop: 18,
                fontSize: 18,
                color: colors.tangaroa[500],
              }}
            >
              {' '}
              Cancel{' '}
            </Text>
          </Pressable>
        </Box>
      </Box>
    </PageContainer>
  )
}

export default AccountSubscriptionScreen
