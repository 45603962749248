import React from 'react'
import { number, string } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G, Rect } from 'react-native-svg'

export function Home({ width, height, stroke }) {
  return (
    <Icon
      viewBox="0 0 22 23"
      width={width}
      height={height}
      style={{ height, width }}
    >
      <G id="Rd2" stroke="none" strokeWidth="1" fillRule="evenodd" fill="none">
        <G id="Rd2" strokeWidth="1" fill="#021D3D" fillRule="evenodd">
          <G
            id="Tab-Bar"
            transform="translate(-37.000000, -2.000000)"
            fill="#021D3D"
          >
            <G
              fill={stroke === '#021D3D' ? '#021D3D' : stroke}
              id="Home"
              transform="translate(38.000000, 3.000000)"
            >
              <Path
                d="M8.52904498,0.332265413 L0.342172276,6.51811784 C-0.345688674,7.03785309 -0.75,7.85008985 -0.75,8.71222523 L-0.75,18.7166748 C-0.75,20.2354579 0.481216938,21.4666748 2,21.4666748 L18,21.4666748 C19.5187831,21.4666748 20.75,20.2354579 20.75,18.7166748 L20.75,8.68665571 C20.75,7.84442016 20.3640479,7.04862688 19.7026635,6.52715644 L11.8895362,0.366873532 C10.9075346,-0.407388476 9.52678412,-0.421608175 8.52904498,0.332265413 Z M10.9608106,1.54478223 L18.7739379,7.70506513 C19.0745672,7.94209715 19.25,8.30382137 19.25,8.68665571 L19.25,18.7166748 C19.25,19.4070307 18.6903559,19.9666748 18,19.9666748 L2,19.9666748 C1.30964406,19.9666748 0.75,19.4070307 0.75,18.7166748 L0.75,8.71222523 C0.75,8.32034551 0.933777875,7.95114699 1.24644194,7.71490369 L9.43331464,1.52905126 C9.88683244,1.18638145 10.5144463,1.19284495 10.9608106,1.54478223 Z"
                id="Rectangle"
                fillRule="nonzero"
              />
              <Rect id="Rectangle" x="8" y="14" width="4" height="7" rx="1" />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}

Home.defaulProps = {
  width: 20,
  height: 20,
  stroke: '#021D3D',
}

Home.propTypes = {
  width: number,
  height: number,
  stroke: string,
}
