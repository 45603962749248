import React from 'react'
import { bool, node, string } from 'prop-types'
import { Box, ScrollView, useTheme, Center, Container } from 'native-base'
import { Platform } from 'react-native'
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs'

import Footer from '../../Footer'

const sharedPropTypes = {
  children: node.isRequired,
  scheme: string,
  type: string,
  padTabBar: bool,
  standAlone: bool,
}

const PAGE_MAX_WIDTH = 1024

function PageWrapper({ children, type, scheme, pt, padTabBar, standAlone, maxWidth = PAGE_MAX_WIDTH }) {
  const { colors } = useTheme()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tabBarHeight = Platform.OS === 'ios' ? useBottomTabBarHeight() : null
  const bg = { backgroundColor: colors[scheme][500], height: '100%' }

  return (
    <>
      {type === 'scrollable' ? (
        <ScrollView
          safeArea
          px={6}
          pt={pt || (Platform.OS === 'android' ? 6 : 16)}
          style={bg}
        >
          <Center>
            <Container width={'100%'} maxWidth={maxWidth}>
              {padTabBar ? <Box height={tabBarHeight} /> : null}
              <Box width="100%">
                {children}
              </Box>
            </Container>
          </Center>
          <Footer />
        </ScrollView>
      ) : (
        <Box safeArea px={6} style={bg}>
          <Center>
              <Container width={'100%'} maxWidth={maxWidth}>
              {padTabBar ? <Box height={tabBarHeight} /> : null}
                <Box width="100%">
                  {children}
                </Box>
            </Container>
          </Center>
          <Footer />
        </Box>
      )}
    </>
  )
}

export function PageContainer({
  children,
  padTabBar = false,
  pt = null,
  scheme = 'white',
  type = 'scrollable',
  standAlone = true,
  maxWidth = PAGE_MAX_WIDTH
}) {
  return (
    <PageWrapper
      type={type}
      scheme={scheme}
      padTabBar={padTabBar}
      pt={pt}
      standAlone={standAlone}
      maxWidth={maxWidth}
    >
      {children}
    </PageWrapper>
  )
}

PageWrapper.propTypes = sharedPropTypes
PageContainer.propTypes = sharedPropTypes
