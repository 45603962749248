import React from 'react'
import { number, string } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G } from 'react-native-svg'

export function Quote({ width, height, fill }) {
  return (
    <Icon width={width} height={height} viewBox="0 0 37 31">
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G
          id="Home---Mini-Player"
          transform="translate(-40.000000, -789.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <G id="Group-7" transform="translate(25.000000, 728.000000)">
            <Path
              d="M29.448,84.744 C29.672,81.608 27.32,79.592 24.296,77.688 L21.16,75.784 C22.616,71.752 26.2,67.608 32.136,63.688 L30.344,61 C21.16,66.488 15,74.888 15,82.392 C15,87.88 18.248,90.904 22.168,91.128 C26.088,91.352 29.224,88.888 29.448,84.744 Z M48.824,84.744 C49.048,81.608 46.696,79.592 43.672,77.688 L40.648,75.784 C41.992,71.752 45.576,67.608 51.624,63.688 L49.72,61 C40.536,66.488 34.376,74.888 34.376,82.392 C34.376,87.88 37.624,90.904 41.544,91.128 C45.464,91.352 48.6,88.888 48.824,84.744 Z"
              id="“"
            />
          </G>
        </G>
      </G>
    </Icon>
  )
}

Quote.defaulProps = {
  width: 37,
  height: 31,
}

Quote.propTypes = {
  width: number,
  height: number,
  fill: string.isRequired,
}
