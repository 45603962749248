import { object, string } from 'yup'
import { INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES } from 'state/consts'
import { Button, Text, Icon } from 'native-base'
import { TextInput } from 'components/Form/Inputs/TextInput'
import { AntDesign } from '@native-base/icons'

export const resetConfig = {
  dark: true,
  validationSchema: object().shape({
    email: string().email('Invalid email').required('Required').trim(),
  }),
  heading: {
    title: 'Oh no!',
    subTitle: "Let's start your email recovery",
  },
  actionFooter: [
    {
      link: 'AuthLogin',
      text: (
        <Text style={{ color: 'white' }}>
          <Icon size={4} as={AntDesign} name="arrowleft" color="white" /> Back
          to Login with your credentials
        </Text>
      ),
    },
  ],
  rows: [
    {
      id: 'reset_row0',
      fields: [
        {
          id: 'email',
          label: 'Email',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          inputProps: {
            autoCapitalize: 'none',
            autoComplete: 'email',
            autoCorrect: false,
            autoFocus: true,
          },
          keyboardType: KEYBOARD_TYPES.EMAIL,
          textContentType: TEXT_CONTENT_TYPES.EMAIL,
        },
      ],
    },
  ],
  actions: {
    submit: {
      id: 'reset_submit',
      component: Button,
      variant: 'primary',
      label: 'RESET PASSWORD',
    },
  },
}
