import React, { useEffect } from 'react'
import { Box, useMediaQuery } from 'native-base'
import { arrayOf, object, shape, string } from 'prop-types'
import { SubCard } from 'components/Cards/SubCard'

export function SubCards({ config, expired, onPressSubscription, navigation }) {
  const [isSmallScreen] = useMediaQuery({
    minWidth: 767,
    maxWidth: 767,
  })

  return (
    <>
      {config.map(({ id, link, title, source, cardbg }, index) => (
        <Box
          backgroundColor={cardbg}
          key={id}
          flex={1}
          borderRadius={15}
          mb={5}
          width={'100%'}
          maxWidth={'100%'}
          height={{ base: '15rem', md: '15rem', lg: '20rem' }}
          ml={isSmallScreen ? 4 : 0}
        >
          <SubCard
            navigation={navigation}
            link={link}
            title={title}
            source={source}
            expired={expired}
          />
        </Box>
      ))}
    </>
  )
}

SubCards.propTypes = {
  config: arrayOf(
    shape({
      id: string.isRequired,
      source: object.isRequired,
      link: string.isRequired,
      title: string.isRequired,
    })
  ),
}
