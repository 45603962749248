import { EnumCycleStepType } from 'state/cyclesMatrix'
import _ from 'lodash'

export function whichTrackStep(state, direction, dayComplete = false) {
  const { cycle, day, track, progress, steps, questionnaires } = state

  let out = {
    cycle,
    day,
    track,
    progress,
  }

  const day0Exists = questionnaires[0] ? true : false
  const day21Exists = questionnaires[20] ? true : false
  const day42Exists = questionnaires[41] ? true : false
  const day63Exists = questionnaires[62] ? true : false

  const totalDays = 63
  const currentDay = steps[day]
  const totalTracks = day >= 21 ? 0 : currentDay.length - 1
  let nextDay = day,
    nextTrack = track

  if (direction === 'next') {
    // Move to the next day
    if (track + 1 >= currentDay.length || dayComplete) {
      nextTrack = 0
      // Move to the next cycle
      if (day + 1 >= steps.length) {
        nextDay = 0
        out.cycle++
      } else {
        // Stay on the same cycle, but move to the next day
        nextDay = day + 1
      }
    } else {
      // Stay on the same day, but move to the next track
      nextTrack++
    }

    out.progress = (nextDay / steps.length) * 100
  }

  if (direction === 'back') {
    nextDay = day - 1
    out.progress = (nextDay / steps.length) * 100

    out.activeReachPop = track === totalTracks && nextDay <= totalDays
    switch (true) {
      case nextDay !== 63:
        out.track = 0
        out.day = nextDay
        out.questionnairePop =
          (nextDay === 20 && !day21Exists) ||
          (nextDay === 42 && !day42Exists) ||
          (nextDay === 62 && !day63Exists)
            ? true
            : false
        out.questionnaireCompleted = false
        break

      case nextDay === 63:
        out.activeReachPop = false
        out.track = 0
        out.day = 0
        out.cycle += 1
        break

      default:
        break
    }
  }

  if (direction === 'prev') {
    nextTrack = track - 1
    nextDay = Math.max(nextTrack < 0 ? day - 1 : day, 0)

    function getPrevTrack(_day) {
      return _.findLastIndex(steps[_day], (t) =>
        [
          EnumCycleStepType.AUDIO,
          EnumCycleStepType.VIDEO,
          EnumCycleStepType.DAILY_INFO,
        ].includes(t.type)
      )
    }

    if (nextTrack < 0) {
      if (nextDay > 0) {
        nextTrack = Math.max(getPrevTrack(nextDay), 0)
      } else {
        nextTrack = 0
      }
    }
    out.progress = (nextDay / steps.length) * 100
  }
  out.day = nextDay
  out.track = nextTrack
  if (nextDay >= 0 && nextTrack >= 0) {
    out.step = steps[nextDay][nextTrack]
    out.type = out.step.type
  }
  return out
}
