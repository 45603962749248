import React, { Fragment, useState } from 'react'
import { useWindowDimensions, Platform } from 'react-native'
import {
  Box,
  Center,
  Pressable,
  Text,
  HStack,
  CloseIcon,
  useTheme,
  Container,
} from 'native-base'
import * as Haptics from 'expo-haptics'
import { useNavigation } from '@react-navigation/native'
import { DISPATCH } from 'state/consts'
import {
  Back15,
  Ellipses,
  Forward15,
  NextTrack,
  PauseTrack,
  PlayTrack,
  PreviousTrack,
} from 'components/Icons'
import { PlayerHeaderAction } from 'components/Player/PlayerHeaderAction'
import { PlayerHelpCard } from 'components/Cards'
import { Loading } from 'components/Loading'
import { PlayerSlider } from './PlayerSlider'
import { ICycleStepProps } from 'state/interfaces'
import { TCycleStep } from 'state/cyclesMatrix'
import { usePlayer, useCycles, useAuth, usePurchases } from 'hooks/index'

export interface Props {
  onNext?: () => void
  onBack?: () => void
  controls: {
    toggleSound: () => AbortController
  }
}

export function FullsizePlayer(props: Props) {
  const { colors } = useTheme()
  const { height: deviceHeight } = useWindowDimensions()
  const navigation = useNavigation()
  const {
    dispatch: { dismissPlayer, dispatch, scrub, skip15, skipTo },
    state,
  } = usePlayer()
  const { state: cycleState } = useCycles()
  const [showHelpModal, setShowHelpModal] = useState(false)
  const { cycle, day } = cycleState
  const { controls } = props
  const [abortController, setAbortController] = useState<AbortController>()
  const purchases = usePurchases()

  const flexStyles = { display: 'flex', justifyContent: 'space-between' }

  async function _toggleSound() {
    setAbortController(controls?.toggleSound())
  }

  async function onNext() {
    if (abortController) abortController.abort('FullsizePlayer props.onNext')
    if (props.onNext) props.onNext()
  }

  async function onBack() {
    if (abortController) abortController.abort('FullsizePlayer props.onBack')
    if (props.onBack) props.onBack()
  }

  return (
    <Box
      style={{
        height: '100%',
      }}
      bgColor={colors.puertorico[500]}
    >
      <Center height="100%">
        <Container width="100%" height="100%" maxWidth={1024}>
          <PlayerHelpCard
            showModal={showHelpModal}
            setShowModal={setShowHelpModal}
            dismissPlayer={dismissPlayer}
            navigation={navigation}
          />
          <Box
            safeArea
            style={{
              ...flexStyles,
              flex: 1,
              width: '100%',
            }}
            px={8}
            py={6}
          >
            <Box style={{ ...flexStyles, flexDirection: 'row' }}>
              <Pressable onPress={() => setShowHelpModal(!showHelpModal)}>
                <Box p={3}>
                  <Ellipses width={20} height={10} fill={colors.white[500]} />
                </Box>
              </Pressable>
              <Box flex={2} pt={1} ml={4} mt={1}>
                {!state.mini ? (
                  <Text textAlign="center" variant="text-playerCycle">
                    Cycle {cycle + 1} (Day {day + 1})
                  </Text>
                ) : null}
              </Box>
              <Pressable
                onPress={() => {
                  if (Platform.OS !== 'web') {
                    Haptics.selectionAsync()
                  }
                  dispatch({ type: DISPATCH.PLAYER.TOGGLE_SIZE }, {})
                }}
              >
                <PlayerHeaderAction
                  icon={() => <CloseIcon color="white.500" size="4" />}
                />
              </Pressable>
            </Box>
            <Box>
              <Center
                width="100%"
                height={deviceHeight * 0.2}
                alignItems="center"
              >
                <Text mb={2} textAlign="center" variant="text-playerTitle">
                  {state.file?.title}
                </Text>
                <Text textAlign="center" variant="text-playerSubTitle">
                  {state.file?.subTitle}
                </Text>
              </Center>
            </Box>
            <Box height={deviceHeight * 0.4}>
              <Box
                style={{
                  ...flexStyles,
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                {state.mini ? (
                  <Box />
                ) : (
                  props.onBack && (
                    <Pressable onPress={onBack}>
                      <Box
                        width={41}
                        minHeight={41}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <PreviousTrack
                          width={30}
                          height={30}
                          innerFill={colors.white[500]}
                          outerFill={colors.puertorico[200]}
                        />
                      </Box>
                    </Pressable>
                  )
                )}
                <Pressable
                  onPress={_toggleSound}
                  style={{ alignSelf: 'center' }}
                >
                  {state.playing ? (
                    <PauseTrack
                      width={160}
                      height={160}
                      innerFill={colors.white[500]}
                      outerfill={colors.puertorico[200]}
                    />
                  ) : (
                    <PlayTrack
                      width={160}
                      height={160}
                      size={100}
                      innerFill={colors.white[500]}
                      outerfill={colors.puertorico[200]}
                    />
                  )}
                </Pressable>
                {state.mini ? (
                  <Box />
                ) : (
                  props.onNext && (
                    <Pressable onPress={onNext}>
                      <Box
                        width={41}
                        minHeight={41}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <NextTrack
                          width={30}
                          height={30}
                          innerFill={colors.white[500]}
                          outerFill={colors.puertorico[200]}
                        />
                      </Box>
                    </Pressable>
                  )
                )}
              </Box>
              {state.loading && <Loading color="white.500" />}
            </Box>
            <HStack
              mb={8}
              flex={1}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Pressable onPress={() => skip15('backward')}>
                <Back15 ml={2} outerfill={colors.puertorico[200]} />
              </Pressable>
              <Pressable onPress={() => skip15('forward')}>
                <Forward15 ml={2} outerfill={colors.puertorico[200]} />
              </Pressable>
            </HStack>
            <Box>
              <PlayerSlider
                timeMs={state.timeMs}
                duration={state.duration}
                scrub={scrub}
                skipTo={skipTo}
              />
            </Box>
          </Box>
        </Container>
      </Center>
    </Box>
  )
}
