import { object, string, date } from 'yup'
import { INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES } from 'state/consts'
import { Button, Text, Icon } from 'native-base'
import { TextInput } from 'components/Form/Inputs/TextInput'
import { DateInput } from 'components/Form/Inputs/DateInput'
import { AntDesign } from '@native-base/icons'

export const registerConfigStep1 = {
  dark: true,
  validationSchema: object().shape({
    firstName: string().required('Required').trim(),
    lastName: string().required('Required').trim(),
    dob: date().required('Required'),
  }),
  heading: {
    title: 'Hello!',
    subTitle: 'Create your account to get started',
  },
  actionFooter: [
    {
      link: 'AuthLogin',
      text: (
        <Text style={{ color: 'white' }}>
          <Icon size={4} as={AntDesign} name="arrowleft" color="white" /> Back
          to Login with your credentials
        </Text>
      ),
    },
  ],
  rows: [
    {
      id: 'register_row0',
      fields: [
        {
          id: 'firstName',
          label: 'First Name',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          inputProps: {
            autoComplete: 'name-given',
            autoFocus: true,
            autoCorrect: false,
            returnKeyType: 'next',
          },
          textContentType: TEXT_CONTENT_TYPES.FIRST_NAME,
          required: true,
        },
      ],
    },
    {
      id: 'register_row1',
      fields: [
        {
          id: 'lastName',
          label: 'Last Name',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          inputProps: {
            autoComplete: 'name-family',
            autoCorrect: false,
            returnKeyType: 'next',
          },
          textContentType: TEXT_CONTENT_TYPES.LAST_NAME,
          required: true,
        },
      ],
    },
    {
      id: 'register_row2',
      fields: [
        {
          id: 'dob',
          label: 'Birthdate',
          type: INPUT_TYPES.DATE,
          component: DateInput,
          inputProps: {
            autoComplete: 'birthdate-full',
            placeholder: ' ',
            returnKeyType: 'done',
          },
          textContentType: TEXT_CONTENT_TYPES.DOB,
          required: true,
        },
      ],
    },
  ],
  actions: {
    submit: {
      id: 'register_submit_step1',
      component: Button,
      variant: 'primary',
      label: 'CONTINUE',
    },
  },
}
