/* eslint-disable max-len */
import { APP_LINKS, FAQ_TYPES } from 'state/consts'

export const newCycleText = [
  {
    type: FAQ_TYPES.PARAGRAPH,
    text: 'If you miss a day, the app will remember where you left off. Try to be as diligent as possible to do the program daily. If you do miss a day or two, just carry on where you stopped. If, however, you miss more than two days, it is better to restart from day one.',
  },
  {
    type: FAQ_TYPES.PARAGRAPH,
    text: 'You can either go back and read through your history from day 1 to where you are now as a reminder and then carry on with the 21 day cycle, or you can restart from day 1 in the account section of the app.',
  },
]

const faqs = [
  {
    type: FAQ_TYPES.HEADING,
    text: 'a. BILLING AND SUBSCRIPTION',
    children: [
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What are the different subscription options? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You can either purchase a one month subscription for $14.99, a 3-month subscription for $29.99 or a year subscription for $99.99. Subscriptions automatically renew. You will be emailed a receipt for every purchase.  ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I change my plan?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You can change your subscription anytime by going to the subscription page in MY ACCOUNT. You will first need to disable the auto-renew feature for your current subscription and let it expire. Once it Is expired, you will then be able to select a new plan. ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I cancel my subscription?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'If you purchased your subscription via iTunes:',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Open the Settings app',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Scroll down to "iTunes and App Store"',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Your AppleID Email',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Select "View AppleID" (You may be asked to log in)',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap "Subscriptions"',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Select the NEUROCYCLE subscription',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap "Cancel Subscription" to disable it from auto-renewing at the end of the current billing cycle',
              },
            ],
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'If you purchased your subscription via Google Play:',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Open the Google Play Store',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Menu (3 small horizontal lines) next to "Google Play"',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Account and then Subscriptions',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Find the subscription You want to cancel',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Cancel',
              },
            ],
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I turn off automatic renew?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'If you purchased your subscription via iTunes: ',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Open the Settings app',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Scroll down to "iTunes and App Store"',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Your AppleID Email',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Select "View AppleID" (You may be asked to log in)',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap "Subscriptions"',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Select the NEUROCYCLE subscription',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap "Cancel Subscription" to disable it from auto-renewing at the end of the current billing cycle',
              },
            ],
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'If you purchased your subscription via Google Play: ',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Open the Google Play Store',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Menu (3 small horizontal lines) next to "Google Play"',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Account and then Subscriptions 9. Find the subscription You want to cancel',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Tap Cancel',
              },
            ],
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I apply a promo/gift card?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'To redeem a promo, voucher, or gift code:',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Go to MY ACCOUNT and click on SUBSCRIPTIOS at the top',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Click on the subscription option you want to purchase',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'At the checkout enter your code',
              },
            ],
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'I accidentally purchased an annual subscription in the app. How do I get a refund?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: `As iTunes has a separate system for billing and payment information, we kindly ask that you visit the iTunes customer care department for more information as to your account status and to arrange a refund or by calling their support number in your region. Once you have canceled and received the refund through iTunes, and you would like a different type of subscription please send an email to ${APP_LINKS.APP_SUPPORT.link} so we can correct your account on our end. `,
          },
        ],
      },
    ],
  },
  {
    type: FAQ_TYPES.HEADING,
    text: 'b. My Account',
    children: [
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I update my account information? ',
        children: [
          {
            text: 'To update your email and password follow these steps:',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Go to MY ACCOUNT',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Go to PROFILE',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Click the EDIT button in the top right',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Make your changes',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Click SAVE',
              },
            ],
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I change my password? ',
        children: [
          {
            text: 'To update your password follow these steps:',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Go to MY ACCOUNT',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Go to PROFILE',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Look for and tap the CHANGE PASSWORD button',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Make your changes',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Click SAVE',
              },
            ],
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I delete my account? ',
        children: [
          {
            text: `Please send an email to ${APP_LINKS.APP_SUPPORT.link} and we will take care of it for you. If you wish to return to NEUROCYCLE you will need to create a new account.`,
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I restart a cycle? ',
        children: [
          {
            text: 'To restart a cycle go to the ACCOUNT screen and click START NEW CYCLE located lower on the page.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I log out? ',
        children: [
          {
            type: FAQ_TYPES.ANSWER,
            text: 'Go to MY ACCOUNT',
          },
          {
            type: FAQ_TYPES.ANSWER,
            text: 'Look for and tap the LOGOUT button',
          },
        ],
      },
    ],
  },
  {
    type: FAQ_TYPES.HEADING,
    text: 'c. THE NEUROCYLE PROGRAM',
    children: [
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What is the NEUROCYCLE? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Neurocycle is based on Switch On Your brain 5-Step Learning Process® researched, developed and applied clinically over 30 years by Dr. Leaf. It is designed to help you find AND eliminate the underlying toxic thought and habit that is causing the mental health issues in your life. The Neurocycle, as a scientifically tested step system will help you increase self-awareness, self-regulation, and self-mastery of your thoughts with their embedded emotions and subsequent behaviors and reactions, AND decrease anxiety and depression by up to 81%.  ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Dr. Leaf will guide you through 5 steps over 21 days, and in those 21 days you will learn how to detox your mind, break down toxic thinking, and build new healthy thought patterns. Once you have completed the 21 days, you will need to stabilize the healthy new thought pattern you just built over the next 42 days – this just takes a minute a day - and turn it into a useful habit. Dr. Leaf guides you though this process at the end of every cycle. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Each step of the Neurocycle is meticulously designed to stimulate positive neuroplastic changes and balance the energy between the different frequencies in the brain (delta, theta, alpha, beta, gamma) to increase cognitive flexibility, insight, introspection, deep thinking, resilience, intelligence, and function, as well as overall improved mental and physical health.',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Thoughts change the structure of our brains (neuroplasticity), sending chemicals from the thought structure in the brain throughout the body, where they interface with our cells and DNA. My research shows that using the Neurocycle teaches you how to manage your mind and direct the neuroplasticity of your brain, thereby helping you make healthy changes in your brain and body. By doing this, you can create sustainable change in your brain that translates into sustainable changes in your mental, emotional and physical life, that is your overall wellbeing. ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What are the 5 Steps of the Neurocycle? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'The Neurocycle is a way to harness your thinking power to direct neuroplasticity when you are awake, which will then have a positive influence on the neuroplasticity happening in your brain while you sleep. It can be used for any task that requires thinking, feeling and choosing, which is everything! It is a scientifically researched 5-step process and acts as a “delivery system”: you are using your mind to “deliver” information to your mind and your brain, which will increase your overall brain and mental health. Here are the 5 steps:',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Gather/input: read, listen, observe. The goal of this step is to focus on and embrace what you are inputting into your brain through your mind by gathering awareness of the emotional and physical warning signals, as well as the behaviors and perspectives, you are manifesting. The purpose of this step is straightforward: to gather awareness of and embrace what you are hearing, reading, and experiencing, and to get information into the brain. When you gather awareness in this way, you can reconceptualize your thinking. ',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Reflect/analyze: ask, answer, discuss. The aim of this step is to focus on one thought, with all its embedded memories (which could be in the 10’s, 100’s or 1000’s), asking what it is about, answering yourself and discussing this thought in depth. You are essentially putting the thought on trial. This challenges the brain to move into a higher gear and introspect more deeply, which is what it is designed for: deep, intellectual thinking! Reflection makes a thought susceptible to change. ',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Write: in a metacog in your neurocycle journal. In this step, you will write down what you have been reflecting on in step 2. Writing things down is like putting your brain on paper, and helps to pull up buried memories from the nonconscious mind, adding clarity to what you are thinking about and allowing you to better see the area that needs to be detoxed by helping you to visualize your thoughts. This reinforces healthy dendrite growth and helps you change the connections in your brain. ',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Recheck: an editing process to check for accuracy. This step is like a mental autopsy. It helps build healthy, long-term thoughts with their embedded memories into the brain, allowing you to reconceptualize the toxic thought and turn the new healthy thought into a habit. In this step, you will evaluate what you have written in step 3 and think about the new healthy thought you want to build. You will also be able to rethink your reaction to the information, evaluating how the thought you are working on is changing, and then reconceptualizing it by looking for patterns and triggers (little by little, day by day). This is a progressive, forward-moving step, and is where you wire in the changes you want to see in your life. ',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Active reach: practice, apply, reteach. An Active Reach is the action you do during the day in response to the toxic thought you are working on. Is keeps you anchored in mental peace. You decide what the action is each day as you work through the 5 steps. You can do the same Active Reach as the previous day or a brand-new Active Reach—it is completely up to you. ',
              },
            ],
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What is a thought? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'A thought itself is the concept, the big idea. Thoughts have memories, like trees have branches. There are three types of memories in a thought: information, emotions, and physical sensations. The mind is made up of trillions and trillions of thoughts. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'A thought is a real physical thing that occupies mental real estate in the brain and mind. A thought is built into the brain as you use your mind—that is, as you think and feel and choose. These thoughts look like trees. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Thoughts are in three different places: in your brain, in the cells of your body, and in your mind. During the day you think, feel, and choose to build new thoughts into your mind and brain; at night you think, feel, and choose to sort out the thoughts you have built during the day, which provides the content for your dreams. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'A thought is something you build into your brain structurally as you think, feel and choose. When you think, feel and choose, this is your “mind in action”, and as you think feel and choose, you activate genetic expression and build protein branches called dendrites onto the neurons in your brain. With your mind, you actually change the structure of your brain—this is what it means to think! ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What is the science behind the Neurocycle? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Dr. Leaf has researched the mind-brain connection and the science of thought over 30 years. She, developed, researched and applied in clinical practice a model of learning based on her theory of thinking she developed called, the Geodesic Information Processing Theory™. The theory is based on the neurophysiology, neuroscience and neuropsychology of the learning process and was demonstrated to improve cognitive, academic, social and emotional performance by a conservative measure of 35-75%. Dr. Leaf recently conducted more clinical on an updated version of this Switch On Your Brain 5-Step Process®, now called the Neurocycle, and demonstrated that you can become empowered to feel in control of your mind, which can increase your feelings of control over your anxiety by up to 81%. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'People who have been taught to “tune in” to their nonconscious mind to manage their thought life and “detox” and “build” the brain are better able to navigate the ups and downs of life because they have a sense of control, which gives them hope. In our clinical trial, the subjects in the experimental group improved their mental health, brain health, blood physiology and cellular health by using the 5 steps of mind-management in the NEUROCYCLE app, and so can you! You can read more at https://drleaf.com/pages/current-clinical-trials. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Essentially, the Geodesic Information Processing Theory™ describes the mind-brain connection and science of thought stating that there are three levels: (1) the nonconscious metacognitive level; (2) the conscious cognitive level; and (3) symbolic output level. The Nonconscious metacognitive level, where 90-99% of the action in your mind is, operates at approximately 1027 for 24 hours a day and drives the conscious cognitive level. The conscious cognitive level, where up to 1-10% of the mind action is, operates at approximately 2000 actions per second when we are awake, and drives the Symbolic output level. Finally, the symbolic output level incorporates the 5 senses, with which you receive information from the outside world, and the means by which you express yourself, through speech or writing for example. Thoughts cycle through the 3 levels from Nonconscious metacognitive to Conscious cognitive to Symbolic output and vice versa. As we think, thoughts cycle through the 3 levels and they change the thoughts connected to them in a dynamic interrelationship.',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: `The Geodesic Information Processing Theory™ is explained in Dr. Leaf’s peer-reviewed journal articles, The Development of a Model for Geodesic Learning and her book ${APP_LINKS.BOOKS.LEARN_SUCCEED.link} `,
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What is the difference between the nonconscious and conscious mind?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'The nonconscious level of mind is where most mind-action occurs, and is active twenty-four hours a day, even when we are sleeping. The conscious mind is what operates when we are awake. What we consciously think about, say and do, which is the conscious level of mind, is driven by what is planted in our nonconscious level of mind—it has the roots of all our actions and words. The conscious and unconscious mind are designed to work together. The more intentional and deliberate our thinking, the more the y work together and the more wise, intelligent and brain heathy we become.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What must I do when the music is playing and no one is speaking? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Whenever just music is playing you have time to carry out whatever instruction I have given you. For example, I may ask you a question and then you will hear music playing. This music is the signal that you are meant to be thinking about, writing down or rechecking something about the thought. If you need more time, just press pause on the audio and continue when ready. Don’t be distressed if you don’t completely finish what you are doing - you will pick up where you left off again with more insight the next day. ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How many times can I re-listen to each step?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You can re-listen to each step as many times as you need to in order to make sure you know what you need to do, so do not be worried if you have to stop and replay each step as often as is required. ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'Why is it important to write down your thoughts?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'It is really important to write concepts down through the ask/answer/discuss process, because this reinforces healthy dendrite growth and really forces you to think about your thinking and introspect on a deeper level to find the cause behind the warning signals being issued from the toxic thought. The brain operates like a quantum computer, and deep-thinking mind action provides the signal to the millions of neurobiological quantum computers inside the dendrites. As you think (step 2 = ask/answer/discuss), you create signals in your brain; as you write words down, you reinforce and strengthen the quantum signals and what you have just grown in the dendrites. You are literally influencing your genetic expression and growing your brain at will.)',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do you do the active reach (step 5)?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'An active reach is the action step. You will need to set a reminder through this app or on your phone to remind you to do this seven times a day. Please note: An Active Reach IS NOT simply repeating a statement 7 times. It is NOT positive affirmation. An Active Reach IS responding in a specific way each time the situation or toxic thought rises up in your mind. I guide you through what to do for your active reach each day from days 1-21. From days 22-63 you can use the reminder in the app or on the phone.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What if I miss a day?',
        children: newCycleText,
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'Throughout the program, do I work on just one specific toxic thought or more than one?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You want to stick with ONE thought from the toxic issue. A thought is the concept; for example, the thought = poor self-esteem and imposter syndrome. A thought is made of many memories, so when you work on one thought, you will be working on many memories. To take the example above, a couple of the memories associated with the thought of poor self-esteem and imposter syndrome could be “I am a fake”, “I am not good enough”, “how do I think I can get away with this”, “I can’t accept a compliment” and so on. Try to get to the root of the issue by writing down associated memories in the thoughts and categorize them. The metacog is an excellent way to do this. This will help you to see if they are associated branches or something new to work on as a separate toxic thought in a future cycle. The associated memories are like manifestations, and an Active Reach will help you deal with these. ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'When should I start the program?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You can start the program at any time during the year. I recommend doing it up to 10-17 times per year – so 365 days in a year, divided by 21 days gives 17 cycles. Remember this is a lifestyle, so you never stop doing the 21 day cycles because you will need to be detoxing and controlling your mind for the rest of your life to keep chaotic and toxic thinking and depression and anxiety at bay.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What do I do after the 21-day cycle?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'The next 42 days are really easy; in fact, they are so easy they may not feel as important as the first 21 days, but they are really significant! For enduring long-lasting change, you need to invest a minute or two each day for 42 more days (see the video on 63 days). If you consciously and deliberately practice using your newly reconceptualized thought for a few minutes each day for another 42 days, you will be adding sufficient energy to boost the chemicals and proteins of the thought and strengthen it. It will become the literal agent of change in your life that everyone will notice, and you will start feeling the mental peace you deserve!',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'However, without these extra 42 days, the thought will become weak and inactive in the nonconscious mind, and all the work you have done won’t be evident in your life, which will be very frustrating!',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Here is what you do: work out a statement that captures and describes your newly reconceptualized thought. This could be your final Active Reach from day 21 or another that you feel is a better summary. Add a beautiful visual image onto this, which becomes a hook where eventually just thinking of the image will remind you of the new way of thinking. Write it into your Neurocycle journal and type it into the reminder function on the app and/or in the reminders on your phone and set it to pop up once a day for 42 days. When it pops up, leave it on your screen and read it to yourself out loud. During the day, see if there is opportunity to practice the change this statement represents.',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Each 63-day period you can begin a new cycle, which means you can work on about 6 major areas in your life each year, which is actually amazing because most people hardly change in a five-year period and tend to get stuck for a long time! As you get into the neurocycle lifestyle, and become more familiar and comfortable with the process, you can start a new 21-day detox at the same time you are doing the 42 days practice on the thought you have just worked on—this is totally up to you and the Neurocycling rhythm you get into.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I find the root of my toxic thought?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'As you work through the 5 steps of the program day by day, you will be finding more and more toxic memories associated with the toxic thought you are working on. Somewhere between day 4 and 14, the pieces of the origin story or cause will start becoming evident, which means you are starting to get to the root of the toxic issue and are putting the pieces of the puzzle together. This will be like an “AhHa” moment. For example, the big issue you may start working on is anxiety. As you work through the days and answer my questions, you will narrow down the broad issue to something very specific relating to your life, like an underlying trauma or event.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'Why 15-45 minutes per day?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'According to research this is an ideal time to work on dealing with toxic issues without overwhelming yourself with the toxic emotions.',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'What is Dr. Leaf’s approach to mental health? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Dr. Leaf, along with many other mental health specialists, psychiatrists and scientists, agree that mental health is not a biological disease. Rather, it is the result of living in the world full of challenges—it is the human condition. We cannot reduce a human experience to a single neurological mechanism. As such, we believe that mental health needs to be redefined, and the mental health system needs to change. Misery should not be medicalized and pathologized; human suffering needs to be acknowledged, validated and dealt with. Socioeconomic, political and economic conditions need to be acknowledged and recognized as a source of human suffering, and steps need to be taken on both a global and local level to help people help themselves, with a loving and supportive community—this is where our research dollars should be invested. We do not have a mental health problem; we have a mismanagement of mental health problem. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Dr. Leaf’s research and work over the past 30 years in low-income environments and in her clinical practice has consistently shown that, although our circumstances can affect our mental and physical health, the mind is incredibly resilient. When individuals learn to recognize how powerful their thoughts are, they can grow and change on a cognitive, emotional, social and academic level through mind-directed neuroplasticity techniques, within a supportive community. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Dr. Leaf developed the Switch on Your Brain with the 5-step Learning Process ®from over three decades of study clinical practice and research. This learning process, which is based on her geodesic learning theory, has been shown to be not only effective in mental health, but also treating physical damage to the brain that occurs in traumatic brain injury (TBI), learning disabilities and to improve learning techniques in both schools and the corporate world. ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'Can I start another cycle while working on stabilizing my thought over the next 42 days? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'The first time you do the 21 days you will only do one 21-day cycle followed by the 42 days to complete the 63 day cycle it takes to build a habit, which will result in a behavior change in your life, as discussed in point xiii above. When you feel comfortable with the process, you can start another full cycle of detoxing while you do the 42 days practice. So, as you start another 21 days with a new toxic thought, you will be also practicing the 42 “reminder” days of the previous thought you have reconceptualized. ',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You can even cycle through three toxic thoughts, as each will be at a different stage. Here’s what this would look like: ',
            children: [
              {
                type: FAQ_TYPES.ANSWER,
                text: 'The 21 days intensive 7-16 minutes each day of the third toxic thought. ',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Day 42-63 reminders of the first toxic thought. ',
              },
              {
                type: FAQ_TYPES.ANSWER,
                text: 'Day 21-42 reminders of the second toxic thought. ',
              },
            ],
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'Hence you are always doing just three tasks per day after round one, and this will take under an hour of your day (to change your life forever!). This is a small investment to make to change how you think, learn and succeed in every area of your life, improving your mental and physical health. This process goes beyond being mindful and calming down, allowing your mind to develop a constructive mental self-care routine that will produce real results in your life.',
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'This is what it will look like in your life: ',
          },
          {
            type: FAQ_TYPES.BOLD,
            text: 'Cycle 1',
            children: [
              {
                text: 'A new 21-day cycle',
                type: FAQ_TYPES.ANSWER,
              },
            ],
          },
          {
            type: FAQ_TYPES.BOLD,
            text: 'Cycle 2',
            children: [
              {
                text: 'A new 21-day cycle',
                type: FAQ_TYPES.ANSWER,
              },
              {
                text: 'Practicing cycle 1 thought for 42 days with reminders',
                type: FAQ_TYPES.ANSWER,
              },
            ],
          },
          {
            type: FAQ_TYPES.BOLD,
            text: 'Cycle 3',
            children: [
              {
                text: 'A new 21-day cycle',
                type: FAQ_TYPES.ANSWER,
              },
              {
                text: 'Practicing with reminders for days 42-63 of cycle 1',
                type: FAQ_TYPES.ANSWER,
              },
              {
                text: 'Practicing with reminders for days 21-42 of cycle 2',
                type: FAQ_TYPES.ANSWER,
              },
            ],
          },
          {
            type: FAQ_TYPES.BOLD,
            text: 'Cycle 4',
            children: [
              {
                text: 'A new 21-day cycle',
                type: FAQ_TYPES.ANSWER,
              },
              {
                text: 'Practicing with reminders for days 42-63 of cycle 2',
                type: FAQ_TYPES.ANSWER,
              },
              {
                text: 'Practicing with reminders for days 21-42 of cycle 3',
                type: FAQ_TYPES.ANSWER,
              },
            ],
          },
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'This means you can work on 6-17 toxic areas each year (365 days divided by 63 or 21 days). This is of course not a hard and fast rule, just a guideline, but most people work on between 5 and 15 toxic chaotic thought habits in a year. Considering chaotic thoughts contribute to a majority of mental and physical issues, this is a worthwhile investment of your time! ',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'How do I set reminders?',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'You can use the reminder function on your phone or in the app',
          },
        ],
      },
      {
        type: FAQ_TYPES.QUESTION,
        text: 'Why 21 days? ',
        children: [
          {
            type: FAQ_TYPES.PARAGRAPH,
            text: 'The brain and body work in cycles of 21 days to build long term memory. It takes 21 days to build long term memory and 63 days to build a habit.',
          },
        ],
      },
    ],
  },
]

export default faqs
