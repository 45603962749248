import React, { useEffect } from 'react'
import { ActiveReach } from 'screens/Home/ActiveReach'
import DecompressionTipsScreen from 'screens/Home/DecompressionTips'
import { HomeLandingScreen } from 'screens/Home/Landing/Home'
import MiniCyclesScreen from 'screens/Home/MiniCycles'
import ProfileInfo from 'screens/Account/Profile/ProfileInfo'
import { REMOTE_BGS } from 'consts'
import { ResourcesScreen } from 'screens/Resources'
import ResultsScreen from 'screens/Results'
import { buildCustomAppHeader } from 'components/Headings'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useTheme } from 'native-base'
import { useWindowDimensions } from 'react-native'
import NavigationWeb from 'components/Navigation'
import { LoginScreen } from 'screens/Auth/Login'
import { RegisterScreen } from 'screens/Auth/Register'
import { ResetScreen } from 'screens/Auth/Reset'
import { SplashScreen } from 'screens/Auth/Splash'
import { useNavigation } from '@react-navigation/native'
import AccountLandingScreen from 'screens/Account/Landing'
import { HistoryNavigator } from 'screens/Account/History/HistoryNavigator'
import AccountSubscriptionScreen from 'screens/Account/Subscription'
import AppAboutScreen from 'screens/Account/General/About'
import AppInfoScreen from 'screens/Account/General/Info'
import AppHelpScreen from 'screens/Account/General/Help'
import AppContactUsScreen from 'screens/Account/General/ContactUs'
import ChangePassword from 'screens/Account/Profile/ChangePassword'
import ResetCycle from 'screens/Account/General/ResetCycle'
import { useAuth } from 'hooks/useAuth'
import AccountSubscriptionCancel from 'screens/Account/Subscription/cancel'
import AccountSubscriptionSuccess from 'screens/Account/Subscription/success'
import Neurolive from 'screens/Neurolive'

import { NotificationsNavigator } from '../Notifications/NotificationsNavigator'
import { routeNames } from '../../state/routeNames'
import ParentCyclesScreen from 'screens/Home/ParentNeurocycle'

const { Navigator, Screen } = createBottomTabNavigator()
const Stack = createNativeStackNavigator()

export function Landing() {
  const { colors, ui } = useTheme()
  const {
    state: { user },
  } = useAuth()

  function HomeStackScreen() {
    return (
      <Stack.Navigator>
        <Screen
          name="Main"
          component={HomeLandingScreen}
          options={{
            headerShown: false,
            headerTitle: ' ',
          }}
        />
        <Screen
          name="DecompressionTips"
          component={DecompressionTipsScreen}
          // options={buildCustomAppHeader(
          //   REMOTE_BGS.DECOMPRESSION_TIPS,
          //   'Decompression',
          //   'Guides'
          // )}
          options={{
            headerShown: true,
            headerTitle: 'Decompression Guides',
          }}
        />
        <Screen
          name="ProfileInfo"
          component={ProfileInfo}
          options={buildCustomAppHeader(
            REMOTE_BGS.DECOMPRESSION_TIPS,
            'Profile',
            'Setup'
          )}
        />
        <Screen
          name="MiniCycles"
          component={MiniCyclesScreen}
          // options={buildCustomAppHeader(
          //   REMOTE_BGS.MINI_CYCLES,
          //   'Mini',
          //   'Neurocycles'
          // )}
          options={{
            headerShown: true,
            headerTitle: 'Mini Neurocycles',
          }}
        />
          <Screen
          name="ParentNeurocycles"
          component={ParentCyclesScreen}
          // options={buildCustomAppHeader(
          //   REMOTE_BGS.MINI_CYCLES,
          //   'Mini',
          //   'Neurocycles'
          // )}
          options={{
            headerShown: true,
            headerTitle: 'Parent Neurocycles',
          }}
        />
        <Screen
          name={routeNames.NOTIFICATIONS_NAVIGATOR}
          component={NotificationsNavigator}
          options={{ headerShown: false, headerTitle: ' ' }}
        />
        <Screen
          name="ActiveReach"
          component={ActiveReach}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    )
  }
  const navigation = useNavigation()

  const { width } = useWindowDimensions()
  const mobileWidth = width <= 767

  useEffect(() => {
    if (user && navigation.getState()) {
      const originalurl =
        navigation.getState().routes[navigation.getState().routes.length - 1]
          .name
      originalurl
        ? navigation.navigate(originalurl)
        : navigation.navigate('Home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <>
      {
        <>
          {user && <NavigationWeb />}
          <Stack.Navigator
            screenOptions={{
              headerShown: false,
            }}
          >
            {!user ? (
              <>
                <Screen
                  name="AuthSplash"
                  component={SplashScreen}
                  options={{ headerShown: false }}
                />
                <Screen
                  name="AuthLogin"
                  component={LoginScreen}
                  options={{ headerShown: false }}
                />
                <Screen
                  name="AuthRegister"
                  component={RegisterScreen}
                  options={{ headerShown: false }}
                />
                <Screen
                  name="AuthReset"
                  component={ResetScreen}
                  options={{ headerShown: false }}
                />
              </>
            ) : (
              <>
                <Stack.Group>
                  <Screen name="Home" component={HomeLandingScreen} />
                  <Screen
                    name="DecompressionTips"
                    component={DecompressionTipsScreen}
                    // options={buildCustomAppHeader(
                    //   REMOTE_BGS.DECOMPRESSION_TIPS,
                    //   'Decompression',
                    //   'Guides'
                    // )}
                    options={{
                      headerShown: true,
                      headerTitle: 'Decompression Guides',
                    }}
                  />
                  <Screen
                    name="ProfileInfo"
                    component={ProfileInfo}
                    options={buildCustomAppHeader(
                      REMOTE_BGS.DECOMPRESSION_TIPS,
                      'Profile',
                      'Setup'
                    )}
                  />
                  <Screen
                    name="MiniCycles"
                    component={MiniCyclesScreen}
                    // options={buildCustomAppHeader(
                    //   REMOTE_BGS.MINI_CYCLES,
                    //   'Mini',
                    //   'Neurocycles'
                    // )}
                    options={{
                      headerShown: true,
                      headerTitle: 'Mini Neurocycles',
                    }}
                  />
                            <Screen
          name="ParentNeurocycles"
          component={ParentCyclesScreen}
          // options={buildCustomAppHeader(
          //   REMOTE_BGS.MINI_CYCLES,
          //   'Mini',
          //   'Neurocycles'
          // )}
          options={{
            headerShown: true,
            headerTitle: 'Parent Neurocycles',
          }}
        />
                  <Screen
                    name={routeNames.NOTIFICATIONS_NAVIGATOR}
                    component={NotificationsNavigator}
                    options={{ headerShown: false, headerTitle: ' ' }}
                  />
                  <Screen
                    name="ActiveReach"
                    component={ActiveReach}
                    options={{ headerShown: false }}
                  />
                </Stack.Group>
                <Stack.Screen name="Results" component={ResultsScreen} />
                <Stack.Screen name="Neurolive" component={Neurolive} />
                <Stack.Screen name="Resources" component={ResourcesScreen} />
                <Stack.Group>
                  <Stack.Screen
                    name="AccountLanding"
                    title="AccountLanding"
                    component={AccountLandingScreen}
                    options={{ headerShown: false, headerTitle: ' ' }}
                  />
                  <Stack.Screen
                    name={routeNames.HISTORY_NAVIGATOR}
                    component={HistoryNavigator}
                    options={{ headerShown: false }}
                  />
                  <Stack.Screen
                    component={ResultsScreen}
                    name="AccountResults"
                    title="AccountResults"
                    options={{ headerTitle: 'Account Results' }}
                  />
                  <Stack.Screen
                    name="AccountProfile"
                    title="AccountProfile"
                    component={ProfileInfo}
                    options={{ headerTitle: 'Profile' }}
                  />
                  <Stack.Screen
                    name="AccountSubscription"
                    title="AccountSubscription"
                    component={AccountSubscriptionScreen}
                    options={{ headerTitle: 'Subscription Success' }}
                  />
                  <Stack.Screen
                    name="AccountSubscriptionCancel"
                    title="AccountSubscriptionCancel"
                    component={AccountSubscriptionCancel}
                    options={{ headerTitle: 'Subscription Cancel' }}
                  />
                  <Stack.Screen
                    name="AccountSubscriptionSuccess"
                    title="AccountSubscriptionSuccess"
                    component={AccountSubscriptionSuccess}
                    options={{ headerTitle: 'Subscription' }}
                  />
                  <Stack.Screen
                    name="GeneralAbout"
                    title="GeneralAbout"
                    component={AppAboutScreen}
                    options={{ headerTitle: 'About' }}
                  />
                  <Stack.Screen
                    name="GeneralInfo"
                    title="GeneralInfo"
                    component={AppInfoScreen}
                    options={{ headerTitle: 'General Info' }}
                  />
                  <Stack.Screen
                    name="GeneralHelp"
                    title="GeneralHelp"
                    component={AppHelpScreen}
                    options={{ headerTitle: 'Help' }}
                  />
                  <Stack.Screen
                    name="GeneralContactUs"
                    title="GeneralContactUs"
                    component={AppContactUsScreen}
                    options={{ headerTitle: 'Contact Us' }}
                  />
                  <Stack.Screen
                    name={routeNames.RESET_CYCLE}
                    component={ResetCycle}
                    options={{ headerTitle: 'Restart current cycle' }}
                  />
                  <Stack.Screen
                    name="ChangePassword"
                    component={ChangePassword}
                    options={{ headerTitle: 'Change Password' }}
                  />
                </Stack.Group>
              </>
            )}
          </Stack.Navigator>
        </>
      }
      {/* {mobileWidth && (
        <Navigator
          screenOptions={({ route }) => ({
            headerShown: false,
            tabBarIcon: ({ color, size }) => {
              const styles = {
                stroke: color,
                height: 26,
                width: 26,
              }
              switch (true) {
                case route.name === 'Home':
                  return <Home {...styles} />

                case route.name === 'Results':
                  return <Results {...styles} />

                case route.name === 'Resources':
                  return <Resources {...styles} />

                case route.name === 'Account':
                  return <Account {...styles} />
              }
            },
            tabBarActiveTintColor: colors.puertorico['500'],
            tabBarAllowFontScaling: false,
            tabBarInactiveTintColor: colors.tangaroa['500'],
            tabBarLabelStyle: {
              ...ui['text-appBarText'],
              paddingBottom: 3,
            },
            tabBarStyle: {
              elevation: 10,
              zIndex: 10,
            },
          })}
        >
          <>
            {!user ? (
              <>
                <Screen
                  name="AuthSplash"
                  component={SplashScreen}
                  options={{ headerShown: false }}
                />
                <Screen
                  name="AuthLogin"
                  component={LoginScreen}
                  options={{ headerShown: false }}
                />
                <Screen
                  name="AuthRegister"
                  component={RegisterScreen}
                  options={{ headerShown: false }}
                />
                <Screen
                  name="AuthReset"
                  component={ResetScreen}
                  options={{ headerShown: false }}
                />
              </>
            ) : (
              <>
                <Screen
                  name="Home"
                  component={HomeStackScreen}
                  options={{ headerShown: false, headerTitle: ' ' }}
                />
                <Screen name="Results" component={ResultsScreen} />
                <Screen name="Resources" component={ResourcesScreen} />
                <Screen name="Account" component={AccountScreen} />
              </>
            )}
          </>
        </Navigator>
      )} */}
    </>
  )
}
