import { object, string } from 'yup'
import { INPUT_TYPES } from 'state/consts'
import { Button } from 'native-base'
import { TextInput } from 'components/Form/Inputs/TextInput'

export const contactUsConfig = {
  validationSchema: object().shape({
    fullName: string().required('Required'),
    email: string().email('Invalid email').required('Required'),
    message: string().required('Required'),
  }),
  heading: {
    title: 'Contact Us',
    subTitle: 'Have questions? Feel free to reach out to us via email:',
  },
  authFooter: [],
  rows: [
    {
      id: 'contactus_row0',
      fields: [
        {
          id: 'fullName',
          label: 'Enter your Full Name',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
    {
      id: 'contactus_row1',
      fields: [
        {
          id: 'email',
          label: 'Email',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
    {
      id: 'contactus_row2',
      fields: [
        {
          id: 'message',
          label: 'Your message',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
  ],
  actionFooter: [],
  actions: {
    submit: {
      id: 'contactus_submit',
      component: Button,
      variant: 'primary',
      variantDisabled: 'primaryDisabled',
      label: 'SEND MESSAGE',
    },
  },
}
