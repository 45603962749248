import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import { PageContainer } from 'components/Containers/Page'
import { AppTabs } from 'components/Tabs'
import AboutDrLeaf from 'screens/Account/General/About/AboutScreen'

function AppHelpScreen() {
  const tabs = [{ id: 0, name: 'About Dr Leaf', component: AboutDrLeaf }]

  return (
    <PageContainer pt={5} scheme="willowbrook" standAlone={false}>
      <AppTabs data={tabs} />
    </PageContainer>
  )
}

export default AppHelpScreen
