import React from 'react'
import { number, string } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G, Circle } from 'react-native-svg'

export function Account({ width, height, stroke }) {
  return (
    <Icon
      viewBox="0 0 22 22"
      width={width}
      height={height}
      style={{ height, width }}
    >
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G id="Rd2" strokeWidth="1" fill="none" fillRule="evenodd">
          <G
            id="Tab-Bar"
            transform="translate(-319.000000, -3.000000)"
            fill="#021D3D"
            fillRule="nonzero"
          >
            <G
              fill={stroke === '#021D3D' ? '#021D3D' : stroke}
              id="Profile"
              transform="translate(320.000000, 4.000000)"
            >
              <Path
                d="M10,-0.75 C4.06293894,-0.75 -0.75,4.06293894 -0.75,10 C-0.75,15.9370611 4.06293894,20.75 10,20.75 C15.9370611,20.75 20.75,15.9370611 20.75,10 C20.75,4.06293894 15.9370611,-0.75 10,-0.75 Z M10,0.75 C15.1086339,0.75 19.25,4.89136606 19.25,10 C19.25,15.1086339 15.1086339,19.25 10,19.25 C4.89136606,19.25 0.75,15.1086339 0.75,10 C0.75,4.89136606 4.89136606,0.75 10,0.75 Z"
                id="Oval"
              />
              <Path
                d="M13.5454753,12.3445143 C13.7743226,11.9992582 14.2397254,11.9048902 14.5849815,12.1337375 C14.9302376,12.3625849 15.0246056,12.8279876 14.7957583,13.1732438 C13.7377522,14.7694305 11.9516443,15.75 10,15.75 C8.20314609,15.75 6.54199626,14.9194497 5.45969811,13.5285698 C5.20532197,13.2016667 5.26411671,12.7304468 5.59101984,12.4760706 C5.91792297,12.2216945 6.38914292,12.2804892 6.64351906,12.6073924 C7.44458307,13.6368534 8.67091259,14.25 10,14.25 C11.443345,14.25 12.7624817,13.5257967 13.5454753,12.3445143 Z"
                id="Path"
              />
              <Path
                d="M13.83022,7.83034692 C14.8191588,7.83034692 15.7376389,8.27150328 16.3566429,9.01783292 C16.6210748,9.33665684 16.5769812,9.80947903 16.2581573,10.0739108 C15.9393334,10.3383427 15.4665112,10.2942491 15.2020794,9.97542522 C14.865094,9.56912382 14.3679645,9.33034692 13.83022,9.33034692 C13.2660353,9.33034692 12.7469236,9.5935029 12.4114637,10.0344507 C12.1606691,10.3641095 11.6901187,10.4280416 11.3604598,10.1772471 C11.030801,9.92645247 10.9668689,9.45590199 11.2176634,9.12624315 C11.8338428,8.31630151 12.7924557,7.83034692 13.83022,7.83034692 Z"
                id="Path"
              />
              <Path
                d="M6.01565643,7.83034692 C7.00459521,7.83034692 7.92307532,8.27150328 8.54207937,9.01783292 C8.80651118,9.33665684 8.76241767,9.80947903 8.44359375,10.0739108 C8.12476983,10.3383427 7.65194764,10.2942491 7.38751582,9.97542522 C7.0505304,9.56912382 6.55340091,9.33034692 6.01565643,9.33034692 C5.4514717,9.33034692 4.93236002,9.5935029 4.59690013,10.0344507 C4.34610555,10.3641095 3.87555508,10.4280416 3.54589623,10.1772471 C3.21623739,9.92645247 3.15230528,9.45590199 3.40309987,9.12624315 C4.01927924,8.31630151 4.97789208,7.83034692 6.01565643,7.83034692 Z"
                id="Path-Copy"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}

Account.defaulProps = {
  width: 20,
  height: 20,
  stroke: '#021D3D',
}

Account.propTypes = {
  width: number,
  height: number,
  stroke: string,
}
