import { Platform, Alert } from 'react-native';
import { useEffect, useMemo } from 'react';
import { routeNames } from '../../state/routeNames';
import { FIREBASE_COLLECTIONS } from '../consts';
import notifee, { EventType } from '@notifee/react-native';
import { firebase } from '../../config/firebase.web.js';

export async function getToken() {
  if (Platform.OS === 'web' && firebase.messaging.isSupported()) {
    // Web-specific code for supported browsers
    try {
      const currentToken = await firebase.messaging().getToken({ vapidKey: 'your-vapid-key' });
      console.log('DEVICE TOKEN =', currentToken);
      return currentToken;
    } catch (error) {
      console.error('Error getting token:', error);
      return null; 
    }
  } else if (firebase.messaging.isSupported()) {
    // Mobile-specific code
    const token = await firebase.messaging().getToken();
    console.log('DEVICE TOKEN =', token);
    return token;
  } else {
    console.warn('Messaging not supported on this platform.');
    return null;
  }
}

export async function requestUserPermission() {
  if (Platform.OS === 'web' && firebase.messaging.isSupported()) {
    // Web-specific permission request
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('Push notifications permission granted.');
        return true;
      } else {
        Alert.alert('Permission denied', 'You will not receive push notifications.');
        return false;
      }
    } catch (error) {
      console.error('Permission request error:', error);
      return false;
    }
  } else if (firebase.messaging.isSupported()) {
    // Mobile-specific permission request
    const authStatus = await firebase.messaging().requestPermission();
    const enabled =
      authStatus === firebase.messaging.AuthorizationStatus.AUTHORIZED ||
      authStatus === firebase.messaging.AuthorizationStatus.PROVISIONAL;
    if (enabled) {
      console.log('Authorization status:', authStatus);
      return true;
    } else {
      console.log('Push notifications permission denied.');
      return false;
    }
  } else {
    console.warn('Messaging not supported on this platform.');
    return false;
  }
}

export function useNotifications(user, navigate) {
  const onInAppMessageReceived = async (remoteMessage) => {
    if (Platform.OS !== 'web') {
      await notifee.displayNotification({
        data: remoteMessage.data,
        title: remoteMessage.notification.title,
        body: remoteMessage.notification.body,
      });
    } else if (firebase.messaging.isSupported()) {
      // Web-specific notification handling
      new Notification(remoteMessage.notification.title, {
        body: remoteMessage.notification.body,
        tag: remoteMessage.notification.tag || '',
        icon: '/logoN.png',
      });
    } else {
      console.error('Notification API is not available in this environment.');
    }
  };

  const onMessageReceived = useMemo(() => {
    return (remoteMessage) => {
      navigate(routeNames.NOTIFICATIONS_NAVIGATOR);
    };
  }, [navigate]);

  useEffect(() => {
    if (Platform.OS === 'web' && firebase.messaging.isSupported()) {
      // Web-specific message handling
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        onInAppMessageReceived(payload);
      });
    } else if (firebase.messaging.isSupported()) {
      // Mobile-specific message handling
      const unsubscribe = firebase.messaging().onMessage(async (remoteMessage) => {
        onInAppMessageReceived(remoteMessage);
      });
      return unsubscribe;
    }
  }, [onInAppMessageReceived]);

  useEffect(() => {
    // Handle notification when the app is opened
    if (Platform.OS !== 'web' && firebase.messaging.isSupported()) {
      // Mobile-specific code
      firebase.messaging().onNotificationOpenedApp((remoteMessage) => {
        onMessageReceived(remoteMessage);
      });

      firebase.messaging()
        .getInitialNotification()
        .then((remoteMessage) => {
          if (remoteMessage) {
            onMessageReceived(remoteMessage);
          }
        });
    }
  }, [onMessageReceived]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      // Mobile-specific foreground event handling
      return notifee.onForegroundEvent(({ type, detail }) => {
        if (type === EventType.PRESS) {
          onMessageReceived(detail.notification);
        }
      });
    }
  }, [onMessageReceived]);

  useEffect(() => {
    if (!user) return;

    async function handleToken() {
      const permissionResult = await requestUserPermission();

      if (permissionResult) {
        const token = await getToken();
        if (token) {
          console.log(':::::: TOKEN IS HERE ::::::', token);
          // saveTokenToDatabase(token);
        }
      }

      if (Platform.OS === 'web' && firebase.messaging.isSupported()) {
        // Web-specific token refresh handling
        const messaging = firebase.messaging();
        messaging.onTokenRefresh((token) => {
          // saveTokenToDatabase(token);
        });
      } else if (firebase.messaging.isSupported()) {
        // Mobile-specific token refresh handling
        firebase.messaging().onTokenRefresh((token) => {
          // saveTokenToDatabase(token);
        });
      }
    }

    handleToken();
  }, [user]);
}
