import React from 'react'
import { Box, Text, Slider } from 'native-base'

export function QuestionsProgress({ current, max }) {
  return (
    <Box>
      <Slider
        defaultValue={0}
        minValue={0}
        maxValue={max}
        accessibilityLabel="21 Step Questionnaire"
        step={1}
        value={current + 1}
        mb={2}
      >
        <Slider.Track variant="progress">
          <Slider.FilledTrack variant="progress" />
        </Slider.Track>
      </Slider>
      <Box>
        <Text variant="player" fontSize="lg">
          {current + 1} of {max}
        </Text>
      </Box>
    </Box>
  )
}
