import React, { useMemo } from 'react'
import { string, object } from 'prop-types'
import {
    AspectRatio,
    Box,
    Pressable,
    Skeleton,
    Text,
    VStack,
    View,
    useTheme,
} from 'native-base'
import { ImageBackground, ImageSourcePropType, Linking, TouchableHighlight } from 'react-native'
import { InfomationalButtonsPrim } from './InformationalButtonsPrim'

interface InfomationalButtonsProps extends React.ComponentProps<'div'> {
    title: string
    subTitle?: string
    source: ImageSourcePropType
    link?: string
    backgroundColor?: string
}

export function InfomationalButtons({ title, subTitle, source, link, backgroundColor }: InfomationalButtonsProps) {
    const { ui, fontConfig } = useTheme()

    const handlePress = async () => {
        console.log('Link:', link)
        Linking.openURL(link)
    }

    const textShadow = {
        textShadowColor: 'rgba(0, 0, 0, 0.05)',
        textShadowOffset: { width: 1, height: 2 },
        textShadowRadius: 5,
    }

    return (
        <Box display="flex"
            justifyContent="flex-start"
            flexWrap="wrap"
            width={'100%'}
            flexGrow={1}
            mb={'2%'}
            height={{ base: '3.5rem', md: '4rem', lg: '4.5rem' }}
            flexDirection="column"
        >
            <InfomationalButtonsPrim
                backgroundColor={backgroundColor}
                title={title}
                source={source}
                link={link}
            />
        </Box>
    )
}