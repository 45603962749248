import React, { createContext, useState } from 'react'

export const PlayerStatusContext = createContext({
  timeMs: 0,
  setTime: (time: number) => time,
})

export function PlayerStatusProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [timeMs, setTime] = useState(0)
  const value = { timeMs, setTime }

  return (
    <PlayerStatusContext.Provider value={value}>
      {children}
    </PlayerStatusContext.Provider>
  )
}
