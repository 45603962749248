import React, { useState } from 'react'
import { KeyboardAvoidingView, useTheme, useToast } from 'native-base'
import { Platform, ScrollView } from 'react-native'
import { auth, crashlytics, firestore, functions } from 'config/firebase'
import { registerConfigStep1, registerConfigStep2 } from 'screens/Auth/config'

import { AppForm } from 'components/Form'
import { AppToast } from 'components/Toast'
import { AuthContainer } from 'components/Containers/Auth'
import { Config } from 'config/config'
import type { FirebaseAuthTypes } from '@react-native-firebase/auth'
import { REGISTER_ERRORS } from 'state/consts'
import moment from 'moment'
import set from 'lodash/set'

export const RegisterScreen: React.FC = () => {
  const { colors } = useTheme()
  const toast = useToast()
  const [error, setError] = useState(null)
  const initialValues = {}
  const configStep1 = set(registerConfigStep1, 'initialValues', initialValues)
  const configStep2 = set(registerConfigStep2, 'initialValues', initialValues)
  const style = {
    bg: colors.endeavour[500],
    fontColor: colors.white[500],
    borderColor: colors.white[500],
    actionFooter: { textAlign: 'center' },
    authFooter: { textAlign: 'center' },
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'center',
  }
  const [stepToShow, setStepToShow] = useState(1)
  const [data, setData] = useState<{
    firstName: string
    lastName: string
    dob: moment.Moment | null
  }>({
    firstName: '',
    lastName: '',
    dob: null,
  })

  set(
    configStep1,
    'actions.submit.handle',
    (
      {
        firstName,
        lastName,
        dob,
      }: {
        firstName: string
        lastName: string
        dob: moment.Moment | null
      },
      {
        setSubmitting,
        resetForm,
      }: {
        setSubmitting: (submitting: boolean) => void
        resetForm: () => void
      }
    ) => {
      if (moment().diff(dob, 'years') < 13) {
        showError({ code: 'auth/age-limit' })
      } else {
        resetForm()
        setData({ firstName, lastName, dob })
        setStepToShow(2)
      }
      setSubmitting(false)
    }
  )

  function showError(err: any) {
    if (err) {
      crashlytics().recordError(err)
    }
    setError(err.code)
    console.log(err)
    toast.show({
      placement: 'top',
      status: 'error',
      duration: 4900,
      render: () => (
        <AppToast status="error" message={REGISTER_ERRORS[err.code]} />
      ),
    })
  }

  set(
    configStep2,
    'actions.submit.handle',
    ({
      email,
      password,
    }: {
      firstName: string
      lastName: string
      dob: moment.Moment | null
      email: string
      password: string
    }) => {
      email = email.trim().toLowerCase()
      crashlytics().log(`Starting registration, email: ${email}`)
      auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          addMember(res) // send signup email
          saveToFirestore(res)
        })
        .catch((err) => {
          showError(err)
        })
    }
  )

  const addMember = async (res: FirebaseAuthTypes.UserCredential) => {
    functions()
      .httpsCallable('proxyEmail')({
        uri: 'v2/list/VbtVaz/members',
        body: {
          profiles: [
            {
              email: res?.user?.email,
            },
          ],
        },
      })
      .catch((err) => {
        crashlytics().recordError(err)
      })
  }

  const saveToFirestore = async (res: FirebaseAuthTypes.UserCredential) => {
    await firestore()
      .collection('users')
      .doc(res?.user?.uid)
      .set(
        {
          email: res?.user?.email,
          uid: res?.user?.uid,
          received: firestore.FieldValue.serverTimestamp(),
          ...data,
        },
        { merge: true }
      )
      .then((response) => console.log(response))
      .catch((err) => {
        crashlytics().recordError(err)
      })
  }

  return (
    <AuthContainer style={style}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
        style={{ flex: 1, width: '100%' }}
      >
        <ScrollView
          style={{
            height: '100%',
          }}
          showsVerticalScrollIndicator={false}
          keyboardShouldPersistTaps="handled"
        >
          {stepToShow === 1 ? (
            <AppForm
              config={configStep1}
              haveError={error}
              style={style}
              noKeyboardAvoidingView={true}
              noBottomSpacer={false}
              children={undefined}
            />
          ) : (
            <AppForm
              config={configStep2}
              haveError={error}
              style={style}
              noKeyboardAvoidingView={true}
              noBottomSpacer={false}
              children={undefined}
            />
          )}
        </ScrollView>
      </KeyboardAvoidingView>
    </AuthContainer>
  )
}
