import React, { useState, useEffect } from 'react'
import { Box, useTheme } from 'native-base'
import { Calendar, LocaleConfig } from 'react-native-calendars'
import { firestore } from 'config/firebase'
import { useAuth } from 'state/hooks/useAuth'
import {
  addMonths,
  differenceInCalendarDays,
  format,
  isLastDayOfMonth,
  lastDayOfMonth,
  subMonths,
} from 'date-fns'

import { FIREBASE_COLLECTIONS } from '../../state/consts'

LocaleConfig.locales[''] = {
  ...LocaleConfig.locales[''],
  dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
}

function getTimeWindow(date) {
  return {
    end: addMonths(
      new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0),
      1
    ),
    start: subMonths(
      new Date(
        date.getFullYear(),
        date.getMonth(),
        lastDayOfMonth(date).getDate(),
        23,
        59,
        59
      ),
      1
    ),
  }
}

function toMarkedDates(dates, selectedDate) {
  return dates.reduce((dateMap, date) => {
    const dateKey = format(date, 'yyyy-MM-dd')
    const day = date.getDate()
    if (!dateMap[dateKey]) {
      dateMap[dateKey] = {
        color: '#50cebb',
        startingDay:
          day === 1 ||
          !dates.some((d) => {
            // check if has immediate previous day
            return differenceInCalendarDays(date, d) === 1
          }),
        endingDay:
          isLastDayOfMonth(date) ||
          !dates.some((d) => {
            // check if has immediate next day
            return differenceInCalendarDays(d, date) === 1
          }),
      }
      if (day === new Date().getDate()) {
        dateMap[dateKey].textColor = '#ffffff'
      }
    }
    return dateMap
  }, {})
}

export function AppCalendarStatic(props) {
  const [data, setData] = useState()
  const today = new Date()
  const { colors, fonts } = useTheme()
  const [timeWindow, setTimeWindow] = useState(getTimeWindow(today))
  const {
    state: { user },
  } = useAuth()

  useEffect(() => {
    async function getActiveReaches() {
      return firestore()
        .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
        .where('uid', '==', user?.uid)
        .where('createdAt', '>=', timeWindow.start)
        .where('createdAt', '<=', timeWindow.end)
        .orderBy('createdAt')
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            return snapShot.docs.map((d) => d.data().createdAt.toDate())
          }
          return []
        })
    }
    async function getAudioHistory() {
      return firestore()
        .collection(FIREBASE_COLLECTIONS.HISTORY)
        .where('uid', '==', user?.uid)
        .where('createdAt', '>=', timeWindow.start)
        .where('createdAt', '<=', timeWindow.end)
        .orderBy('createdAt')
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            return snapShot.docs.map((d) => d.data().createdAt.toDate())
          }
          return []
        })
    }

    async function getCycleHistory() {
      return firestore()
        .collection(FIREBASE_COLLECTIONS.HISTORY_CYCLE)
        .where('uid', '==', user?.uid)
        .where('createdAt', '>=', timeWindow.start)
        .where('createdAt', '<=', timeWindow.end)
        .orderBy('createdAt')
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            return snapShot.docs.map((d) => d.data().createdAt.toDate())
          }
          return []
        })
    }

    async function getDates() {
      const [reaches, audioHistory, cycleHistory] = await Promise.all([
        getActiveReaches(),
        getAudioHistory(),
        getCycleHistory(),
      ])
      setData((dates) => {
        return {
          ...(dates || {}),
          ...toMarkedDates([
            ...new Set(reaches.concat(audioHistory, cycleHistory)),
          ]),
        }
      })
    }
    if (user?.uid) {
      getDates()
    }
  }, [user, timeWindow])

  return (
    <Box>
      <Calendar
        disableMonthChange={true}
        firstDay={1}
        onDayPress={(day) => {
          if (data && day && data[day.dateString]) {
            props.onDayPress?.(day)
          }
        }}
        hideArrows={true}
        hideDayNames={true}
        markingType={'period'}
        markedDates={data}
        // renderHeader={(date) => {
        //   /*Return JSX*/
        // }}
        theme={{
          todayTextColor: colors.tangaroa[500],
          dayTextColor: colors.tangaroa[500],
          'stylesheet.day.period': {
            text: {
              fontSize: 16,
              fontFamily: fonts.semibold,
              marginTop: 10,
            },
          },
          'stylesheet.calendar.header': {
            dayHeader: {
              marginTop: 2,
              marginBottom: 7,
              width: 32,
              textAlign: 'center',
              fontSize: 17,
              fontFamily: fonts.light,
              color: colors.tangaroa[500],
            },
            header: {
              flexDirection: 'row',
              fontFamily: fonts.bold,
              marginTop: 6,
            },
            monthText: {
              fontSize: 18,
              fontFamily: fonts.bold,
              marginBottom: 3,
              marginTop: 12,
            },
          },
        }}
      />
    </Box>
  )
}
