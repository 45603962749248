import React from 'react'
import { number } from 'prop-types'
import { Box, Icon } from 'native-base'
import { Path, G } from 'react-native-svg'

export function TrackVisualiser({ width, height, outerfill, innerFill }) {
  return (
    <Box
      backgroundColor={outerfill}
      width={width}
      height={height}
      borderRadius={100}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Icon
        viewBox="0 0 13 13"
        xmlns="http://www.w3.org/2000/svg"
        width={width - 28}
        height={height - 28}
      >
        <G
          transform="translate(-24 -24) translate(13 12) translate(11.7 12.6)"
          fill={innerFill}
          stroke="none"
          strokeWidth={1}
          fillRule="evenodd"
        >
          <Path d="M0 1.8H1.35V11.700000000000001H0z" />
          <Path d="M2.7 0H4.050000000000001V11.7H2.7z" />
          <Path d="M5.4 5.4H6.75V11.7H5.4z" />
          <Path d="M8.1 0H9.45V11.7H8.1z" />
          <Path d="M10.8 9H12.15V11.7H10.8z" />
        </G>
      </Icon>
    </Box>
  )
}

TrackVisualiser.defaulProps = {
  width: 20,
  height: 20,
  outerfill: '#D8D8D8',
  innerFill: '#FFFFFF',
}

TrackVisualiser.propTypes = {
  width: number,
  height: number,
}
