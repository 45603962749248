import { useEffect, useRef, useState } from "react";
import { Input, Text, useTheme } from "native-base";
import { Alert, Box, Button, CloseIcon, FormControl, HStack, Heading, IconButton, Modal, VStack } from "native-base";
import { Linking, TouchableOpacity } from "react-native";
import { PROMO_VIDEOS } from "consts";
import { Audio, Video } from "expo-av";
// import AsyncStorage from '@react-native-async-storage/async-storage';

export function AdCard() {
    const [showModal, setShowModal] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const { fontConfig } = useTheme()

    // AUTOMATIC POPUP MODAL FOR MOBILE
    // useEffect(() => {
    //     AsyncStorage.getItem("isModalShown").then((value) => {
    //         if (!value) {

    //             setShowModal(true); // Set showModal to true when the component is mounted
    //             Audio.setAudioModeAsync({
    //                 playsInSilentModeIOS: true,
    //             })
    //             AsyncStorage.setItem("isModalShown", "true");
    //         } else if (value) {
    //             //Even if it has been opened before, still sync the audio
    //             Audio.setAudioModeAsync({
    //                 playsInSilentModeIOS: true,
    //             })
    //         }
    //     })
    // }, []);

    const handleButtonPress = () => {
        Linking.openURL('https://www.mentallyresilientkids.com/')
    }

    const handleModalClose = () => {
        localStorage.setItem("seenPopUp", true);
        setPlayVideo(false)
        setShowModal(false)
    }
    const handleModalOpen = () => {
        setPlayVideo(true)
        setShowModal(true)
    }

    useEffect(() => {
        // getting value of "seenPopUp" key from localStorage
        let returningUser = localStorage.getItem("seenPopUp");
        // if it's not there, for a new user, it will be null
        // if it's there it will be boolean true
        // setting the opposite to state, false for returning user, true for a new user
        setShowModal(!returningUser);
    }, []);

    const selectedVideo = require('../../assets/videos/promo/KidsBook_Regular.mp4');
    return <>
        <Button onPress={handleModalOpen} shadow={2} mb="5%">
            <Text style={{ ...fontConfig.center, fontFamily: "Gilroy-Black", color: 'white' }}>
                Order My New Book for Kids & Parents
            </Text>
        </Button>
        <Modal isOpen={showModal} onClose={handleModalClose}>
            <Modal.Content maxWidth="400px">
                <Modal.Header maxWidth="350px">The #1 Guide for Parents & Teachers</Modal.Header>
                <Modal.CloseButton mt={-25} />
                <Modal.Body>
                    <video controls autoPlay={playVideo} style={{ width: '100%' }}>
                        <source src={selectedVideo} type="video/mp4" />
                    </video>
                    <Button onPress={() => {
                        handleModalClose()
                        handleButtonPress()
                    }}
                        mt="5%"
                        shadow={2}>
                        <Text style={{ ...fontConfig.center, fontFamily: "Gilroy-Black", color: 'white' }}>
                            GET THE BOOK NOW
                        </Text>
                    </Button>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    </>
}