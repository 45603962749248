import React, { useState, useEffect } from 'react'
import { Box, Text, Center, Container, useTheme } from 'native-base'
import { firestore } from 'config/firebase'
import { SwipeListView } from 'react-native-swipe-list-view'
import { FIREBASE_COLLECTIONS, NOTIFICATION_STATUS } from 'state/consts'
import { formatDateByTimezone } from 'utils/dateHelpers'
import { AppModal } from 'components/Modal'
import { useAuth } from 'state/hooks/useAuth'
import notifee from '@notifee/react-native'
import { useIsFocused } from '@react-navigation/native'
import { Loading } from 'components/Loading'

import { routeNames } from '../../state/routeNames'
import { saveReminder } from '../../state/firebase/saveReminder'

import { RowActionButton } from './RowActionButton'
import { NotificationRow } from './NotificationRow'

const NotificationsScreen = ({
  showModal,
  setShowModal,
  clearAll = false,
  setClearAll,
}) => {
  const isFocused = useIsFocused()
  const { colors } = useTheme()

  const {
    state: { user },
  } = useAuth()
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [dataList, setDataList] = useState(false)
  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow()
    }
  }

  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey)
    const newData = [...list]
    const prevIndex = list.findIndex((item) => item.key === rowKey)

    if (list[prevIndex].id) {
      saveReminder(
        {
          status: NOTIFICATION_STATUS.DELETED,
          uid: user.uid,
        },
        list[prevIndex].id
      )
    }
    newData.splice(prevIndex, 1)
    setList(newData)
  }

  useEffect(() => {
    async function fetchNotifications() {
      setLoading(true)
      try {
        await firestore()
          .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
          .where('status', 'in', [
            NOTIFICATION_STATUS.NEW,
            NOTIFICATION_STATUS.SEEN,
          ])
          .where('uid', '==', user?.uid)
          .orderBy('createdAt', 'desc')
          .get()
          .then((snapShot) => {
            const formattedNotification = snapShot.docs.map((doc, i) => {
              const data = doc.data()
              return {
                key: `${i}`,
                id: doc.id,
                heading:
                  doc.data().notifiedAt &&
                  formatDateByTimezone(
                    doc.data().notifiedAt?.toDate(),
                    'MMMM d, yyyy, hh:mm a'
                  ),
                link: `/${routeNames.NOTIFICATION}/${doc.id}`,
                reminderId: doc.id,
                status: data.status,
                text: data.messages,
                timezone: data?.timezone,
              }
            })
            setList(formattedNotification)
            setDataList(
              snapShot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                timestamp:
                  doc.data().notifiedAt &&
                  formatDateByTimezone(
                    doc.data().notifiedAt?.toDate(),
                    'mm HH:mm:ss'
                  ),
              }))
            )
          })
      } catch (error) {
        throw new Error(error)
      }
      setLoading(false)
    }
    if (user && isFocused) {
      fetchNotifications()
    }
  }, [user, isFocused])

  useEffect(() => {
    if (clearAll) {
      setShowModal(!showModal)
      setList([])
      setClearAll(!clearAll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAll])

  // clear badge on mount
  useEffect(() => {
    notifee.getBadgeCount().then((count) => {
      if (count) notifee.setBadgeCount(0)
    })
  }, [])

  return (
    <Box style={{ flex: 1, paddingTop: 15 }} bgColor={colors.willowbrook[500]}>
      <AppModal
        action={setClearAll}
        showModal={showModal}
        setShowModal={setShowModal}
        text="Are you sure you want to clear all notifications?"
      />
      <Center width="100%" height="100%">
        <Container width="100%" height="100%" maxWidth={1024}>
          {loading ? (
            <Loading />
          ) : list?.length ? (
            <SwipeListView
              disableRightSwipe
              data={list}
              renderItem={(data, rowMap) => {
                return (
                  <NotificationRow
                    data={data}
                    rowMap={rowMap}
                    skeleton={data.item.status === NOTIFICATION_STATUS.SEEN}
                  />
                )
              }}
              renderHiddenItem={(data, rowMap) => (
                <RowActionButton
                  data={data}
                  rowMap={rowMap}
                  deleteRow={deleteRow}
                />
              )}
              rightOpenValue={-115}
              previewRowKey={`${list?.length - 1}`}
              previewOpenValue={-40}
              previewOpenDelay={3000}
                style={{ width: '100%' }}
            />
          ) : (
            <Box px={6} py={4}>
              <Text bold fontSize="lg" pb={2} variant="notificationSeen">
                Well Done
              </Text>
              <Text variant="notificationSeen">
                Your notification are all caught up.
              </Text>
            </Box>
          )}
        </Container>
      </Center>
    </Box>
  )
}

export default NotificationsScreen
