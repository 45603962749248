import { StyleSheet } from 'react-native'
import { gilroyFonts } from 'config/theme'

export default StyleSheet.create({
  miniCardContainer: {
    width: '45%',
    height: 185,
    marginBottom: 24,
    borderRadius: 15,
    backgroundColor: '#FFFFFF',
    paddingLeft: 14,
    paddingRight: 5,
    paddingTop: 25,
    paddingBottom: 20,
    justifyContent: 'space-evenly',
  },
  cardContainer: {
    width: '100%',
    marginBottom: 24,
    borderRadius: 15,
    backgroundColor: '#FFFFFF',
    paddingHorizontal: 14,
    paddingTop: 25,
    paddingBottom: 20,
    justifyContent: 'space-evenly',
  },
  titleStyle: {
    paddingTop: 10,
    color: '#021d3d',
    ...gilroyFonts,
    fontWeight: 'bold',
  },
  highlightedText: {
    paddingTop: 10,
    paddingBottom: 20,
    color: '#43CCBB',
    fontSize: 20,
    fontWeight: 'bold',
  },
  descriptionStyle: {
    ...gilroyFonts,
    paddingTop: 12,
  },
})
