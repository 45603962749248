import React from 'react'
import { Checkbox } from 'native-base'
import { AppFormControl as FormControl } from 'components/Form/Inputs/FormControl'
import { INPUT_TYPES } from 'state/consts'
import { oneOf } from 'prop-types'

export function RCFIDCheckbox({
  type,
  id,
  formik,
  required,
  style,
  inputProps,
  dark = false,
}) {
  const { handleBlur, handleChange, values } = formik

  return (
    <FormControl id={id} formik={formik} required={required} style={style}>
      <Checkbox
        mt={'-11px'}
        id={id}
        type={type}
        onBlur={handleBlur(id)}
        isChecked={values[id] === 'true' ? true : false}
        alignSelf="flex-start"
        marginLeft={1}
        onChange={(bool) => {
          bool ? handleChange(id)('true') : handleChange(id)('false')
        }}
        variant={dark ? 'outlineDark' : 'outline'}
        dark={dark}
        {...inputProps}
      >
        I am a Certified Facilitator
      </Checkbox>
    </FormControl>
  )
}

RCFIDCheckbox.defaultProps = { type: 'text' }
RCFIDCheckbox.propTypes = {
  type: oneOf([INPUT_TYPES.TEXT]),
}
