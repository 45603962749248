import React, { useState } from 'react'
import {
  Box,
  KeyboardAvoidingView,
  Text,
  useTheme,
  useToast,
} from 'native-base'
import { Platform, ScrollView, View } from 'react-native'
import { auth, crashlytics } from 'config/firebase'

import { AUTH_ERRORS } from 'state/consts'
import { AppForm } from 'components/Form'
import { AppToast } from 'components/Toast'
import { AuthContainer } from 'components/Containers/Auth'
import { Link } from '@react-navigation/native'
import { loginConfig } from 'screens/Auth/config'
import set from 'lodash/set'

export const LoginScreen: React.FC = () => {
  const { colors } = useTheme()
  const [error, setError] = useState(null)
  const toast = useToast()
  const initialValues = {}
  const config = set(loginConfig, 'initialValues', initialValues)
  const style = {
    bg: '#FF7B6C',
    fontColor: colors.white[500],
    borderColor: colors.white[500],
    authFooter: { textAlign: 'right' },
    actionFooter: { textAlign: 'center' },
    justifyContent: 'center',
  }
  set(
    config,
    'actions.submit.handle',
    ({ email, password }: { email: string; password: string }) => {
      crashlytics().log(`Starting login: ${email}`)
      email = email.trim().toLowerCase()
      auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => ({ loading: true, error: null }))
        .catch((err) => {
          console.log(err)
          toast.show({
            placement: 'top',
            status: 'error',
            duration: 4900,
            render: () => (
              <AppToast status="error" message={AUTH_ERRORS[err.code]} />
            ),
          })
          crashlytics().recordError(err)
          setError(err?.code)
        })
    }
  )

  return (
    <View style={{ height: '100%', flex: 1 }}>
      <AuthContainer style={style}>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          style={{ flex: 1, width: '100%' }}
        >
          <ScrollView
            style={{
              height: '100%',
              width: '100%',
            }}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
          >
            <AppForm
              config={config}
              haveError={error}
              style={style}
              noKeyboardAvoidingView
              noBottomSpacer
            >
              <Box flex={1} alignItems="flex-end">
                <Link to={{ screen: 'AuthReset' }} mb={4}>
                  <Text underline color={colors.white[500]}>
                    Forgot Password?
                  </Text>
                </Link>
              </Box>
            </AppForm>
          </ScrollView>
        </KeyboardAvoidingView>
      </AuthContainer>
    </View>
  )
}
