import React from 'react'
import { Pressable } from 'native-base'
import { Back } from 'components/Icons/Back'

export function BackPressable(props, mainprops) {
  return (
    <Pressable {...props} pl={5} pr={10} py={10}>
      {console.log(props, mainprops)}

      <Back />
    </Pressable>
  )
}
