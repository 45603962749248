import { BUCKET_ASSETS } from 'state/consts'

export type TCycleStepType =
  | 'audio'
  | 'video'
  | 'questionnaire'
  | 'activeReach'
  | 'congrats'
  | 'gather'
  | 'dailyInfo'

export interface TFile {
  uri: string
  locale: 'en' | 'es'
}

export interface TCycleStep {
  id: number
  title: string
  subTitle: string
  type: TCycleStepType
  resourceId?: number
  files?: TFile[]
  duration?: number
}

export type TCycleDay = TCycleStep[]
export type TCycle = TCycleDay[]
export type TCyclesMatrix = TCycle[]

export const EnumCycleStepType: {
  [key in string]: TCycleStepType
} = {
  AUDIO: 'audio',
  VIDEO: 'video',
  QUESTIONNAIRE: 'questionnaire',
  ACTIVE_REACH: 'activeReach',
  DAILY_INFO: 'dailyInfo',
  GATHER: 'gather',
  CONGRATS: 'congrats',
}

function generateAudioFilePath(day: number, step: number): string {
  return `${BUCKET_ASSETS.BASE_URL_NEUROCYCLES}${day}/${step}.mp3`
}

function createAudioStep(
  id: number,
  props: { title: string; subTitle?: string; day: number; step: number }
): TCycleStep {
  return {
    id,
    title: props.title,
    subTitle: props.subTitle || '',
    type: 'audio',
    files: [
      {
        locale: 'en',
        uri: generateAudioFilePath(props.day, props.step),
      },
    ],
  }
}

function createActiveReachStep(id: number): TCycleStep {
  return {
    id,
    title: 'Active Reach',
    subTitle: '',
    type: 'activeReach',
  }
}

function createCongratsStep(id: number): TCycleStep {
  return {
    id,
    title: 'Congrats',
    subTitle: '',
    type: 'congrats',
  }
}

function createGatherStep(id: number): TCycleStep {
  return {
    id,
    title: 'Gather',
    subTitle: '',
    type: 'gather',
  }
}

function createResourceVideo(id: number, resourceId: number): TCycleStep {
  return {
    id,
    title: 'Resource Video',
    subTitle: '',
    type: 'video',
    resourceId,
  }
}

const introMiniLessonVariant = createAudioStep(0, {
  title: 'Intro & Mini Lesson',
  day: 0,
  step: 1,
})

const introBaseVariant = (day: number) =>
  createAudioStep(0, {
    title: 'Intro Lesson',
    day,
    step: 1,
  })

const dailyBaseVariant: TCycleStep = {
  id: 0,
  title: 'Daily Video',
  subTitle: '',
  type: 'dailyInfo',
}

const baseSteps = (day: number): TCycleStep[] => [
  createAudioStep(2, {
    title: 'Step 1',
    subTitle: 'Gather Awareness',
    day,
    step: 3,
  }),
  createAudioStep(2, {
    title: 'Step 2',
    subTitle: 'Reflect & Analyze',
    day,
    step: 4,
  }),
  createAudioStep(2, {
    title: 'Step 3',
    subTitle: 'Write it down!',
    day,
    step: 5,
  }),
  createAudioStep(2, {
    title: 'Step 4',
    subTitle: 'Recheck and edit what you just wrote',
    day,
    step: 6,
  }),
  createAudioStep(2, {
    title: 'Step 5',
    subTitle: 'Practice and apply through an active reach',
    day,
    step: 7,
  }),
  createActiveReachStep(7),
  createCongratsStep(8),
]

const variantSteps = (day: number): TCycleStep[] => [
  createAudioStep(0, {
    title: 'Brain Prep',
    day,
    step: 2,
  }),
  ...baseSteps(day),
]

const questionnaireStep: TCycleStep = {
  id: 1,
  title: 'Questionnaire',
  subTitle: '',
  type: 'questionnaire',
}

function remapIds(steps: TCycleStep[]): TCycleStep[] {
  return steps.map((step, index) => ({ ...step, id: index }))
}

const day1Step = (day: number): TCycleStep[] => [
  introMiniLessonVariant,
  questionnaireStep,
  createGatherStep(2),
  createResourceVideo(3, 5),
  ...variantSteps(day - 1),
]

const day6Step = (day: number): TCycleStep[] => [
  introBaseVariant(day - 1),
  createResourceVideo(5, 3),
  ...variantSteps(day - 1).slice(0, 4),
  ...variantSteps(day - 1).slice(4, variantSteps(day - 1).length),
]

const day21Step = (day: number): TCycleStep[] => [
  introBaseVariant(day - 1),
  ...variantSteps(day - 1).slice(0, variantSteps(day - 1).length - 1),
  questionnaireStep,
  createCongratsStep(8),
]

const day42Step = (day: number): TCycleStep[] => [
  dailyBaseVariant,
  questionnaireStep,
  createCongratsStep(1),
]

const day63Step = (day: number): TCycleStep[] => [
  dailyBaseVariant,
  questionnaireStep,
  createCongratsStep(1),
]

const standardDayStep = (day: number): TCycleStep[] => [
  introBaseVariant(day - 1),
  ...variantSteps(day - 1),
]

const standardExtendedDayStep = (day: number): TCycleStep[] => [
  dailyBaseVariant,
  createCongratsStep(1),
]

const cyclesMatrix: TCyclesMatrix = [
  [
    day1Step(1),
    standardDayStep(2),
    standardDayStep(3),
    standardDayStep(4),
    standardDayStep(5),
    day6Step(6),
    standardDayStep(7),
    standardDayStep(8),
    standardDayStep(9),
    standardDayStep(10),
    standardDayStep(11),
    standardDayStep(12),
    standardDayStep(13),
    standardDayStep(14),
    standardDayStep(15),
    standardDayStep(16),
    standardDayStep(17),
    standardDayStep(18),
    standardDayStep(19),
    standardDayStep(20),
    day21Step(21),
    standardExtendedDayStep(22),
    standardExtendedDayStep(23),
    standardExtendedDayStep(24),
    standardExtendedDayStep(25),
    standardExtendedDayStep(26),
    standardExtendedDayStep(27),
    standardExtendedDayStep(28),
    standardExtendedDayStep(29),
    standardExtendedDayStep(30),
    standardExtendedDayStep(31),
    standardExtendedDayStep(32),
    standardExtendedDayStep(33),
    standardExtendedDayStep(34),
    standardExtendedDayStep(35),
    standardExtendedDayStep(36),
    standardExtendedDayStep(37),
    standardExtendedDayStep(38),
    standardExtendedDayStep(39),
    standardExtendedDayStep(40),
    standardExtendedDayStep(41),
    day42Step(42),
    standardExtendedDayStep(43),
    standardExtendedDayStep(44),
    standardExtendedDayStep(45),
    standardExtendedDayStep(46),
    standardExtendedDayStep(47),
    standardExtendedDayStep(48),
    standardExtendedDayStep(49),
    standardExtendedDayStep(50),
    standardExtendedDayStep(51),
    standardExtendedDayStep(52),
    standardExtendedDayStep(53),
    standardExtendedDayStep(54),
    standardExtendedDayStep(55),
    standardExtendedDayStep(56),
    standardExtendedDayStep(57),
    standardExtendedDayStep(58),
    standardExtendedDayStep(59),
    standardExtendedDayStep(60),
    standardExtendedDayStep(61),
    standardExtendedDayStep(62),
    day63Step(63),
  ],
]

export function getCycleMatrixByCycleNumber(cycleNumber: number): TCycle {
  return cyclesMatrix[0]
}

export default cyclesMatrix
