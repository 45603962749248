import React, { Fragment } from 'react'
import {
  AutonomyView,
  AwarenessView,
  BarriersView,
  EmpowermentView,
  ToxicStressView,
  ToxicThoughtsView,
} from 'screens/Results/views'

export function OverviewView() {
  return (
    <Fragment>
      <AutonomyView overview={true} />
      <AwarenessView overview={true} />
      <ToxicThoughtsView overview={true} />
      <ToxicStressView overview={true} />
      <BarriersView overview={true} />
      <EmpowermentView overview={true} />
    </Fragment>
  )
}
