import { useContext } from 'react'
import { PurchaseContext } from 'providers/PurchaseProvider'
import { IUsePurchases } from 'state/interfaces'

export function usePurchases() {
  const context = useContext<IUsePurchases>(PurchaseContext as any)
  if (context === undefined) {
    throw new Error('usePurchase must be used within a PurchaseProvider')
  }
  return context
}
