import React, { useState } from 'react'
import { Pressable, HStack, Spinner, Text, useTheme } from 'native-base'
import { PDF } from 'components/Icons'

export function DownloadCard({ data: { title, type, url } }) {
  const { colors } = useTheme()
  const [downloading, setDownloading] = useState(false)

  const downloadFile = async () => {
    actualDownload()
  }
  const actualDownload = () => {
    setDownloading(true)

    tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.setAttribute('target', '_blank')
    tempLink.click()
    setDownloading(false)
  }

  return (
    <Pressable
      onPress={url ? () => downloadFile() : null}
      width="100%"
      height={100}
      borderRadius={15}
      backgroundColor={colors.willowbrook[500]}
      mb={8}
    >
      <HStack
        alignItems="center"
        flexDirection="row"
        style={{ height: '100%', padding: 24 }}
      >
        {downloading && <Spinner color={colors.puertorico[700]} size="sm" />}
        {type === 'pdf' && !downloading ? <PDF width={25} height={25} /> : null}
        <Text fontSize="xl" bold style={{ marginLeft: 10 }}>
          {title}
        </Text>
      </HStack>
    </Pressable>
  )
}

DownloadCard.propTypes = {}
