import { AuthFooter, AuthHeading } from 'components/Headings'
import {
  Box,
  KeyboardAvoidingView,
  ScrollView,
  Spinner,
  Text,
  useTheme,
  VStack,
} from 'native-base'

import { Formik } from 'formik'
import { Platform } from 'react-native'
import React, { useState } from 'react'
import map from 'lodash/map'
import DatePicker from 'react-day-picker'

import 'react-responsive-datepicker/dist/index.css'
export function AppForm({
  children,
  config,
  haveError,
  noKeyboardAvoidingView,
  noFooter,
  style,
}) {
  const {
    actions,
    authFooter,
    actionFooter,
    heading,
    initialValues,
    rows,
    validationSchema,
    enableReinitialize,
    validateOnBlur,
    validateOnChange,
    dark = false,
    noScroll = false,
  } = config

  const BodyComponent = noScroll ? Box : ScrollView
  const [show, setShow] = useState(false)
  const { colors } = useTheme()

  const slot = (
    <>
      <Formik
        enableReinitialize={enableReinitialize ?? true}
        initialValues={initialValues}
        onSubmit={(values, formik) => {
          actions.submit.handle(values, formik)
        }}
        validateOnBlur={validateOnBlur ?? true}
        validateOnChange={validateOnChange ?? true}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          setSubmitting,
          dirty,
          isValid,
          ...formik
        }) => {
          if (haveError && isSubmitting) {
            setSubmitting(false)
          }
          return (
            <>
              <Box
                flex={1}
                flexBasis="auto"
                style={{
                  minHeight: '60vh',
                }}
                justifyContent="space-around"
                {...(!noScroll && { height: '100%' })}
              >
                <BodyComponent
                  contentContainerStyle={{ flexGrow: 1 }}
                  showsVerticalScrollIndicator={false}
                  fadingEdgeLength={100}
                >
                  <VStack>
                    {heading.title ? (
                      <AuthHeading
                        style={style}
                        heading={heading.title}
                        subHeading={heading.subTitle}
                      />
                    ) : (
                      !heading.noSpacer && <Box pt={6} />
                    )}
                    <Box flex={1} py={6} flexBasis='auto'>
                      {map(rows, ({ fields }) =>
                        map(
                          fields,
                          ({
                            id: fieldId,
                            component: Component,
                            label,
                            type,
                            items,
                            inputProps,
                            keyboardType,
                            textContentType,
                          }) => (
                            <Box mb={4} key={fieldId}>
                              <Component
                                formik={formik}
                                id={fieldId}
                                name={fieldId}
                                label={label}
                                setShow={setShow}
                                show={show}
                                required={
                                  validationSchema?.fields[fieldId]?.spec
                                    ?.presence === 'required' || false
                                }
                                type={type}
                                style={style}
                                items={items}
                                inputProps={inputProps}
                                keyboardType={keyboardType}
                                textContentType={textContentType}
                                dark={dark}
                              />
                            </Box>
                          )
                        )
                      )}
                      {children}
                    </Box>
                  </VStack>
                </BodyComponent>
                <Box mb={Platform.OS === 'android' && -6}>
                  {!noFooter && (
                    <Box display="flex">
                      {(!Array.isArray(authFooter) ||
                        authFooter.length > 0) && (
                        <AuthFooter
                          data={authFooter}
                          style={style}
                          version="authFooter"
                        />
                      )}
                      {(!Array.isArray(actionFooter) ||
                        actionFooter.length > 0) && (
                        <AuthFooter
                          data={actionFooter}
                          style={style}
                          version="actionFooter"
                        />
                      )}
                    </Box>
                  )}
                  {actions &&
                    map(
                      actions,
                      ({
                        id,
                        component: Component,
                        label,
                        variant,
                        variantDisabled,
                      }) => (
                        <Component
                          key={id}
                          onPress={handleSubmit}
                          variant={isSubmitting ? variantDisabled : variant}
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          <Text
                            fontSize="lg"
                            variant={isSubmitting ? variantDisabled : variant}
                            style={{ letterSpacing: 0.5 }}
                          >
                            {isSubmitting ? (
                              <Spinner color="white.500" size="sm" pt={1} />
                            ) : (
                              label
                            )}
                          </Text>
                        </Component>
                      )
                    )}
                </Box>
              </Box>
            </>
          )
        }}
      </Formik>
    </>
  )

  return noKeyboardAvoidingView ? (
    slot
  ) : (
    <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={50}>
      {slot}
    </KeyboardAvoidingView>
  )
}
