import React from 'react'
import { CyclesProvider } from 'state/providers/CyclesProvider'
import { useAuth } from 'state/hooks/useAuth'
import { PlayerStatusProvider } from 'state/providers/PlayerStatusProvider'
import { PlayerProvider } from 'state/providers/PlayerProvider'
import { Player } from 'components/Player'
import CyclesManager from 'components/Cycles/CyclesManager'

export function MainLandingProvider({ children }: React.ComponentProps<'div'>) {
  const {
    state: { user },
  } = useAuth()

  return (
    <PlayerStatusProvider>
      <PlayerProvider>
        <CyclesProvider user={user}>
          <CyclesManager />
          <Player />

          {children}
        </CyclesProvider>
      </PlayerProvider>
    </PlayerStatusProvider>
  )
}
