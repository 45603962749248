import React from 'react'
import { WebViewLoader } from 'components/WebViewLoader'
import { WEBVIEWS } from 'state/consts'
import { Button } from 'native-base'
import { Linking } from 'react-native'
export function BlogView() {
  const open = async () => {
    await Linking.openURL(WEBVIEWS.BLOG)
  }
  return (
    <Button onPress={open} variant="primary">
      Read our Blog
    </Button>
  )
}
