import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, HStack, Text, View } from 'native-base'
import {
  DailyInspiration,
  GreetingsCard,
  MainCard,
  SubCards,
} from 'components/Cards'
import { EXTRA_LMM_QUESTIONS, REMOTE_BGS } from 'state/consts'
import { AppState } from 'react-native'
import { PageContainer } from 'components/Containers/Page'
import { Subscription } from 'components/Modal/Subscription'
import Tutorial from 'components/Modal/Tutorial'
import notifee from '@notifee/react-native'
import { useIsFocused } from '@react-navigation/native'
import { usePurchases, useAuth, useCycles } from 'hooks/index'
import { useQuery } from 'urql'
import { AdCard } from 'components/Cards/AdCard'
import { InfomationalButtons } from 'components/Buttons/InfomationalButtons'
import { CongratsProgress } from 'components/Progress'

export function HomeLandingScreen({ navigation }) {
  const [tutorial, showTutorial] = useState(false)
  const [hasNotification, setHasNotification] = useState(false)
  const [setShowSubscription] = useState(false)
  const {
    dispatch: { dispatch, setQuestions },
    state: cycleState,
  } = useCycles()
  const { day } = cycleState
  const {
    dispatch: { setProfile },
    state: { profile, user, showSubscriptionModal, showRemindMeLater, loading },
  } = useAuth()
  const { state } = usePurchases()

  const isFocused = useIsFocused()
  const appState = useRef(AppState.currentState)

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        notifee.getBadgeCount().then((count) => setHasNotification(!!count))
      }
      appState.current = nextAppState
    })

    return () => {
      subscription?.remove()
    }
  }, [])
  useEffect(() => {
    if (isFocused) {
      notifee.getBadgeCount().then((count) => setHasNotification(!!count))
    }
  }, [isFocused])

  // useEffect(() => {
  //   async function getUserProfile() {
  //     try {
  //       if (!profile?.firstName || !profile?.lastName) {
  //         navigation.navigate('ProfileInfo')
  //       }
  //       if (!profile?.seenTutorial) {
  //         showTutorial(true)
  //       }
  //     } catch (error) {
  //       throw new Error(`${error}`)
  //     }
  //   }
  //   getUserProfile()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [profile])

  const query = `#graphql
  query {
    allQuestionnaires(first: 100, where: { active: true }) {
      edges {
        node{
          _meta {
            id
          }
          type
          question
        }
      }
    }
  }
`

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {},
  })

  const cleanData = useMemo(() => {
    if (!result.data?.allQuestionnaires?.edges) {
      return []
    }
    return [].concat(
      ...result.data?.allQuestionnaires?.edges?.map((edge) => edge.node),
      ...EXTRA_LMM_QUESTIONS
    )
  }, [result])

  const formattedCell = cleanData.map((elem, i) => {
    return {
      type: elem.type,
      question: elem.question[0].text,
    }
  })

  useEffect(() => {
    if (cycleState?.questions?.length === 0 && formattedCell?.length > 0) {
      setQuestions(dispatch, formattedCell)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  if (showSubscriptionModal) {
    return (
      <Subscription
        user={user}
        showRemindMeLater={showRemindMeLater}
        userProfile={profile}
        setShowSubscription={setShowSubscription}
        showSubscription={showSubscriptionModal}
      />
    )
  }

  if (tutorial) {
    return (
      <Tutorial
        setProfile={setProfile}
        user={user}
        userProfile={profile}
        navToProfileInfo={() => navigation.navigate('ProfileInfo')}
        tutorial={tutorial}
        showTutorial={showTutorial}
      />
    )
  }

  const subCards = [
    {
      id: 'restart',
      source: REMOTE_BGS.DECOMPRESSION_TIPS,
      link: 'DecompressionTips',
      title: 'Decompression Guides',
      cardbg: '#4cbfe1',
    },
    {
      id: 'mini',
      source: REMOTE_BGS.MINI_CYCLES,
      link: 'MiniCycles',
      title: 'Mini Neurocycles',
      cardbg: '#fc7b6c',
    },
    {
      id: 'parent',
      source: REMOTE_BGS.PARENT_CYCLES,
      link: 'ParentNeurocycles',
      title: 'For Parents',
      cardbg: '#4cbfe1',
    },
  ]

  return (
    <PageContainer maxWidth={1440}>
      <Box width="100%" px={2}>
        <GreetingsCard
          hasNotification={hasNotification}
          name={profile?.firstName}
          loading={loading}
        />
      </Box>
      {/* <AdCard /> */}
      <CongratsProgress />
      <HStack justifyContent="flex-end">
        <Text
          mt={-2}
          mb={5}
          fontSize={20}
          color={'#000812'}
          variant="text-MainCardCycleDays"
        >
          Completed {day + 1} out of 63 Days
        </Text>
      </HStack>
      <HStack justifyContent={'start'}>
        <Text
          mt="15"
          color={'#000812'} bold fontSize={'4xl'}
          mb="5"
          colorScheme="primary"
          adjustsFontSizeToFit={true}
          numberOfLines={1}
        >
          Neurocycle Program
        </Text>
      </HStack>
      <Box
        height={{ base: '25rem', md: '25rem', lg: '30rem' }}
        mb={8}>

        <MainCard
          // title={`${cycleState.day > 0 || cycleState.track > 0 ? 'Continue' : 'Start'
          //   } your 63 Day Neurocycle Program`}
          title={`${cycleState.day > 0 || cycleState.track > 0 ? 'Resume' : 'Start'
            }`}
          subTitle=""
          source={REMOTE_BGS.TWENTY_ONE}
          purchaseHistory={
            typeof state.purchaseHistory === 'object'
              ? state.purchaseHistory
              : false
          }
        />
      </Box>
      <Text color={'#000812'} bold fontSize={30} mt={6} mb={6}>Explore</Text>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexWrap="wrap"
        width={'100%'}
        flexGrow={1}
        width="100%"
        height={{ base: 1400, md: 250, lg: 350 }}
        flexDirection={{
          base: 'col',
          md: 'row',
          lg: 'row',
        }}
      >


        <SubCards
          navigation={navigation}
          expired={state.expired}
          config={subCards}
          purchaseHistory={
            typeof state.purchaseHistory === 'object'
              ? state.purchaseHistory
              : false
          }
        />
      </Box>

      <Box
        mt={'4%'}
      >
        <InfomationalButtons
          backgroundColor={'#fee174'}
          title={'Podcast'}
          source={REMOTE_BGS.PODCASTBTN_BG}
          link="https://anchor.fm/cleaningupthementalmess"
        />
        <InfomationalButtons
          backgroundColor={'#43ccbb'}
          title={'Store'}
          source={REMOTE_BGS.STOREBTN_BG}
          link="https://drleaf.com/collections/all"
        />
        <InfomationalButtons
          backgroundColor={'#ff7b6c'}
          title={'Find a Facilitator'}
          source={REMOTE_BGS.FACILITATORBTN_BG}
          link="https://neurocycleinstitute.com/find-a-facilitator/"
        />
      </Box>
      <DailyInspiration />
    </PageContainer>
  )
}
