import React, { createContext, useEffect } from 'react'
import { DISPATCH } from 'state/consts'
import { useImmerReducer } from 'use-immer'
import { firestore, auth } from 'config/firebase'

export const AuthContext = createContext()
const initialState = {
  subscriberBool: false,
  showSubscriptionModal: false,
  loading: true,
  user: false,
  error: null,
  profile: null,
  settings: {
    autoplay: true,
  },
}

function authReducer(state, action) {
  switch (action.type) {
    case DISPATCH.AUTH.GET_USER: {
      try {
        const user = auth().currentUser
        if (user) {
          return { ...state, user }
        }
        return { ...state, loading: false }
      } catch (error) {
        return { ...state, loading: false, error: error.code }
      }
    }
    case DISPATCH.AUTH.SET_PROFILE: {
      return {
        ...state,
        profile: action.profile,
        user: action.user,
        settings: { ...initialState.settings, ...action.profile.settings },
        loading: false,
      }
    }
    case DISPATCH.AUTH.RESET_PASSWORD: {
      break
    }
    case DISPATCH.AUTH.LOGOUT_USER: {
      auth().signOut()
      return { ...initialState, loading: false }
    }
    case DISPATCH.AUTH.SHOW_SUBSCRIPTION: {
      return {
        ...state,
        showRemindMeLater: action.remindMeLaterBool,
        showSubscriptionModal: action.showSubscriptionBool,
      }
    }
    case DISPATCH.AUTH.SET_SUBSCRIBER_BOOL: {
      return {
        ...state,
        subscriberBool: action.subscriberBool,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useImmerReducer(authReducer, initialState)

  async function onAuthStateChanged(user) {
    await loadProfile(user)
  }

  const loadProfile = async (user) => {
    if (!user) {
      dispatch({ type: DISPATCH.AUTH.GET_USER })
      return
    }
    const userProfile = await firestore()
      .collection('users')
      .doc(user.uid)
      .get()
    if (userProfile?.data()) {
      setProfile(user, userProfile.data())
    }
    return userProfile?.data()
  }

  const setProfile = (user, profile) => {
    dispatch({
      user,
      profile,
      type: DISPATCH.AUTH.SET_PROFILE,
    })
  }

  const setShowSubscriptionModal = (
    showSubscriptionBool,
    remindMeLaterBool
  ) => {
    dispatch({
      showSubscriptionBool,
      remindMeLaterBool,
      type: DISPATCH.AUTH.SHOW_SUBSCRIPTION,
    })
  }

  const setSubscriberBool = (subscriberBool) => {
    dispatch({
      subscriberBool: subscriberBool,
      type: DISPATCH.AUTH.SET_SUBSCRIBER_BOOL,
    })
  }

  const value = {
    state,
    dispatch: {
      setSubscriberBool,
      setShowSubscriptionModal,
      setProfile,
      loadProfile,
      dispatch,
    },
  }

  useEffect(() => {
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged)
    return subscriber
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
