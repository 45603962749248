import { object, string, ref } from 'yup'
import { INPUT_TYPES } from 'state/consts'
import { Button } from 'native-base'
import { TextInput } from 'components/Form/Inputs/TextInput'

export const changePasswordConfig = {
  validationSchema: object({
    currentPassword: string().required('Current password is required'),
    password: string()
      .required('New password is required')
      .min(8, 'Should be at least 8 characters'),
    confirmPassword: string().oneOf(
      [ref('password'), null],
      'Passwords must match'
    ),
  }),
  heading: {},
  rows: [
    {
      id: 'profile_row0',
      fields: [
        {
          id: 'currentPassword',
          label: 'Current Password',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          required: true,
          inputProps: {
            autoFocus: true,
          },
        },
      ],
    },
    {
      id: 'profile_row1',
      fields: [
        {
          id: 'password',
          label: 'New Password',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          required: true,
        },
      ],
    },
    {
      id: 'profile_row2',
      fields: [
        {
          id: 'confirmPassword',
          label: 'Confirm password',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
  ],
  actions: {
    submit: {
      id: 'confirmPassword_submit',
      component: Button,
      variant: 'primary',
      variantDisabled: 'primaryDisabled',
      label: 'Set Password',
    },
  },
}
