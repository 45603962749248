import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G, Defs, Circle, Use } from 'react-native-svg'

export function PauseTrack({ width, height, outerfill, innerFill }) {
  return (
    <Icon width={width} height={height} viewBox="0 0 40 40">
      <G
        transform="translate(-309 -1607) translate(25 1607) translate(284)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Circle fill={outerfill} cx={20} cy={20} r={20} />
        <G transform="translate(16.5 15)" fill={innerFill}>
          <Path d="M0 0H2V10H0z" />
          <Path d="M5 0H7V10H5z" />
        </G>
      </G>
    </Icon>
  )
}

PauseTrack.defaulProps = {
  width: 20,
  height: 20,
  outerfill: '#D8D8D8',
  innerFill: '#FFFFFF',
}

PauseTrack.propTypes = {
  width: number,
  height: number,
}
