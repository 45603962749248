import { object, string } from 'yup'
import { INPUT_TYPES } from 'state/consts'
import { Button } from 'native-base'
import { RadioInput } from 'components/Form/Inputs/RadioInput'

import { TextAreaInput } from '../../../components/Form/Inputs/TextAreaInput'

export const activeReachConfig = {
  enableReinitialize: true,
  noScroll: true,
  validationSchema: object().shape({
    reach: string().required('Required'),
  }),
  heading: {
    noSpacer: true,
  },
  authFooter: [],
  rows: [
    {
      id: 'activeReach_row0',
      fields: [
        {
          id: 'reach',
          label: 'Active Reach',
          type: INPUT_TYPES.TEXT,
          component: TextAreaInput,
          inputProps: {
            rows: 2,
          },
        },
      ],
    },
    {
      id: 'activeReach_row1',
      fields: [
        {
          id: 'interval',
          label: 'How often do you want to be reminded?',
          type: INPUT_TYPES.RADIO,
          component: RadioInput,
          items: [
            { id: 0, label: 'EVERY HOUR', value: 1 },
            { id: 1, label: 'EVERY 2 HOURS', value: 2 },
            { id: 2, label: 'EVERY 3 HOURS', value: 3 },
            { id: 3, label: 'EVERY 5 HOURS', value: 5 },
            { id: 4, label: 'EVERY 8 HOURS', value: 8 },
            { id: 5, label: 'SNOOZE', value: null },
          ],
        },
      ],
    },
  ],
  actionFooter: [],
}
