import React from 'react'
import { number, string } from 'prop-types'
import { Icon } from 'native-base'
import { Polygon, Rect, G } from 'react-native-svg'

export function PreviousTrack({ width, height, stroke }) {
  return (
    <Icon width={width} height={height} viewBox="0 0 20 25">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-player-skip-back"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="#FFFFFF"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        {' '}
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />{' '}
        <path d="M20 5v14l-12 -7z" /> <line x1="4" y1="5" x2="4" y2="19" />{' '}
      </svg>
    </Icon>
  )
}

PreviousTrack.defaulProps = {
  width: 20,
  height: 20,
  stroke: '#FFFFFF',
}

PreviousTrack.propTypes = {
  width: number,
  height: number,
  stroke: string,
}
