import format from 'date-fns/format'
import React, { useMemo, useState, Platform } from 'react'
import { Button, Text, useTheme, Icon } from 'native-base'
import { AppFormControl as FormControl } from 'components/Form/Inputs/FormControl'
import _ from 'lodash'
import { AntDesign } from '@native-base/icons'
import { DatePicker } from 'react-responsive-datepicker'

export function DateInput({
  type,
  id,
  formik,
  label,
  required,

  inputProps = {},
  setShow,
  show,
  dark = false,
}) {
  const { values } = formik
  const { colors } = useTheme()

  let humanDate = inputProps.placeholder || 'Select Date...'
  if (values[id]) {
    try {
      humanDate = format(new Date(values[id]), 'do MMM, yyyy')
    } catch (e) {}
  }

  let textColor
  if (humanDate === inputProps.placeholder) {
    textColor = colors.cadet[800]
  } else if (dark) {
    textColor = colors.white[500]
  } else {
    colors.black[500]
  }

  let date = new Date(values[id])
  const value = date instanceof Date && !!date.getDate() ? date : new Date()

  return (
    <>
      <FormControl id={id} formik={formik} required={required} label={label}>
        <Button
          // startIcon={<Calendar mr={1} mt={-1} size="sm" color={dark ? colors.white[500] : textColor} />}
          startIcon={
            <Icon
              size={6}
              as={AntDesign}
              name="calendar"
              color={dark ? colors.white[500] : textColor}
            />
          }
          mt={2}
          onPress={() => setShow(!show)}
          size="container"
          style={{
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingLeft: 22,
            borderColor: dark ? colors.white[500] : undefined,
            height: 60,
          }}
          variant="input"
          {..._(inputProps).omit(['style']).value()}
        >
          <Text fontColor="color" style={{ color: textColor }}>
            {humanDate}
          </Text>
        </Button>
        <DatePicker
          isOpen={show}
          colorScheme={colors.puertorico[500]}
          headerFormat="DD, MM dd"
          defaultValue={value}
          onChange={(_date) => {
            setShow(false)
            formik.setFieldValue(id, _date.toISOString())
          }}
          clearText={''}
          onClose={() => {
            setShow(false)
          }}
          minDate={(() => {
            const minDate = new Date()
            minDate.setFullYear(minDate.getFullYear() - 100)
            return minDate
          })()}
        />
      </FormControl>
    </>
  )
}
