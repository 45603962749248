import React, { useEffect, useRef, useState } from 'react'
import {
  ArrowBackIcon,
  Box,
  Button,
  CloseIcon,
  Image,
  Modal,
  Pressable,
  ScrollView,
  Text,
  ZStack,
  Center,
  Container,
} from 'native-base'
import { Audio, ResizeMode, Video, VideoFullscreenUpdate } from 'expo-av'
import { ICycleStepProps } from 'state/interfaces'
import { Loading } from 'components/Loading'
import { PlayTrack } from 'components/Icons'

type TVideo = {
  img: string
  uniqueKey: number
  title: string
  url: string
  type: 'video'
}
interface Props extends ICycleStepProps {
  video: TVideo
}

const DayModal = (props: Props) => {
  const { colors } = props.useTheme
  const [activeVideo, setActiveVideo] = useState<TVideo | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [uniqueKey] = useState(0)
  const player = useRef<Video>(null)

  const handlePress = () => {
    props.onNext()
  }

  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
    })
  }, [])
  return (
    <Modal isOpen={true} backgroundColor={colors.puertorico[500]} safeArea>
      <Center width="100%" height="100%">
        <Container width="100%" height="100%" maxWidth={1024}>
          <ScrollView height="100%" width="100%">
            <Box
              safeArea
              flex={1}
              width="100%"
              px={6}
              pb={8}
              style={{ position: 'relative', zIndex: 9 }}
            >
              <Box flexDirection="row" justifyContent="space-between">
                <Pressable
                  onPress={props.onBack}
                  style={{
                    height: '10%',
                    width: 25,
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <ArrowBackIcon color="#FFFFFF" size="6" />
                </Pressable>
                <Pressable
                  onPress={props.onClose}
                  style={{
                    height: '10%',
                    width: 25,
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CloseIcon color="#FFFFFF" size="4" />
                </Pressable>
              </Box>
              <Box justifyContent="flex-start">
                <Box
                  marginTop={4}
                  flexDirection="row"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                >
                  <Text
                    fontSize="3xl"
                    fontWeight="800"
                    style={{ color: 'white' }}
                    mb={4}
                  >
                    {props.video.title}
                  </Text>
                  {props.video.uniqueKey === 3 && (
                    <>
                      <Text
                        fontWeight="800"
                        fontFamily="Arial"
                        style={{ color: 'white' }}
                      >
                        &reg;
                      </Text>
                      <Text
                        fontSize="3xl"
                        fontWeight="800"
                        flexGrow={0}
                        style={{ color: 'white' }}
                        mb={4}
                      >
                        {' '}
                        Method
                      </Text>
                    </>
                  )}
                </Box>
                <Pressable
                  onPress={() => {
                    setActiveVideo(props.video)
                  }}
                >
                  <ZStack
                    height={300}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Image
                      height={300}
                      width="100%"
                      resizeMode="contain"
                      source={{ uri: props.video.img }}
                      alt={props.video.title}
                    />
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <PlayTrack
                        innerFill={colors.white[500]}
                        outerFill={colors.puertorico[200]}
                        height={80}
                        width={80}
                      />
                    )}
                  </ZStack>
                </Pressable>
                <Video
                  key={uniqueKey}
                  posterSource={{ uri: props.video.img }}
                  source={{
                    uri: activeVideo && activeVideo.url,
                  }} // Can be a URL or a local file.
                  ref={player}
                  resizeMode={ResizeMode.CONTAIN}
                  onFullscreenUpdate={({ fullscreenUpdate }) => {
                    switch (fullscreenUpdate) {
                      case VideoFullscreenUpdate.PLAYER_WILL_PRESENT:
                        break
                      case VideoFullscreenUpdate.PLAYER_DID_PRESENT:
                        player.current?.playFromPositionAsync(0)
                        break
                      case VideoFullscreenUpdate.PLAYER_WILL_DISMISS:
                        player.current?.pauseAsync()
                        setActiveVideo(null)
                        break
                      case VideoFullscreenUpdate.PLAYER_DID_DISMISS:
                        player.current?.pauseAsync()
                    }
                  }}
                  onLoadStart={() => {
                    setIsLoading(true)
                    player.current?.presentFullscreenPlayer()
                  }}
                  onLoad={() => setIsLoading(false)}
                  onError={() => setIsLoading(false)}
                  style={{
                    width: '100%',
                    height: '0%',
                  }}
                />
              </Box>
            </Box>
          </ScrollView>
          <Box width="100%" px={6}>
            <Button mb={10} variant="primary" onPress={() => handlePress()}>
              NEXT
            </Button>
          </Box>
        </Container>
      </Center>
    </Modal>
  )
}

const styles = {
  onStage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#021D3D',
  },
  offStage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: 'white',
  },
}

// eslint-disable-next-line import/no-default-export
export default DayModal
