import React from 'react'
import { Box, Button, Modal, Text, useTheme } from 'native-base'

export function AppModal({ showModal, setShowModal, text, action }) {
  const { colors, ui } = useTheme()
  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      backgroundColor={ui.modalBg}
    >
      <Box
        backgroundColor={colors.puertorico[500]}
        style={{ borderRadius: ui.borderRadius }}
        p={4}
        m={4}
      >
        <Box>
          <Text textAlign="center" variant="player" fontSize="3xl" p={6}>
            {text}
          </Text>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
          mt={4}
        >
          <Button
            width="45%"
            variant="cancel"
            onPress={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button width="45%" variant="confirm" onPress={() => action(true)}>
            Clear All
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
