import { Audio } from 'expo-av'

export type SetupLockScreenControlsProps = {
  sound: Audio.Sound
  signal: AbortSignal
  state: any
  playSound: any
  pauseSound: any
}

export const setupLockScreenControls = (
  props: SetupLockScreenControlsProps
) => {}
