import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Svg, Path, G, Line } from 'react-native-svg'

export function UpArrow({ width, height }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 9"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M2 0.5L0.5 2L7 8.5L13.5 2L12 0.5L7 5.5L2 0.5Z"
        fill="#BFBFBF"
      />
    </Svg>
  )
}

UpArrow.defaulProps = {
  width: 20,
  height: 20,
}

UpArrow.propTypes = {
  width: number,
  height: number,
}
