import map from 'lodash/map'
import { getTimeZone } from 'react-native-localize'
import { format } from 'date-fns-tz'
import getUnixTime from 'date-fns/getUnixTime'
import subDays from 'date-fns/subDays'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import ms from 'pretty-ms'

export function buildDate(date) {
  const timeZone = 'Europe/Moscow'

  const out = {}
  const dateIn = new Date(date)
  out.dateString = format(dateIn, 'yyyy-MM-dd', { timeZone })
  out.day = Number(format(dateIn, 'dd', { timeZone }))
  out.month = Number(format(dateIn, 'M', { timeZone }))
  out.timestamp = Number(getUnixTime(dateIn))
  out.year = Number(format(dateIn, 'yyyy', { timeZone }))
  return out
}

export function buildDates(dates) {
  const out = {}
  map(dates, (date) => (out[date] = {}))
  return out
}

export function getCurrentWeek() {
  const output = []
  const end = new Date()
  const start = subDays(end, 6)
  const range = eachDayOfInterval({ start, end })
  map(range, (date) => output.push(format(new Date(date), 'yyyy-MM-dd')))
  return output
}

export function formatDaysByDate(dates) {
  const output = []
  map(dates, (date) => output.push(format(new Date(date), 'EEEEE')))
  return output
}

export function convertMillisToTimeStamp(millis) {
  return ms(millis ? parseInt(millis, 10) : 0, {
    colonNotation: true,
    secondsDecimalDigits: 0,
  })
}
export function dateFromTimestampInLocalTimezone(timestamp) {
  const offset = new Date(timestamp).getTimezoneOffset() * 60 * 1000
  return new Date(offset + timestamp)
}

export function formatDateByTimezone(date, dateFormat) {
  return format(new Date(date), dateFormat, { timeZone: getTimeZone() })
}

export function getNumberOfDays(start, end) {
  const date1 = new Date(start)
  const date2 = new Date(end)
  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24
  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime()
  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay)
  return diffInDays
}
