import { Fragment, useEffect } from 'react'
import { useTheme } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import {
  AudioStep,
  VideoStep,
  GatherStep,
  QuestionnaireStep,
  ActiveReachStep,
  CongratsStep,
  DailyInfoStep,
} from 'components/Cycles'
import { EnumCycleStepType, TCycleStep } from 'state/cyclesMatrix'
import { useCycles, usePlayer, useAuth, usePurchases } from 'hooks/index'

export default function CyclesManager() {
  const useThemeResult = useTheme()
  const useCyclesResult = useCycles()
  const usePlayerResult = usePlayer()
  const useAuthResult = useAuth()
  // const usePurchasesResult = usePurchases()
  const useNavigationResult = useNavigation()
  const {
    state: { user, profile },
  } = useAuthResult
  const {
    state: cycleState,
    dispatch: { cycleTrack, dispatch, setOpen },
  } = useCyclesResult
  const {
    dispatch: { checkIfShouldShowSubscriptionModal },
  } = usePurchases()
  const {
    state: playerState,
    dispatch: { initStepSound, dismissPlayer, toggleSize },
  } = usePlayerResult

  async function _cycleTrack(direction: 'next' | 'prev') {
    await cycleTrack({
      checkIfShouldShowSubscriptionModal,
      direction,
      dispatch,
      playerState,
      state: cycleState,
      user,
      profile,
      dismissPlayer,
    })
  }

  const props = {
    useTheme: useThemeResult,
    useCycles: useCyclesResult,
    usePlayer: usePlayerResult,
    useAuth: useAuthResult,
    // usePurchases: usePurchasesResult,
    useNavigation: useNavigationResult,
    onNext() {
      _cycleTrack('next')
    },
    onBack() {
      _cycleTrack('prev')
    },
    onClose() {
      setOpen(false, dispatch)
    },
  }

  function _loadSound(step: TCycleStep) {
    initStepSound({
      step,
      onNext: props.onNext,
      onBack: props.onBack,
    })
  }

  useEffect(() => {
    if (cycleState.isOpen) {
      if (cycleState.type === EnumCycleStepType.AUDIO) {
        toggleSize('full')
        _loadSound(cycleState.step)
      } else {
        dismissPlayer()
      }
    }
  }, [cycleState.isOpen, cycleState.type, cycleState.step])

  function render() {
    switch (cycleState.type) {
      case EnumCycleStepType.AUDIO:
        return <AudioStep open={cycleState.isOpen} {...props} />
      case EnumCycleStepType.QUESTIONNAIRE:
        return <QuestionnaireStep open={cycleState.isOpen} {...props} />
      case EnumCycleStepType.GATHER:
        return <GatherStep open={cycleState.isOpen} {...props} />
      case EnumCycleStepType.VIDEO:
        return <VideoStep open={cycleState.isOpen} {...props} />
      case EnumCycleStepType.DAILY_INFO:
        return <DailyInfoStep open={cycleState.isOpen} {...props} />
      case EnumCycleStepType.ACTIVE_REACH:
        return <ActiveReachStep open={cycleState.isOpen} {...props} />
      case EnumCycleStepType.CONGRATS:
        return <CongratsStep open={cycleState.isOpen} {...props} />
      default:
        null
    }
  }

  return <Fragment>{cycleState.isOpen && render()}</Fragment>
}
