import React from 'react'
import { Box, Image, Text, Pressable, ZStack, useTheme } from 'native-base'
import { Video } from 'expo-av'

import { PlayTrack } from 'components/Icons'
import { Loading } from 'components/Loading'

export function ResourcesCard({
  data: { img, title, url },
  play,
  noPadding,
  isLoading = false,
}) {
  const { colors } = useTheme()
  return (
    <Box width="100%">
      <Box justifyContent="flex-start">
        <Text fontSize="2xl" fontWeight="800" my={noPadding ? 0 : 5}>
          {title}
        </Text>
        <Pressable
          onPress={() => {
            play(url)
          }}
        >
          <ZStack height={230} alignItems="center" justifyContent="center">
            {isLoading ? (
              <Loading color="gray.300" />
            ) : (
              <PlayTrack
                innerFill={colors.white[500]}
                outerFill={colors.puertorico[200]}
                height={80}
                width={80}
              />
            )}
            <Image
              borderRadius={15}
              height={'100%'}
              width={500}
              resizeMode="cover"
              alt={img}
              source={{ uri: img }}
              style={{
                width: '100%',
                height: '100%',
                zIndex: 2,
              }}
            />
          </ZStack>
        </Pressable>
      </Box>
    </Box>
  )
}

ResourcesCard.propTypes = {}
