import React, { useState } from 'react'
import Congrats from 'components/Modal/Congrats'
import { ICycleStepProps } from 'state/interfaces'

export interface Props extends ICycleStepProps {}

const CongratsStep: React.FC<Props> = (props) => {
  return <>{<Congrats {...props} />}</>
}

export default CongratsStep
