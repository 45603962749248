import React, { useState } from 'react'
import { ActivityIndicator, Linking, Platform } from 'react-native'
import {
  Box,
  Button,
  CloseIcon,
  Modal,
  Pressable,
  ScrollView,
  Text,
  useTheme,
  useToast,
} from 'native-base'
import { PurchasesOffering, PurchasesPackage } from 'react-native-purchases'
import _ from 'lodash'
import { useAuth, usePurchases } from 'state/hooks/index'
import { Back } from 'components/Icons/Back'
import PromoCodeModal from './PromoCode'
import { AppToast } from 'components/Toast'
import { v4 as uuidv4 } from 'uuid'

type SubscriptionProps = {
  showScreen: boolean
  showSubscription: (show: boolean) => void
  showRemindMeLater?: (show: boolean) => void
}

export const Subscription = ({
  showScreen = false,
  showSubscription,
  showRemindMeLater,
}: SubscriptionProps) => {
  const {
    dispatch: { setShowSubscriptionModal },
  } = useAuth()
  const {
    dispatch: {
      makePurchase,
      restorePurchase,
      setAvailableProds,
      cancelWebSubscription,
    },
    state,
  } = usePurchases()
  const toast = useToast()

  const subscriptionText = [
    'The full 63 day brain detox program',
    'All your mind-management results',
    'Detailed feedback & recommendations',
    'Over 30 mini audio guides for specific areas of concern',
    'Decompression audio guides',
    'And more!',
  ]

  const { colors } = useTheme()
  const [activeSubscription, setActiveSubscription] = useState<
    PurchasesPackage | undefined
  >(state.availableProducts ? state.availableProducts.monthly! : undefined)
  const [loading, setLoading] = useState(false)
  const [restoreLoading, setRestoreLoading] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)

  window.dataLayer = window.dataLayer || []

  const handleSubmit = async () => {
    if (loading) return
    try {
      setLoading(true)
      makePurchase(activeSubscription).then(() => {
        const orderReference = uuidv4()
        console.log('Subscription purchased successfully')

        const transformPriceId = {
          price_1Lg5FqEk0kqQuWoXUJmxlkDB: 'bronze',
          price_1Lg5GlEk0kqQuWoXHYv6Xta1: 'silver',
          price_1Lg5HIEk0kqQuWoXCLOw8fB8: 'gold',
        }

        let priceTier = activeSubscription?.platform_product_identifier
        if (transformPriceId[priceTier]) {
          priceTier = transformPriceId[priceTier]
        }

        const subscriptionData = {
          ...activeSubscription,
          orderReference,
          priceTier, // Add the transformed priceTier to the subscription data
        }

        // Save subscription information locally
        window.localStorage.setItem(
          'activeSubscription',
          JSON.stringify(subscriptionData)
        )

        setLoading(false)
        console.log('Subscription DATA:', subscriptionData)
      })
      console.log('Subscription details:', activeSubscription)
    } catch (err) {
      console.log('Error ->', err)
    }
  }

  const handleRestore = () => {
    setRestoreLoading(true)
    restorePurchase().then(() => setRestoreLoading(false))
  }

  const [showPromoCodePrompt, setShowPromoCodePrompt] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const submitPromoCode = (code?: string, offerings?: PurchasesOffering) => {
    if (code) {
      setPromoCode(code)
    }
    setShowPromoCodePrompt(false)
    if (offerings) {
      toast.show({
        placement: 'top',
        duration: 4900,
        render: () => (
          <AppToast
            status="success"
            message={`Promo ${code} successfully applied!`}
          />
        ),
      })
      setAvailableProds({
        ...state.availableProducts,
        ..._.pick(offerings, ['monthly', 'threeMonth', 'annual']),
      })
    } else {
      toast.show({
        placement: 'top',
        duration: 4900,
        render: () => (
          <AppToast status="error" message="Promo code invalid or not found." />
        ),
      })
    }
  }

  async function handleCancel() {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      setCancelLoading(true)
      try {
        await cancelWebSubscription()
        window.alert('Subscription cancelled successfully.')
        window.location.reload()
      } catch (error) {
        console.error('Failed to cancel', error)
        window.alert(
          'Subscription cancellation failed. Please try again later.'
        )
      }
      setCancelLoading(false)
    }
  }

  function showScreenHeader() {
    return showRemindMeLater ? (
      <Box flexDirection="row" py={8} alignItems="center">
        <Pressable
          onPress={() => {
            setShowSubscriptionModal(false, false)
          }}
          width="40%"
        >
          <Back stroke="white" />
        </Pressable>
        <Pressable
          onPress={() => {
            setShowSubscriptionModal(false, false)
          }}
          style={{
            height: '20%',
            width: '60%',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Text style={{ color: 'white' }}>Remind me later</Text>
        </Pressable>
      </Box>
    ) : (
      <Box flexDirection="row" py={8} alignItems="center">
        {!showScreen && (
          <Box width="95%">
            <Text style={{ color: 'white', textAlign: 'center' }} fontSize="xl">
              Subscription
            </Text>
          </Box>
        )}
      </Box>
    )
  }

  function modalHeader() {
    return showRemindMeLater ? (
      <Box flexDirection="row" py={8} alignItems="center">
        <Pressable
          onPress={() => {
            setShowSubscriptionModal(false, false)
          }}
          width="40%"
        >
          <Back stroke="white" />
        </Pressable>
        <Pressable
          onPress={() => {
            setShowSubscriptionModal(false, false)
          }}
          style={{
            height: '20%',
            width: '60%',
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Text style={{ color: 'white' }}>Remind me later</Text>
        </Pressable>
      </Box>
    ) : (
      <Box flexDirection="row" py={8} alignItems="center">
        <Box width="95%">
          <Text style={{ color: 'white', textAlign: 'center' }} fontSize="xl">
            Subscription
          </Text>
        </Box>
        <Pressable
          onPress={() => {
            setShowSubscriptionModal(false, false)
          }}
          style={{
            padding: 4,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CloseIcon color={colors.white[500]} size="4" />
        </Pressable>
      </Box>
    )
  }

  const purchases = usePurchases()
  const c = (
    <Box flex={1}>
      <ScrollView safeArea height="100%">
        <Box px={6} pb={8}>
          {showScreen ? showScreenHeader() : modalHeader()}
          <Box mb={4} justifyContent="flex-start">
            {!purchases.state.premium && (
              <Text
                fontSize="4xl"
                fontWeight="800"
                style={{ color: 'white' }}
                mb={8}
              >
                Subscribe for continued access to:
              </Text>
            )}
            {purchases.state.premium && (
              <Text
                fontSize="4xl"
                fontWeight="800"
                style={{ color: 'white' }}
                mb={8}
              >
                You are already{' '}
                <Text
                  fontSize="4xl"
                  fontWeight="800"
                  style={{ color: colors.kournikova[500] }}
                >
                  Subscribed
                </Text>
              </Text>
            )}
            {subscriptionText.map((value, i) => {
              return (
                <Box flexDirection="row" key={i}>
                  <Text
                    flexDirection="row"
                    fontSize="2xl"
                    style={{ color: 'white' }}
                    mb={4}
                  >
                    •
                  </Text>
                  <Text
                    flexDirection="row"
                    fontSize="xl"
                    style={{ color: 'white' }}
                    pl={5}
                    mb={4}
                  >
                    {value}
                  </Text>
                </Box>
              )
            })}
          </Box>
          {state.availableProducts ? (
            <Box mb={4} justifyContent="space-between">
              {_(state.availableProducts)
                .pick(['monthly', 'threeMonth', 'annual'])
                .toArray()
                .value()
                .map(
                  (product) =>
                    product && (
                      <Box key={product.identifier}>
                        {product.packageType === 'THREE_MONTH' && (
                          <Box
                            style={{
                              position: 'absolute',
                              zIndex: 1,
                              right: 5,
                              top: 5,
                              paddingHorizontal: 10,
                              backgroundColor: '#FF7B6C',
                              borderRadius: 9,
                              height: 18,
                              justifyContent: 'center',
                            }}
                          >
                            <Text style={{ color: 'white', fontSize: 10 }}>
                              Most popular
                            </Text>
                          </Box>
                        )}
                        {product.packageType === 'ANNUAL' && (
                          <Box
                            style={{
                              position: 'absolute',
                              zIndex: 1,
                              right: 5,
                              top: 5,
                              paddingHorizontal: 10,
                              backgroundColor: '#FF7B6C',
                              borderRadius: 9,
                              height: 18,
                              justifyContent: 'center',
                            }}
                          >
                            <Text style={{ color: 'white', fontSize: 10 }}>
                              Best Deal
                            </Text>
                          </Box>
                        )}
                        <Button
                          style={[
                            styles.btnContainer,
                            activeSubscription &&
                              activeSubscription!.packageType ===
                                product.packageType && {
                                backgroundColor: colors.kournikova[500],
                              },
                          ]}
                          onPress={() => setActiveSubscription(product)}
                        >
                          <Text fontSize={20} fontWeight="800">
                            {
                              (
                                {
                                  MONTHLY: '1 Month',
                                  THREE_MONTH: '3 Month',
                                  ANNUAL: '12 Month',
                                } as any
                              )[product.packageType]
                            }{' '}
                            / {product.product.priceString} USD
                          </Text>
                        </Button>
                      </Box>
                    )
                )}
            </Box>
          ) : (
            <Box />
          )}
          {Platform.OS !== 'web' && (
            <Box>
              <Text style={{ color: 'white' }} mb={1}>
                PROMO CODE
              </Text>
              <Button
                variant="outlineDark"
                size="xs"
                style={{}}
                onPress={() => setShowPromoCodePrompt(true)}
              >
                <Text textAlign="left" style={{ color: 'white' }}>
                  {promoCode || '―'}
                </Text>
              </Button>
            </Box>
          )}
        </Box>
        {Platform.OS !== 'web' && (
          <Box flexDirection="row" justifyContent="center">
            <Text
              flexDirection="row"
              fontSize="xl"
              style={{ color: 'white', textAlign: 'center' }}
              mb={4}
              px={6}
            >
              To purchase, renew, or upgrade your subscription visit the app
              store.
            </Text>
          </Box>
        )}
        <Box width="100%" px={6} style={{ position: 'relative', zIndex: 9 }}>
          {loading ? (
            <ActivityIndicator size="small" />
          ) : (
            <Box >
              {!purchases.state.premium && (
                <>
                      <Button variant="primary" onPress={() => handleSubmit()}>
                        SUBSCRIBE
                      </Button>
                      <Box
  width="100%"
  mt={4}
  px={6}
  style={{ position: 'relative', zIndex: 9 }}
  justifyContent="flex-start"
  alignItems="center"
>

                  <Text
                    style={{
                      color: 'white',
                    }}
                  >
                    Your subscription will automatically renew unless cancelled.
                  </Text>
</Box>
                </>
              )}
              {(state.store === 'stripe' || state.store === 'promotional') &&
                !cancelLoading && (
                  <Pressable
                    style={{ marginVertical: 20, alignSelf: 'center' }}
                    onPress={() => handleCancel()}
                  >
                    <Text
                      style={{
                        color: 'white',
                        textDecorationLine: 'underline',
                      }}
                    >
                      Cancel Subscription
                    </Text>
                  </Pressable>
                )}
              {state.store === 'stripe' && cancelLoading && (
                <Box style={{ marginVertical: 20 }}>
                  <ActivityIndicator size="small" />
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box flexDirection="row" justifyContent="center" mt={6} pb={100}>
          <Text
            onPress={() =>
              Linking.openURL('https://www.neurocycle.app/terms-conditions')
            }
            flexDirection="row"
            style={{
              color: 'white',
              textAlign: 'center',
              textDecorationLine: 'underline',
            }}
          >
            Terms and Conditions
          </Text>
        </Box>
      </ScrollView>
      {showPromoCodePrompt && <PromoCodeModal closeModal={submitPromoCode} />}
    </Box>
  )

  return showScreen ? (
    <Box flex={1} height="100%" backgroundColor={colors.endeavour[500]}>
      {c}
    </Box>
  ) : (
    <Modal
      isOpen={showSubscription}
      backgroundColor={colors.endeavour[500]}
      safeArea
    >
      {c}
    </Modal>
  )
}

const styles = {
  onStage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: '#021D3D',
  },
  offStage: {
    height: 10,
    width: 10,
    borderRadius: 5,
    backgroundColor: 'white',
  },
  btnContainer: {
    heigth: '10%',
    marginBottom: 10,
    backgroundColor: 'white',
  },
}
