import React from 'react'
import { useWindowDimensions } from 'react-native'
import { Box, HStack, Pressable, Text, useTheme } from 'native-base'
import { DISPATCH } from 'state/consts'
import { PlayTrack, PauseTrack } from 'components/Icons'
import { ICycleStepProps } from 'state/interfaces'
import { TCycleStep } from 'state/cyclesMatrix'
import { usePlayer } from 'state/hooks'

export interface Props extends ICycleStepProps {
  controls: any
  step: TCycleStep
}

export function SmallPlayer(props: Props) {
  const { colors } = useTheme()
  const {
    dispatch: { dispatch },
    state,
  } = usePlayer()
  const { width: deviceWidth } = useWindowDimensions()
  const { controls } = props

  return (
    <Box width={deviceWidth}>
      <HStack justifyContent="space-between" alignItems="center" p={4}>
        <Pressable
          onPress={() => dispatch({ type: DISPATCH.PLAYER.TOGGLE_SIZE })}
        >
          <Box>
            <Text variant="text-miniPlayerTitle" maxWidth={280} isTruncated>
              {state.file?.title}
            </Text>
            <Text variant="text-miniPlayerSubTitle">
              {state.file?.subTitle}
            </Text>
          </Box>
        </Pressable>
        <Box>
          <Pressable onPress={controls?.toggleSound}>
            {props.playerState.playing ? (
              <PauseTrack
                width={45}
                height={45}
                outerfill={colors.tangaroa[500]}
                innerFill={colors.puertorico[500]}
              />
            ) : (
              <PlayTrack
                width={45}
                height={45}
                outerfill={colors.tangaroa[500]}
                innerFill={colors.puertorico[500]}
              />
            )}
          </Pressable>
        </Box>
      </HStack>
    </Box>
  )
}
