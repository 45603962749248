import React from 'react'
import { Box, Text, useTheme } from 'native-base'

import { Quote } from 'components/Icons/Quote'

export function Inspiration({ text, secondary = false }) {
  const { ui, colors } = useTheme()
  return (
    <Box
      width="100%"
      borderRadius={ui.borderRadius}
      backgroundColor={secondary ? colors.white[500] : colors.willowbrook[500]}
      p={8}
    >
      <Box style={{ position: 'absolute', top: 15, left: 15 }}>
        <Quote width={37} height={37} fill={colors.puertorico[500]} />
      </Box>
      <Box marginLeft={5}>
        <Text variant="text-inspiration">{text}</Text>
      </Box>
    </Box>
  )
}
