import React from 'react'
import { ArrowBackIcon, Box, CloseIcon, Pressable, Text } from 'native-base'

export function QuestionsHeader({ setShowModal, setPrevStep }) {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
      px={6}
      pb={4}
    >
      <Pressable
        onPress={setPrevStep}
        justifyContent="center"
        alignItems="center"
        py={5}
      >
        <ArrowBackIcon size="6" color="white.500" />
      </Pressable>

      <Box
        width="100%"
        style={{ position: 'relative', zIndex: 1 }}
        justifyContent="center"
        alignItems="center"
        pt={1}
      >
        <Text fontSize="lg" bold variant="player">
          63 Day Neurocycle
        </Text>
      </Box>
      <Pressable
        onPress={setShowModal}
        justifyContent="center"
        alignItems="center"
        py={5}
      >
        <CloseIcon size="4" color="white.500" />
      </Pressable>
    </Box>
  )
}
