import React, { useEffect, useMemo, useState } from 'react'
import {
  ArrowBackIcon,
  Box,
  Button,
  CloseIcon,
  Modal,
  Pressable,
  Text,
} from 'native-base'
import { AppForm } from 'components/Form'
import { DayInfoCard } from 'components/Cards/DayInfoCard'
import { FIREBASE_COLLECTIONS } from 'state/consts'
import { ICycleStepProps } from 'state/interfaces'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import KeyboardListener from 'react-native-keyboard-listener'
import { Loading } from 'components/Loading'
import RNLocalize from 'react-native-localize'
import { activeReachConfig } from 'screens/Home/ActiveReach/formActiveReachDailyInfoConfig'
import { firestore } from 'config/firebase'
import moment from 'moment-timezone'
import { prismicDayToCard } from '../../utils/prismicDayToCard'
import { saveReminder } from '../../state/firebase/saveReminder'
import set from 'lodash/set'
import { useQuery } from 'urql'

export interface Props extends ICycleStepProps {}

const DailyInfo = (props: Props) => {
  const {
    state: { day },
    state: cycleState,
    dispatch: { dispatch, setShowDayInfoCard },
  } = props.useCycles
  const { colors } = props.useTheme
  const {
    state: { user },
  } = props.useAuth
  const [activeReach, setActiveReach] = useState<any>({})
  const [activeReachText, setActiveReachText] = useState('')
  const [activeReachInterval, setActiveReachInterval] = useState(1)

  const [config, setConfig] = useState(
    set(activeReachConfig, 'initialValues', { reach: '', interval: null })
  )

  const lastTimezone = RNLocalize.getTimeZone()

  const query = `#graphql
    query($day: Float!) {
      allDays(where: {day_number: $day}){
        edges {
          cursor
          node {
            _meta {
              id
            }
            day_number
            fact
            gather
            recheck
            reflect
            title
            write
            video
            thumbnail
            subtitle
          }
        }
      }
    }
  `

  const styleForm = {
    justifyContent: 'flex-start',
    fontColor: colors.tangaroa[500],
    borderColor: colors.tangaroa[500],
  }

  const [result] = useQuery({
    query,
    variables: {
      day: cycleState.day + 1,
    },
  })

  const dayData = useMemo(() => {
    if (!result.data?.allDays?.edges) {
      return []
    }
    return [].concat(
      ...result.data?.allDays?.edges?.map((edge: any) =>
        prismicDayToCard(edge.node, colors)
      )
    )
  }, [result, colors])

  const subtitle = useMemo(() => {
    return result.data?.allDays?.edges?.[0]?.node?.subtitle
  }, [result])

  useEffect(() => {
    if (!user?.uid) {
      return
    }
    async function fetchNotification() {
      const snapshotReminder = await firestore()
        .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
        .where('uid', '==', user?.uid)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
      if (!snapshotReminder.empty) {
        const reminderDoc = snapshotReminder.docs.pop()
        const reminderData = reminderDoc?.data()
        if (reminderData) {
          setActiveReach({
            ...reminderData,
            id: reminderDoc?.id,
          })
          setConfig((c) => ({
            ...c,
            initialValues: {
              reach: reminderData?.messages,
              interval: reminderData?.interval,
            },
          }))
          setActiveReachText(reminderData?.messages)
          setActiveReachInterval(reminderData?.interval)
        }
      }
    }
    fetchNotification()
  }, [user])

  set(config, 'rows.0.fields.0.inputProps.onChange', ({ nativeEvent }: any) => {
    setActiveReachText(nativeEvent.text)
  })

  set(config, 'rows.1.fields.0.inputProps.onChange', (event: any) => {
    setActiveReachInterval(event.value)
  })

  function onSubmit() {
    props.onNext()
    let activeReachIsFromToday = false
    if (activeReach) {
      if (activeReach.cycle !== undefined) {
        activeReachIsFromToday =
          activeReach.cycle == cycleState.cycle &&
          activeReach.day == cycleState.day &&
          activeReach.track == cycleState.track
      }
    }
    saveReminder(
      {
        messages: activeReachText,
        uid: user.uid,
        interval: activeReachInterval || null,
        timezone: lastTimezone,
        enabled: !!activeReachInterval,
        cycle: cycleState.cycle,
        day: cycleState.day,
        track: cycleState.track,
      },
      activeReachIsFromToday && activeReach.id
    )
  }

  return (
    <Modal isOpen={props.open} backgroundColor={colors.white[500]} safeArea>
      {result.fetching ? (
        <Loading />
      ) : (
        <Box
          flex={1}
          width="100%"
          justifyContent="flex-start"
          px={6}
          style={{ position: 'relative', zIndex: 9 }}
        >
          <Box flexDirection="row" justifyContent="space-between" mb={2}>
            <Pressable
              onPress={() => {
                props.onBack()
              }}
              justifyContent="center"
              alignItems="center"
            >
              <ArrowBackIcon size="6" />
            </Pressable>
            <Box style={{ alignItems: 'center' }}>
              <Text
                style={{
                  fontSize: 20,
                  color: colors.tangaroa[500],
                }}
              >
                Daily Reminder
              </Text>
            </Box>
            <Pressable
              onPress={() => {
                props.onClose()
              }}
              justifyContent="center"
              alignItems="center"
            >
              <CloseIcon size="4" />
            </Pressable>
          </Box>
          <KeyboardAwareScrollView
            extraScrollHeight={240}
            keyboardShouldPersistTaps="handled"
            style={{ width: '100%', height: '100%' }}
            contentContainerStyle={{ flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <Box flexDirection="row" pb={4} pt={4}>
              <Box>
                <Text
                  style={{
                    fontSize: 30,
                    color: colors.tangaroa[500],
                  }}
                >
                  Day {cycleState.day + 1}
                </Text>
                <Text
                  style={{
                    fontSize: 30,
                    color: colors.tangaroa[500],
                  }}
                >
                  {subtitle}
                </Text>
              </Box>
            </Box>
            {dayData.map((elem) => {
              return (
                <Box width="100%">
                  <DayInfoCard data={elem} />
                </Box>
              )
            })}
            <Box>
              <AppForm
                config={config}
                noBottomSpacer={true}
                style={styleForm}
              />
            </Box>
            <Box mt={8} flexDirection="column">
              <Button
                variant="primary"
                onPress={onSubmit}
                // disabled={
                //   !activeReachText ||
                //   !(activeReachInterval > 0 || activeReachInterval === null)
                // }
                mb={4}
              >
                CONTINUE
              </Button>
            </Box>
          </KeyboardAwareScrollView>
        </Box>
      )}
    </Modal>
  )
}

export default DailyInfo
