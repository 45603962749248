import React from 'react'
import { string } from 'prop-types'
import { Box, Center, Text, HStack, useTheme } from 'native-base'
import { Link } from '@react-navigation/native'
import { PlayMini } from 'components/Icons'

export function SliverCard({ title, link, scheme }) {
  const { ui } = useTheme()
  return (
    <Center
      backgroundColor={scheme}
      height={20}
      borderRadius={ui.borderRadius}
      mb={8}
      pl={4}
    >
      <HStack justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="xl" bold color="#000812">
          <Link to={link}>{title}</Link>
        </Text>
        <Box pr={3} pt={1}>
          <Link to={link}>
            <PlayMini width={50} height={50} />
          </Link>
        </Box>
      </HStack>
    </Center>
  )
}

SliverCard.propTypes = {
  title: string.isRequired,
  link: string.isRequired,
  scheme: string.isRequired,
}
