import React from 'react'
import { Box, Button, Text, useTheme } from 'native-base'
import { TouchableOpacity } from 'react-native'
import { Trash } from 'components/Icons'

export function RowActionButton({ data, rowMap, deleteRow }) {
  const { colors, ui } = useTheme()

  return (
    <Box
      style={{
        alignItems: 'center',
        backgroundColor: colors.willowbrook[500],
        borderColor: colors.willowbrook[500],
        borderWidth: 1,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 15,
        marginVertical: 10,
      }}
    >
      <TouchableOpacity
        style={[
          {
            top: 0,
            right: 20,
            bottom: 0,
            width: 95,
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            borderRadius: ui.borderRadius,
          },
        ]}
        onPress={() => deleteRow(rowMap, data.item.key)}
      >
        <Button variant="ghost">
          <Trash width={28} height={28} />
          <Text fontSize="xs" mt={2}>
            Delete
          </Text>
        </Button>
      </TouchableOpacity>
    </Box>
  )
}
