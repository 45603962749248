import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Polygon, Polyline, Line } from 'react-native-svg'

export function WriteIcon(props) {
  return (
    <Icon width="34px" height="40px" viewBox="0 0 34 40" version="1.1">
      <G
        id="Phase-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Home--Notification-Scroll-"
          transform="translate(-46.000000, -966.000000)"
          stroke="#021D3D"
          strokeWidth="1.5"
        >
          <G id="Write-Copy" transform="translate(25.000000, 952.000000)">
            <G
              id="Group-4"
              transform="translate(38.091776, 34.399343) rotate(25.000000) translate(-38.091776, -34.399343) translate(27.570616, 18.681679)"
            >
              <Polygon
                id="Rectangle"
                points="0.516665449 -1.13686838e-13 7.51666545 -1.13686838e-13 7.51666545 24.9754145 4.08103719 30 0.516665449 24.9754145"
              />
              <Line
                x1="0.583333333"
                y1="24.4614841"
                x2="6.41666667"
                y2="24.4614841"
                id="Line-3"
                strokeLinecap="square"
              />
              <Line
                x1="4.5423205"
                y1="30.9353277"
                x2="20.1140159"
                y2="23.492662"
                id="Line-5"
                strokeLinecap="square"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}
