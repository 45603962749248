import { useEffect, useState } from 'react'
const collection = 'questionnaires'
import { firestore } from 'config/firebase'

function useQuestionnaire({ uid, cycle }) {
  const [questionnaire, setQuestionnaire] = useState()
  useEffect(() => {
    async function getActiveQuestionnaire() {
      return await firestore()
        .collection(collection)
        .doc(`${uid}-${cycle}`)
        .get()
        .then((documentSnapshot) => {
          if (documentSnapshot.exists) {
            setQuestionnaire(documentSnapshot.data())
          } else {
            setQuestionnaire(null)
          }
        })
    }
    getActiveQuestionnaire()
  }, [cycle, uid])

  async function saveQuestionnaire({ uid, cycle, ...rest }) {
    if (!uid || cycle === undefined) {
      throw new Error('missing cycle or uid')
    }
    return await firestore()
      .collection(collection)
      .doc(`${uid}-${cycle}`)
      .set({
        ...rest,
        uid,
        cycle,
      })
      .then(() => {
        setQuestionnaire({
          ...rest,
          uid,
          cycle,
        })
      })
      .catch((err) => {
        throw new Error(err)
      })
  }

  return {
    questionnaire,
    saveQuestionnaire,
  }
}

export default useQuestionnaire
