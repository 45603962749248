import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G, Defs, Circle, Use } from 'react-native-svg'

export function PlayTrack({ size, width, height, outerfill, innerFill }) {
  return (
    <Icon zIndex={200} size={size} viewBox="0 0 50 50">
      <Defs>
        <Path
          d="M1.56915007,1.08616553 L10.3694991,7.17776633 C10.8236063,7.49209924 10.9369156,8.11504295 10.6225827,8.56915007 C10.5541279,8.66804469 10.4683938,8.75377882 10.3694991,8.82223367 L1.56915007,14.9138345 C1.11504295,15.2281674 0.492099236,15.114858 0.177766334,14.6607509 C0.0620128181,14.4935253 6.91040633e-16,14.2949804 0,14.0916008 L0,1.9083992 C-6.76353751e-17,1.35611445 0.44771525,0.908399196 1,0.908399196 C1.20337961,0.908399196 1.40192451,0.970412014 1.56915007,1.08616553 Z"
          id="path-1"
        />
      </Defs>
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G id="Home" transform="translate(-287.000000, -160.000000)">
          <G id="Group-2-Copy" transform="translate(25.000000, 150.000000)">
            <G id="CTA" transform="translate(262.000000, 10.000000)">
              <Circle id="Oval" fill={outerfill} cx="25" cy="25" r="25" />
              <G
                id="icons/ic/outline-keyboard_arrow_right"
                transform="translate(20.887314, 17.000000)"
              >
                <Use
                  id="icons/ic/outline-keyboard_arrow_right-24px"
                  fill={innerFill}
                  href="#path-1"
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}

PlayTrack.defaulProps = {
  width: 20,
  height: 20,
  size: undefined,
  outerfill: '#D8D8D8',
  innerFill: '#FFFFFF',
}

PlayTrack.propTypes = {
  size: number,
  width: number,
  height: number,
}
