import React from 'react'
import { PageContainer } from 'components/Containers/Page'
import { PageHeading } from 'components/Headings'
import { AppTabs } from 'components/Tabs'
import { FAQView } from 'screens/HelpCentre/FAQView'
import { TermsAndConditions } from 'screens/Account/General/Help/TermsAndConditions'
import { PrivacyPolicy } from 'screens/Account/General/Help/PrivacyPolicy'

function AppHelpScreen() {
  const tabs = [
    { id: 0, name: 'FAQ', component: FAQView },
    { id: 1, name: 'Terms & Conditions', component: TermsAndConditions },
    { id: 2, name: 'Privacy Policy', component: PrivacyPolicy },
  ]

  return (
    <PageContainer scheme="willowbrook" pt={5} standAlone={false}>
      <PageHeading title="Help" />
      <AppTabs data={tabs} />
    </PageContainer>
  )
}

export default AppHelpScreen
