export function pushOrAppend(arr, item, keyToCompare, keyToUpdate) {
  const newArr = [...arr]
  var index = newArr.findIndex((x) => x[keyToCompare] === item[keyToCompare])
  if (index === -1) {
    newArr.push(item)
  } else {
    newArr[index][keyToUpdate] = item[keyToUpdate]
  }
  return newArr
}
