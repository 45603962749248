import { firestore } from 'config/firebase'

export async function fetchDocumentByIndex(key, index) {
  try {
    return await firestore()
      .collection(key)
      .where('index', '==', parseInt(index, 10))
      .get()
      .then((snapShot) =>
        snapShot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      )
  } catch (error) {
    console.log('fetch error', error)
    throw new Error(error)
  }
}
