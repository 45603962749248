import { useContext } from 'react'

import { QuestionsContext } from '../providers/QuestionsProvider'

export function useQuestions() {
  const context = useContext(QuestionsContext)
  if (context === undefined) {
    throw new Error('useQuestions must be used within a QuestionsProvider')
  }
  return context
}
