import React, { useState } from 'react'
import { string, number } from 'prop-types'
import { ScrollView, useWindowDimensions, View } from 'react-native'
import { WebView } from 'react-native-webview'
import { Loading } from 'components/Loading'

export function WebViewLoader({ uri, heightOffset }) {
  const { height: deviceHeight } = useWindowDimensions();
  const [webViewHeight, setWebViewHeight] = useState(
    deviceHeight - heightOffset
  );
  const onWebViewMessage = (event) => {
    setWebViewHeight(Number(event.nativeEvent.data))
  }

  return (
    <>
      {console.log(uri)}
      <WebView
        source={{ uri }}
        style={{ height: '100%' }}
        renderLoading={() => <Loading />}
      />
    </>
  )
}

WebViewLoader.defaultProps = {
  heightOffset: 290,
}

WebViewLoader.propTypes = {
  uri: string.isRequired,
  heightOffset: number,
}
