import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Text,
  Pressable,
  CloseIcon,
  ArrowBackIcon,
  Center,
  Container,
} from 'native-base'
import { Confetti, ShareIcon } from 'components/Icons'
import { CongratsProgress } from 'components/Progress'
import ConfettiCannon from 'react-native-confetti-cannon'
import Dialog from 'react-native-dialog'
import type { ICycleStepProps } from 'state/interfaces'
import { Share, Platform } from 'react-native'
import useStoreReview from 'hooks/useStoreReview'

export type Props = ICycleStepProps

const Congrats = (props: Props) => {
  const {
    state: { day, track },
  } = props.useCycles
  const { colors } = props.useTheme
  const { dialogState, setDialogState, submitFeedback, handleReview } =
    useStoreReview()
  const onShare = async () => {
    try {
      const result = await Share.share({
        message: 'I just finished my daily lesson on the Neurocycle app!',
      })
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      throw new Error(error.message)
    }
  }

  const getDay = () => day + 1

  useEffect(() => {
    if (
      (['ios', 'android'].includes(Platform.OS) && day === 20) ||
      day === 62
    ) {
      handleReview(day)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
      }}
      backgroundColor={colors.salmon[500]}
      safeArea
    >
      {Platform.OS === 'web' ? (
        <ConfettiCannon
          count={300}
          origin={{ x: -10, y: 0 }}
          autoStart={true}
        />
      ) : (
        <Confetti
          style={{
            position: 'absolute',
            zIndex: 10,
          }}
          height={700}
          width={1000}
          fill={colors.endeavour[500]}
        />
      )}
      <Center height="100%">
        <Container width="100%" height="100%" maxWidth={1024}>
          <Box
            px={6}
            py={5}
            _android={{ py: 8 }}
            safeArea
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            width="100%"
          >
            <Pressable onPress={props.onBack}>
              <ArrowBackIcon color="#FFFFFF" size={6} />
            </Pressable>
            <Pressable onPress={props.onClose}>
              <CloseIcon color="#FFFFFF" size={5} />
            </Pressable>
          </Box>

          <Box
            flex={1}
            height="100%"
            width="100%"
            justifyContent="space-between"
            px={6}
            pb={8}
            style={{ zIndex: 10 }}
          >
            <Box mt={32}>
              <Text fontSize="5xl" variant="player" mb={4}>
                Congrats!
              </Text>
              <Text fontSize="3xl" mb={1} variant="player">
                You've completed
              </Text>
              <Text fontSize="3xl" variant="player">
                {getDay()} of 63 days
              </Text>
              <CongratsProgress current={Math.round((getDay() / 63) * 100)} />
            </Box>
            <Box mt={6}>
              {Platform.OS !== 'web' && (
                <Button variant="ghost" onPress={() => onShare()}>
                  <Box
                    flexDirection="row"
                    justifyContent="center"
                    align="center"
                  >
                    <ShareIcon
                      fill={colors.white[500]}
                      width={25}
                      height={25}
                    />
                    <Text variant="player" ml={3} pt={1}>
                      Share on social media
                    </Text>
                  </Box>
                </Button>
              )}
              <Button variant="primary" onPress={props.onNext}>
                FINISH
              </Button>
            </Box>
          </Box>
          {Platform.OS !== 'web' && (
            <Dialog.Container
              visible={dialogState.isOpen}
              onBackdropPress={() =>
                setDialogState({ ...dialogState, isOpen: false })
              }
            >
              <Dialog.Title>Tell us what you think</Dialog.Title>
              <Dialog.Input
                multiline
                numberOfLines={10}
                value={dialogState.message}
                onChangeText={(text) =>
                  setDialogState({ ...dialogState, message: text })
                }
              />
              <Dialog.Button
                onPress={() =>
                  setDialogState({ ...dialogState, isOpen: false })
                }
                label="Cancel"
              />
              <Dialog.Button
                onPress={() => {
                  setDialogState({ ...dialogState, isOpen: false })
                  submitFeedback()
                }}
                label="Submit"
              />
            </Dialog.Container>
          )}
        </Container>
      </Center>
    </Box>
  )
}

export default Congrats
