import React, { useMemo } from 'react'
import { string, object } from 'prop-types'
import {
    AspectRatio,
    Box,
    Pressable,
    Skeleton,
    Text,
    VStack,
    View,
    useTheme,
} from 'native-base'
import { ImageBackground, ImageSourcePropType, Linking, TouchableHighlight } from 'react-native'

interface InfomationalButtonsProps extends React.ComponentProps<'div'> {
    title: string
    subTitle?: string
    source: ImageSourcePropType
    link?: string
    color?: string
    backgroundColor?: string
}

export function InfomationalButtonsPrim({ title, subTitle, source, link, backgroundColor }: InfomationalButtonsProps) {
    const { ui, fontConfig } = useTheme()

    const handlePress = async () => {
        console.log('Link:', link)
        Linking.openURL(link)
    }

    const textShadow = {
        textShadowColor: 'rgba(0, 0, 0, 0.05)',
        textShadowOffset: { width: 1, height: 2 },
        textShadowRadius: 5,
    }

    return (
        <Pressable
            borderRadius={15}
            backgroundColor={backgroundColor}
            style={ui.mainCard}
            onPress={() => handlePress()}
            mb={6}
        >
            {/* <View style={{ width: '100%', height: '100%', position: 'relative' }}>
                <ImageBackground
                    source={source}
                    style={{ width: '100%', height: '100%' }}
                    resizeMode="cover" // Assuming you want the image to cover the area and be positioned to the right
                />
            </View> */}
            <Box
                borderRadius={ui.borderRadius}
                position="absolute" // This ensures the title box does not affect the background image positioning
                left={0} // Aligns the title box to the left
                top={0} // Positions the title box at the top; adjust as needed
                p={2}
                style={ui.mainCard}
            >
                <Text
                    variant="text-MainCardTitle"
                    bold
                    style={{ ...fontConfig.center, ...textShadow, color: '#000812' }}
                    p={2}
                    fontSize={30}
                >
                    {title}
                </Text>
            </Box>
        </Pressable>

    )
}