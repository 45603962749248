import React, { Fragment, useEffect, useState } from 'react'
import { Landing } from 'screens/Landing'
import { Loading } from 'components/Loading'
import { useCycles, useAuth, usePurchases } from 'hooks/index'
import { sanityCheck } from 'config/config'
import * as RootNavigation from './RootNavigation'
import { useNotifications } from './state/hooks/useNotifications'
import { register } from './serviceWorkerRegistration';

register()
export function App() {
  const {
    state: { profile, user, loading },
  } = useAuth()

  const { state: cycleState } = useCycles()

  const [isInitialized, setInitialized] = useState(false)

  const {
    dispatch: { initPurchases },
  } = usePurchases()

  useNotifications(user, RootNavigation.navigate)

  useEffect(() => {
    if (cycleState.loaded && user && profile && !isInitialized) {
      sanityCheck()
      initPurchases(user, profile, cycleState)
      setInitialized(true)
    }
  }, [isInitialized, user, profile, cycleState, loading])

  // useEffect(() => {
  //   // Allow user to receive messages now setup is complete
  //   inAppMessaging().setMessagesDisplaySuppressed(false)
  // }, [])

  return (
    <Fragment>
      {/* {loading ? <Loading /> : null}
      {!loading && !user ? <AuthLanding /> : null}
      {!loading && user ? <Landing /> : null} */}
      {loading && !user ? <Loading /> : <Landing />}
    </Fragment>
  )
}
