export const fontsConfig = {
  'Gilroy-Black': require('../assets/fonts/Gilroy/Gilroy-Black.otf'),
  'Gilroy-BlackItalic': require('../assets/fonts/Gilroy/Gilroy-BlackItalic.otf'),
  'Gilroy-Bold': require('../assets/fonts/Gilroy/Gilroy-Bold.otf'),
  'Gilroy-BoldItalic': require('../assets/fonts/Gilroy/Gilroy-BoldItalic.otf'),
  'Gilroy-Extrabold': require('../assets/fonts/Gilroy/Gilroy-Extrabold.otf'),
  'Gilroy-ExtraboldItalic': require('../assets/fonts/Gilroy/Gilroy-ExtraboldItalic.otf'),
  'Gilroy-Heavy': require('../assets/fonts/Gilroy/Gilroy-Heavy.otf'),
  'Gilroy-HeavyItalic': require('../assets/fonts/Gilroy/Gilroy-HeavyItalic.otf'),
  'Gilroy-Light': require('../assets/fonts/Gilroy/Gilroy-Light.otf'),
  'Gilroy-LightItalic': require('../assets/fonts/Gilroy/Gilroy-LightItalic.otf'),
  'Gilroy-Medium': require('../assets/fonts/Gilroy/Gilroy-Medium.otf'),
  'Gilroy-MediumItalic': require('../assets/fonts/Gilroy/Gilroy-MediumItalic.otf'),
  'Gilroy-Regular': require('../assets/fonts/Gilroy/Gilroy-Regular.otf'),
  'Gilroy-RegularItalic': require('../assets/fonts/Gilroy/Gilroy-RegularItalic.otf'),
  'Gilroy-Semibold': require('../assets/fonts/Gilroy/Gilroy-Semibold.otf'),
  'Gilroy-SemiboldItalic': require('../assets/fonts/Gilroy/Gilroy-SemiboldItalic.otf'),
  'Gilroy-Thin': require('../assets/fonts/Gilroy/Gilroy-Thin.otf'),
  'Gilroy-ThinItalic': require('../assets/fonts/Gilroy/Gilroy-ThinItalic.otf'),
  'Gilroy-UltraLight': require('../assets/fonts/Gilroy/Gilroy-UltraLight.otf'),
  'Gilroy-UltraLightItalic': require('../assets/fonts/Gilroy/Gilroy-UltraLightItalic.otf'),
}
