import React, { useMemo } from 'react'
import { string, object } from 'prop-types'
import { Box, Button, Text, useTheme, useMediaQuery } from 'native-base'
import { ImageBackground } from 'react-native'
import { useCycles, usePlayer, usePurchases, useAuth } from 'hooks/index'

function LinkBox({ to, children, navigation, showSubscription }) {
  const { state: cycleState } = useCycles()
  const {
    dispatch: { dismissPlayer },
  } = usePlayer()
  const purchases = usePurchases()
  const {
    dispatch: { setShowSubscriptionModal },
  } = useAuth()

  const loading = useMemo(() => {
    return !cycleState.loaded || !purchases.state.initialized
  }, [cycleState.loaded, purchases.state.initialized])

  const onPress = () => {
    if (loading) return
    if (!purchases.state.premium || purchases.state.expired) {
      setShowSubscriptionModal(true, true)
    } else {
      dismissPlayer()
      navigation.navigate(to)
    }
  }

  return (
    <Button
      onPress={onPress}
      flex={1}
      backgroundColor="transparent"
      alignItems="flex-start"
      justifyContent="flex-start"
      px={3}
      pt={3}
    >
      {children}
    </Button>
  )
}

export function SubCard({
  title,
  link,
  source,
  setShowSubscription,
  expired,
  navigation,
}) {
  const { ui, fontConfig } = useTheme()

  const [isSmallScreen] = useMediaQuery({
    minWidth: 767,
    maxWidth: 767,
  })
  return (
    <ImageBackground
      source={source}
      style={{
        ...ui.backgroundFill,
        width: '100%',
        height: isSmallScreen ? '100%' : 450,
        position: 'cover',
        bottom: 0,
        padding: '1rem',
      }}
      imageStyle={{
        resizeMode: 'stretch',
        alignSelf: 'flex-end',
      }}
    >
      <LinkBox
        to={link}
        navigation={navigation}
        expired={expired}
        setShowSubscription={setShowSubscription}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          width="100%"
          height={60}
          flexGrow={1}
          paddingX={2}
        >
          <Text
            variant="text-SubCards"
            bold
            style={{
              ...fontConfig.center,
              color: '#000812',
            }}
            p={2}
          >
            {title}
          </Text>
        </Box>
      </LinkBox>
    </ImageBackground>
  )
}

SubCard.propTypes = {
  title: string.isRequired,
  link: string.isRequired,
  source: object.isRequired,
}
