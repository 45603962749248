import React, { Suspense } from 'react'
import { Loading } from 'components/Loading'
import { Resources } from 'components/Resources'

export function ResourcesView() {
  return (
    <Suspense fallback={<Loading />}>
      <Resources />
    </Suspense>
  )
}
