import React from 'react'
import { Box, Text, useTheme } from 'native-base'
import { TouchableHighlight } from 'react-native'
import { useLinkTo } from '@react-navigation/native'

export function NotificationRowWrapper({
  heading,
  link,
  text,
  skeleton = false,
}) {
  const { colors, ui } = useTheme()
  const linkTo = useLinkTo()

  return (
    <TouchableHighlight
      onPress={() => {
        link && linkTo(link)
      }}
      style={{
        backgroundColor: skeleton ? colors.white[500] : colors.puertorico[500],
        justifyContent: 'flex-start',
        minHeight: 100,
        marginVertical: 10,
        marginHorizontal: 20,
        borderRadius: ui.borderRadius,
        padding: 25,
      }}
    >
      <Box>
        {heading ? (
          <Text
            variant={skeleton ? 'notificationSeen' : 'notificationNew'}
            fontSize="lg"
            bold
            mb={2}
          >
            {heading}
          </Text>
        ) : null}
        {text ? (
          <Text variant={skeleton ? 'notificationSeen' : 'notificationNew'}>
            {text}
          </Text>
        ) : null}
      </Box>
    </TouchableHighlight>
  )
}

export function NotificationRow({ data, skeleton }) {
  return (
    <NotificationRowWrapper
      id={data?.item?.id}
      heading={data?.item?.heading}
      link={data?.item?.link}
      text={`${data?.item?.text}`}
      skeleton={skeleton}
    />
  )
}
