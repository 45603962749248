import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/analytics';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyAl-DCBP_MxrGxDKyqnzqswmacviScyblc',
  authDomain: 'neurocycle.firebaseapp.com',
  databaseURL: 'https://neurocycle-default-rtdb.firebaseio.com',
  projectId: 'neurocycle',
  storageBucket: 'neurocycle.appspot.com',
  messagingSenderId: '466588973497',
  appId: '1:466588973497:web:bdd1ca476143720d449e68',
  measurementId: 'G-Q37BRBWERC',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Check if messaging is supported
const isBrowserSupportedForMessaging = () => {
  return firebase.messaging.isSupported();
};

// Initialize Firestore and Auth
const fbFirestore = firebase.firestore();
const fbAuth = firebase.auth();

let fbMessaging = null;

// Initialize Messaging if supported
if (isBrowserSupportedForMessaging()) {
  fbMessaging = firebase.messaging();

  const requestMessagingToken = async () => {
    try {
      await fbMessaging.requestPermission();
      const token = await fbMessaging.getToken();
      console.log('FCM Token:', token);
    } catch (error) {
      console.error('Error getting FCM token:', error);
    }
  };

  // Request FCM token on supported browsers
  requestMessagingToken();
} else {
  console.log('Firebase Messaging is not supported on this platform. Push notifications will not work.');
  // Optionally, display a message to the user or hide notification-related UI elements
}

// Crashlytics mock implementation
const fbCrashlytics = () => {
  return {
    recordError: (err) => {
      console.warn('crashlytics().recordError() is not implemented');
    },
    log: (message) => {
      console.log('crashlytics().log()', message);
    },
  };
};

// Export Firestore, Auth, and other Firebase services
const firestore = () => fbFirestore;
firestore.FieldValue = firebase.firestore.FieldValue;
const auth = () => fbAuth;
const messaging = () => (firebase.messaging.isSupported() ? fbMessaging : null);
const crashlytics = () => fbCrashlytics();
const analytics = () => firebase.analytics();
const functions = () => firebase.functions();

console.log('Messaging is supported:', isBrowserSupportedForMessaging());

export {
  firebase,
  firestore,
  auth,
  messaging,
  crashlytics,
  analytics,
  functions,
};
