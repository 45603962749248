import { useState, useRef } from 'react'
import { Modal, FormControl, Input, Button, useTheme } from 'native-base'
import { PurchasesOffering } from 'react-native-purchases'
import _ from 'lodash'
import { usePurchases } from 'hooks/usePurchases'
import { functions } from 'config/firebase'

type PromoCodeModalProps = {
  closeModal: (code?: string, offerings?: PurchasesOffering) => void
}

export default function PromoCodeModal({ closeModal }: PromoCodeModalProps) {
  const { ui } = useTheme()
  const [inputCode, setInputCode] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const { state } = usePurchases()
  const initialFocusRef = useRef<'input'>(null)

  const checkPromo = async (
    code: string
  ): Promise<{ code?: string; offerings?: PurchasesOffering }> => {
    if (!state.allProducts) {
      return {}
    }
    const response: { data: { code: string; enabled: boolean }[] } =
      await functions().httpsCallable('checkPromoCode')({
        query: {
          code,
        },
        code,
      })
    // Get updated prices
    if (response.data.length && response.data[0].enabled) {
      const _code = response.data[0].code
      return {
        code: _code.toUpperCase(),
        offerings: _.get(state.allProducts, code.toLowerCase()),
      }
    }
    return {}
  }

  const handleSubmit = async () => {
    if (!inputCode) return
    setLoading(true)
    try {
      const { code, offerings } = await checkPromo(inputCode)
      closeModal(code, offerings)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  return (
    <Modal
      isOpen={true}
      onClose={() => closeModal()}
      avoidKeyboard
      initialFocusRef={initialFocusRef}
      safeArea
    >
      <Modal.Content>
        <Modal.CloseButton height={30} />
        <Modal.Header>REDEEM PROMO CODE</Modal.Header>
        <Modal.Body>
          Enter a promo code & tap "REDEEM CODE" to redeem it.
          <FormControl mt="3">
            <FormControl.Label>CODE</FormControl.Label>
            <Input
              borderRadius={ui.borderRadius}
              fontSize="xl"
              my={2}
              onChangeText={(value) => setInputCode(value)}
              px={6}
              py={4}
              type="text"
              variant="outline"
              ref={initialFocusRef}
              autoCorrect={false}
              autoCapitalize="none"
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer pr={6}>
          <Button
            variant="primary"
            onPress={handleSubmit}
            isLoading={loading}
            width="100%"
          >
            REDEEM CODE
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  )
}
