import { useContext } from 'react'
import { PlayerContext } from '../providers/PlayerProvider'
import { IUsePlayer } from 'state/interfaces'

export function usePlayer() {
  const context = useContext<IUsePlayer>(PlayerContext as any)
  if (context === undefined) {
    throw new Error('usePlayer must be used within a PlayerProvider')
  }
  return context
}
