import { COUNTRIES, INPUT_TYPES } from 'state/consts'
import { boolean, date, number, object, string } from 'yup'
import { Button } from 'native-base'
import { DateInput } from 'components/Form/Inputs/DateInput'
import { RCFIDCheckbox } from 'components/Form/Inputs/RCFIDCheckbox'
import { SelectInput } from 'components/Form/Inputs/SelectInput'
import { TextInput } from 'components/Form/Inputs/TextInput'

export const profileInfoConfig = {
  validationSchema: object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    dob: date().required('Required'),
    gender: string(),
    country: string(),
    ethnicity: string(),
    age: number(),
    reason: string(),
    password: string(),
    cfid: string().max(20, 'Must be less than 20 characters'),
    rcfid: string(),
    orgid: string().max(20, 'Must be less than 20 characters'),
    autoplay: boolean(),
  }),
  enableReinitialize: true,
  heading: {},
  rows: [
    {
      id: 'profile_row0',
      fields: [
        {
          id: 'firstName',
          label: 'First name',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          required: true,
        },
      ],
    },
    {
      id: 'profile_row1',
      fields: [
        {
          id: 'lastName',
          label: 'Last name',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
    {
      id: 'profile_row3',
      fields: [
        {
          id: 'dob',
          label: 'Date of birth',
          component: DateInput,
          required: true,
        },
      ],
    },
    {
      id: 'profile_row4',
      fields: [
        {
          id: 'gender',
          label: 'Gender',
          type: INPUT_TYPES.SELECT,
          component: SelectInput,
          items: [
            { id: 0, label: 'Male', value: 'male' },
            { id: 1, label: 'Female', value: 'female' },
            { id: 2, label: 'Prefer Not to Say', value: 'n/a' },
          ],
        },
      ],
    },
    {
      id: 'profile_row5',
      fields: [
        {
          id: 'country',
          label: 'Country',
          type: INPUT_TYPES.SELECT,
          component: SelectInput,
          items: COUNTRIES.map((elem, i) => {
            return {
              id: i,
              label: elem.name,
              value: elem.name.toLowerCase(),
            }
          }),
        },
      ],
    },
    {
      id: 'profile_row6',
      fields: [
        {
          id: 'ethnicity',
          label: 'I identify my ethnicity as',
          type: INPUT_TYPES.SELECT,
          component: SelectInput,
          items: [
            { id: 0, label: 'Caucasian', value: 'caucasian' },
            { id: 1, label: 'Black/African', value: 'black' },
            { id: 2, label: 'Hispanic/Latinx', value: 'latin' },
            { id: 3, label: 'Asian', value: 'asian' },
            { id: 4, label: 'Native American', value: 'nativeAmerican' },
            { id: 5, label: 'Pacific Islander', value: 'pacificIslander' },
            { id: 6, label: 'Prefer Not to say', value: 'n/a' },
            { id: 7, label: 'Other', value: 'other' },
          ],
        },
      ],
    },
    {
      id: 'profile_row7',
      fields: [
        {
          id: 'age',
          label: 'Age',
          type: 'number',
          component: TextInput,
        },
      ],
    },
    {
      id: 'profile_row8',
      fields: [
        {
          id: 'reason',
          label: 'Reason for doing this program',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
    {
      id: 'profile_row9',
      fields: [
        {
          id: 'cfid',
          label: 'CFID',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
    {
      id: 'profile_row9',
      fields: [
        {
          id: 'rcfid',
          label: 'RCFID',
          type: INPUT_TYPES.BOOLEAN,
          component: RCFIDCheckbox,
        },
      ],
    },
    {
      id: 'profile_row10',
      fields: [
        {
          id: 'orgid',
          label: 'ORGID',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
        },
      ],
    },
    // {
    //   id: 'profile_row10',
    //   fields: [
    //     {
    //       id: 'autoplay',
    //       label: 'Autoplay audio & videos',
    //       type: INPUT_TYPES.RADIO,
    //       component: RadioInput,
    //       items: [
    //         { id: 0, label: 'No', value: false },
    //         { id: 1, label: 'Yes', value: true },
    //       ],
    //     },
    //   ],
    // },
  ],
  actions: {
    submit: {
      id: 'additionalInfo_submit',
      component: Button,
      variant: 'primary',
      variantDisabled: 'primaryDisabled',
      label: 'SAVE CHANGES',
    },
  },
}
