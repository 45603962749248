import React from 'react'
import { number, string } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G } from 'react-native-svg'

export function Results({ width, height, stroke }) {
  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 22 22"
      style={{ height, width }}
    >
      <G
        id="Rd2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <G id="Rd2" strokeWidth="1" fill="none" fillRule="evenodd">
          <G
            id="Tab-Bar"
            transform="translate(-132.000000, -3.000000)"
            fill="#021D3D"
            fillRule="nonzero"
          >
            <G
              fill={stroke === '#021D3D' ? '#021D3D' : stroke}
              id="Results"
              transform="translate(132.250000, 3.250000)"
            >
              <Path
                d="M12.7576917,1.8852417 L12.859544,1.89074166 C17.7695792,2.48837438 21.5,6.67118796 21.5,11.6590909 C21.5,17.0940749 17.0940749,21.5 11.6590909,21.5 C6.67936309,21.5 2.50101759,17.781523 1.89343794,12.8814907 C1.84246785,12.4704251 2.13438293,12.0958711 2.54544853,12.0449011 C2.95651413,11.993931 3.33106806,12.285846 3.38203815,12.6969116 C3.89673764,16.8478804 7.43868362,20 11.6590909,20 C16.2656478,20 20,16.2656478 20,11.6590909 C20,7.43175437 16.8377613,3.88602703 12.6783067,3.37975242 C12.2671277,3.32970512 11.9743726,2.95580736 12.0244199,2.54462839 C12.0702966,2.16771434 12.3882977,1.89030926 12.7576917,1.8852417 Z"
                id="Path"
              />
              <Path
                d="M9.84090909,0 C4.40592508,0 0,4.40592508 0,9.84090909 C0,10.2551227 0.335786438,10.5909091 0.75,10.5909091 L9.84090909,10.5909091 C10.2551227,10.5909091 10.5909091,10.2551227 10.5909091,9.84090909 L10.5909091,0.75 C10.5909091,0.335786438 10.2551227,0 9.84090909,0 Z M9.09,1.533 L9.09,9.09 L1.533,9.09 C1.89505922,5.08099504 5.08099504,1.89505922 9.09,1.533 Z"
                id="Oval"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}

Results.defaulProps = {
  width: 20,
  height: 20,
  stroke: '#021D3D',
}

Results.propTypes = {
  width: number,
  height: number,
  stroke: string,
}
