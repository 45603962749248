import React from 'react'
import Gather from 'components/Modal/Gather'
import { useAuth } from 'hooks/useAuth'
import { ICycleStepProps } from 'state/interfaces'

export interface Props extends ICycleStepProps {}

const GatherStep: React.FC<Props> = (props) => {
  const {
    state: { user },
  } = props.useAuth
  return <>{props.open && <Gather user={user} {...props} />}</>
}

export default GatherStep
