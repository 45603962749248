import React, { useState, useRef, useEffect } from 'react'
import { Box } from 'native-base'
import { PrismicRichText } from '@prismicio/react'
import { Text } from 'react-native'
import { Audio, Video, VideoFullscreenUpdate, ResizeMode } from 'expo-av'

import { ResourcesCard } from './ResourcesCard'

export const DayInfoCard = ({ data }: { data: any }) => {
  const [uniqueKey] = useState(0)
  const player = useRef<Video>(null)
  const [activeVideo, setActiveVideo] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const play = async (url: string) => {
    setActiveVideo(url)
    if (player.current) {
      await player.current.loadAsync({ uri: url })
    }
  }
  useEffect(() => {
    Audio.setAudioModeAsync({
      playsInSilentModeIOS: true,
    })
  }, [])

  if (data?.url) {
    return (
      <Box style={data.containerStyle}>
        {data?.url === activeVideo && !isLoading ? (
          <>
            <Video
              onLoad={() => setIsLoading(false)}
              onError={() => setIsLoading(false)}
              ref={player}
              shouldPlay
              useNativeControls
              resizeMode="contain"
              usePoster={false}
              source={{
                uri: data?.url,
              }}
            />
          </>
        ) : (
          <ResourcesCard
            data={{ img: data.img, title: data.title, url: data.url }}
            key={uniqueKey}
            play={play}
            noPadding={true}
            isLoading={isLoading}
          />
        )}
      </Box>
    )
  }
  return (
    <Box style={data.containerStyle}>
      <Box px={4} pt={4} pb={3}>
        {data?.icon}
        <Text style={data?.textStyles}>{data?.title}</Text>
        <Box
          pt={4}
          style={{
            width: '90%',
            borderBottomWidth: 1,
            borderColor: 'rgba(2,29,61, .1)',
          }}
        />
        <PrismicRichText
          field={data.data}
          components={{
            paragraph: ({ children, key }) => (
              <Text key={key} style={data?.descriptionStyles}>
                {children}
              </Text>
            ),
          }}
        />
      </Box>
      <Box width="100%" py={1} />
    </Box>
  )
}
