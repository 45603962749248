import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Box, Text } from 'native-base'
import map from 'lodash/map'
import { Audio, Video, VideoFullscreenUpdate, ResizeMode } from 'expo-av'
import { useQuery } from 'urql'

import { ResourcesCard } from '../Cards/ResourcesCard'

export const Resources = () => {
  const [activeVideo, setActiveVideo] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const player = useRef<Video>(null)

  const play = async (url: string) => {
    if (player.current) {
      if (activeVideo === url) {
        await player.current.loadAsync({ uri: url })
        await player.current.playFromPositionAsync(0)
      } else await player.current.unloadAsync()
      setActiveVideo(url)
    }

    setActiveVideo(url)
  }

  const query = `#graphql
    query {
      allResource_videoss {
        edges {
          node{
            _meta {
              id
            }
            title
            img
            url
            type
            screenshottimestamp
          }
        }
      }
    }
  `

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {
      day: 1,
    },
  })

  const cleanData = useMemo(() => {
    if (!result.data?.allResource_videoss?.edges) {
      return []
    }
    return [].concat(
      ...result.data?.allResource_videoss?.edges?.map((edge) => edge.node)
    )
  }, [result])

  const formattedData = cleanData.map((elem, i) => {
    return {
      uniqueKey: i,
      type: 'video',
      img: elem.img[0].text,
      title: elem.title[0].text,
      url: elem.url[0].text,
    }
  })

  return (
    <Box>
      {map(formattedData, ({ uniqueKey, img, title, url }) => (
        <Box mb={4} key={url}>
          {url === activeVideo && !isLoading && (
            <>
              <Text fontSize="2xl" fontWeight="800" my={2}>
                {title}
              </Text>
              <Video
                resizeMode="contain"
                style={{
                  height: '230px',
                }}
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
                ref={player}
                useNativeControls
                usePoster={false}
                shouldPlay
                source={{
                  uri: url,
                }}
              />
            </>
          )}
          {url !== activeVideo && (
            <ResourcesCard
              data={{ img, title, url }}
              key={uniqueKey}
              play={play}
              noPadding={false}
              isLoading={isLoading && url === activeVideo}
            />
          )}
        </Box>
      ))}
    </Box>
  )
}
