import React, { useEffect } from 'react'
import { PageContainer } from 'components/Containers/Page'
import { Box, Text, useTheme, Pressable } from 'native-base'
import Lottie from 'lottie-react'
import CancelAnimation from './cancel-animation.json'

function AccountSubscriptionCancel() {
  return (
    <>
      <PageContainer scheme="willowbrook" standAlone={false}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          width={'100%'}
        >
          <Lottie
            style={{ height: 400 }}
            animationData={CancelAnimation}
            loop={false}
          />
          <Text variant="text-SuccessSub">Transaction Cancelled</Text>
        </Box>
      </PageContainer>
    </>
  )
}

export default AccountSubscriptionCancel
