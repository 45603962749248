import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { Modal, Text, Pressable } from 'native-base'
import { Animated, useWindowDimensions } from 'react-native'
import { SmallPlayer } from 'components/Player/SmallPlayer'
import { FullsizePlayer } from 'components/Player/FullsizePlayer'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ICycleStepProps } from 'state/interfaces'
import { TCycleStep } from 'state/cyclesMatrix'
import { usePlayer, useCycles } from 'state/hooks'

const sharedStyles = {
  position: 'absolute',
  backgroundColor: '#43CCBB',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

export type PlayerProps = {
  onNext?: () => void
  onBack?: () => void
}

export function Player(props: PlayerProps = {}) {
  const {
    dispatch: { toggleSound },
    state: playerState,
  } = usePlayer()
  const { state: cycleState } = useCycles()
  const insets = useSafeAreaInsets()

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 100,
      useNativeDriver: true,
    }).start()
  }, [fadeAnim])

  const fadeOut = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 100,
      useNativeDriver: true,
    }).start()
  }, [fadeAnim])

  useEffect(() => {
    if (playerState.loading || playerState.visible) {
      fadeIn()
    } else if (!playerState.visible) {
      fadeOut()
    }
  }, [fadeIn, fadeOut, playerState.visible, playerState.loading])

  const [timer, setTimer] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [current, setCurrent] = useState()
  const [sound, setSound] = useState(false)
  const [duration, setDuration] = useState(0)

  const min = [
    {
      bottom: 0,
      height: 80,
      opacity: fadeAnim,
      zIndex: 9,
      // elevation: 9,
    },
    sharedStyles,
  ]

  async function stepTrack(direction: 'next' | 'prev') {
    if (direction === 'next' && props.onNext) {
      props.onNext()
    } else if (direction === 'prev' && props.onBack) {
      props.onBack()
    }
  }

  return playerState.visible && cycleState.step ? (
    <Animated.View style={playerState.maximum ? { height: '100%' } : min}>
      {playerState.maximum ? (
        <FullsizePlayer
          play={{ playing, setPlaying }}
          dur={{ duration, setDuration }}
          current={{ current, setCurrent }}
          sound={{ sound, setSound }}
          controls={{ toggleSound, stepTrack }}
          time={{ timer, setTimer }}
          step={cycleState.step}
          onNext={playerState.onNext}
          onBack={playerState.onBack}
        />
      ) : (
        <SmallPlayer
          play={{ playing, setPlaying }}
          dur={{ duration, setDuration }}
          current={{ current, setCurrent }}
          playerState={playerState}
          controls={{ toggleSound, stepTrack }}
          step={cycleState.step}
        />
      )}
    </Animated.View>
  ) : null
}
