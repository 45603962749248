import React, { useEffect, useMemo, useState } from 'react'
import { PageContainer } from 'components/Containers/Page'
import { Box, HStack, Text, useTheme } from 'native-base'
import { PauseTrack, PlayTrack } from 'components/Icons'
import { Pressable } from 'react-native'
import { firestore } from 'config/firebase'
import { useAuth } from 'state/hooks/useAuth'
import { usePlayer } from 'state/hooks/usePlayer'
import { useQuery } from 'urql'
import { uniqBy } from 'lodash'
import { DayInfoCard } from 'components/Cards/DayInfoCard'

import { dateFromTimestampInLocalTimezone } from '../../../utils/dateHelpers'
import { FIREBASE_COLLECTIONS } from '../../../state/consts'
import { prismicDayToCard } from '../../../utils/prismicDayToCard'

export function CalendarDayScreen(props) {
  const { colors, fonts } = useTheme()
  const [audioHistory, setAudioHistory] = useState([])
  const [activeReach, setActiveReach] = useState(null)
  const [days, setDays] = useState([])
  const {
    dispatch: { loadSound },
    state,
  } = usePlayer()
  const {
    state: { user },
  } = useAuth()

  const query = `#graphql
    query($days: [Float!]) {
      allDays(where: {day_number_range: $days}){
        edges {
          cursor
          node {
            _meta {
              id
            }
            day_number
            gather
            recheck
            reflect
            title
            write
            video
            thumbnail
          }
        }
      }
    }
  `

  const [result, reexecuteQuery] = useQuery({
    pause: !days.length,
    query,
    variables: {
      days: days.sort(),
    },
  })

  const daysData = useMemo(() => {
    return [].concat(
      ...(result.data?.allDays?.edges || []).map((e) =>
        prismicDayToCard(e.node, colors)
      )
    )
  }, [result, colors])

  useEffect(() => {
    reexecuteQuery({
      variables: {
        days: days.sort(),
      },
    })
  }, [days, reexecuteQuery])

  useEffect(() => {
    if (!user) {
      return
    }
    const today = dateFromTimestampInLocalTimezone(props.route.params.timestamp)
    const start = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    )
    const end = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    )
    firestore()
      .collection(FIREBASE_COLLECTIONS.HISTORY)
      .where('uid', '==', user?.uid)
      .where('createdAt', '>=', start)
      .where('createdAt', '<=', end)
      .orderBy('createdAt')
      .get()
      .then((snapShot) => {
        if (!snapShot.empty) {
          setAudioHistory(
            uniqBy(
              snapShot.docs.map((d) => d.data()),
              (a) => a.uri
            )
          )
        }
      })

    firestore()
      .collection(FIREBASE_COLLECTIONS.HISTORY_CYCLE)
      .where('uid', '==', user?.uid)
      .where('createdAt', '>=', start)
      .where('createdAt', '<=', end)
      .orderBy('createdAt')
      .get()
      .then((snapShot) => {
        if (!snapShot.empty) {
          setDays(
            uniqBy(
              snapShot.docs.map((d) => d.data()),
              (a) => a.day
            ).map((d) => d.day + 1)
          )
        }
      })

    firestore()
      .collection(FIREBASE_COLLECTIONS.REMINDERS_CRON)
      .where('uid', '==', user?.uid)
      .where('createdAt', '>=', start)
      .where('createdAt', '<=', end)
      .orderBy('createdAt')
      .get()
      .then((snapShot) => {
        if (!snapShot.empty) {
          setActiveReach(snapShot.docs.map((d) => d.data())[0])
        }
      })
  }, [props.route.params.timestamp, user])

  return (
    <PageContainer pt={6} type="scrollable">
      {audioHistory.map((a) => {
        return (
          <Pressable
            key={a.uri}
            onPress={() => {
              loadSound(
                {
                  id: a.uri,
                  title: a.title,
                  description: a.description,
                  uri: a.uri,
                  mini: true,
                  playing: true,
                  visible: true,
                },
                user
              )
            }}
            style={{ marginBottom: 16 }}
          >
            <HStack alignItems={'center'}>
              {state.id === a.uri && state.playing ? (
                <PauseTrack
                  width={56}
                  height={56}
                  outerfill={colors.puertorico[500]}
                  innerFill={colors.tangaroa[500]}
                />
              ) : (
                <PlayTrack
                  width={56}
                  height={56}
                  outerfill={colors.puertorico[500]}
                  innerFill={colors.tangaroa[500]}
                />
              )}
              <Box pl={4} pr={12}>
                <Text fontSize="2xl" fontFamily={fonts.semibold}>
                  {a.title}
                </Text>
                {!!a.description && (
                  <Text fontSize="xl" mt={1}>
                    {a.description}
                  </Text>
                )}
              </Box>
            </HStack>
          </Pressable>
        )
      })}
      {daysData.map((elem) => {
        return (
          <Box width="100%">
            <DayInfoCard data={elem} />
          </Box>
        )
      })}
      {activeReach && (
        <Box mt={!!audioHistory.length && 2}>
          <Text fontFamily={fonts.regular} fontSize="lg" mb={2}>
            This day's active reach
          </Text>
          <Box
            borderRadius={15}
            border="2px solid rgba(2,29,61,0.5)"
            bgColor={colors.white[500]}
            flex={1}
            px={4}
            py={5}
            justifyContent="flex-start"
          >
            <Text fontSize="xl" variant="base">
              {activeReach.messages}
            </Text>
          </Box>
        </Box>
      )}
    </PageContainer>
  )
}
