import React from 'react'
import { Box, Text, Slider, useTheme } from 'native-base'
import { Oval } from 'components/Icons'
import { useCycles } from 'hooks/useCycles'
import { Platform } from 'react-native'

const CustomSliderThumb = ({ currentPercent, colors }) => {
  // Directly return null if not iOS to avoid rendering
  if (Platform.OS === 'android') return null;

  return (
    <Slider.Thumb variant="congrats" zIndex={4000} position={'absolute'}>
      <Box
        style={{ top: -55, left: -4 }}
        justifyContent="center"
        alignItems="center"
        width={30}
        height={60}
      >
        <Box style={{ position: 'absolute' }}>
          <Oval fill={colors.kournikova[500]} width={60} padding={40} height={60} />
        </Box>
        <Box style={{ position: 'absolute', top: 18 }}>
          <Text fontSize="10px" bold textAlign="center">
            {currentPercent}%
          </Text>
        </Box>
      </Box>
    </Slider.Thumb>
  );
};

export function CongratsProgress({ current }) {
  const { state } = useCycles();
  const currentPercent = Math.round((state.day / 63) * 100);
  const { colors } = useTheme();

  return (
    <Box mt={10}>
      <Slider
        defaultValue={0}
        minValue={0}
        maxValue={100}
        accessibilityLabel="Days Completed"
        step={1}
        value={currentPercent + 1}
        mb={2}
        size="lg"
      >
        <CustomSliderThumb currentPercent={currentPercent} colors={colors} />
        <Slider.Track variant="congrats">
          <Slider.FilledTrack variant="congrats" />
        </Slider.Track>
      </Slider>
    </Box>
  );
}