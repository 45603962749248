import React from 'react'
import { Box, Text } from 'native-base'

export function AppToast({ status, message }) {
  return (
    <Box variant={status}>
      <Text fontSize="xl" lineHeight={26} variant={status}>
        {message}
      </Text>
    </Box>
  )
}
