import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, Dimensions, ImageBackground, Platform, Text, TouchableOpacity, useWindowDimensions } from 'react-native';
import { View, StyleSheet } from 'react-native';
import { socket } from 'utils/socket';
import { firestore } from '../../config/firebase'
import * as fttt from "../../config/firebase.web"
import { ResizeMode, Video } from 'expo-av';
import audiop from "./simple-notification-152054.mp3"
import toast, { Toaster } from 'react-hot-toast';
import Ionicons from '@expo/vector-icons/MaterialIcons';
import { background, backgroundImage, display } from 'styled-system';
import { PageContainer } from 'components/Containers/Page';
import { REMOTE_BGS } from 'consts';
import { Box, Button } from 'native-base';
import _ from 'lodash';

export function removeFileExtension(filename) {
    // Find the last dot in the filename
    const lastDotIndex = filename.lastIndexOf('.');

    // Check if a dot was found and it's not the first character
    if (lastDotIndex !== -1 && lastDotIndex > 0) {
        // Extract the substring before the last dot
        return filename.substring(0, lastDotIndex).length > 40 ? filename.substring(0, lastDotIndex).slice(0, 40) + "..." : filename.substring(0, lastDotIndex)
    } else {
        // No dot found or dot is the first character
        return filename.length > 40 ? filename.slice(0, 40) + "..." : filename;
    }
}

export const LightBoxShadow = ({ children, style }) => {
    const boxShadowStyle = Platform.select({
        ios: {
            shadowColor: 'black',
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.1,
            shadowRadius: 2,
        },
        android: {
            elevation: 2,
        },
        web: {
            boxShadow: '0.5px 1px 3px rgba(0, 0, 0, 0.45)',
        }
    });

    return (
        <View style={[boxShadowStyle, style]}>
            {children}
        </View>
    );
};

const formatFirebaseDate = (firebaseDate) => {
    const date = new Date(firebaseDate);
    const options = { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' };
    return date.toLocaleDateString(undefined, options);
};

const CustomButton = ({ bgColor, getRes, attachedResources }) => {

    return (
        <View style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10
        }}>
            <TouchableOpacity
                onPress={() => { getRes(attachedResources) }}
                style={{
                    backgroundColor: bgColor,
                    padding: 15,
                    borderRadius: 10,
                    ...Platform.select({
                        android: {
                            elevation: 5,
                        },
                        ios: {
                            shadowColor: 'rgba(0,0,0,0.5)',
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.8,
                            shadowRadius: 2,
                        },
                    }),
                }}
            >
                <Text
                    style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: 'bold',
                        textAlign: 'center',
                    }}
                >
                    Preview Resource(s)
                </Text>
            </TouchableOpacity>
        </View>
    );
};
let currentIndex = 0;
let index = 0;
const ScheduledCards = ({ length, featured, title, date, dateTime, time, attachedResources, id, color, clickOn, refI, attachedVideoUrl, type, getRes, description, showLength, scheduledCard, libraryCard }) => {
    const formattedDate = formatFirebaseDate(date);
    const [componentIndex, setComponentIndex] = useState(index);



    const formattedTime = new Date(dateTime.seconds * 1000).toLocaleTimeString(
        "en-US",
        { hour: "numeric", minute: "numeric" }
    );

    const thumbnailPaths = [
        REMOTE_BGS.BLUE_THUMBNAIL,
        REMOTE_BGS.GREEN_THUMBNAIL,
        REMOTE_BGS.ORANGE_THUMBNAIL,
        REMOTE_BGS.PINK_THUMBNAIL,
        REMOTE_BGS.RED_THUMBNAIL,
        REMOTE_BGS.YELLOW_THUMBNAIL
    ];


    console.log('componentIndex:', componentIndex);
    console.log('thumbnailPaths.length:', thumbnailPaths.length);
    if (scheduledCard) {
        // replace with below
        // REMOTE_BGS.DRLEAF_THUMBNAIL
        backgroundThumbnail = REMOTE_BGS.DRLEAF_THUMBNAIL; // Set green thumbnail for scheduled cards
    } else {
        backgroundThumbnail = thumbnailPaths[componentIndex % thumbnailPaths.length]; // Keep the existing backgroundImage for non-scheduled cards
    }
    index++

    let textColor = "#000"
    const thumbnailsToChangeText = [REMOTE_BGS.BLUE_THUMBNAIL];

    if (thumbnailsToChangeText.includes(backgroundThumbnail)) {
        textColor = "#fff";
    }

    const windowWidth = useWindowDimensions().width;
    const [respWidth, setRespWidth] = useState('15rem');
    const [respHeight, setRespHeight] = useState(150);
    const [margin, setMargin] = useState();

    useEffect(() => {
        if (libraryCard && windowWidth <= 1275) {
            setRespWidth('18rem');
            setRespHeight(175);
            setMargin(70)
        } else {
            setRespWidth('15rem');
            setRespHeight(150);
        }
    }, [windowWidth]);


    return (
        <>
            <View style={{
                marginRight: 2,
                marginBottom: margin
            }}>
                {/* 18, 180 */}
                <ImageBackground source={backgroundThumbnail} style={{
                    width: respWidth,
                    height: respHeight,
                    padding: 10,
                    borderRadius: 15,

                }}
                    imageStyle={{ borderRadius: 15 }}
                >
                    <TouchableOpacity onPress={() => {
                        clickOn({
                            title,
                            refI,
                            featured,
                            attachedVideoUrl,
                            type,
                            description
                        });
                    }} style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 5,
                        display: "flex",
                        flexDirection: 'col',
                    }}>
                        <Text style={{ color: type === "live" ? textColor : textColor, fontSize: 18, fontWeight: "bold", width: 170 }}>{title}</Text>
                        {scheduledCard && (
                            <Text style={{ fontSize: 13, color: textColor, marginTop: 10 }}>{formattedDate} at {time}</Text>
                        )}
                    </TouchableOpacity>
                </ImageBackground>
                {/* <CustomButton bgColor={color} getRes={getRes} attachedResources={attachedResources} /> */}
                {showLength && (
                    <Text style={{ marginTop: 8, color: "#fff", backgroundColor: "#021D3D", width: "4rem", padding: 5, justifyContent: "center", display: "flex", fontWeight: 600, borderRadius: 3 }}>{length} min</Text>
                )}
            </View>
        </>
    );
};



export const getPastXhoursTimeStamp = (num) => {
    console.log(fttt.firebase.firestore.Timestamp.now(), " pppppp::::999999")

    // Get the current Firebase Timestamp
    const currentTimeMillis = new Date().getTime();

    // Subtract 2 hours in milliseconds
    const xInMillis = num * 60 * 60 * 1000;
    const resultTimeInMillis = currentTimeMillis - xInMillis;
    // Create a new Timestamp with the adjusted time
    const newTimestamp = fttt.firebase.firestore.Timestamp.fromMillis(resultTimeInMillis);
    return newTimestamp
}

const db = firestore();

const Neurolive = () => {
    const [colorIcon] = useState([
        "#DC143C", // Crimson
        "#FFA500", // Orange
        "#D2691E", // Chocolate
        "#A52A2A", // Brown
        "#B22222", // FireBrick
        "#CD5C5C",
        "#FFB6C1", // LightPink
        "#FF69B4", // HotPink
        "#FF1493", // DeepPink
        "#FF00FF", // Magenta
        "#FF00FF", // Fuchsia
        "#C71585", // MediumVioletRed
        "#DB7093", // PaleVioletRed
        "#8B4513", // SaddleBrown
        "#FF4500", // OrangeRed
        "#FF6347", // Tomato
        "#FF7F50", // Coral
        "#FFA07A", // LightSalmon
        "#FFD700", // Gold
        "#FF8C00", // DarkOrange
    ]);
    const { width } = useWindowDimensions();
    const player = useRef(null)
    const [activeVideo, setActiveVideo] = useState(null)
    const [isLive, setisLive] = useState(false)
    const [uniqueKey] = useState(0)
    const [status, setStatus] = useState({});
    const [isCallActive, setIsCallActive] = useState(false); // Control the visibility of the button
    const [isModalVisible, setModalVisible] = useState(false);
    const [tabClicked, settabClicked] = useState(true);
    const [webviewError, setWebviewError] = useState(false);
    const [isFullScreenActive, setisFullScreenActive] = useState(false);
    const [webURI, setwebURI] = useState("https://developertest.neurolive.net/drleaf/mobile-live");
    const webViewRef = useRef(null);
    const handleWebViewError = () => {
        setWebviewError(true);
    };
    const [dbNLib, setdbNLib] = useState([]);
    const [dbScheduled, setdbScheduled] = useState([]);
    const [selectedCard, setselectedCard] = useState({
        title: "",
        refI: "",
        featured: "",
        attachedVideoUrl: "",
        type: "lib" || "live",
        attachedResources: [],
        description: "",
        length
    })
    const [modalVisible, setModalVisibleMain] = useState(true);

    const audioRef = useRef(null)

    const [scheduleModalVisibleResources, setScheduleModalVisibleResources] = useState(false);
    const [isloadingData, setisloadingData] = useState(!true);
    const [resourceDto, setResourceDto] = useState([])
    const windowDimensions = Dimensions.get('window');
    const isMonitorSize = windowDimensions.width > 1024 && windowDimensions.height > 768;
    const [page, setPage] = useState(1);
    const cardsPerPage = 10;

    // Calculate the start and end index of cards to display based on the current page
    const startIndex = (page - 1) * cardsPerPage;
    const endIndex = page * cardsPerPage;

    // Render only the cards for the current page
    const visibleCards = dbNLib.slice(startIndex, endIndex);

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        setPage(prevPage => Math.max(prevPage - 1, 1));
    };
    const windowWidth = useWindowDimensions().width;
    const [gridColumns, setGridColumns] = useState('repeat(5, 1fr)');
    const [gridGap, setGridGap] = useState(60);
    const [margin, setMargin] = useState(60);

    useEffect(() => {
        if (windowWidth <= 1275) {
            setGridColumns('repeat(4, 1fr)');
            setGridGap(0);
        } else {
            setGridColumns('repeat(5, 1fr)');
            setGridGap(60);
        }
    }, [windowWidth]);


    useEffect(() => {
        console.log(socket.connected, " :::: socket connect ::::")

        toast("click a card to view the live", {
            icon: "👍🏻",
            position: "top-right",
            duration: 5000
        })

        socket.on("DR_LEAF_IS_LIVE", (d) => {
            console.log(d, " :::::: logger :::::::")
            setIsCallActive(d)
        })

        socket.emit("DR_LEAF_IS_LIVE", "::::: check from mobile ios app :::::");

        console.log(firestore, " :::: pool ----- 5555 ----- ")
        const fetchDataLib = async () => {

            try {
                const dbDto = await db.collection("neurolives").where("dateTime", "<", getPastXhoursTimeStamp(2)).get();

                dbDto.forEach((doc) => {
                    console.log(doc.data())
                    if (!selectedCard.featured) {
                        setselectedCard({
                            refI: doc.id,
                            title: doc.data().title,
                            featured: doc.data().featured,
                            attachedVideoUrl: doc.data().attachedVideoUrl,
                            type: "lib",
                            attachedResources: doc.data().attachedResources || [],
                            description: doc.data().description,
                            length: doc.data().length
                        })
                    }
                    setdbNLib(prev => [...prev, { ...doc.data(), refI: doc.id, type: "lib" }].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()))
                })

                console.log(" ::::: list from neurolives ::::: ", [])

            } catch (error) {
                console.error('Error fetching Neurolives from Firestore:', error);
            }
        };

        const fetchDataSchLiv = async () => {

            try {
                const dbDto = await db.collection("neurolives").where("dateTime", ">", getPastXhoursTimeStamp(2)).get();

                dbDto.forEach((doc) => {
                    if (!selectedCard.featured) {
                        setselectedCard({
                            refI: doc.id,
                            title: doc.data().title,
                            featured: doc.data().featured,
                            attachedVideoUrl: doc.data().attachedVideoUrl,
                            type: "live",
                            attachedResources: doc.data().attachedResources || [],
                            description: doc.data().description,
                            length: doc.data().length
                        })
                    }
                    setdbScheduled(prev => [...prev, { ...doc.data(), refI: doc.id, type: "live" }].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()))
                })

                // let sortedData = list.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

                // sortedData = sortedData.map(d => { delete d["dateTime"]; return d });

                // Set the fetched data to state
                // setdbScheduledNeurolive(sortedData);

                // dispatch(addScheduledLive(sortedData))

                console.log(" ::::: list from neurolives ::::: ", [])

            } catch (error) {
                console.error('Error fetching Neurolives from Firestore:', error);
            }
        };

        fetchDataLib();
        fetchDataSchLiv();
        toast.success("Welcome, click the cards to watch/join a live", {
            icon: "👋🏻"
        })
    }, [])

    const handleGetResourcesById = async (attachedResources = []) => {
        console.log("HRT :::::::::::: ", attachedResources)
        setResourceDto([])
        try {
            setisloadingData(true);
            setScheduleModalVisibleResources(true);

            const neuroliveResourceCollection = await db.collection("NeurolivesResources").get();
            const neuroliveData = neuroliveResourceCollection.docs.map((doc) => {
                if (attachedResources.includes(doc.id)) return (doc.data())
            });
            setisloadingData(false);
            console.log(attachedResources, " :::::: handler ::::: ", neuroliveData);

            if (neuroliveData.length > 0) {
                for (const i of neuroliveData) {
                    if (i?.downloadURL && i?.fileName && i?.fileType) {
                        setResourceDto(prev => [...prev, {
                            downloadUrl: i["downloadURL"],
                            fileName: i["fileName"],
                            fileType: i["fileType"]
                        }])
                    }
                }
            }

        } catch (e) {
            console.error(e)
            setisloadingData(false);
        }
    }

    const [notifyCallActiveCount, setnotifyCallActiveCount] = useState(0)

    useEffect(() => {
        if (isCallActive && notifyCallActiveCount < 4) {
            setnotifyCallActiveCount(prev => prev + 1)
            audioRef.current.play()
            toast.success(`Join our live broadcast! Click the card under 'Scheduled Live' and hit refresh now or connect. Your presence makes a difference. Thank you!`)
        }
    }, [selectedCard, isCallActive])

    return (
        <>
            {Platform.OS === "web" ? (
                <a
                    id='webDownloadResAnchorTag'
                    href=""
                    target="_blank"
                    download={""}
                    style={{ width: 0, height: 0, visibility: 'hidden', margin: 0, padding: 0 }}>
                    Click here to download
                </a>
            ) : <Text>{" "}</Text>}
            <PageContainer useMaxWidth={false}>

                <View style={[isMonitorSize && styles.centeredContainer]}>
                    <View>
                        <View>
                            {scheduleModalVisibleResources && (
                                <View
                                    style={{
                                        position: "absolute",
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent dark background
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 8000,
                                        flex: 1,
                                        height: "100vh",
                                        width: "100%"
                                    }}
                                >

                                    <View
                                        style={{
                                            width: '35%', padding: 20, backgroundColor: '#dddd', borderRadius: 10, alignItems: 'center', height: 500
                                        }}
                                    >
                                        <Text>Resource(s)</Text>
                                        <TouchableOpacity style={{ position: "absolute", left: "10%", height: "50px", width: "50px", backgroundColor: "transparent", zIndex: 11000, }} onPress={() => {
                                            setScheduleModalVisibleResources(false)
                                        }}>
                                            <Ionicons
                                                name="cancel"
                                                size={45}
                                                color="grey"
                                                style={{ opacity: 0.6 }} />
                                        </TouchableOpacity>
                                        <View style={{ padding: 20, width: '100%', backgroundColor: 'transparent', borderRadius: 10, alignItems: 'center', zIndex: 8000, height: "100%", overflow: "scroll" }}>
                                            {isloadingData ? (<View>
                                                <ActivityIndicator size={"large"} />
                                                <Text>Loading...</Text>
                                            </View>
                                            ) : resourceDto.length < 1 ? (<Text style={{ marginTop: 50 }}>No Resource available at the moment</Text>) :
                                                resourceDto.map((d, i) => <TouchableOpacity
                                                    onPress={() => {
                                                        if (Platform.OS === "web") {
                                                            //                                                 // Create a new link element
                                                            // const link = document.createElement("a");
                                                            // document.body.appendChild(link); // Append it to the body

                                                            // link.href = d.downloadUrl;
                                                            // link.setAttribute("download", d.fileName); // Set the download attribute
                                                            // link.click();

                                                            // document.body.removeChild(link); // Now safely remove the link
                                                            window.open(d.downloadUrl, '_blank');
                                                        };
                                                    }}>
                                                    <View style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-evenly", backgroundColor: "#fff", borderRadius: 25, width: 300, height: 70, marginTop: 15, paddingLeft: 5, paddingRight: 15, }}>
                                                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginLeft: 10, alignItems: "center", }}>
                                                            <Ionicons name="file-download" size={20} color={colorIcon[Math.abs(Math.floor(Math.random() * (colorIcon.length - 1)))]} style={{ opacity: 0.6 }} />
                                                            <Text style={{ fontWeight: "bold", fontSize: 13, marginLeft: 10 }}>{removeFileExtension(d.fileName)}</Text>
                                                        </View>
                                                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginLeft: 10, alignItems: "center", }}>
                                                            <Ionicons name="circle" size={12} color="grey" style={{ opacity: 0.6 }} />
                                                            <Text
                                                                style={{ fontSize: 9, color: "#2e2e2e" }}>{d.fileType}</Text>
                                                        </View>
                                                    </View>
                                                </TouchableOpacity>)
                                            }
                                        </View>
                                    </View>
                                </View>
                            )}
                            <Toaster position="bottom-center"
                                toastOptions={{
                                    className: '',
                                    duration: 8000,
                                }}
                            />
                            {/* VIEWING SECTION */}
                            <View style={{ display: "flex", flexDirection: "row", margin: 5, padddingTop: "1rem" }}>
                                {/* Horizontal Grid inside the first panel */}
                                <View style={{
                                    overflow: "hidden", backgroundColor: "transparent", height: "100%",
                                }}>
                                    {
                                        selectedCard.type === "lib" ? (
                                            <Video
                                                ref={player}
                                                style={{
                                                    height: "33rem", width: "63rem", padding: 5, marginTop: 10, borderRadius: 0,
                                                }}
                                                source={{
                                                    uri: selectedCard.attachedVideoUrl ?? null,
                                                }}
                                                useNativeControls
                                                resizeMode={ResizeMode.COVER}
                                                isLooping
                                                onPlaybackStatusUpdate={status => setStatus(() => status)}
                                            />
                                        ) : (
                                            <iframe
                                                style={{
                                                    height: "33rem",
                                                    width: "63rem",
                                                    marginTop: 10,
                                                    borderRadius: 10,
                                                    overflow: "hidden"
                                                }}
                                                src="https://developertest.neurolive.net/drleaf/live"
                                                frameborder="0"
                                                scrolling="no"
                                                allowfullscreen={false}></iframe>
                                        )
                                    }

                                    <View style={{
                                        display: "flex",
                                        flexDirection: "row", // Use row direction
                                        justifyContent: "space-between",
                                    }}>
                                        <View>
                                            <Text style={[{ marginTop: 5, color: "#000", fontSize: 36 }, selectedCard.type === "live" && isCallActive ? {
                                                background: "limegreen", borderRadius: "1rem", fontWeight: 800, color: "#fff",
                                            } : {}]}>{selectedCard.title}</Text>
                                            <Text style={{ marginTop: 8, fontSize: 24, width: 960 }}>{selectedCard.description}</Text>
                                        </View>

                                        <View>
                                            {/* I WANA PUT CUSTOM BUTTON HERE */}
                                            {/* <CustomButton bgColor="blue" getRes={() => handleGetResourcesById(selectedCard.attachedResources)} attachedResources={selectedCard.attachedResources} /> */}
                                        </View>

                                    </View>
                                    {
                                        selectedCard.type === "lib" && !selectedCard.attachedVideoUrl ? <Text style={{
                                            // textAlign: "center",
                                            marginTop: 5,
                                            color: "#ff000094",
                                            fontWeight: 60
                                        }}>(The media attached to this past live event is not available at the moment.)</Text>
                                            : <></>
                                    }
                                </View>
                                <View style={{
                                    marginLeft: "23px"
                                }}>
                                    {/* {GRADIENT} */}
                                    <Text style={{ fontSize: 36, marginTop: 1 }}>Upcoming</Text>
                                    <View style={{
                                        display: "grid",
                                        gridTemplateRows: "repeat(3, 1fr)",
                                        gridGap: 20,
                                        marginTop: 20,
                                    }}>
                                        {dbScheduled.length < 1 ? (
                                            <Text>No scheduled live at the moment</Text>
                                        ) : (
                                            dbScheduled
                                                .slice(0, 3)
                                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                                .map((d, i) => (
                                                    <ScheduledCards
                                                        getRes={(res) => handleGetResourcesById(res)}
                                                        clickOn={({ title, refI, featured, attachedVideoUrl, type, description }) => {
                                                            setselectedCard({ type, title, refI, featured, attachedVideoUrl, description })
                                                        }}
                                                        showLength={false}
                                                        scheduledCard={true}
                                                        color={"rgb(255, 123, 108)"}
                                                        key={i}
                                                        {...d}
                                                    />
                                                ))
                                        )}
                                    </View>
                                </View>
                            </View>
                            {/* LIB SECTION */}
                        </View>
                        <View style={{
                            flexDirection: "col",
                            //width: "100%",
                        }}>
                            <Text style={{ fontSize: 36, marginTop: "42px" }}>Recommended for you</Text>
                            <View style={{
                                display: "grid",
                                gridTemplateColumns: gridColumns,
                                gridGap: gridGap,
                                gridAutoFlow: "dense",
                                gridAutoRows: "1fr",
                                width: "auto",
                                maxWidth: 1242,
                                marginTop: 20
                            }}>
                                {/* FOR PAGINATION REPLACE "dbNLib" with "visibleCards" */}
                                {dbNLib.map((d, i) => (
                                    <ScheduledCards
                                        getRes={(res) => handleGetResourcesById(res)}
                                        clickOn={({ title, refI, featured, attachedVideoUrl, type, description }) => {
                                            setselectedCard({ type, title, refI, featured, attachedVideoUrl, description })
                                        }}
                                        libraryCard={true}
                                        showLength={true}
                                        scheduledCard={false}
                                        color={"rgb(255, 123, 108)"}
                                        key={i}
                                        {...d}
                                    />
                                ))}
                                {/* <Text>{length}aa</Text> */}
                            </View>
                            {/* pagination */}
                            {/* <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <Button title="Previous Page" onPress={handlePrevPage} disabled={page === 1} />
                                <Text style={{ marginHorizontal: 10 }}>{page}</Text>
                                <Button title="Next Page" onPress={handleNextPage} disabled={endIndex >= dbNLib.length} />
                            </View> */}
                        </View>
                    </View>
                </View>
            </PageContainer>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    verticalGrid: {
        flex: 1,
        flexDirection: 'col', // Vertical grid or Col
    },
    panel: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        flexDirection: 'row',
    },
    horizontalGrid: {
        flex: 1,
        flexDirection: 'column',
    },
    centeredContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default Neurolive;
