import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Polygon, Polyline, Line } from 'react-native-svg'

export function ReflectIcon(props) {
  return (
    <Icon width="31px" height="34px" viewBox="0 0 31 34" version="1.1">
      <G
        id="Phase-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <G
          id="Home--Notification-Scroll-"
          transform="translate(-44.000000, -740.000000)"
          stroke="#021D3D"
          strokeWidth="1.5"
        >
          <G id="Reflect-Copy-2" transform="translate(26.000000, 726.000000)">
            <G id="Icon" transform="translate(19.000000, 15.000000)">
              <Line
                x1="14.5"
                y1="0.5"
                x2="14.5"
                y2="31.5"
                id="Line-2"
                strokeLinecap="square"
              />
              <Polygon
                id="Triangle"
                transform="translate(23.500000, 15.500000) rotate(-90.000000) translate(-23.500000, -15.500000) "
                points="23.5 10 29 21 18 21"
              />
              <Polygon
                id="Triangle-Copy"
                transform="translate(5.500000, 15.500000) scale(-1, 1) rotate(-90.000000) translate(-5.500000, -15.500000) "
                points="5.5 10 11 21 0 21"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}
