import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { Box, Text } from 'native-base'
import { PageHeading } from 'components/Headings'

const PrismicCard = ({ body, title, image }) => {
  return (
    <>
      {title && <PageHeading title={title[0]?.text} />}
      <PrismicRichText
        field={body}
        components={{
          oList: ({ children, key }) => (
            <Box key={key} style={{}}>
              {children}
            </Box>
          ),
          oListItem: ({ children, key, text }) => (
            <Text key={key} style={Styles.textStyles}>
              {text}
              {children}
            </Text>
          ),
          hyperlink: ({ children, key, text }) => (
            <Text key={key} style={Styles.textStyles}>
              {text}
              {children}
            </Text>
          ),
          list: ({ children, key }) => (
            <Box key={key} style={{}}>
              {children}
            </Box>
          ),
          listItem: ({ children, key, text }) => (
            <Text key={key} style={Styles.textStyles}>
              {text}
              {children}
            </Text>
          ),
          span: ({ children, key }) => (
            <Box key={key} style={{}}>
              {children}
            </Box>
          ),
          paragraph: (props) => (
            <Text variant="paragraph" padding={0} margin={1}>
              {props.text}
            </Text>
          ),
        }}
      />
    </>
  )
}

const Styles = {
  textStyles: {
    fontSize: 14,
    paddingTop: 8,
    lineHeight: 24,
    color: '#000000',
  },
}

export default PrismicCard
