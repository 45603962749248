export const routeNames = {
  CALENDAR: 'Calendar',
  CALENDAR_DAY: 'CalendarDay',
  HISTORY_NAVIGATOR: 'HistoryNavigator',
  HOME: 'Home',
  NOTIFICATION: 'Notification',
  NOTIFICATIONS: 'Notifications',
  NOTIFICATIONS_NAVIGATOR: 'NotificationsNavigator',
  RESET_CYCLE: 'ResetCycle',
  ACCOUNT_LANDING: 'AccountLanding',
  NEUROLIVE: "Neurolive"
}
