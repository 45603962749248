import React, { Suspense, lazy } from 'react'
import { Loading } from 'components/Loading'

export function DownloadsView() {
  const Downloads = lazy(() => import('components/Downloads'))
  return (
    <Suspense fallback={<Loading />}>
      <Downloads />
    </Suspense>
  )
}
