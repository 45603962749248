import React from 'react'
import { Icon } from 'native-base'
import { Path } from 'react-native-svg'

export function Back15(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0l.001 2.118C15.535 2.838 19 6.764 19 11.5A9.5 9.5 0 11.013 11h1.502A8 8 0 1011 3.64V6L7 3.333V6L2.5 3 7 0v2.667L11 0z"
        fill="#fff"
      />
      <Path
        d="M7.56 14H6.65V7.98l-1.82.668v-.82l2.587-.972h.142V14zm3.061-3.565l.361-3.544h3.643v.835h-2.876l-.215 1.938a2.294 2.294 0 011.187-.308c.647 0 1.162.215 1.543.645.38.426.571 1.004.571 1.733 0 .733-.199 1.31-.596 1.734-.394.42-.945.63-1.655.63-.628 0-1.141-.175-1.538-.523-.397-.348-.623-.83-.679-1.445h.855c.055.407.2.714.434.923.235.205.544.307.928.307.42 0 .749-.143.986-.43.241-.286.362-.681.362-1.186 0-.475-.13-.856-.391-1.142-.257-.29-.6-.435-1.03-.435-.394 0-.703.086-.928.259l-.24.195-.722-.186z"
        fill="#fff"
      />
    </Icon>
  )
}
