import Prismic from 'prismic-javascript'
import { createClient, dedupExchange, cacheExchange, fetchExchange } from 'urql'
import { makeOperation } from '@urql/core'
import { authExchange } from '@urql/exchange-auth'

const baseUri = 'https://neurocycle.cdn.prismic.io'

const prismicClient = Prismic.client(`${baseUri}/api/v2`, {})

export const client = createClient({
  url: `${baseUri}/graphql`,
  requestPolicy: 'network-only',
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange({
      addAuthToOperation: ({ authState, operation }) => {
        // the token isn't in the auth state, return the operation without changes
        if (!authState) {
          return operation
        }

        // fetchOptions can be a function (See Client API) but you can simplify this based on usage
        const fetchOptions =
          typeof operation.context.fetchOptions === 'function'
            ? operation.context.fetchOptions()
            : operation.context.fetchOptions || {}

        return makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              ...fetchOptions.headers,
              ...authState.headers,
            },
          },
        })
      },
      didAuthError: ({ error }) => {
        return error && error.response && error.response.status === 403
      },
      getAuth: async ({ authState, mutate }) => {
        // for initial launch, fetch the auth state from storage (local storage, async storage etc)
        try {
          const api = await prismicClient.getApi()
          return {
            time: new Date(),
            headers: {
              // 'Cache-Control': 'no-cache',
              'Prismic-Ref': api.masterRef.ref,
            },
          }
        } catch (error) {
          return null
        }
      },
    }),
    fetchExchange,
  ],
  preferGetMethod: true,
})
