import React, { useState, useEffect } from 'react'
import { Box, ScrollView, useTheme, Center, Container } from 'native-base'
import map from 'lodash/map'
import { fetchCollectionByKey } from 'state/firebase/fetchCollectionByKey'
import { usePlayer } from 'state/hooks/usePlayer'
import { PlaylistCard } from 'components/Cards'
import { Loading } from 'components/Loading'
import { PLAYLIST_GROUPS } from 'state/consts'

function MiniCyclesScreen() {
  const { colors } = useTheme()
  const { state } = usePlayer()
  const [playList, setPlayList] = useState()
  const tracks = playList?.filter(
    ({ asset, publicUrl }) => asset !== 'null' && publicUrl !== undefined
  )

  useEffect(() => {
    if (!playList) {
      fetchCollectionByKey(PLAYLIST_GROUPS.MINI_NEUROCYCLES, setPlayList)
    }
  }, [playList])

  return (
    <ScrollView safeArea>
      <Box
        style={{
          flex: 1,
          justifyContent: 'flex-start',
          backgroundColor: colors.willowbrook[500],
          padding: 20,
        }}
      >
        <Center width="100%" height="100%">
          <Container width="100%" height="100%" maxWidth={1024}>
            {tracks?.length ? (
              map(tracks, (data) => (
                <PlaylistCard
                  key={data.id}
                  data={{ ...data, type: 'Mini Neurocycles' }}
                  selected={state}
                />
              ))
            ) : (
              <Loading />
            )}
          </Container>
        </Center>
      </Box>
    </ScrollView>
  )
}

export default MiniCyclesScreen
