import React from 'react'
import { Select, CheckIcon } from 'native-base'
import map from 'lodash/map'
import { AppFormControl as FormControl } from 'components/Form/Inputs/FormControl'

export function SelectInput({ id, formik, label, required, style, items }) {
  const { values, setFieldValue } = formik

  return (
    <FormControl
      id={id}
      formik={formik}
      required={required}
      label={label}
      style={style}
    >
      <Select
        minWidth="200"
        accessibilityLabel={label}
        placeholder="Please Select"
        _selectedItem={{
          bg: 'teal.600',
          endIcon: <CheckIcon size={5} />,
        }}
        borderColor={style.borderColor}
        fontColor={style.fontColor}
        mt="1"
        py={5}
        borderRadius="15"
        onValueChange={(val) => {
          setFieldValue(id, val)
        }}
        selectedValue={values[id]}
      >
        {map(items, ({ id: itemId, label: itemLabel, value }) => (
          <Select.Item
            key={itemId}
            id={itemId}
            label={itemLabel}
            value={value}
          />
        ))}
      </Select>
    </FormControl>
  )
}

SelectInput.defaultProps = { type: 'select' }
SelectInput.propTypes = {}
