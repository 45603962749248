import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Circle } from 'react-native-svg'

export function ShareIcon({ fill, width, height }) {
  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 19 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G
        transform="translate(-80 -631) translate(81 632)"
        stroke={fill}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <Circle cx={13.7142857} cy={3.42857143} r={3.42857143} />
        <Circle cx={13.7142857} cy={14.5714286} r={3.42857143} />
        <Circle cx={3.42857143} cy={8.57142857} r={3.42857143} />
        <Path
          strokeLinecap="square"
          d="M6.6222447 7.13180106L10.441197 4.81661551"
        />
        <Path
          strokeLinecap="square"
          transform="matrix(1 0 0 -1 0 23.948)"
          d="M6.6222447 13.1318011L10.441197 10.8166155"
        />
      </G>
    </Icon>
  )
}
