import React from 'react'
import {
  GatherIcon,
  ReflectIcon,
  BrainIcon,
  WriteIcon,
  RecheckIcon,
} from 'components/Icons'

const Styles = {
  containerStyle: {
    fontWeight: 'bold',
    borderRadius: 15,
    marginBottom: 24,
  },
  textStyles: {
    fontSize: 20,
    fontWeight: 'bold',
    paddingTop: 8,
  },
  descriptionStyles: {
    fontSize: 14,
    paddingTop: 8,
    lineHeight: 24,
  },
}

export function prismicDayToCard(day, colors) {
  const cards = []
  if (day?.video?.[0]?.text) {
    cards.push({
      title: '',
      // url: 'https://www.youtube.com/watch?v=mIMMZQJ1H6E&list=RDmIMMZQJ1H6E&start_radio=1',
      url: day.video[0].text,
      img: day.thumbnail[0].text,
      uniqueKey: day.day_number,
      containerStyle: [Styles.containerStyle],
    })
  }
  if (day?.fact) {
    cards.push({
      title: 'Mind/brain fact',
      data: day.fact,
      textStyles: [Styles.textStyles, { color: colors.white[500] }],
      descriptionStyles: [
        Styles.descriptionStyles,
        { color: colors.white[500] },
      ],
      icon: <BrainIcon />,
      containerStyle: [Styles.containerStyle, { backgroundColor: '#43CCBB' }],
    })
  }

  if (day?.gather) {
    cards.push({
      title: 'Gather',
      data: day.gather,
      textStyles: [Styles.textStyles, { color: colors.white[500] }],
      descriptionStyles: [
        Styles.descriptionStyles,
        { color: colors.white[500] },
      ],
      icon: <GatherIcon />,
      containerStyle: [
        Styles.containerStyle,
        { backgroundColor: colors.hotpink[500] },
      ],
    })
  }

  if (day?.reflect) {
    cards.push({
      title: 'Reflect',
      data: day.reflect,
      textStyles: [Styles.textStyles, { color: colors.white[500] }],
      descriptionStyles: [
        Styles.descriptionStyles,
        { color: colors.white[500] },
      ],
      icon: <ReflectIcon />,
      containerStyle: [
        Styles.containerStyle,
        { backgroundColor: colors.salmon[500] },
      ],
    })
  }

  if (day?.write) {
    cards.push({
      title: 'Write',
      data: day.write,
      textStyles: [Styles.textStyles, { color: colors.white[500] }],
      descriptionStyles: [
        Styles.descriptionStyles,
        { color: colors.white[500] },
      ],
      icon: <WriteIcon />,
      containerStyle: [
        Styles.containerStyle,
        { backgroundColor: colors.picton[500] },
      ],
    })
  }

  if (day?.recheck) {
    cards.push({
      title: 'Recheck',
      data: day.recheck,
      textStyles: [Styles.textStyles, { color: colors.black[500] }],
      descriptionStyles: [
        Styles.descriptionStyles,
        { color: colors.black[500] },
      ],
      icon: <RecheckIcon />,
      containerStyle: [
        Styles.containerStyle,
        { backgroundColor: colors.kournikova[500] },
      ],
    })
  }
  return cards
}
