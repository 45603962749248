import React from 'react'
import { G, Path } from 'react-native-svg'
import { Icon } from 'native-base'

export function Oval({ fill, size }) {
  return (
    <Icon viewBox="0 0 40 53" xmlns="http://www.w3.org/2000/svg" size={size}>
      <Path
        d="M19.826 52s19.827-21.224 19.827-32.174S30.776 0 19.826 0C8.876 0 0 8.877 0 19.826 0 30.776 19.826 52 19.826 52z"
        transform="translate(-24 -287) translate(24.174 287.36)"
        fill={fill}
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </Icon>
  )
}
