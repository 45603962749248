import React, { useState } from 'react'
import { oneOf } from 'prop-types'
import { TextArea, useTheme } from 'native-base'
import { INPUT_TYPES } from 'state/consts'
import { AppFormControl as FormControl } from 'components/Form/Inputs/FormControl'

export function TextAreaInput({
  type,
  id,
  formik,
  label,
  required,
  style,
  inputProps,
}) {
  const { ui } = useTheme()
  const { handleBlur, handleChange, values } = formik
  const minHeight = 100
  const [height, setHeight] = useState(minHeight)

  return (
    <FormControl
      id={id}
      formik={formik}
      required={required}
      label={label}
      style={{
        ...style,
        height: Math.max(height, minHeight),
      }}
    >
      <TextArea
        id={id}
        borderColor={style && style.borderColor}
        borderRadius={ui.borderRadius}
        fontColor={style && style.fontColor}
        fontSize="xl"
        my={2}
        onBlur={handleBlur(id)}
        onChangeText={handleChange(id)}
        onContentSizeChange={(event) => {
          setHeight(Math.max(height, event.nativeEvent.contentSize.height))
        }}
        p={6}
        placeholderTextColor={style && style.placeholderFontColor}
        type={type}
        value={values[id]}
        variant="outline"
        {...inputProps}
      />
    </FormControl>
  )
}

TextAreaInput.defaultProps = { type: 'text' }
TextAreaInput.propTypes = {
  type: oneOf([INPUT_TYPES.TEXT, INPUT_TYPES.PASSWORD, INPUT_TYPES.NUMBER]),
}
