import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G, Defs, Mask, Use } from 'react-native-svg'

export function Ellipses({ width, height, fill }) {
  return (
    <Icon
      width={width}
      height={height}
      viewBox="0 0 16 4"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <Defs>
        <Path
          d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
          id="a"
        />
      </Defs>
      <G
        transform="translate(-329 -67) translate(329 67)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <Mask id="b" fill={fill}>
          <Use xlinkHref="#a" />
        </Mask>
        <Use fillOpacity={0} fill={fill} xlinkHref="#a" />
        <G mask="url(#b)" fill={fill}>
          <Path transform="translate(-4 -10)" d="M0 0H24.24V24.24H0z" />
        </G>
      </G>
    </Icon>
  )
}

Ellipses.defaulProps = {
  width: 20,
  height: 20,
}

Ellipses.propTypes = {
  width: number,
  height: number,
}
