import React from 'react'
import { Box, FormControl, Stack, Text, useTheme } from 'native-base'

export function AppFormControl({
  children,
  id,
  formik,
  label,
  required,
  style,
}) {
  const { colors } = useTheme()
  const { errors, touched } = formik
  const isInvalid = errors[id]
  return (
    <FormControl touched={touched} isRequired={required} isInvalid={isInvalid}>
      <Stack>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Text
            color={colors.white[500]}
            style={style && { color: style.fontColor }}
          >
            {label}
            {required ? ' *' : ''}
          </Text>
          {isInvalid ? (
            <FormControl.ErrorMessage>{errors[id]}</FormControl.ErrorMessage>
          ) : null}
        </Box>
        {children}
      </Stack>
    </FormControl>
  )
}
