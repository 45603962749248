import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import { useQuery } from 'urql'
import { Box, Text } from 'native-base'
import { PageHeading } from 'components/Headings'
import PrismicCard from 'components/Cards/PrismicCard'

function InfoInfoScreen() {
  const lineHeight = 24
  const [finalResult, setFinalResult] = useState([])

  const query = `#graphql
    query {
      allClinical_trialss {
        edges {
          node{
            _meta {
              id
            }
            title
            body
          }
        }
      }
    }
  `

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {
      day: 1,
    },
  })

  const formattedData = useMemo(() => {
    if (!result.data?.allClinical_trialss?.edges) {
      return []
    }
    return (
      result.data?.allClinical_trialss?.edges?.map((edge) => edge.node) || []
    )
  }, [result])

  useEffect(() => {
    const endBody = []
    let currentIndex = 0
    formattedData[0]?.body?.forEach((elem, i) => {
      if (elem.text === '') {
        currentIndex += 1
      }
      if (Array.isArray(endBody[currentIndex])) {
        endBody[currentIndex].push(elem)
      } else {
        endBody[currentIndex] = [elem]
      }
      if (elem.text === '') {
        currentIndex += 1
      }
    })

    const endResult = endBody
      ?.map((element, index) => {
        return element.reduce(
          (prev, current) => ({
            text: prev.text + ' ' + current.text,
            type: 'paragraph',
            spans: [],
          }),
          { text: '' }
        )
      })
      .flat()
    setFinalResult(endResult)
  }, [formattedData])

  return (
    <PrismicCard
      title={formattedData[0]?.title}
      image={formattedData[0]?.image}
      body={finalResult}
    />
  )
}

export default InfoInfoScreen
