import React, { Fragment } from 'react'
import * as Application from 'expo-application'
import { Box, HStack, Pressable, Text, ChevronRightIcon } from 'native-base'
import { DISPATCH } from 'state/consts'
import GatherCard from 'components/Cards/GatherCard'
import { Inspiration } from 'components/Cards'
import { PageContainer } from 'components/Containers/Page'
import { PageHeading } from 'components/Headings'
import { AppCalendarStatic } from 'components/AppCalendar/CalendarStatic'
import { useAuth, useCycles, usePurchases } from 'state/hooks/index'
import { navigate } from 'RootNavigation'
import { routeNames } from 'state/routeNames'

const history = [
  { id: 'history', label: 'History', link: `${routeNames.HISTORY_NAVIGATOR}` },
  { id: 'results', label: 'Neurocycle Results', link: 'AccountResults' },
]

const account = [
  { id: 'profile', label: 'Edit Profile', link: 'AccountProfile' },
  { id: 'subscription', label: 'Subscription', link: 'AccountSubscription' },
]

const general = [
  { id: 'about', label: 'About', link: 'GeneralAbout' },
  { id: 'info', label: 'Info', link: 'GeneralInfo' },
  { id: 'help', label: 'Help', link: 'GeneralHelp' },
  { id: 'contactus', label: 'Contact Us', link: 'GeneralContactUs' },
  {
    id: 'resetcycle',
    label: 'Restart Current Cycle',
    link: 'ResetCycle',
  },
]

export function ListBox({ items }) {
  return (
    <Box w="100%">
      {items.map(({ id, label, link }) => (
        <Box
          key={id}
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Pressable
            onPress={() => navigate(link)}
            justifyContent="center"
            alignItems="center"
          >
            <HStack justifyContent="space-between" width="100%">
              <Text variant="text-menuItem">{label}</Text>
              <Text fontSize="lg">
                <ChevronRightIcon color="gray.300" />
              </Text>
            </HStack>
          </Pressable>
        </Box>
      ))}
    </Box>
  )
}

export function ListTitle({ text }) {
  return (
    <Box pt={6} pb={2}>
      <Text variant="text-menuHeading">{text}</Text>
    </Box>
  )
}

function AccountLandingScreen() {
  const {
    dispatch: { dispatch: authDispatch },
  } = useAuth()
  const purchases = usePurchases()
  const { state } = useCycles()

  function onDayPress({ day, month, year }) {
    const date = new Date(year, month - 1, day)
    navigate(routeNames.HISTORY_NAVIGATOR, {
      screen: routeNames.CALENDAR_DAY,
      params: {
        timestamp: date.getTime(),
      },
    })
  }

  function StatBox({ title, stat }) {
    return (
      <Box flex={1} alignItems="center">
        <Text variant="stats-title" textAlign="center">
          {title}
        </Text>
        <Text variant="stats-stat" mt={2} textAlign="center">
          {stat}
        </Text>
      </Box>
    )
  }

  return (
    <Fragment>
      <PageContainer scheme="willowbrook" standAlone={false}>
        <PageHeading title="My Account" />
        <Box pt={2} width="100%">
          <HStack space={4}>
            {/* <StatBox title="Active Streak" stat={0} /> */}
            <StatBox title="Days in Cycle" stat={`${state.day + 1} / 63`} />
            <StatBox title="Total Cycles" stat={state?.cycle + 1} />
          </HStack>
        </Box>
        <Box
          style={{
            width: '100%',
          }}
          bgColor="white.500"
          borderRadius={12}
          px={2}
          my={4}
        >
          <AppCalendarStatic onDayPress={onDayPress} />
        </Box>
        <Inspiration
          text={
            state.day === 0
              ? 'Keep going! You are making great progress on your anxiety.'
              : 'Get started with your first Neurocycle™ to start tracking your progress'
          }
          secondary
        />
        {state?.gatherInfo?.firstThought && <GatherCard />}
        <Box
          width="100%"
          backgroundColor="white"
          mt={4}
          p={6}
          mb={6}
          borderRadius={15}
        >
          <ListTitle text="History & Results" />
          <ListBox items={history} />
          <ListTitle text="Account Settings" />
          <ListBox items={account} />
          <ListTitle text="General" />
          <ListBox items={general} />
          <Pressable
            onPress={() => authDispatch({ type: DISPATCH.AUTH.LOGOUT_USER })}
          >
            <Text fontSize="lg" my={6}>
              Logout
            </Text>
          </Pressable>
        </Box>
        <Box alignItems="center" m={2}>
          <Text color="willowbrook.800">
            {Application.nativeApplicationVersion}
            {Application.nativeBuildVersion}
          </Text>
          {purchases.state.premium && (
            <Text mt={2} color="willowbrook.800" fontSize="sm">
              PREMIUM
            </Text>
          )}
        </Box>
      </PageContainer>
    </Fragment>
  )
}

export default AccountLandingScreen
