import React from 'react'
import { number } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G, Line } from 'react-native-svg'

export function Bell({ width, height }) {
  return (
    <Icon viewBox="0 0 19 22" width={width} height={height}>
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G
          id="Notifications-Copy"
          transform="translate(1.468412, 1.000000)"
          stroke="#42566E"
          strokeWidth="1.5"
        >
          <Path
            d="M16.2171764,13.6210526 L15.2698079,12.4842105 C14.6803343,11.7684211 14.3645448,10.8631579 14.3645448,9.93684211 L14.3645448,7.81052632 C14.3645448,4.73684211 12.0487553,2.18947368 9.08033426,1.81052632 L9.08033426,0.757894737 C9.08033426,0.336842105 8.74349215,0 8.32243952,0 C7.90138689,0 7.56454478,0.336842105 7.56454478,0.757894737 L7.56454478,1.81052632 C4.59612373,2.18947368 2.28033426,4.73684211 2.28033426,7.81052632 L2.28033426,9.93684211 C2.28033426,10.8631579 1.96454478,11.7684211 1.3750711,12.4842105 L0.44875531,13.6210526 C-0.0354552167,14.2105263 -0.140718375,15.0315789 0.196123731,15.7263158 C0.532965836,16.4210526 1.20665005,16.8631579 1.98559741,16.8631579 L4.89086057,16.8631579 C5.05928163,18.6315789 6.53296584,20 8.34349215,20 C10.1540185,20 11.6277027,18.6105263 11.7961237,16.8631579 L14.7013869,16.8631579 C15.4803343,16.8631579 16.1540185,16.4210526 16.4908606,15.7263158 C16.7855974,15.0315789 16.7013869,14.2315789 16.2171764,13.6210526 Z"
            id="Path"
            fillRule="nonzero"
          />
          <Line
            x1="4.89086057"
            y1="16.8631579"
            x2="11.7961237"
            y2="16.8631579"
            id="Path-6"
          />
          <Line
            x1="9.08033426"
            y1="1.81052632"
            x2="7.56454478"
            y2="1.81052632"
            id="Path-7"
          />
        </G>
      </G>
    </Icon>
  )
}

Bell.defaulProps = {
  width: 20,
  height: 20,
}

Bell.propTypes = {
  width: number,
  height: number,
}
