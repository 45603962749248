import React from 'react'
import { Icon } from 'native-base'
import { G, Rect, Polyline } from 'react-native-svg'

export function ContactUs(props) {
  return (
    <Icon
      width="22px"
      height="16px"
      viewBox="0 0 22 16"
      version="1.1"
      {...props}
    >
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G
          id="Player---More-Options"
          transform="translate(-29.000000, -412.000000)"
          stroke="#FFFFFF"
          strokeWidth="1.5"
        >
          <G id="Contact-Icon" transform="translate(29.846316, 413.000000)">
            <Rect id="Rectangle" x="0" y="0" width="20.2222222" height="14" />
            <Polyline
              id="Path-3"
              points="0 0 10.1111111 7.22222222 20.4989432 0"
            />
          </G>
        </G>
      </G>
    </Icon>
  )
}
