import React, { useMemo, useEffect, useState } from 'react'
import {
  View,
  Text,
  Pressable,
  VStack,
  HStack,
  ChevronRightIcon,
  Progress,
} from 'native-base'
import { ImageSourcePropType } from 'react-native'
import { SectionHeading } from 'components/Headings'
import { useCycles } from 'hooks/useCycles'
import { useAuth } from 'hooks/useAuth'
import { usePurchases } from 'hooks/usePurchases'
import { Config } from 'config/config'

const TRIAL_DAYS = Config.TRIAL_DAYS

interface TrialCardProps extends React.ComponentProps<'div'> {
  source: ImageSourcePropType
  setShowSubscription(val: boolean, type: boolean): any
}

export function TrialCard({
  source,
  setShowSubscription,
  ...props
}: TrialCardProps) {
  const handlePress = () => {
    if (setShowSubscription) {
      setShowSubscription(true, false)
    }
  }

  const [trialDays, setTrialDays] = useState(TRIAL_DAYS)
  const { state: cycleState } = useCycles()
  const {
    state: { user },
  } = useAuth()
  const {
    dispatch: { userTrialDays },
  } = usePurchases()
  const { day, track } = cycleState

  useEffect(() => {
    if (user) {
      setTrialDays(userTrialDays(user))
    }
  }, [user])

  return (
    <Pressable onPress={handlePress} mb={6}>
      <VStack space={4} alignItems="center">
        <View width="100%" flexDirection="column">
          <HStack
            space={4}
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <SectionHeading
              title={
                trialDays - day - 1
                  ? `${Math.max(trialDays - day, 0)} cycle day${
                      Math.max(trialDays - day, 0) === 1 ? '' : 's'
                    } left of trial`
                  : day === trialDays - 1 && track === 6
                  ? 'Trial has ended'
                  : 'Last cycle day of trial'
              }
              mb={0}
            />
            <HStack alignItems="center" justifyContent="center">
              <Text>Upgrade</Text>
              <ChevronRightIcon size="sm" />
            </HStack>
          </HStack>
          <Progress
            value={
              ((day === trialDays - 1 && track === 6 ? trialDays : day) /
                trialDays) *
              100
            }
            colorScheme="hotpink"
            bg="warmGray.100"
          />
        </View>
      </VStack>
    </Pressable>
  )
}
