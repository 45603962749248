import React from 'react'
import { number, string } from 'prop-types'
import { Icon } from 'native-base'
import { Path, G } from 'react-native-svg'

export function Resources({ width, height, stroke }) {
  return (
    <Icon
      viewBox="0 0 17 23"
      width={width}
      height={height}
      style={{ height, width }}
    >
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G id="Rd2" strokeWidth="1" fill="none" fillRule="evenodd">
          <G id="Tab-Bar" transform="translate(-228.000000, -2.000000)">
            <G
              fill={stroke === '#021D3D' ? '#021D3D' : stroke}
              id="Resources"
              transform="translate(229.000000, 3.000000)"
            >
              <Path
                d="M13,-0.75 C14.4625318,-0.75 15.6584043,0.391704595 15.7449812,1.83247767 L15.75,2 L15.75,19 C15.75,20.4625318 14.6082954,21.6584043 13.1675223,21.7449812 L13,21.75 L2.06616211,21.75 L2.06616211,20.25 L13,20.25 C13.6472087,20.25 14.1795339,19.7581253 14.2435464,19.1278052 L14.25,19 L14.25,2 C14.25,1.35279131 13.7581253,0.820466101 13.1278052,0.756453609 L13,0.75 L2,0.75 C1.35279131,0.75 0.820466101,1.24187466 0.756453609,1.87219476 L0.75,2 L0.75,18 L-0.75,18 L-0.75,2 C-0.75,0.537468162 0.391704595,-0.658404279 1.83247767,-0.744981206 L2,-0.75 L13,-0.75 Z"
                id="Path"
              />
              <Path
                d="M15.75,19 L15.75,15.25 L2.5,15.25 C0.705074563,15.25 -0.75,16.7050746 -0.75,18.5 C-0.75,20.2949254 0.705074563,21.75 2.5,21.75 L13,21.75 C14.5187831,21.75 15.75,20.5187831 15.75,19 Z M2.5,16.75 L14.25,16.75 L14.25,19 C14.25,19.6903559 13.6903559,20.25 13,20.25 L2.5,20.25 C1.53350169,20.25 0.75,19.4664983 0.75,18.5 C0.75,17.5335017 1.53350169,16.75 2.5,16.75 Z"
                id="Rectangle"
              />
              <Path
                d="M14.5,17.75 C14.9142136,17.75 15.25,18.0857864 15.25,18.5 C15.25,18.8796958 14.9678461,19.193491 14.6017706,19.2431534 L14.5,19.25 L4.5,19.25 C4.08578644,19.25 3.75,18.9142136 3.75,18.5 C3.75,18.1203042 4.03215388,17.806509 4.39822944,17.7568466 L4.5,17.75 L14.5,17.75 Z"
                id="Line-3"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}

Resources.defaulProps = {
  width: 20,
  height: 20,
  stroke: '#021D3D',
}

Resources.propTypes = {
  width: number,
  height: number,
  stroke: string,
}
