import React from 'react'
import { Box, Button, Text } from 'native-base'

export function QuestionButton({ index, text, action, selected }) {
  return (
    <Box style={{ height: 80 }} mb={6}>
      <Button
        variant={selected === index ? 'questionSelected' : 'questionDefault'}
        flex={1}
        onPress={() => action(index)}
      >
        <Text fontSize="xl" bold px={4}>
          {text}
        </Text>
      </Button>
    </Box>
  )
}
