// serviceWorkerRegistration.js

// This code is used to register the Firebase Messaging service worker.
// By default, it registers 'firebase-messaging-sw.js' to enable push notifications.

// It ensures that the service worker is registered in both development and production environments,
// including localhost.

// To learn more about service workers, read https://developers.google.com/web/fundamentals/primers/service-workers

export function register(config) {
  console.log("::::RESGISTEATION SW FMC")
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `/firebase-messaging-sw.js`;

      console.log(swUrl, ' ::::: SWURL')

      navigator.serviceWorker
        .register(swUrl, { scope: '/' })
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);

          // Optional: Handle service worker updates
          if (registration.waiting) {
            handleUpdate(registration, config);
          }

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // New content is available; please refresh.
                  console.log('New content is available; please refresh.');

                  // Execute callback
                  if (config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                } else {
                  // Content is cached for offline use.
                  console.log('Content is cached for offline use.');

                  // Execute callback
                  if (config && config.onSuccess) {
                    config.onSuccess(registration);
                  }
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error('Error during service worker registration:', error);
        });
    });
  }
}

function handleUpdate(registration, config) {
  // Prompt user to refresh the page
  console.log('An updated service worker is waiting to activate.');

  // Optionally, you can notify the user or force the update
  // For example, you might display a modal prompting the user to refresh
  // Here, we send a message to the waiting service worker to activate immediately
  registration.waiting.postMessage({ type: 'SKIP_WAITING' });

  if (config && config.onUpdate) {
    config.onUpdate(registration);
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
