import React, { useEffect, useState } from 'react'
import { Box } from 'native-base'
import { Inspiration } from 'components/Cards/Inspiration'
import { Loading } from 'components/Loading'
import { SectionHeading } from 'components/Headings'
import { fetchDocumentByIndex } from 'state/firebase/fetchDocumentByIndex'
import format from 'date-fns/format'

let mounted = false
export function DailyInspiration() {
  const today = format(new Date(), 'D', { useAdditionalDayOfYearTokens: true })
  const [wisdom, setWisdom] = useState(false)
  mounted = true

  useEffect(() => {
    async function getInspiration() {
      if (mounted) {
        setWisdom(await fetchDocumentByIndex('daily-inspiration', today))
      }
    }
    if (!wisdom || today !== wisdom?.index) {
      getInspiration()
    }
    return () => {
      mounted = false
    }
  }, [wisdom, today])
  return (
    <Box mb={6} width="100%">
      <Box mx={2}>
        <SectionHeading title="Today's Inspiration" />
      </Box>
      {wisdom ? <Inspiration text={wisdom[0]?.text} /> : <Loading />}
    </Box>
  )
}
