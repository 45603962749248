import React, { useState } from 'react'
import { Box, Text, useTheme } from 'native-base'
import { SectionHeading } from 'components/Headings'
import _ from 'lodash'
import { useCycles } from 'hooks/useCycles'

const GatherCard: React.FC = () => {
  const { colors } = useTheme()
  const { state: cycleState } = useCycles()

  const gatherKeys = Object.keys(cycleState?.gatherInfo)

  return (
    <Box
      mt={4}
      p={5}
      style={{
        borderRadius: 15,
        backgroundColor: colors.white[500],
      }}
    >
      <Box pt={2} pb={4} paddingX={1}>
        <Text variant="text-menuHeading">{`Cycle ${
          cycleState.cycle + 1
        }`}</Text>
      </Box>
      <Box width="100%">
        {gatherKeys.map((gather, index) => (
          <Box flexDirection="row" alignItems="center" key={gather} py={1}>
            <Box
              justifyContent="center"
              alignItems="center"
              mr={4}
              style={{
                height: 30,
                width: 30,
                backgroundColor: colors.puertorico[500],
                borderRadius: 15,
                borderWidth: 1,
                borderColor: colors.puertorico[500],
              }}
            >
              <Text variant="text-menuItem">{index + 1}</Text>
            </Box>
            <Text variant="text-menuItem">
              {cycleState?.gatherInfo[gather]}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default GatherCard
