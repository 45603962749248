import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import { Loading } from 'components/Loading'
import { useQuery } from 'urql'

export function PrivacyPolicy() {
  const Privacy = lazy(() => import('components/PrivacyPolicy'))
  const query = `#graphql
    query {
      allPrivacy_policys {
        edges {
          node{
            _meta {
              id
            }
            title
            body
          }
        }
      }
    }
  `

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {
      day: 1,
    },
  })

  const formattedData = useMemo(() => {
    if (!result.data?.allPrivacy_policys?.edges) {
      return []
    }
    return [].concat(
      ...result.data?.allPrivacy_policys?.edges?.map((edge) => edge.node)
    )
  }, [result])

  return (
    <Suspense fallback={<Loading />}>
      <Privacy title={formattedData[0]?.title} body={formattedData[0]?.body} />
    </Suspense>
  )
}
