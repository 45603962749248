import React from 'react'
import {
  Box,
  CloseIcon,
  InfoOutlineIcon,
  Text,
  Modal,
  Pressable,
  useTheme,
  QuestionOutlineIcon,
} from 'native-base'
import { ContactUs, History } from 'components/Icons'

import { routeNames } from '../../state/routeNames'

function PlayerModalItem({
  icon: ItemIcon,
  link,
  text,
  navigation,
  showModal,
  setShowModal,
  dismissPlayer,
}) {
  return (
    <Pressable
      onPress={() => {
        navigation.navigate('Landing', {
          screen: 'Account',
          params: { screen: link, intial: false },
          intial: false,
        })
        dismissPlayer(true)
        setShowModal(!showModal)
      }}
      mb={16}
    >
      <Box
        style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}
      >
        <ItemIcon color="white.500" />
        <Text fontSize="4xl" variant="player" pl={4}>
          {text}
        </Text>
      </Box>
    </Pressable>
  )
}

export function PlayerHelpCard({
  showModal,
  setShowModal,
  navigation,
  dismissPlayer,
}) {
  const { colors } = useTheme()

  return (
    <Modal
      isOpen={showModal}
      style={{
        backgroundColor: colors.puertorico.blur,
        backdropFilter: 'blur(5px)',
      }}
    >
      <Box mt={6} safeArea style={{ height: '100%', width: '100%' }} p={8}>
        <Box flexDirection="row" justifyContent="flex-end">
          <Pressable onPress={() => setShowModal(!showModal)}>
            <CloseIcon color="white.500" size="4" />
          </Pressable>
        </Box>
        <Box mt={20}>
          <PlayerModalItem
            icon={QuestionOutlineIcon}
            link="GeneralHelp"
            text="Help Center"
            navigation={navigation}
            showModal={showModal}
            setShowModal={setShowModal}
            dismissPlayer={dismissPlayer}
          />
          <PlayerModalItem
            icon={History}
            link={routeNames.HISTORY_NAVIGATOR}
            text="History"
            navigation={navigation}
            showModal={showModal}
            setShowModal={setShowModal}
            dismissPlayer={dismissPlayer}
          />
          <PlayerModalItem
            icon={InfoOutlineIcon}
            link="GeneralAbout"
            text="About"
            navigation={navigation}
            showModal={showModal}
            setShowModal={setShowModal}
            dismissPlayer={dismissPlayer}
          />
          <PlayerModalItem
            icon={ContactUs}
            iconProps={{
              mt: 3,
              style: {
                marginTop: 4,
              },
            }}
            link="GeneralContactUs"
            text="Contact Us"
            navigation={navigation}
            showModal={showModal}
            setShowModal={setShowModal}
            dismissPlayer={dismissPlayer}
          />
        </Box>
      </Box>
    </Modal>
  )
}
