import React, { useState } from 'react'
import set from 'lodash/set'
import { firestore, crashlytics } from 'config/firebase'
import { useToast, useTheme, Link, Box } from 'native-base'
import { useNavigation } from '@react-navigation/native'
import { AuthHeading } from 'components/Headings'
import { AppToast } from 'components/Toast'
import { contactUsConfig } from 'screens/Account/General/ContactUs/formConfig'
import { FormContainer } from 'components/Containers/Forms'
import { useAuth } from 'state/hooks/useAuth'
import { APP_LINKS } from '../../../../state/consts'

const ContactUs = () => {
  const {
    state: { user },
  } = useAuth()
  const { colors } = useTheme()
  const toast = useToast()
  const navigation = useNavigation()
  const initialValues = {
    fullName: user?.displayName || '',
    email: user?.email || '',
    message: '',
  }
  const [error, setError] = useState(null)
  const config = set(contactUsConfig, 'initialValues', initialValues)
  const style = {
    justifyContent: 'flex-start',
    fontColor: colors.tangaroa[500],
    borderColor: colors.tangaroa[500],
  }

  async function saveToFirebase(data) {
    await firestore()
      .collection('messages')
      .add({
        ...data,
        received: firestore.FieldValue.serverTimestamp(),
        authEmail: user?.email,
        uid: user?.uid,
      })
      .then((res) => res.id)
      .catch((err) => {
        throw new Error(err)
      })
  }

  set(config, 'actions.submit.handle', ({ email, fullName, message }) => {
    crashlytics().log('Starting contact us', user?.email)
    try {
      saveToFirebase({
        email: email,
        fullName: fullName,
        message: message,
      })
      toast.show({
        placement: 'top',
        status: 'error',
        duration: 2900,
        onCloseComplete: () => navigation.goBack(),
        render: () => <AppToast status="success" message="Message sent!" />,
      })
    } catch (err) {
      crashlytics().recordError(err)
      setError('Contact us failed')
    }
  })

  return (
    <FormContainer style={style}>
      <Box justifyContent="space-between" height="100%">
        <Box>
          <AuthHeading
            heading={config.heading.title}
            subHeading={config.heading.subTitle}
          />
          <Link
            href={'mailto:' + APP_LINKS.APP_SUPPORT.link}
            isExternal
            isUnderlined
            _text={{
              fontSize: '3xl',
              color: 'puertorico.500',
            }}
          >
            {APP_LINKS.APP_SUPPORT.link}
          </Link>
        </Box>
      </Box>
    </FormContainer>
  )
}

export default ContactUs
