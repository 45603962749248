import React from 'react'
import { Box, Text, HStack, useTheme } from 'native-base'
import DashedLine from 'react-native-dashed-line'
import map from 'lodash/map'
import times from 'lodash/times'

export function ResultsCard({ title, data }) {
  const { colors } = useTheme()
  const daysScheme = [
    { id: 0, name: 'Day 1', scheme: colors.puertorico[500] },
    { id: 1, name: 'Day 21', scheme: colors.salmon[500] },
    { id: 2, name: 'Day 42', scheme: colors.lightBlue[500] },
    { id: 3, name: 'Day 63', scheme: colors.picton[500] },
  ]
  const metrics = ['Minimal', 'Moderate', 'Good', 'Excellent']
  const stack = { width: '100%' }
  const stackedBox = { ...stack, position: 'absolute', top: 30 }
  const textHeading = {
    width: `${100 / metrics.length}%`,
    textAlign: 'center',
  }

  return (
    <Box
      width="100%"
      height={200}
      borderRadius={15}
      backgroundColor="white"
      mb={8}
    >
      <Text variant="text-graphHeading" py={4} pl={6}>
        {title}
      </Text>
      <Box style={{ ...stackedBox, zIndex: 2 }} p={6}>
        <HStack justifyContent="space-between" style={{ ...stack, height: 20 }}>
          {map(metrics, (key) => (
            <Text
              key={key}
              style={textHeading}
              variant="text-graphSectionTitles"
            >
              {key}
            </Text>
          ))}
        </HStack>
        <HStack
          justifyContent="space-between"
          style={{ ...stack, height: 120 }}
          pb={2}
        >
          {map(times(metrics.length + 1), (id) => (
            <DashedLine
              key={id}
              axis="vertical"
              dashLength={5}
              dashThickness={1}
              dashColor={colors.cadet[500]}
            />
          ))}
        </HStack>
      </Box>
      <Box style={{ ...stackedBox, zIndex: 3 }} px={6} pt={12}>
        {map(data, ({ id, count }) => {
          const width = `${count}%`
          const radius = 12
          return (
            <Box
              key={id}
              bg={daysScheme[id].scheme}
              style={{
                width,
                borderTopRightRadius: radius,
                borderBottomRightRadius: radius,
              }}
              mb={2}
            >
              <Text
                style={{
                  fontSize: 10,
                  color: '#000812',
                  textAlign: 'right',
                  lineHeight: 19,
                }}
                pr={4}
              >
                {daysScheme[id].name}
              </Text>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
