import React, { createContext, useReducer } from 'react'
import {
  Audio,
  InterruptionModeAndroid,
  InterruptionModeIOS,
  AVPlaybackStatusSuccess,
  AVPlaybackSource,
} from 'expo-av'
import _ from 'lodash'
import { firestore, crashlytics } from 'config/firebase'
import { DISPATCH } from 'state/consts'
import { TCycleStep, TFile } from 'state/cyclesMatrix'
import { IUser } from 'state/interfaces'
import { useAuth } from 'hooks/index'
import { FIREBASE_COLLECTIONS } from '../consts'
import { setupLockScreenControls } from './MusicControl'

export const PlayerContext = createContext({})

export type TLoadSoundFile = {
  file: TFile
  title: string
  subTitle?: string
  duration: number
  id?: string
}

export type TInitStepSoundProps = {
  step: TCycleStep
  onNext: () => void
  onBack: () => void
}

export type TLoadSoundProps = {
  file: TLoadSoundFile
  onNext?: () => void
  onBack?: () => void
  signal: AbortSignal
}

export type TPlayerState = {
  loading: boolean
  loaded: boolean
  maximum: boolean
  mini: boolean
  next: boolean
  playing: boolean
  previous: boolean
  scrubbing: boolean
  sound: Audio.Sound | null
  timeMs: number
  duration: number
  track: boolean
  tracks: any
  visible: boolean
  file?: TLoadSoundFile
  onNext?: () => void
  onBack?: () => void
}

const initialState: TPlayerState = {
  loading: false,
  loaded: false,
  maximum: false,
  mini: false,
  next: false,
  playing: false,
  previous: false,
  scrubbing: false,
  sound: null,
  timeMs: 0,
  duration: 0,
  track: false,
  tracks: {},
  visible: false,
}

function playerReducer(
  state: TPlayerState,
  action: { type: string; data?: any }
): TPlayerState {
  switch (action.type) {
    case DISPATCH.PLAYER.UPDATE:
      return { ...state, ...action.data }
    case DISPATCH.PLAYER.PAUSE:
      return { ...state, playing: false, loading: false }
    case DISPATCH.PLAYER.PLAY:
      return { ...state, playing: true, loading: false }
    case DISPATCH.PLAYER.TOGGLE_VISIBILITY: {
      return { ...state, visible: !state.visible }
    }
    case DISPATCH.PLAYER.DISMISS: {
      return { ...state, ...initialState, visible: false }
    }
    case DISPATCH.PLAYER.SET_VISIBLE: {
      return { ...state, visible: true }
    }
    case DISPATCH.PLAYER.SET_HIDE: {
      return { ...state, visible: false }
    }
    case DISPATCH.PLAYER.SET_LOADING: {
      return { ...state, loading: true, visible: true }
    }
    case DISPATCH.PLAYER.TIME_UPDATE: {
      if (state.scrubbing && action.data.scrubbing === undefined) return state
      return {
        ...state,
        timeMs: action.data.timeMs,
        duration: action.data.duration,
        scrubbing: action.data.scrubbing || false,
      }
    }
    case DISPATCH.PLAYER.TOGGLE_SIZE: {
      return { ...state, maximum: !state.maximum }
    }
    case DISPATCH.PLAYER.SET_FULLSIZE: {
      return { ...state, visible: true, maximum: true, ...action?.data }
    }
    case DISPATCH.PLAYER.SET_SMALLSIZE: {
      return { ...state, visible: true, maximum: false, ...action?.data }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

async function updateHistory(step: TCycleStep, user: IUser) {
  if (!user?.uid) throw new Error('User missing')
  try {
    const historyRef = firestore()
      .collection(FIREBASE_COLLECTIONS.HISTORY)
      .doc()
    await historyRef.set({
      uid: user?.uid,
      ...step,
      createdAt: firestore.FieldValue.serverTimestamp(),
    })
  } catch (error: any) {
    throw new Error(error)
  }
}

export function PlayerProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(playerReducer, initialState)
  const {
    state: { user },
  } = useAuth()

  function toggleSize(size: 'full' | 'small') {
    dispatch({
      type:
        size === 'small'
          ? DISPATCH.PLAYER.SET_SMALLSIZE
          : DISPATCH.PLAYER.SET_FULLSIZE,
      data: {},
    })
  }

  function initStepSound(props: TInitStepSoundProps) {
    if (!props.step) throw new Error('Step missing')
    const file = props.step.files?.find((file) => file.locale === 'en')
    if (!file || !file.uri)
      throw new Error('loadSound: No uri found in current step')
    //Haptics.selectionAsync()
    const { title, subTitle, duration = 0 } = props.step
    dispatch({
      type: DISPATCH.PLAYER.UPDATE,
      data: {
        loading: false,
        scrubbing: false,
        loaded: false,
        playing: false,
        file: {
          file,
          title,
          subTitle,
          duration,
        },
        onNext: props.onNext,
        onBack: props.onBack,
      },
    })
    updateHistory(props.step, user)
    if (state.sound) {
      state.sound.pauseAsync()
    }
  }

  async function loadSound({
    file,
    signal,
  }: TLoadSoundProps): Promise<Audio.Sound | undefined> {
    if (state.loading) return state.sound!
    else if (state.sound) await state.sound.unloadAsync()

    function startLoadStatusUpdate() {
      dispatch({
        type: DISPATCH.PLAYER.UPDATE,
        data: {
          state,
          loading: true,
          loaded: false,
          scrubbing: false,
          file,
        },
      })
    }

    function doneLoadStatusUpdate(sound: Audio.Sound) {
      dispatch({
        type: DISPATCH.PLAYER.UPDATE,
        data: {
          state,
          scrubbing: false,
          loading: false,
          loaded: true,
          sound,
          timeMs: 0,
          duration: (result as AVPlaybackStatusSuccess)?.durationMillis,
        },
      })
      return sound
    }

    if (
      state.sound &&
      state.file &&
      state.file.file.uri === file.file.uri &&
      state.loaded
    ) {
      return doneLoadStatusUpdate(state.sound)
    }

    startLoadStatusUpdate()

    await Audio.setIsEnabledAsync(true)
    if (signal?.aborted) return
    await Audio.setAudioModeAsync({
      allowsRecordingIOS: false,
      interruptionModeIOS: InterruptionModeIOS.DoNotMix,
      playsInSilentModeIOS: true,
      staysActiveInBackground: true,
      interruptionModeAndroid: InterruptionModeAndroid.DoNotMix,
      shouldDuckAndroid: true,
      playThroughEarpieceAndroid: false,
    })
    if (signal?.aborted) return

    const { sound } = await Audio.Sound.createAsync(
      { uri: file.file.uri } as AVPlaybackSource,
      { shouldPlay: false },
      (ps) => {
        // MusicControl.updatePlayback({
        //   state: playing
        //     ? MusicControl.STATE_PLAYING
        //     : MusicControl.STATE_PAUSED,
        //   speed: 1,
        //   elapsedTime: (ps.positionMillis || 0) * 0.001,
        // })
      }
    )
    if (signal?.aborted) return

    const result = await sound.getStatusAsync()
    if (signal?.aborted) return

    sound.setOnPlaybackStatusUpdate(function (status) {
      dispatch({
        type: DISPATCH.PLAYER.TIME_UPDATE,
        data: {
          timeMs: (status as AVPlaybackStatusSuccess).positionMillis,
          duration: (status as AVPlaybackStatusSuccess).durationMillis,
        },
      })
    })
    if (signal?.aborted) return
    setupLockScreenControls({ sound, signal, playSound, pauseSound, state })
    return doneLoadStatusUpdate(sound)
  }

  async function dismissPlayer() {
    // Haptics.selectionAsync()
    dispatch({
      type: DISPATCH.PLAYER.SET_LOADING,
    })
    if (state.playing && state.sound) {
      await state.sound.pauseAsync()
    }
    dispatch({
      type: DISPATCH.PLAYER.DISMISS,
    })
  }

  const scrub = async (seekPosition: number) => {
    if (state.sound) {
      // Haptics.selectionAsync()
      dispatch({
        type: DISPATCH.PLAYER.TIME_UPDATE,
        data: {
          scrubbing: true,
          timeMs: seekPosition,
        },
      })
    }
  }

  const skipTo = async (seekPosition: number) => {
    if (state.sound) {
      // Haptics.selectionAsync()
      await state.sound.setPositionAsync(seekPosition)
      dispatch({
        type: DISPATCH.PLAYER.TIME_UPDATE,
        data: {
          scrubbing: false,
          timeMs: seekPosition,
        },
      })
    }
  }

  const skip15 = async (op: 'forward' | 'backward') => {
    // Haptics.selectionAsync()
    const result = await state.sound?.getStatusAsync()
    const currentPosition = result?.positionMillis
    let seekPosition = currentPosition
    if (op === 'forward')
      seekPosition = Math.min(15000 + currentPosition, state.duration!)
    if (op === 'backward') seekPosition = Math.max(0, currentPosition - 15000)
    if (!state.playing) {
      dispatch({
        type: DISPATCH.PLAYER.TIME_UPDATE,
        data: {
          timeMs: seekPosition,
        },
      })
    }
    await state.sound?.setPositionAsync(seekPosition)
  }

  function toggleSound(): AbortController | undefined {
    if (state.loading) return
    // Haptics.selectionAsync()
    const abortController = new AbortController()
    try {
      _toggleSound(state.sound, state.playing, abortController.signal)
    } catch (e) {
      console.log('>> toggleSound error ', e)
      crashlytics().recordError(e)
      dispatch({
        type: DISPATCH.PLAYER.PAUSE,
      })
    }
    return abortController
  }

  const _toggleSound = async (
    sound: Audio.Sound | null,
    onPlaying: boolean,
    signal: AbortSignal
  ) => {
    dispatch({
      type: onPlaying ? DISPATCH.PLAYER.PAUSE : DISPATCH.PLAYER.PLAY,
    })
    let _sound: Audio.Sound | undefined | null = sound || state.sound
    if (!_sound || !state.loaded) {
      _sound = await loadSound({ file: state.file!, signal })
      if (!_sound) throw Error('Sound failed to load')
    }
    if (signal?.aborted) throw Error('Aborted')
    if (onPlaying) {
      await pauseSound(_sound, signal)
    } else {
      await playSound(_sound, signal)
    }
  }

  async function pauseSound(sound: Audio.Sound, signal: AbortSignal) {
    dispatch({
      type: DISPATCH.PLAYER.PAUSE,
    })
    await sound.pauseAsync()
    if (signal?.aborted) throw Error('Aborted')
    const result = await sound.getStatusAsync()
    if (signal?.aborted) throw Error('Aborted')
    const currentPosition = (result as AVPlaybackStatusSuccess).positionMillis
    // MusicControl.updatePlayback({
    //   state: MusicControl.STATE_PAUSED,
    //   elapsedTime: currentPosition / 1000,
    // })
  }

  async function playSound(sound: Audio.Sound, signal: AbortSignal) {
    dispatch({
      type: DISPATCH.PLAYER.PLAY,
    })
    await sound.playAsync()
    if (signal?.aborted) throw Error('Aborted')
    const result = await sound.getStatusAsync()
    if (signal?.aborted) throw Error('Aborted')
    const currentPosition = (result as AVPlaybackStatusSuccess).positionMillis
    // MusicControl.updatePlayback({
    //   state: MusicControl.STATE_PLAYING,
    //   elapsedTime: currentPosition / 1000,
    // })
  }

  const value = {
    state,
    dispatch: {
      dismissPlayer,
      dispatch,
      loadSound,
      scrub,
      skip15,
      skipTo,
      toggleSound,
      pauseSound,
      playSound,
      toggleSize,
      initStepSound,
    },
  }

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  )
}
