import React from 'react'
import { View, Text } from 'react-native'
import { object, string } from 'prop-types'

import Style from './Style'

export function MiniCard({ icon, title, highlightedText }) {
  return (
    <View style={Style.miniCardContainer}>
      {icon && icon}
      <Text style={Style.titleStyle}>{title}</Text>
      <Text style={Style.highlightedText}>{highlightedText}</Text>
    </View>
  )
}

MiniCard.propTypes = {
  icon: object.isRequired,
  title: string.isRequired,
  highlightedText: string.isRequired,
}
