/* eslint-disable camelcase */
import React, { createContext, useEffect, useReducer } from 'react'
import { analytics, firestore, functions, crashlytics } from 'config/firebase'
import cyclesMatrix, {
  TCycle,
  TCycleStep,
  TCycleStepType,
} from 'state/cyclesMatrix'
import { Config } from 'config/config'
import { DISPATCH, FIREBASE_COLLECTIONS } from 'consts'
import { calcScores } from 'utils/scores'
import moment from 'moment'
import { profileHasSubscriptionOverride } from 'utils/profileHasSubscriptionOverride'
import { whichTrackStep } from 'utils/tracks'
import { usePlayer } from 'hooks/usePlayer'
import { EnumCycleStepType } from 'state/cyclesMatrix'

export const CyclesContext = createContext({})
const firebaseQuestionnaires = 'questionnaires'

export type TCycleState = {
  cycleHistory: any
  lastCompletedDay: Date | null
  questionnaires: any
  answers: any[]
  currentQuestion: number
  cycle: number
  day: number
  loaded: boolean
  progress: number
  questions: any[]
  scores: any[]
  steps: TCycle
  step: TCycleStep
  track: number
  type: TCycleStepType | null
  isOpen: boolean
}

const initialState: TCycleState = {
  cycleHistory: null,
  lastCompletedDay: null,
  questionnaires: {},
  answers: [],
  currentQuestion: 0,
  cycle: 0,
  day: 0,
  loaded: false,
  progress: 0,
  questions: [],
  scores: [],
  steps: cyclesMatrix[0],
  step: cyclesMatrix[0][0][0],
  track: 0,
  type: null,
  isOpen: false,
}

const cyclesGatherUpdate = async ({
  cycle,
  gatherInfo,
  userUid,
  dispatch,
}: any) => {
  // save to firestore
  await cycleUpsert({
    cycle,
    data: {
      gatherInfo,
    },
    userUid,
  })

  dispatch({
    gatherInfo,
    type: DISPATCH.CYCLES.GATHER_UPDATE,
  })
}

const checkIfAnswersExist = (day: number, questionnaires: any[]) => {
  if (questionnaires[day]) {
    return true
  }
  return false
}

const cycleQuestionsDone = async ({
  answers,
  cycle,
  dispatch,
  userUid,
  day,
  questionnaires,
}: any) => {
  const scores = calcScores(answers)

  await saveQuestionnaire({
    cycle,
    day,
    data: {
      answers,
      scores,
    },
    userUid,
  })
  dispatch({
    data: {
      questionnaires: {
        ...questionnaires,
        [day]: {
          answers,
          scores,
          uid: userUid,
        },
      },
    },
    type: DISPATCH.CYCLES.QUESTIONS_DONE,
  })
}

function cyclesReducer(state: TCycleState, action: any) {
  switch (action.type) {
    case DISPATCH.CYCLES.ACTIVE_REACH_COMPLETED:
      return {
        ...state,
        activeReachPop: false,
      }
    case DISPATCH.CYCLES.LOAD: {
      const day = Math.max(action.data.day, 0)
      let track = Math.max(
        Math.min(action.data.track || 0, state.steps[day].length - 1),
        0
      )
      const step = state.steps[day] && state.steps[day][track]
      let type = step && step.type
      if (!type) {
        track = 0
        type = step.type
      }
      return {
        ...state,
        ...action.data,
        loaded: true,
        showVideoModal: shouldShowVideoModal(state, {
          ...state,
          ...action.data,
        }),
        track,
        type,
        step,
        day,
      }
    }
    case DISPATCH.CYCLES.NEXT_TRACK: {
      analytics().logEvent(
        `cycle_${state.cycle}_day_${state.day}_step_${state.track}`
      )
      return {
        ...state,
        showVideoModal: shouldShowVideoModal(state, action.data),
        ...action.data,
      }
    }
    case DISPATCH.CYCLES.GATHER_UPDATE: {
      return {
        ...state,
        gatherInfo: action.gatherInfo,
        showGatherModal: false,
      }
    }
    case DISPATCH.CYCLES.QUESTIONS_DONE: {
      return {
        ...state,
        ...action.data,
        questionnairePop: false,
        questionnaireCompleted: true,
      }
    }
    case DISPATCH.CYCLES.HIDE_VIDEO_MODAL: {
      return {
        ...state,
        showVideoModal: false,
      }
    }
    case DISPATCH.CYCLES.SHOW_VIDEO_MODAL: {
      return {
        ...state,
        showVideoModal: true,
      }
    }
    case DISPATCH.CYCLES.SET_SHOW_GATHER: {
      return {
        ...state,
        showGatherModal: action.showGatherModal,
      }
    }
    case DISPATCH.CYCLES.SET_SHOW_DAY_INFOCARD: {
      return {
        ...state,
        showDayInfoCard: action.showDayInfoCard,
      }
    }
    case DISPATCH.CYCLES.SET_QUESTIONNAIRES: {
      return {
        ...state,
        questionnaires: action.questionnaires,
        questionnairePop: action.questionnairePop,
      }
    }
    case DISPATCH.CYCLES.SET_QUESTIONNAIRES_POP: {
      return {
        ...state,
        questionnairePop: action.questionnairePop,
      }
    }
    case DISPATCH.CYCLES.SET_CYCLE_HISTORY: {
      return {
        ...state,
        cycleHistory: action.cycleHistory,
        lastCompletedDay: action.lastCompletedDay,
      }
    }
    case DISPATCH.CYCLES.SET_QUESTIONS: {
      return {
        ...state,
        questions: action.questions,
      }
    }
    case DISPATCH.CYCLES.SET_OPEN: {
      return {
        ...state,
        isOpen: action.isOpen,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
const getList = (day: string) => {
  if (day === '4day') {
    return 'Wca6ma'
  }
  if (day === '7day') {
    return 'WyM7uL'
  }
  if (day === '14day') {
    return 'TctNbB'
  }
  if (day === '21day') {
    return 'Yferju'
  }
  if (day === '22day') {
    return 'YbEqr4'
  }
  if (day === '32day') {
    return 'QNFRsM'
  }
  if (day === '42day') {
    return 'WVpz5j'
  }
  if (day === '52day') {
    return 'WFgxZG'
  }
  if (day === '63day') {
    return 'U38kV8'
  }
}

const addMember = async (day: string, user: any, profile: any) => {
  functions()
    .httpsCallable('proxyEmail')({
      uri: `v2/list/${getList(day)}/members`,
      body: {
        profiles: [
          {
            first_name: profile?.firstName,
            last_name: profile?.lastName,
            email: user.email,
          },
        ],
      },
    })
    .catch((err) => {
      crashlytics().recordError(err)
    })
}

const checkNotificationEmail = (
  cycle: any,
  day: number,
  track: number,
  user: any,
  profile: any
) => {
  // send email on day 4
  if (cycle === 0 && day === 3 && track === 0) {
    addMember('4day', user, profile) // the list will only accept same email once
  }
  // send email on day 7
  if (cycle === 0 && day === 6 && track === 0) {
    addMember('7day', user, profile) // the list will only accept same email once
  }
  // send email on day 14 - wonts add existing user
  if (cycle === 0 && day === 13 && track === 0) {
    addMember('14day', user, profile) // the list will only accept same email once
  }
  // send email on day 21 - wonts add existing user
  if (cycle === 0 && day === 20 && track === 0) {
    addMember('21day', user, profile) // the list will only accept same email once
  }

  // send email on day 22 - wonts add existing user
  if (cycle === 0 && day === 21 && track === 0) {
    addMember('22day', user, profile) // the list will only accept same email once
  }
  // send email on day 32 - wonts add existing user
  if (cycle === 0 && day === 31 && track === 0) {
    addMember('32day', user, profile) // the list will only accept same email once
  }
  // send email on day 42 - wonts add existing user
  if (cycle === 0 && day === 41 && track === 0) {
    addMember('42day', user, profile) // the list will only accept same email once
  }
  // send email on day 52 - wonts add existing user
  if (cycle === 0 && day === 51 && track === 0) {
    addMember('52day', user, profile) // the list will only accept same email once
  }
  // send email on day 63 - wonts add existing user
  if (cycle === 0 && day === 62 && track === 0) {
    addMember('63day', user, profile) // the list will only accept same email once
  }
}

const cycleNew = async ({
  dispatch,
  state,
  user,
  reset,
}: {
  dispatch: (data: any) => void
  state: any
  user: any
  reset: boolean
}) => {
  const cycle = reset ? state.cycle : state.cycle + 1
  const day = 0
  const track = 0
  await cycleUpsert({
    cycle,
    data: {
      cycle,
      day,
      progress: 0,
      track,
      gatherInfo: [],
    },
    userUid: user.uid,
  })
  if (reset) {
    await deleteCycleQuestionnaires(user.uid, state.cycle)
  }
  dispatch({
    data: {
      day,
      track,
      cycle,
      progress: 0,
    },
    type: DISPATCH.CYCLES.LOAD,
  })
}

const setShowDayInfoCard = (
  showDayInfoCard: boolean,
  dispatch: (data: any) => void
) => {
  dispatch({
    showDayInfoCard,
    type: DISPATCH.CYCLES.SET_SHOW_DAY_INFOCARD,
  })
}

const cycleTrack = async ({
  checkIfShouldShowSubscriptionModal,
  direction,
  dispatch,
  loadSound,
  playerState,
  state,
  user,
  profile,
  dismissPlayer,
  dayComplete,
}: {
  checkIfShouldShowSubscriptionModal: (nextState: any, user: any) => boolean
  direction: 'next' | 'prev'
  dispatch: (data: any) => void
  loadSound: (data: any) => void
  playerState: any
  state: any
  user: any
  profile: any
  dismissPlayer: () => void
  dayComplete: boolean
}) => {
  const nextState = whichTrackStep(state, direction, dayComplete)
  // TODO sometimes missing the uid

  // Check everytime, latestDayCompleted.day - nextState.day = 1
  if (checkIfShouldShowSubscriptionModal(nextState, user)) {
    return
  }

  if (direction === 'next') {
    let _latestDayCompleted = state?.cycleHistory?.latestDayCompleted
    let latestDayCompleted = state.day
    const _dayComplete =
      state.day < 21 ? state.track === state.steps[state.day].length - 1 : true
    if (_latestDayCompleted > state.day) {
      latestDayCompleted = _latestDayCompleted
    }

    updateHistory({
      user,
      // NOTE: day will always be the PRIOR day until the last step in the current day is complete
      day: state.day,
      // NOTE: dayComplete helps us track if the day has been completed
      // because the updateHistory is called every single time.
      // and we need to know when it was actually completed to allow us to stop them from
      // going further
      dayComplete: _dayComplete,
      latestDayCompleted: latestDayCompleted,
      cycle: nextState.cycle,
    })
  } else {
    updateHistory({
      user,
      day: state.day,
      dayComplete: false,
      latestDayCompleted: state?.cycleHistory?.latestDayCompleted || '',
      cycle: nextState.cycle,
    })
  }
  getCyclesHistory(user.uid, dispatch)

  const baseUrl =
    'https://storage.googleapis.com/neurocycle.appspot.com/cycle_clips_min/day-'
  const { cycle, track, day } = nextState
  const { steps } = initialState
  const cycleTrackId = `cycle-${cycle}-day-${day}-track-${track}`

  checkNotificationEmail(state?.cycle, state?.day, state?.track, user, profile)
  cycleUpsert({
    cycle,
    data: {
      cycle: nextState.cycle,
      day: nextState.day,
      progress: nextState.progress,
      track: nextState.track,
    },
    userUid: user.uid,
  })
  const step =
    state.steps[nextState.day] && state.steps[nextState.day][nextState.track]
  dispatch({
    type: DISPATCH.CYCLES.NEXT_TRACK,
    data: {
      ...nextState,
      step,
      isOpen: true,
    },
  })

  if (day >= 21) {
    await dismissPlayer()
  }
  return { state, nextState }
}

const cycleUpsert = async ({ cycle, data, userUid }: any) => {
  return await firestore()
    .collection(FIREBASE_COLLECTIONS.CYCLES)
    .doc(`${userUid}-${cycle}`)
    .set(
      {
        ...data,
        cycle,
        uid: userUid,
      },
      {
        merge: true,
      }
    )
}

const saveQuestionnaire = async ({ cycle, data, userUid, day }: any) => {
  return await firestore()
    .collection(firebaseQuestionnaires)
    .doc()
    .set(
      {
        ...data,
        cycle,
        day,
        uid: userUid,
        createdAt: firestore.FieldValue.serverTimestamp(),
      },
      {
        merge: true,
      }
    )
}

const deleteCycleQuestionnaires = async (userId: string, cycle: number) => {
  const batch = firestore().batch()
  return await firestore()
    .collection(firebaseQuestionnaires)
    .where('uid', '==', userId)
    .where('cycle', '==', cycle)
    .get()
    .then(async (snapshot) => {
      if (!snapshot.empty) {
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref)
        })
        await batch.commit()
      }
    })
}

const hideGather = ({ dispatch }: { dispatch: (data: any) => void }) => {
  dispatch({
    type: DISPATCH.CYCLES.SET_SHOW_GATHER,
    showGatherModal: false,
  })
}

const setQuestions = (dispatch: (data: any) => void, value: any) => {
  dispatch({
    type: DISPATCH.CYCLES.SET_QUESTIONS,
    questions: value,
  })
}

const shouldShowVideoModal = (previousState: any, nextState: any) => {
  if (previousState.day === 21 && nextState.track === 0) {
    return false
  }

  return (
    (nextState.day === 0 && nextState.track === 1) ||
    ([5].includes(nextState.day) && nextState.track === 5)
  )
}

const syncQuestionnaires = (questionnaires: any) => {
  const accumulator: any = {}
  questionnaires.forEach((q: any) => {
    accumulator[q.day] = q
  })
  return accumulator
}

async function updateHistory({
  day,
  dayComplete,
  cycle,
  user,
  latestDayCompleted,
}: any) {
  if (!user?.uid) {
    throw new Error('User missing')
  }
  try {
    const historyRef = firestore()
      .collection(FIREBASE_COLLECTIONS.HISTORY_CYCLE)
      .doc()
    await historyRef.set({
      uid: user?.uid,
      day,
      dayComplete,
      cycle,
      latestDayCompleted,
      createdAt: firestore.FieldValue.serverTimestamp(),
    })
  } catch (error: any) {
    throw new Error(error)
  }
}

export async function getCyclesHistory(
  userUid: string,
  dispatch: (data: any) => void
) {
  return await firestore()
    .collection(FIREBASE_COLLECTIONS.HISTORY_CYCLE)
    .where('uid', '==', userUid)
    .orderBy('createdAt')
    .get()
    .then((snapShot) => {
      if (!snapShot.empty) {
        const cycleHistory = snapShot.docs.pop()!.data()
        const cycleDate = moment(
          cycleHistory.createdAt ? cycleHistory.createdAt.toDate() : new Date()
        ).format()
        dispatch({
          type: DISPATCH.CYCLES.SET_CYCLE_HISTORY,
          lastCompletedDay: cycleDate,
          cycleHistory: cycleHistory,
        })
      }
      return 0
    })
}

const useCyclesLoad = (dispatch: (data: any) => void, user: any) => {
  useEffect(() => {
    async function getQuestionnaires(cycle: string) {
      return await firestore()
        .collection(firebaseQuestionnaires)
        .where('uid', '==', user.uid)
        .where('cycle', '==', cycle)
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            const val = snapShot.docs.map((elem) => {
              return {
                ...elem.data(),
                id: elem.id,
              }
            })
            const syncedVals = syncQuestionnaires(val)
            dispatch({
              type: DISPATCH.CYCLES.SET_QUESTIONNAIRES,
              questionnaires: syncedVals,
              questionnairePop: false,
            })
          }
          return []
        })
    }

    async function getCycles() {
      return await firestore()
        .collection(FIREBASE_COLLECTIONS.CYCLES)
        .orderBy('cycle', 'desc')
        .where('uid', '==', user.uid)
        .limit(1)
        .get()
        .then((snapShot) => {
          if (!snapShot.empty) {
            const latestData = snapShot.docs.pop()!.data()
            dispatch({
              data: latestData,
              type: DISPATCH.CYCLES.LOAD,
            })
            return latestData.cycle
          } else {
            const cycle = 0
            const day = 0
            const track = 0
            dispatch({
              data: {
                day,
                track,
                cycle,
                progress: 0,
              },
              type: DISPATCH.CYCLES.LOAD,
            })
          }
          return 0
        })
        .then((cycle) => {
          getQuestionnaires(cycle)
        })
    }
    if (user.uid) {
      const cycle = getCycles()
      const history = getCyclesHistory(user.uid, dispatch)
    }
  }, [dispatch, user.uid])
}

export function CyclesProvider({ children, user }: any) {
  const [state, dispatch] = useReducer(cyclesReducer, initialState)
  const { state: playerState, dispatch: playerDispatch } = usePlayer()
  const markActiveReachAsCompleted = () => {
    dispatch({ type: DISPATCH.CYCLES.ACTIVE_REACH_COMPLETED })
  }
  const hideVideoModal = (dismissPlayer: any, stateVal: any) => {
    dispatch({ type: DISPATCH.CYCLES.HIDE_VIDEO_MODAL })
    if (stateVal.day === 0 && stateVal.track === 0) {
      dismissPlayer()
    }
  }

  const setOpen = (
    isOpen: boolean,
    dispatch: (data: any) => void,
    size: 'full' | 'small' = 'full'
  ) => {
    dispatch({
      isOpen,
      type: DISPATCH.CYCLES.SET_OPEN,
    })
    if (state.type === EnumCycleStepType.AUDIO) {
      playerDispatch.toggleSize(size)
    }
  }

  const value = {
    dispatch: {
      cycleNew,
      deleteCycleQuestionnaires,
      setQuestions,
      cycleTrack,
      cycleQuestionsDone,
      dispatch,
      hideVideoModal,
      markActiveReachAsCompleted,
      hideGather,
      cyclesGatherUpdate,
      setShowDayInfoCard,
      setOpen,
    },
    state,
  }

  // load cycles for user
  useCyclesLoad(dispatch, user)

  return (
    <CyclesContext.Provider value={value}>{children}</CyclesContext.Provider>
  )
}
