import React, { useMemo } from 'react'
import { View } from 'native-base'
import { ResultsCard } from 'components/Cards/ResultsCard'
import { graphScoreSplit, graphScoreDecreasing } from 'utils/scores'
import { useCycles } from 'state/hooks/useCycles'
import { MiniCard } from 'components/Cards/MiniCard'
import { TextCard } from 'components/Cards/TextCard'
import { Calendar, Graph } from 'components/Icons'
import { SCORES_CONFIG, LMM_SECTIONS, FAQ_TYPES } from 'state/consts'
import { useQuery } from 'urql'

import Style from './Style'

export function ResultsSectionView({ section, overview = false }) {
  const { state: cyclesState } = useCycles()

  const query = `#graphql
    query {
      allScoreChanges(first: 100) {
          totalCount
          edges {
            node{
              _meta {
                id
              }
              title
              description
              category
              day
            }
            cursor
          }
        }
      }
    `

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {},
  })

  const cleanData = useMemo(() => {
    if (!result.data?.allScoreChanges?.edges) {
      return []
    }
    return [].concat(
      ...result.data?.allScoreChanges?.edges?.map((edge) => edge.node)
    )
  }, [result])

  const ELEM_OBJ = {}

  const formattedCell = cleanData.map((elem, i) => {
    const cell = {
      category: elem.category,
      title: elem.title,
      day: elem.day,
      description: elem.description[0].text,
    }

    ELEM_OBJ[elem.category.toLowerCase()] = Array.isArray(
      ELEM_OBJ[elem.category.toLowerCase()]
    )
      ? (ELEM_OBJ[elem.category.toLowerCase()] = [
          ...ELEM_OBJ[elem.category.toLowerCase()],
          cell,
        ])
      : [cell]
    return cell
  })

  const data = Object.keys(cyclesState.questionnaires || {})
    .sort()
    .map((key, id) => {
      const [score] = cyclesState.questionnaires[key].scores
      const { value, level, message } = [
        'TOXIC_THOUGHTS',
        'TOXIC_STRESS',
        'BARRIERS',
      ].includes(section)
        ? graphScoreDecreasing(
            SCORES_CONFIG[section],
            score?.scoreSums?.[section]
          )
        : graphScoreSplit(SCORES_CONFIG[section], score?.scoreSums?.[section])

      return {
        id,
        count: value,
        level,
        message,
      }
    })

  const formatOnChangeData = () => {
    const current = data[data.length - 1]
    const before = data[data.length - 2]
    if (current?.count > before?.count)
      return { direction: 'Change - Increased/Improved', current, before }
    if (current?.count < before?.count)
      return { direction: 'Change - Decreased/Worsen', current, before }
    if (current?.count === before?.count)
      return { direction: 'No Change - Stayed the Same', current, before }
  }
  const deltaData = data.length >= 2 ? formatOnChangeData() : null

  const getDay = (arr, day) => {
    let rightDay = 1
    if (day <= 22 <= 42) {
      rightDay = 21
    }
    if (day <= 43 < 63) {
      rightDay = 42
    }
    if (day === 63) {
      rightDay = 63
    }
    const val = arr.find((item) => {
      return item.day === rightDay && item.title === deltaData.direction
    })
    return val || null
  }

  const showChange = () => {
    return getDay(
      ELEM_OBJ[section.toLowerCase().replace('_', '')],
      cyclesState.day
    )
  }

  return (
    <View>
      {!overview ? (
        <View style={Style.headerContainer}>
          <MiniCard
            icon={<Calendar width={20} height={22} />}
            title={`LMM\n${data.length || 1} of 4`}
            highlightedText={`Cycle ${cyclesState?.cycle + 1 || 1}\nDay ${
              cyclesState?.day + 1 || 1
            }`}
          />
          {data[data.length - 1]?.level ? (
            <MiniCard
              icon={<Graph width={20} height={22} />}
              title={'Mind-Management Level'}
              highlightedText={data[data.length - 1]?.level}
            />
          ) : null}
        </View>
      ) : null}
      <ResultsCard title={LMM_SECTIONS[section]} data={data} />
      {!overview && deltaData && showChange() && (
        <TextCard
          title={showChange().title}
          description={showChange().description}
        />
      )}
      {!overview ? (
        <TextCard
          title={LMM_SECTIONS[section]}
          description={data[data.length - 1]?.message}
        />
      ) : null}
    </View>
  )
}
