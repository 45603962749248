import Purchases, {
  PurchasesPackage,
  PurchasesOfferings,
} from 'react-native-purchases'
import axios from 'axios'
import _ from 'lodash'
import { Config } from 'config/config'
import { functions } from 'config/firebase'

export * from 'react-native-purchases'

const client = axios.create({
  baseURL: 'https://api.revenuecat.com/v1',
  headers: {
    Authorization: `Bearer strp_xisenclgndkRryvTHyHebpeXopk`,
    'X-Platform': 'stripe',
  },
})

let appUserID = '',
  appUserEmail = '',
  stripecheckid = ''

export type Package = {
  identifier?: '$rc_annual' | '$rc_three_month' | '$rc_monthly'
  platform_product_identifier: string
}

type PackageOfferings = {
  [string: string]: any
}

const kebabize = (str: string) => {
  return str
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter
        ? `${idx !== 0 ? '_' : ''}${letter.toLowerCase()}`
        : letter
    })
    .join('')
}

export default {
  setDebugLogsEnabled(enabled: Boolean) {},
  configure(props: { apiKey: string; appUserID: string }) {
    appUserID = props.appUserID
  },
  setEmail(email: string) {
    appUserEmail = email
  },
  setDisplayName(name: string) {},
  async syncPurchases() {
    const { data } = await client.get(`/subscribers/${appUserID}/receipts`)
    return data.receipts
  },
  async getCustomerInfo() {
    const { data } = await client.get(`/subscribers/${appUserID}`)
    if (data.subscriber.entitlements.premium) {
      data.subscriber.entitlements.active = {
        premium: {
          ...data.subscriber.entitlements.premium,
          productIdentifier:
            data.subscriber.entitlements.premium.product_identifier,
        },
      }
    }
    return data.subscriber
  },
  async createReceipt(stripecheckid: string) {
    await client.post(`/receipts`, {
      app_user_id: appUserID,
      fetch_token: stripecheckid,
    })
  },
  async getOfferings(): Promise<PurchasesOfferings> {
    const { data } = await client.get(`/subscribers/${appUserID}/offerings`)
    const keys: {
      [key: string]: string
    } = {
      $rc_annual: 'annual',
      $rc_three_month: 'threeMonth',
      $rc_monthly: 'monthly',
    }
    const currentOffering = _(data.offerings)
      .filter((o: { identifier: string }) => o.identifier === 'default')
      .first()
    const allOfferings = _(data.offerings).filter(
      (o: { identifier: string }) => o.identifier !== 'default'
    )

    function reducePackages(identifier: string, packages: PurchasesPackage[]) {
      return packages.reduce((accum: any, p) => {
        accum[keys[p.identifier]] = {
          ...p,
          packageType: kebabize(keys[p.identifier]).toUpperCase(),
          product: {
            productIdentifier: (p as any).platform_product_identifier,
            priceString: (Config.WEB_PRICES as any)[
              `${identifier}.${keys[p.identifier]}`
            ],
          },
        }
        return accum
      }, {})
    }

    return {
      current: reducePackages('default', currentOffering.packages),
      all: _(allOfferings).reduce((accum: any, o: any) => {
        accum[o.identifier] = {
          identifier: o.identifier,
          ...reducePackages(o.identifier, o.packages),
        }
        return accum
      }, {}),
    }
  },
  async purchasePackage(product: PurchasesPackage): Promise<any> {
    const response: { data: { id: string; url: string } } =
      await functions().httpsCallable('createCheckoutUrl')({
        priceId: `${(product as any).platform_product_identifier}`,
        email: `${encodeURIComponent(appUserEmail)}`,
      })
    window.localStorage.setItem('stripecheckid', `${response.data.id}`)
    return (window.location.href = response.data.url)
  },
  restorePurchases() {},
}
