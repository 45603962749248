import * as StoreReview from 'expo-store-review'
import { Alert, Platform } from 'react-native'
import { firestore, crashlytics } from 'config/firebase'
import { useAuth } from 'hooks/useAuth'
import { useState } from 'react'
import { useToast } from 'native-base'

function useStoreReview() {
  const toast = useToast()
  const {
    dispatch: { setProfile },
    state: { user, profile },
  } = useAuth()
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean
    message: string | undefined
  }>({ isOpen: false, message: undefined })

  const initStoreReview = async () => {
    if (await StoreReview.hasAction()) {
      StoreReview.requestReview()
    }
  }

  async function saveMessage() {
    await firestore()
      .collection('messages')
      .add({
        email: user?.email || '',
        fullName: user?.displayName || '',
        message: dialogState.message,
        received: firestore.FieldValue.serverTimestamp(),
        authEmail: user?.email,
        uid: user?.uid,
      })
      .then((res) => res.id)
      .catch((err) => {
        throw new Error(err)
      })
  }

  async function submitFeedback() {
    if (!dialogState.message) return
    crashlytics().log('Starting feedback')
    try {
      saveMessage()
      toast.show({
        placement: 'top',
        status: 'success',
        duration: 2900,
        description: 'Message Sent!',
        onCloseComplete: () =>
          setDialogState({ isOpen: false, message: undefined }),
      })
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      crashlytics().recordError(err)
    }
  }

  async function handleReview(day: number) {
    const appConfig = await firestore()
      .collection('app-config')
      .doc(Platform.OS === 'ios' ? 'ios' : 'android')
      .get()
    if (!appConfig.data()?.gatherReviews) {
      return
    }
    try {
      const seenReview =
        day === 20 ? profile?.seenReviewDay21 : profile?.seenReviewDay63
      if (!seenReview) {
        Alert.alert('Are you enjoying the app?', '', [
          {
            text: 'Yes',
            onPress: () => initStoreReview(),
          },
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'No',
            onPress: () => setDialogState({ ...dialogState, isOpen: true }),
          },
        ])
        day === 20
          ? setProfile(user, { ...profile, seenReviewDay21: true })
          : setProfile(user, { ...profile, seenReviewDay63: true })
        await firestore()
          .collection('users')
          .doc(user.uid)
          .set(
            {
              seenReview: true,
              uid: user.uid,
            },
            { merge: true }
          )
          .catch((err) => {
            toast.show({
              placement: 'top',
              status: 'error',
              duration: 4900,
              description: 'Error saving review',
            })
            crashlytics().recordError(err)
          })
      }
    } catch (error) {
      throw new Error(`${error}`)
    }
  }

  return {
    dialogState,
    setDialogState,
    submitFeedback,
    handleReview,
  }
}

export default useStoreReview
