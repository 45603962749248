import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import { Loading } from 'components/Loading'
import { useQuery } from 'urql'

export function TermsAndConditions() {
  const TANDCS = lazy(() => import('components/TermsAndConditions'))
  const query = `#graphql
    query {
      allTermss {
        edges {
          node{
            _meta {
              id
            }
            title
            body
          }
        }
      }
    }
  `

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {
      day: 1,
    },
  })

  const formattedData = useMemo(() => {
    if (!result.data?.allTermss?.edges) {
      return []
    }

    return result.data?.allTermss?.edges?.map((edge) => edge.node) || []
  }, [result])

  return (
    <Suspense fallback={<Loading />}>
      <TANDCS title={formattedData[0]?.title} body={formattedData[0]?.body} />
    </Suspense>
  )
}
