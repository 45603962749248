import React from 'react'
import { ResultsSectionView } from 'screens/Results/ResultsSectionView'
import { QUESTION_FACTORS } from 'state/consts'

export function AwarenessView({ overview = false }) {
  return (
    <ResultsSectionView
      overview={overview}
      section={QUESTION_FACTORS.AWARENESS}
    />
  )
}
