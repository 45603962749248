import React from 'react'
import 'react-native-gesture-handler'
import { LogBox } from 'react-native'
import { registerRootComponent } from 'expo'
import { useFonts } from 'expo-font'
import { Box, NativeBaseProvider } from 'native-base'
import { NavigationContainer } from '@react-navigation/native'
import { theme } from 'config/theme'
import { fontsConfig } from 'config/fonts'
import { AuthProvider } from 'state/providers/AuthProvider'
import { PurchaseProvider } from 'state/providers/PurchaseProvider'
import { Loading } from 'components/Loading'
import { routeNames } from 'state/routeNames'
import { Provider as UrqlProvider } from 'urql'

import { client } from './src/urqlClient'
import { navigationRef } from '/RootNavigation'
import { App } from './src/App'
import { MainLandingProvider } from './src/state/providers/MainLandingProvider'
import * as Linking from 'expo-linking'

// Comment me during dev
LogBox.ignoreAllLogs()
const prefix = Linking.createURL('/')

const Neurocycle = () => {
  const [fontsLoaded] = useFonts(fontsConfig)
  const config = {
    screens: {
      [routeNames.NOTIFICATION]: routeNames.NOTIFICATION + '/:reminderId',
      DecompressionTips: { exact: true, path: 'DecompressionTips' },
      Home: {
        exact: true,
        path: 'Home',
      },
      Results: {
        exact: true,
        path: 'Results',
      },
      Neurolive: {
        exact: true,
        path: 'Neurolive',
      },
      Resources: {
        exact: true,
        path: 'Resources',
      },
      Account: {
        exact: true,
        path: 'Account',
      },
      AccountLanding: {
        exact: true,
        path: 'AccountLanding',
      },
      AccountSubscription: {
        exact: true,
        path: 'AccountSubscription',
      },
      AccountSubscriptionSuccess: {
        path: 'AccountSubscriptionSuccess',
        exact: true,
      },
      AccountSubscriptionCancel: {
        path: 'AccountSubscriptionCancel',
        exact: true,
      },
      AccountProfile: {
        exact: true,
        path: 'AccountProfile',
      },
      AccountResults: {
        exact: true,
        path: 'AccountResults',
      },
      MiniCycles: {
        exact: true,
        path: 'MiniCycles',
      },
      ParentNeurocycles: {
        exact: true,
        path: 'ParentNeurocycles',
      },
      HistoryNavigator: {
        exact: true,
        path: 'History',
      },
      ProfileInfo: {
        exact: true,
        path: 'ProfileInfo',
      },
    },
  }

  const linking = {
    prefixes: [prefix],
    config,
  }
  return (
    <NavigationContainer
      documentTitle={{
        formatter: (options, route) =>
          typeof route !== 'undefined'
            ? `${options?.title ?? route?.name} - NeuroCycle`
            : 'Home - NeuroCycle',
      }}
      linking={linking}
      fallback={<Loading />}
      ref={navigationRef}
    >
      <NativeBaseProvider theme={theme}>
        {!fontsLoaded ? (
          <Loading />
        ) : (
          <UrqlProvider value={client}>
            <AuthProvider>
              <PurchaseProvider>
                <Box
                  style={{
                    height: '100%',
                    overflowY: 'hidden',
                  }}
                >
                  <MainLandingProvider>
                    <App />
                  </MainLandingProvider>
                </Box>
              </PurchaseProvider>
            </AuthProvider>
          </UrqlProvider>
        )}
      </NativeBaseProvider>
    </NavigationContainer>
  )
}

registerRootComponent(Neurocycle)
