import React, { useEffect, useState, useMemo } from 'react'
import { Box } from 'native-base'
import { PageContainer } from 'components/Containers/Page'
import { ResultsHeading } from 'components/Headings'
import { OverviewView } from 'screens/Results/OverviewView'
import { useCycles } from 'state/hooks/useCycles'
import { PromptCard } from 'components/Cards/PromptCard'
import {
  AwarenessView,
  AutonomyView,
  BarriersView,
  EmpowermentView,
  ToxicThoughtsView,
  ToxicStressView,
} from 'screens/Results/views'
import { AppTabs } from 'components/Tabs'
import { useAuth } from 'state/hooks/useAuth'
import { usePurchases } from 'state/hooks/usePurchases'

const tabs = [
  { id: 0, name: 'Overview', component: OverviewView },
  { id: 1, name: 'Autonomy', component: AutonomyView },
  { id: 2, name: 'Awareness', component: AwarenessView },
  { id: 3, name: 'Toxic Thoughts', component: ToxicThoughtsView },
  { id: 4, name: 'Toxic Stress', component: ToxicStressView },
  { id: 5, name: 'Barriers', component: BarriersView },
  { id: 6, name: 'Empowerment', component: EmpowermentView },
]

function ResultsScreen() {
  const { state: cyclesState } = useCycles()
  const {
    dispatch: { setShowSubscription },
    state: { user, profile, subscriberBool, expired },
  } = useAuth()
  const {
    dispatch: { setAvailableProducts },
    state,
  } = usePurchases()

  const cycleTitle =
    cyclesState?.cycle >= 0 &&
    Object.keys(cyclesState?.questionnaires).length > 0
      ? cyclesState?.cycle + 1
      : false

  return (
    <PageContainer scheme="willowbrook">
      <Box style={{ width: '100%' }}>
        <ResultsHeading
          title="Leaf Mind-Management™‌ Scale"
          cycle={cycleTitle}
        />
        {Object.keys(cyclesState?.questionnaires).length < 1 ? (
          <PromptCard />
        ) : (
          <AppTabs initialTab={0} data={tabs} />
        )}
      </Box>
    </PageContainer>
  )
}

export default ResultsScreen
