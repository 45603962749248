import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  mainHeader: {
    fontSize: 32,
    color: '#021d3d',
    paddingTop: 39,
    paddingBottom: 29,
    lineHeight: 32,
    fontWeight: 'bold',
  },
})
