import React, { useEffect, useState } from 'react'
import { Box, Button, Text, useTheme, useToast } from 'native-base'
import { AppForm } from 'components/Form'
import { AppToast } from 'components/Toast'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { REGISTER_ERRORS } from 'state/consts'
import { firestore } from 'config/firebase'
import moment from 'moment'
import { profileInfoConfig } from 'screens/Account/Profile/config/profileInfo'
import set from 'lodash/set'
import { useAuth } from 'state/hooks/useAuth'
import { useNavigation } from '@react-navigation/native'
import { PageContainer } from 'components/Containers/Page'

const ProfileInfo = () => {
  const { colors } = useTheme()
  const toast = useToast()
  const [error, setError] = useState(null)
  const {
    dispatch: { loadProfile, setProfile },
    state: { user, profile },
  } = useAuth()
  const [userProfile, setUserProfile] = useState(profile)
  const [config, setConfig] = useState(
    set(profileInfoConfig, 'initialValues', {
      ...{
        age: '',
        country: '',
        dob: '',
        email: '',
        ethnicity: '',
        firstName: '',
        gender: '',
        lastName: '',
        reason: '',
        cfid: '',
        rcfid: '',
        orgid: '',
        autoplay: true,
      },
      ...profile,
    })
  )

  const navigation = useNavigation()

  const style = {
    justifyContent: 'flex-start',
    fontColor: colors.tangaroa[500],
    borderColor: colors.tangaroa[500],
  }

  useEffect(() => {
    function getProfileSettings(_profile) {
      return {
        ..._profile.settings,
        autoplay:
          typeof _profile.settings === 'object'
            ? _profile.settings.autoplay
            : true,
      }
    }
    async function getUserProfile() {
      try {
        const userProfileFresh = await loadProfile(user)
        if (userProfileFresh) {
          const data = userProfileFresh
          setConfig((c) => ({
            ...c,
            initialValues: {
              age: data.age || '',
              country: data.country || '',
              dob: data.dob || '',
              email: user.email || '',
              ethnicity: data.ethnicity,
              firstName: data.firstName || '',
              gender: data.gender || '',
              lastName: data.lastName || '',
              reason: data.reason || '',
              cfid: data.cfid || '',
              rcfid: data.rcfid || '',
              orgid: data.orgid || '',
              ...getProfileSettings(data),
            },
          }))
          setUserProfile(userProfileFresh)
        }
      } catch (err) {
        throw new Error(`${err}`)
      }
    }
    getUserProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function saveToFirebase(data) {
    if (!data.ethnicity) data.ethnicity = 'other'
    function formatData() {
      const _data = data
      _data.settings = {
        autoplay: data?.autoplay ? data?.autoplay : false,
      }
      delete _data.autoplay
      if (data.cfid !== profile.cfid) {
        data.cfidUpdatedAt = firestore.FieldValue.serverTimestamp()
      }
      if (data.rcfid !== profile.rcfid) {
        data.rcfidUpdatedAt = firestore.FieldValue.serverTimestamp()
      }
      if (data.orgid !== profile.orgid) {
        data.orgidUpdatedAt = firestore.FieldValue.serverTimestamp()
      }
      return _data
    }
    firestore()
      .collection('users')
      .doc(user.uid)
      .set(
        {
          ...formatData(),
          uid: user?.uid,
          received: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      .then((res) => {
        setProfile(user, {
          ...userProfile,
          ...data,
        })
        res.id
      })
      .catch((err) => {
        setError(err.message)
      })
    toast.show({
      placement: 'top',
      status: 'success',
      duration: 4900,
      render: () => <AppToast status="success" message={'Updated Profile!'} />,
    })
  }

  set(config, 'actions.submit.handle', (values, { setSubmitting }) => {
    if (moment().diff(values.dob, 'years') < 13) {
      setSubmitting(false)
      toast.show({
        placement: 'top',
        status: 'error',
        duration: 4900,
        render: () => (
          <AppToast
            status="error"
            message={REGISTER_ERRORS['auth/age-limit']}
          />
        ),
      })
    } else {
      saveToFirebase(values)
    }
  })

  const changePassword = () => {
    navigation.navigate('ChangePassword')
  }
  return (
    <KeyboardAwareScrollView>
      <PageContainer>
        <Box pt={6} width="100%">
          <Text fontSize="2xl">{user.email}</Text>
        </Box>
        <AppForm
          config={config}
          haveError={error}
          style={style}
          noBottomSpacer={true}
          noKeyboardAvoidingView
          noFooter
        />
        <Button
          my={5}
          onPress={changePassword}
          upperCase={true}
          variant="secondary"
          border={0}
          width="100%"
        >
          CHANGE PASSWORD
        </Button>
      </PageContainer>
    </KeyboardAwareScrollView>
  )
}
export default ProfileInfo
