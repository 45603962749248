import React, { useState } from 'react'
import { arrayOf, shape, number, func } from 'prop-types'
import { Box, Tabs, ScrollView, Text } from 'native-base'
import { Pressable } from 'react-native'

import map from 'lodash/map'

export function AppTabs({ data, initialTab }) {
  const [view, setView] = useState(initialTab)
  const Component = data[view]?.component || null
  return (
    <Box width="100%">
      <ScrollView horizontal={true}>
        <Tabs index={view} width="100%" justifyContent="center">
          <Tabs.Bar align="center">
            {map(data, ({ id, name }) => (
              <Tabs.Tab
                onClick={() => {
                  setView(id)
                }}
                key={id}
              >
                <Text
                  variant={
                    view === id ? 'text-tabHeadingActive' : 'text-tabHeading'
                  }
                >
                  {name}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.Bar>
        </Tabs>
      </ScrollView>

      <Component />
    </Box>
  )
}

AppTabs.defaultProps = {
  initialTab: 0,
}

AppTabs.propTypes = {
  data: arrayOf(
    shape({
      id: number.isRequired,
      initialTab: number,
      component: func.isRequired,
    })
  ),
}
