import React, {
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react'
import { PageHeading } from 'components/Headings'
import { Box, Text, Image } from 'native-base'
import { useQuery } from 'urql'
import PrismicCard from 'components/Cards/PrismicCard'
const drLeaf =
  'https://storage.googleapis.com/neurocycle.appspot.com/app_images/dr_leaf_caroline.png'

function AboutDrLeaf() {
  const query = `#graphql
    query {
      allAbouts {
        edges {
          node{
            _meta {
              id
            }
            title
            body
            image
          }
        }
      }
    }
  `

  const [result, reexecuteQuery] = useQuery({
    query,
    variables: {
      day: 1,
    },
  })

  const formattedData = useMemo(() => {
    if (!result.data?.allAbouts?.edges) {
      return []
    }
    return [].concat(...result.data?.allAbouts?.edges?.map((edge) => edge.node))
  }, [result])

  return (
    <PrismicCard
      title={formattedData[0]?.title}
      image={formattedData[0]?.image}
      body={formattedData[0]?.body}
    />
  )
}

export default AboutDrLeaf
