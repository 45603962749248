import React from 'react'
import { Icon } from 'native-base'
import { Path, G, Mask } from 'react-native-svg'
import { number, string } from 'prop-types'

export function Back({ width, height, stroke }) {
  return (
    <Icon
      width="11"
      height="18"
      viewBox="0 0 11 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Mask
        id="mask0_4_3"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="11"
        height="20"
      >
        <Path
          d="M10.5 15.885L4.01012 9L10.5 2.115L8.50202 0L0 9L8.50202 18L10.5 15.885Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask0_4_3)">
        <Path
          d="M10.5 15.885L4.01012 9L10.5 2.115L8.50202 0L0 9L8.50202 18L10.5 15.885Z"
          stroke={'white'}
          // fill={stroke}
        />
      </G>
    </Icon>
  )
}

Back.defaulProps = {
  width: 11,
  height: 18,
  stroke: '#021d3d',
}

Back.propTypes = {
  width: number,
  height: number,
  stroke: string,
}
