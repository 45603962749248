import React from 'react'
import { ICycleStepProps } from 'state/interfaces'

export interface Props extends ICycleStepProps {}

const AudioStep: React.FC<Props> = () => {
  return <React.Fragment />
}

export default AudioStep
