import { Box, Slider, Text } from 'native-base'
import React, { useMemo } from 'react'
import { convertMillisToTimeStamp } from 'utils/dateHelpers'

export interface PlayerSliderProps {
  timeMs: number
  duration: number
  scrub: (time: number) => void
  skipTo: (time: number) => void
}

export function PlayerSlider(props: PlayerSliderProps) {
  const duration = useMemo(() => props.duration || 100, [props.duration])
  const timeMs = useMemo(() => props.timeMs, [props.timeMs])

  return (
    <>
      <Box>
        <Slider
          defaultValue={0}
          minValue={0}
          maxValue={duration}
          onChange={(v) => {
            props.scrub(Math.floor(v))
          }}
          onChangeEnd={(v) => {
            props.skipTo(Math.floor(v))
          }}
          accessibilityLabel="track player"
          step={1}
          value={timeMs}
        >
          <Slider.Track>
            <Slider.FilledTrack style={{ backgroundColor: 'white' }} />
          </Slider.Track>
          <Slider.Thumb style={{ backgroundColor: 'white' }} />
        </Slider>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
        pt={2}
      >
        <Box>
          <Text variant="text-playerTimeStamp">
            {convertMillisToTimeStamp(timeMs)}
          </Text>
        </Box>
        <Box>
          <Text variant="text-playerTimeStamp">
            {convertMillisToTimeStamp(duration)}
          </Text>
        </Box>
      </Box>
    </>
  )
}
