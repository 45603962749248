import React, { Fragment, useState } from 'react'
import { Box, Button, Modal, ScrollView, Text, View } from 'native-base'
import { QuestionsProgress } from 'components/Cards/QuestionnaireCard/QuestionsProgress'
import { QuestionsHeader } from 'components/Cards/QuestionnaireCard/QuestionsHeader'
import { QuestionButton } from 'components/Cards/QuestionnaireCard/QuestionButton'
import { pushOrAppend } from 'utils/arrays'
import { DISPATCH } from 'state/consts'
import { ICycleStepProps } from 'state/interfaces'
import { usePurchases } from 'hooks/index'

interface Props extends ICycleStepProps {}

export function QuestionnaireCard(props: Props) {
  const { colors } = props.useTheme
  const {
    state: { user, profile },
  } = props.useAuth
  const {
    dispatch: {
      dispatch,
      cycleQuestionsDone,
      dispatch: cyclesDispatch,
      cycleTrack,
    },
    state,
  } = props.useCycles
  const {
    dispatch: { dismissPlayer, loadSound, toggleSound },
    state: playerState,
  } = props.usePlayer
  const {
    dispatch: { checkSubscriptionStatus },
  } = usePurchases()

  const [selected, setSelected] = useState(false)
  const [answers, setAnswers] = useState(
    state.questionnaires?.[state.day]?.answers || []
  )
  const [current, setCurrent] = useState(state?.currentQuestion)

  async function handleQuestionsDone() {
    setCurrent(0)
    cycleQuestionsDone({
      answers,
      cycle: state.cycle,
      dispatch: cyclesDispatch,
      userUid: user.uid,
      day: state.day,
      questionnaires: state?.questionnaires,
      checkSubscriptionStatus,
      direction: 'next',
      loadSound,
      playerState,
      state,
      user,
      profile,
      dismissPlayer,
    })
    setAnswers([])
    props.onNext()
  }

  function stepNext() {
    setAnswers(
      pushOrAppend(
        answers,
        { ...state?.questions[current], score: selected },
        'question',
        'score'
      )
    )
    setCurrent(current + 1)
    setSelected(false)
    if (current + 1 === state.questions.length) {
      handleQuestionsDone()
    }
  }

  interface QuestionButtonProps {
    selected: boolean
    action: any
    current: number
  }
  function primaryQuestionButtons(props: QuestionButtonProps) {
    return (
      <Fragment>
        <QuestionButton index={1} text="Rarely" {...props} />
        <QuestionButton index={2} text="Occasionally" {...props} />
        <QuestionButton index={3} text="Often" {...props} />
      </Fragment>
    )
  }

  function secondaryQuestionButtons(props: QuestionButtonProps) {
    return (
      <Fragment>
        <QuestionButton index={1} text="Strongly agree" {...props} />
        <QuestionButton index={2} text="Agree" {...props} />
        <QuestionButton index={3} text="Neither agree or disagree" {...props} />
        <QuestionButton index={4} text="Disagree" {...props} />
        <QuestionButton index={5} text="Strongly disagree" {...props} />
      </Fragment>
    )
  }

  return current < state?.questions.length ? (
    <Modal
      isOpen={true}
      style={{
        backgroundColor: colors.puertorico[500],
      }}
    >
      <Box
        mt={6}
        safeArea
        style={{ height: '100%', width: '100%', maxWidth: 1024 }}
        p={6}
      >
        <QuestionsHeader
          setPrevStep={props.onBack}
          setShowModal={props.onClose}
        />
        <QuestionsProgress current={current} max={state?.questions.length} />
        <ScrollView
          showsVerticalScrollIndicator={false}
          flex={1}
          persistentScrollbar={true}
        >
          <View>
            <Box flex={1} justifyContent="space-between">
              <Box mt={8}>
                <Text fontSize="4xl" mb={8} variant="player">
                  {state?.questions[current].question}
                </Text>
                {state?.questions[current].type !== 'SURVEY'
                  ? primaryQuestionButtons({
                      selected,
                      action: setSelected,
                      current,
                    })
                  : secondaryQuestionButtons({
                      selected,
                      action: setSelected,
                      current,
                    })}
              </Box>
            </Box>
          </View>
        </ScrollView>
        <View pt={6}>
          <Button
            disabled={!selected}
            onPress={stepNext}
            variant={!selected ? 'primaryDisabled' : 'primary'}
          >
            {current < state?.questions.length - 1
              ? 'NEXT QUESTION'
              : 'COMPLETE'}
          </Button>
        </View>
      </Box>
    </Modal>
  ) : null
}
