import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'

export function graphScoreSplit(config, score) {
  switch (true) {
    case score <= config[0][1]:
      return { value: 25, level: config[0][2], message: config[0][3] }

    case score >= config[1][0] && score <= config[1][1]:
      return { value: 50, level: config[1][2], message: config[1][3] }

    case score >= config[2][0] && score <= config[2][1]:
      return { value: 75, level: config[2][2], message: config[2][3] }

    case score >= config[3][0]:
      return { value: 100, level: config[3][2], message: config[3][3] }

    default:
      return { value: 0, level: false, message: false }
  }
}

export function graphScoreDecreasing(config, score) {
  switch (true) {
    case score <= config[0][1]:
      return { value: 100, level: config[0][2], message: config[0][3] }

    case score >= config[1][0] && score <= config[1][1]:
      return { value: 75, level: config[1][2], message: config[1][3] }

    case score >= config[2][0] && score <= config[2][1]:
      return { value: 50, level: config[2][2], message: config[2][3] }

    case score >= config[3][0]:
      return { value: 25, level: config[3][2], message: config[3][3] }

    default:
      return { value: 0, level: false, message: false }
  }
}

export function calcScores(data) {
  const out = []
  const grouped = data.length ? groupBy(data, (score) => score.type) : data
  const scoreSums = data.length
    ? reduce(
        data,
        (acc, curr) => {
          acc[curr.type] = acc[curr.type]
            ? (acc[curr.type] += curr.score)
            : curr.score
          return acc
        },
        {}
      )
    : data

  out.push({ grouped, scoreSums })
  return out
}
