import React from 'react'
import { node } from 'prop-types'
import { Box, ScrollView } from 'native-base'

export function FormContainer({ children, style }) {
  return (
    <ScrollView>
      <Box
        safeArea
        px={6}
        pb={10}
        pt={8}
        justifyContent="space-between"
        backgroundColor={style.bg}
      >
        {children}
      </Box>
    </ScrollView>
  )
}

FormContainer.propTypes = { children: node.isRequired }
