import React from 'react'
import { QuestionnaireCard } from 'components/Cards/QuestionnaireCard'
import { ICycleStepProps } from 'state/interfaces'

interface Props extends ICycleStepProps {}

const QuestionnaireStep: React.FC<Props> = (props) => {
  return <>{props.open && <QuestionnaireCard {...props} />}</>
}

export default QuestionnaireStep
