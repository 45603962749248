import React from 'react'
import { oneOf } from 'prop-types'
import { HStack, Button, Box } from 'native-base'
import map from 'lodash/map'
import { INPUT_TYPES } from 'state/consts'
import { AppFormControl as FormControl } from 'components/Form/Inputs/FormControl'

export function RadioInput({
  id,
  formik,
  label,
  required,
  style,
  items,
  inputProps = {},
}) {
  const { values, setFieldValue } = formik
  return (
    <FormControl
      id={id}
      formik={formik}
      required={required}
      label={label}
      style={style}
    >
      <HStack flexWrap="wrap" alignItems="center" mt={2}>
        {map(items, (item, i) => (
          <Box
            key={item.id}
            width="50%"
            pl={i % 2 === 1 && 1}
            pr={i % 2 === 0 && 1}
            mb={2}
          >
            <Button
              variant={
                values[id] === item.value
                  ? 'activeReachSelected'
                  : 'activeReach'
              }
              onPress={(e) => {
                setFieldValue(id, item.value)
                if (typeof inputProps.onChange === 'function')
                  inputProps.onChange(item)
              }}
              width="100%"
            >
              {item.label}
            </Button>
          </Box>
        ))}
      </HStack>
    </FormControl>
  )
}

RadioInput.defaultProps = { type: 'text' }
RadioInput.propTypes = {
  type: oneOf([INPUT_TYPES.TEXT, INPUT_TYPES.PASSWORD, INPUT_TYPES.RADIO]),
}
