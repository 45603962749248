import React from 'react'
import { Icon } from 'native-base'
import { G, Polygon } from 'react-native-svg'

export function Tracking() {
  return (
    <Icon viewBox="0 0 24 24">
      <G id="Rd2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G id="Results" transform="translate(-218.000000, -199.000000)">
          <G
            id="show_chart_black_24dp"
            transform="translate(199.000000, 174.000000)"
            filter="url(#filter-1)"
          >
            <G transform="translate(19.000000, 25.000000)">
              <Polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <Polygon
                id="Path"
                fill="#000000"
                fillRule="nonzero"
                points="3.5 18.49 9.5 12.48 13.5 16.48 22 6.92 20.59 5.51 13.5 13.48 9.5 9.48 2 16.99"
              />
            </G>
          </G>
        </G>
      </G>
    </Icon>
  )
}
