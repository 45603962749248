import React, { useMemo } from 'react'
import DailyInfo from 'components/Modal/DailyInfo'
import { ICycleStepProps } from 'state/interfaces'
import { RESOURCE_VIDEOS } from 'consts'

export interface Props extends ICycleStepProps {}

const DailyInfoStep: React.FC<Props> = (props: Props) => {
  return <>{<DailyInfo {...props} />}</>
}

export default DailyInfoStep
