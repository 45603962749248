import React from 'react'
import { Box } from 'native-base'
import { PageContainer } from 'components/Containers/Page'
import { PageHeading } from 'components/Headings'
import {
  BlogView,
  DownloadsView,
  GlossaryView,
  PodcastView,
  StoreView,
  YouTubeView,
  ResourcesView,
} from 'screens/Resources/views'
import { AppTabs } from 'components/Tabs'
import { useAuth } from 'state/hooks/useAuth'
import { Subscription } from 'components/Modal/Subscription'

const tabs = [
  { id: 0, name: 'Podcasts', component: PodcastView },
  { id: 1, name: 'YouTube', component: YouTubeView },
  { id: 2, name: 'Blog', component: BlogView },
  { id: 3, name: 'Glossary', component: GlossaryView },
  { id: 4, name: 'Downloads', component: DownloadsView },
  { id: 5, name: 'Online Store', component: StoreView },
  { id: 6, name: 'Videos', component: ResourcesView },
]

export function ResourcesScreen() {
  return (
    <PageContainer>
      <Box style={{ width: '100%', height: '100%' }}>
        <PageHeading title="Resources" />
        <AppTabs data={tabs} />
      </Box>
    </PageContainer>
  )
}
