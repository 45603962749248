import { object, string } from 'yup'
import { INPUT_TYPES, KEYBOARD_TYPES, TEXT_CONTENT_TYPES } from 'state/consts'
import { Button } from 'native-base'
import { TextInput } from 'components/Form/Inputs/TextInput'

export const loginConfig = {
  dark: true,
  validationSchema: object().shape({
    email: string().email('Invalid email').required('Required').trim(),
    password: string()
      .required('Required')
      .min(8, 'Should be at least 8characters'),
  }),
  heading: {
    title: "Let's sign you in.",
    subTitle: 'Welcome back',
  },
  rows: [
    {
      id: 'login_row0',
      fields: [
        {
          id: 'email',
          label: 'Email',
          type: INPUT_TYPES.TEXT,
          component: TextInput,
          inputProps: {
            autoCapitalize: 'none',
            autoComplete: 'email',
            autoCorrect: false,
            autoFocus: true,
          },
          keyboardType: KEYBOARD_TYPES.EMAIL,
          textContentType: TEXT_CONTENT_TYPES.EMAIL,
        },
      ],
    },
    {
      id: 'login_row1',
      fields: [
        {
          id: 'password',
          label: 'Password',
          type: INPUT_TYPES.PASSWORD,
          component: TextInput,
          inputProps: {
            autoCapitalize: 'none',
            autoComplete: 'password',
            autoCorrect: false,
          },
          textContentType: TEXT_CONTENT_TYPES.PASSWORD,
        },
      ],
    },
  ],
  actionFooter: [
    {
      link: 'AuthRegister',
      text: "Don't have an account yet? Sign up",
    },
  ],
  actions: {
    submit: {
      id: 'login_submit',
      component: Button,
      variant: 'primary',
      label: 'LOGIN',
    },
  },
  // validateOnBlur: false,
  // validateOnChange: false,
}
