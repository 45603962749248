import React, { createContext, useReducer } from 'react'
import { firestore } from 'config/firebase'
import { calcScores } from 'utils/scores'
import { DISPATCH } from 'state/consts'

const firebaseCollection = 'cycles'

export const QuestionsContext = createContext()
const test = false

const initialState = {
  current: 0,
  answers: [],
  max: test ? 1 : 0,
  scores: [],
  questions: [],
}

function questionsReducer(state, action) {
  switch (action.type) {
    case DISPATCH.QUESTIONS.LOAD: {
      return
    }
    case DISPATCH.QUESTIONS.RESET: {
      return { ...initialState }
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export function QuestionsProvider({ children, userUid }) {
  const [state, dispatch] = useReducer(questionsReducer, initialState)

  const value = { state, dispatch }
  return (
    <QuestionsContext.Provider value={value}>
      {children}
    </QuestionsContext.Provider>
  )
}
