import React, { useState } from 'react'
import {
  ArrowBackIcon,
  Box,
  Button,
  CloseIcon,
  Input,
  Modal,
  Pressable,
  ScrollView,
  Text,
} from 'native-base'
import { GatherIcon } from 'components/Icons'
import { ICycleStepProps } from 'state/interfaces'
import { KeyboardAvoidingView } from 'react-native'

interface Props extends ICycleStepProps {
  user: any
}

const Gather = ({ user, ...props }: Props) => {
  const {
    state: { cycle },
    dispatch: { hideGather, dispatch, cyclesGatherUpdate },
  } = props.useCycles
  const { colors, ui } = props.useTheme
  const [firstThought, setFirstThought] = useState('')
  const [secondThought, setSecondThought] = useState('')
  const [thirdThought, setThirdThought] = useState('')

  const handleSubmit = async (gatherInfo: any) => {
    if (!gatherInfo.firstThought) {
      return
    }
    cyclesGatherUpdate({
      cycle,
      gatherInfo,
      userUid: user.uid,
      dispatch,
    })
    props.onNext()
  }

  return (
    <Modal isOpen={true} backgroundColor={colors.white[500]} safeArea py={5}>
      <KeyboardAvoidingView behavior={'position'} style={{ flex: 1 }}>
        <ScrollView keyboardShouldPersistTaps="handled">
          <Box
            safeArea
            flex={1}
            width="100%"
            justifyContent="flex-start"
            px={6}
            style={{ position: 'relative', zIndex: 9 }}
          >
            <Box flexDirection="row" justifyContent="space-between">
              <Pressable
                onPress={() => {
                  hideGather({ dispatch })
                  props.onBack()
                }}
                justifyContent="center"
                alignItems="center"
              >
                <ArrowBackIcon size="6" />
              </Pressable>
              <Box style={{ alignItems: 'center' }}>
                <Text
                  style={{
                    fontSize: 20,
                    color: colors.tangaroa[500],
                  }}
                >
                  Gather
                </Text>
              </Box>
              <Pressable
                onPress={() => {
                  hideGather({ dispatch })
                  props.onClose()
                }}
                justifyContent="center"
                alignItems="center"
              >
                <CloseIcon size="4" />
              </Pressable>
            </Box>
            <Box py={6} height="70%" alignItems="flex-start">
              <Box flexDirection="row">
                <Box
                  px={4}
                  py={4}
                  mr={6}
                  style={{
                    backgroundColor: colors.hotpink[500],
                    borderRadius: 10,
                  }}
                >
                  <GatherIcon />
                </Box>
                <Box>
                  <Text
                    style={{
                      fontSize: 32,
                      color: colors.tangaroa[500],
                    }}
                  >
                    Day 1 Step 1
                  </Text>
                  <Text
                    style={{
                      fontSize: 32,
                      color: colors.tangaroa[500],
                    }}
                  >
                    Gather
                  </Text>
                </Box>
              </Box>
              <Box flexDirection="column" pt={6} pb={3}>
                <Text style={{ lineHeight: 24 }}>
                  Write down your top 3 thoughts that you feel you are
                  struggling the most with right now. You will be able to edit
                  and change these.
                </Text>
                <Text py={3} italic>
                  For example: “Body insecurities”
                </Text>
              </Box>
              <Box width="100%" py={0} mb={0}>
                <Box width="100%">
                  <Text pb={2}>Thought 1</Text>
                  <Input
                    value={firstThought}
                    onChangeText={(e) => setFirstThought(e)}
                    isFullWidth
                    style={{ opacity: 0.5 }}
                    placeholder="Body insecurities"
                    multiline
                    size="lg"
                  />
                </Box>
                <Box width="100%" py={3} my={4}>
                  <Text pb={2}>Thought 2</Text>
                  <Input
                    value={secondThought}
                    onChangeText={(e) => setSecondThought(e)}
                    isFullWidth
                    style={{ opacity: 0.5 }}
                    placeholder="Relationships"
                    multiline
                    size="lg"
                  />
                </Box>
                <Box width="100%">
                  <Text pb={2}>Thought 3</Text>
                  <Input
                    value={thirdThought}
                    onChangeText={(e) => setThirdThought(e)}
                    isFullWidth
                    style={{ opacity: 0.5 }}
                    placeholder="Career pressure"
                    multiline
                    size="lg"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </ScrollView>
      </KeyboardAvoidingView>
      <Box mx={6} maxWidth={1024} width="100%">
        <Button
          variant="primary"
          onPress={() =>
            handleSubmit({
              firstThought,
              secondThought,
              thirdThought,
            })
          }
          disabled={!firstThought || !secondThought || !thirdThought}
        >
          NEXT
        </Button>
      </Box>
    </Modal>
  )
}

export default Gather
