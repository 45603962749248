import React from 'react'
import { AppCalendar } from 'components/AppCalendar'
import { PageContainer } from 'components/Containers/Page'

import { navigate } from '../../../RootNavigation'
import { routeNames } from '../../../state/routeNames'

export default function CalendarScreen() {
  function onDayPress({ day, month, year }) {
    const date = new Date(year, month - 1, day)
    navigate(routeNames.CALENDAR_DAY, { timestamp: date.getTime() })
  }
  return (
    <PageContainer type="scrollable">
      <AppCalendar onDayPress={onDayPress} />
    </PageContainer>
  )
}
