import React from 'react'
import { string, object } from 'prop-types'
import { Box, Text, useTheme, Pressable } from 'native-base'
import { ImageBackground, Linking } from 'react-native'
import { usePlayer } from 'state/hooks/usePlayer'
import { DISPATCH } from 'state/consts'

export function PodcastTeaser({ title, subTitle, source }) {
  const { ui, colors, fontConfig } = useTheme()
  return (
    <ImageBackground source={source} resizeMode="cover">
      <Pressable
        onPress={() =>
          Linking.openURL('https://anchor.fm/cleaningupthementalmess').catch(
            (err) => console.error('Linking failed', err)
          )
        }
      >
        <Box
          height={100}
          width="100%"
          style={{
            position: 'absolute',
            bottom: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.8,
          }}
        >
          <Box
            style={{ backgroundColor: colors.tangaroa[500] }}
            width="90%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            borderRadius={ui.borderRadius}
          >
            <Text fontSize="xl" bold style={{ color: colors.white[500] }}>
              Listen on Anchor
            </Text>
          </Box>
        </Box>
        <Box
          borderRadius={ui.borderRadius}
          width="100%"
          height={500}
          alignItems="center"
          p={4}
        >
          <Text
            fontSize="3xl"
            bold
            style={{ ...fontConfig.center, color: colors.puertorico[500] }}
          >
            {title}
          </Text>
          <Text
            fontSize="md"
            bold
            mt={4}
            style={{ ...fontConfig.center, color: colors.puertorico[500] }}
          >
            {subTitle}
          </Text>
        </Box>
      </Pressable>
    </ImageBackground>
  )
}

PodcastTeaser.propTypes = {
  title: string.isRequired,
  subTitle: string.isRequired,
  source: object.isRequired,
}
